import { Observable, Subject } from 'rxjs';
import { Injectable} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdviceModalComponent } from '@shared/components/advice-modal/advice-modal-default/advice-modal.component';
import { AdviceModalCustomComponent } from '@shared/components/advice-modal/advice-modal-custom/advice-modal-custom.component';
import { InformativeLoadingModalComponent } from '@shared/components/informative-loading-modal/informative-loading-modal.component';
import { ExpandedTextareaComponent } from '@shared/components/expanded-textarea/expanded-textarea.component';
import { NewEvaluationComponent } from '@core/components/new-evaluation/new-evaluation.component';
import { OrganizationModalComponent } from '@shared/components/organization-modal/organization-modal.component';
import { ExportModalComponent } from '@shared/components/export-modal/export-modal.component';
import { ExportFormatModalComponent } from '@shared/components/export-format-modal/export-format-modal.component';
import { ImportModalComponent } from '@shared/components/import-modal/import-modal.component';
import { ResetPasswordComponent } from '@core/components/reset-password/reset-password.component';
import { ManageConfidentialModalComponent } from '@core/components/manage-confidential-modal/manage-confidential-modal.component';
import { ShareDocumentModalComponent } from '@shared/components/share-document-modal/share-document-modal.component';
import { RenameModalComponent } from '@shared/components/rename-modal/rename-modal.component';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { BasicDataModalComponent } from '@shared/components/basic-data-modal/basic-data-modal.component';
import { DuplicateModalComponent } from '@core/components/duplicate-modal/duplicate-modal.component';
import { HelpModalComponent } from '@core/components/help-modal/help-modal.component';
import { DraftEvaluationsComponent } from '@core/components/draft-evaluations/draft-evaluations.component';
import { DuplicateEvaluationComponent } from '@shared/components/duplicate-evaluation/duplicate-evaluation.component';
import { MigrationDataComponent } from '@shared/components/migration-data/migration-data.component';
import { PublicLinkPollComponent } from '@core/components/public-link-poll/public-link-poll.component';
import { ConfigureImageComponent } from '@core/components/configure-image/configure-image.component';
import { PublicLinkComponent } from '@core/components/public-link/public-link.component';
import { CorporativeInformationModalComponent } from '@core/components/corporative-information-modal/corporative-information-modal.component';
import { NotificationPreviewComponent } from '@core/components/notification-preview/notification-preview.component';
import { UploadDocumentsModalComponent } from '@shared/components/upload-document/upload-documents-modal/upload-documents-modal.component';
import { UploadDocumentsCustomModalComponent } from '@shared/components/upload-document/upload-documents-custom-modal/upload-documents-custom-modal.component';
import { ReferenceDocumentComponent } from '@core/components/reference-document/reference-document.component';
import { AssociatedDocumentationComponent } from '@shared/components/associated-documentation/associated-documentation.component';
import { PersonalizedNotificationModalComponent } from '@shared/components/personalized-notification-modal/personalized-notification-modal.component';
import { TaskModalComponent } from '@shared/components/task-modal/task-modal.component';
import { NewDocumentComponent } from '@shared/components/new-document/new-document.component';
import { NewSnippetModalComponent } from '@shared/components/new-snippet-modal/new-snippet-modal.component';
import { RelatedInformationModalComponent } from '@shared/components/related-information-modal/related-information-modal.component';
import { LawInfoComponent } from '@shared/components/law-info/law-info.component';
import { ShareInstanceComponent } from '@shared/components/share-instance/share-instance.component';
import { RelationshipReasonModalComponent } from '@shared/components/relationship-reason-modal/relationship-reason-modal.component';
import { AddAlertComponent } from '@shared/components/add-alert/add-alert.component';
import { ExpirationPeriodComponent } from '@shared/components/expiration-period/expiration-period.component';
import { EstablishPatternModalComponent } from '@shared/components/establish-pattern-modal/establish-pattern-modal.component';
import { ProcessModalComponent } from '@shared/components/process-modal/process-modal.component';
import { ProcessOpsModalComponent } from '@shared/components/process-ops-modal/process-ops-modal.component';
import { ConceptInfoDefaultComponent } from '@shared/components/concept-info/concept-info-default/concept-info-default.component';
import { ConceptInfoCustomComponent } from '@shared/components/concept-info/concept-info-custom/concept-info-custom.component';
import { FormulaSettingModalComponent } from '@core/components/formula-setting-modal/formula-setting-modal.component';
import { UpDocumentComponent } from '@core/components/up-document/up-document.component';
import { EvaluationShareComponent } from '@core/components/evaluation-share/evaluation-share.component';
import { GenerateDocumentComponent } from '@shared/components/generate-document/generate-document-default/generate-document.component';
import { NotificationModalComponent } from '@shared/components/notification-modal/notification-modal.component';
import { VideoPlayerComponent } from '@shared/components/video-player/video-player.component';
import { AttachDocumentModalComponent } from '@shared/components/attach-document-modal/attach-document-modal.component';
import { AlertDetailComponent } from '@shared/components/alert-detail/alert-detail.component';
import { RoleAssignmentModalComponent } from '@core/components/role-assignment-modal/role-assignment-modal.component';
import { SendReminderComponent } from '@core/components/send-reminder/send-reminder.component';
import { ModalMoreInfoComponent } from '@shared/components/modal-more-info/modal-more-info.component';
import { NewReportComponent } from '@core/components/new-report/new-report.component';
import { NewInstantVideoconferenceComponent } from '@core/components/new-instant-videoconference/new-instant-videoconference.component';
import { DocumentHistoricComponent } from '@core/components/document-historic/document-historic.component';
import { NewVersionComponent } from '@shared/components/new-version/new-version.component';
import { CertifyDocumentComponent } from '@core/components/certify-document/certify-document.component';
import { RequestTrackingComponent } from '@core/components/request-tracking/request-tracking.component';
import { DuplicateDocumentComponent } from '@core/components/duplicate-document/duplicate-document.component';
import { DuplicateDocumentationComponent } from '@core/components/duplicate-documentation/duplicate-documentation.component';
import { UploadReportComponent } from '@core/components/upload-report/upload-report.component';
import { RoleErrorModalComponent } from '@shared/components/role-error-modal/role-error-modal.component';
import { QrModalComponent } from '@shared/components/qr-modal/qr-modal.component';
import { ExportResultReportComponent } from '@core/components/export-result-report/export-result-report.component';
import { ViewExtendedReportComponent } from '@core/components/view-extended-report/view-extended-report.component';
import { FieldAssignmentsModalComponent } from '@shared/components/field-assignments-modal/field-assignments-modal.component';
import { AddAssignmentModalComponent } from '@core/components/add-assignment-modal/add-assignment-modal.component';
import { ProcessEditModalComponent } from '@shared/components/process-edit-modal/process-edit-modal.component';
import { StatesTaskHelpModalComponent } from '@core/components/states-task-help-modal/states-task-help-modal.component';
import { ReportExportModalComponent } from '@shared/components/report-export-modal/report-export-modal.component';
import { LinkModalComponent } from '@core/components/link-modal/link-modal.component';
import { AutomaticPostProcessModalComponent } from '@core/components/automatic-post-process-modal/automatic-post-process-modal.component';
import { BlockedEditionModalComponent } from '@core/components/blocked-edition-modal/blocked-edition-modal.component';
import { ProtectionDataModalComponent } from '@core/components/protection-data-modal/protection-data-modal.component';
import { ConcursalFormsModalComponent } from '@core/components/concursal-forms-modal/concursal-forms-modal.component';
import { ChangeStateModalComponent } from '@shared/components/change-state-modal/change-state-modal.component';
import { AssignManagerComponent } from '@shared/components/assign-manager/assign-manager.component';
import { GenerateDocumentCustomComponent } from '@shared/components/generate-document/generate-document-custom/generate-document-custom.component';
import { ConsultListsModalComponent } from '@shared/components/consult-lists-modal/consult-lists-modal.component';
import { MoveModalComponent } from '@core/components/move-modal/move-modal.component';
import { FinishBlockModalComponent } from '@shared/components/finish-block-modal/finish-block-modal.component';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AdviceListModalComponent } from '@shared/components/advice-modal/advice-list-modal/advice-list-modal.component';
import { RecordAudioModalComponent } from '@shared/components/record-audio-modal/record-audio-default-modal/record-audio-modal.component';
import { ListenRecordComponent } from '@shared/components/listen-record/listen-record.component';
import { RecordAudioCustomModalComponent } from '@shared/components/record-audio-modal/record-audio-custom-modal/record-audio-custom-modal.component';
import { FormConfigurationModalComponent } from '@core/components/form-configuration-modal/form-configuration-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {
  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory
  ) {}
  modalRefCollection: any = [];
  isLanding: boolean = false;//this.customLanding.isLanding();
  skin:string;// = this.isLanding?'landing-custom':this.globalCfg.skin;
  params:Params = this.route.snapshot.params;

  private baseModal(component:any, windowConfig:any, configCallback?:Function):Observable<void>{
    this.isLanding = this.customLanding.isLanding();
    this.skin = this.isLanding?'landing-custom':this.globalCfg.skin;
    const _windowConfig:NgbModalOptions = {
      keyboard: windowConfig.keyboard,
      backdrop: windowConfig.backdrop? windowConfig.backdrop : 'static',
      size: windowConfig.size || 'lg',
      windowClass: 'compliance__' + this.skin + ' ' + windowConfig.class
    };

    const modalRef:NgbModalRef = this.modal.open(component, _windowConfig);
    if(configCallback) configCallback(modalRef);

    const modalSubject:Subject<void> = new Subject<void>();
    modalRef.result.then((result:any) => {
      modalSubject.next(result)
    }, (reason:any) => {});
    this.modalRefCollection.push(modalRef);

    return modalSubject.asObservable();
  }

  adviceModal(questionText:any, adviceType:string, from:string='', list:any=[], innerLink: any=undefined, expand: boolean = false) {
    let windowConfig:any = {class:'advice-modal'};
    if(this.customLanding.isLanding()){
      windowConfig.class += '__custom-window';
    } else if(list && list.length) {
      windowConfig.class += '--list';
    }
    let component = this.customLanding.isLanding()? AdviceModalCustomComponent : AdviceModalComponent;
    return this.baseModal(component, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.questionText = questionText;
      componentInstance.adviceType = adviceType;
      componentInstance.from = from;
      componentInstance.list = list;
      componentInstance.innerLink = innerLink;
      componentInstance.expand = expand;
    });
  }

  adviceListModal(questionText:any, adviceType:string, list:any=[], innerLink: any=undefined, expand: boolean = false) {
    let windowConfig:any = {class:'advice-modal'};
    return this.baseModal(AdviceListModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.questionText = questionText;
      componentInstance.adviceType = adviceType;
      componentInstance.list = list;
      componentInstance.innerLink = innerLink;
      componentInstance.expand = expand;
    });
  }

  informativeLoadingModal(itemSelected:any) {
    return this.baseModal(InformativeLoadingModalComponent, {class:'informative-loading-modal__window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.itemSelected = itemSelected;
    });
  }

  expandTextarea(text:string, field:any) {
    let windowConfig = {
      class:'expanded-textarea' + (field.fieldId === 'add-alert-notification-personalized' ? ' expanded-textarea--notification-personalized' : '')
    };
    return this.baseModal(ExpandedTextareaComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.text = text;
      componentInstance.field = field;
    });
  }

  createNewEvaluation(configuration:any=null){
    return this.baseModal(NewEvaluationComponent, {class:'new-evaluation__modal-window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.configuration = configuration;
    });
  }

  organizationModal(configuration:any){
    return this.baseModal(OrganizationModalComponent, {class:'organization-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  exportModal(configuration:any,) {
    return this.baseModal(ExportModalComponent, {class:'export-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  exportFormatModal(configuration:any) {
    return this.baseModal(ExportFormatModalComponent, {class:'export-format-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  importModal(type:string='', concept:any=undefined) {
    return this.baseModal(ImportModalComponent, {class:'users-admin-import__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.type = type;
    });
  }

  migrationData(isClient:boolean=false){
    return this.baseModal(MigrationDataComponent, {class:'migration-data__window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.isClient = isClient;
    });
  }

  resetPassword(){
    return this.baseModal(ResetPasswordComponent, {class:'reset-password'});
  }

  manageConfidentialModal(itemToConfidential:any){
    return this.baseModal(ManageConfidentialModalComponent, {class:'manage-confidential-modal'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.itemToConfidential = itemToConfidential;
    });
  }

  documentShare(configuration:any){
    let windowConfig = {class:'share-document' + (configuration.type === 'shareFieldContainer' ? ' share-document--fieldcontainer' : '')};
    return this.baseModal(ShareDocumentModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.configuration = configuration;
    });
  }

  renameModal(newItem:any, list:any=[], otherList:any=[]){
    let windowConfig = {class:'rename-modal__' + (this.customLanding.isLanding() ? 'custom-' : '') + 'window'};
    return this.baseModal(RenameModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.newItem = newItem;
      componentInstance.list = list;
      componentInstance.otherList = otherList;
    });
  }

  basicDataModal(concept:any, option:any){
    return this.baseModal(BasicDataModalComponent, {class:'basic-data-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.option = option;
    });
  }

  duplicateModal(evaluationList:any, duplicated:any, evaluation:any, listFolders:any){
    return this.baseModal(DuplicateModalComponent, {class:'duplicate-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.evaluationList = evaluationList;
      componentInstance.duplicated = duplicated;
      componentInstance.evaluation = evaluation;
      componentInstance.listFolders = listFolders;
    });
  }

  draftEvaluationsModal(toOther: any, draftList:any){
    return this.baseModal(DraftEvaluationsComponent, {class:'law-info__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.toOther = toOther;
      componentInstance.draftList = draftList;
    });
  }

  duplicateEvaluationModal(evaluation:any){
    let windowConfig = {class:'duplicate-evaluation__window' + (evaluation.specialModal ? '-special' : '')};
    return this.baseModal(DuplicateEvaluationComponent, windowConfig, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.evaluation = evaluation;
    });
  }

  configureImage(logo:any, from:any, preSelectLogos:any){
    return this.baseModal(ConfigureImageComponent, {class:'configure-image__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.logo = logo;
      componentInstance.from = from;
      componentInstance.preSelectLogos = preSelectLogos;
    });
  }

  publicLinkPoll(poll:any, evaluationInfo:any){
    return this.baseModal(PublicLinkPollComponent, {class:'public-link-poll__window', size:'xl'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.poll = poll;
      componentInstance.evaluationInfo = evaluationInfo;
    });
  }

  publicLink(concept:any, evaluationInfo:any){
    return this.baseModal(PublicLinkComponent, {class:'public-link__window', size:'xl'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.evaluationInfo = evaluationInfo;
    });
  }

  corporativeInformation(concept:any, clientId:any, corporativeInformation:any){
    return this.baseModal(CorporativeInformationModalComponent, {class:'corporative-information-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.clientId = clientId;
      componentInstance.corporativeInformation = corporativeInformation;
    });
  }

  notificationPreview(notificationTypeId:any, entityType:any, id:any, data:any = null){
    return this.baseModal(NotificationPreviewComponent, {class:'add-alert__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.notificationTypeId = notificationTypeId;
      componentInstance.entityType = entityType;
      componentInstance.id = id;
      componentInstance.data = data;
    });
  }

  uploadDocuments(type:any, files:any, documentationList:any, itemSelected:any, listSelect:any=[]){
    let windowConfig = {class:'upload-document' + (this.customLanding.isLanding() ? ' upload-document--landing-custom' : '')};
    let component = this.customLanding.isLanding()? UploadDocumentsCustomModalComponent : UploadDocumentsModalComponent;
    return this.baseModal(component, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.type = type;
      componentInstance.files = files;
      componentInstance.documentationList = documentationList;
      componentInstance.itemSelected = itemSelected;
      componentInstance.listSelect = listSelect;
    });
  }

  referenceDocument(configuration:any){
    return this.baseModal(ReferenceDocumentComponent, {class:'reference-document-modal'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  // processModal(evaluationId:any, concept:any, conceptObjectId:any, canChangeState:any, canAddAlerts:any, canAddTask:any, preview:any, onUpdate:any = null){
  processModal(configuration: any){
    return this.baseModal(ProcessModalComponent, {class:'process-modal' + (configuration.preview ? ' process-modal--preview' : '')}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
      // componentInstance.evaluationId = evaluationId;
      // componentInstance.concept = concept;
      // componentInstance.conceptObjectId = conceptObjectId;
      // componentInstance.canChangeState = canChangeState;
      // componentInstance.canAddAlerts = canAddAlerts;
      // componentInstance.canAddTask = canAddTask;
      // componentInstance.onUpdate = onUpdate;
    });
  }

  processOpsModal(evaluationInfo:any, concept:any, from:any){
    return this.baseModal(ProcessOpsModalComponent, {class:'process-ops-modal__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.evaluationInfo = evaluationInfo;
      componentInstance.from = from;
      componentInstance.concept = concept;
    });
  }

  associatedDocumentation(process:any, concept:any, listSelect:any, from:any, document:any=null){
    return this.baseModal(AssociatedDocumentationComponent, {class:'associated-documentation'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.process = process;
      componentInstance.from = from;
      componentInstance.concept = concept;
      componentInstance.listSelect = listSelect;
      componentInstance.document = document;
    });
  }

  personzalizedNotificationModal(concept:any, state:any){
    return this.baseModal(PersonalizedNotificationModalComponent, {class:'personalized-notification-modal'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.state = state;
      componentInstance.concept = concept;
    });
  }

  taskModal(originalTask:any, concept: any){
    return this.baseModal(TaskModalComponent, {class:'process-general-task__window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.originalTask = originalTask;
      modalRef.componentInstance.concept = concept;
    });
  }

  newDocument(list:any, concept:any, type:any, listDocuments:any, listSelect:any=[]){
    return this.baseModal(NewDocumentComponent, {class:'new-document'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.list = list;
      componentInstance.concept = concept;
      componentInstance.type = type;
      componentInstance.listDocuments = listDocuments;
      componentInstance.listSelect = listSelect;
    });
  }

  newSnippetModal(list:any, configuration:any){
    let windowConfig = {class:'new-snippet-modal__window' + (configuration.type == 'new-fragment' ? '--new-fragment' : '')};
    return this.baseModal(NewSnippetModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.list = list;
      componentInstance.configuration = configuration;
    });
  }

  relatedInformationModal(infoRelated: any){
    let windowConfig = {class:'related-information-modal' + (infoRelated.length > 1 ? '--big' : '')};
    return this.baseModal(RelatedInformationModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.infoRelated = infoRelated;
    });
  }

  lawInfoModal(registerEvent:any={}, references:any=[], selectReferenceList:any=[], selected:any= null){
    let windowConfig = {class:'law-info__' + (this.customLanding.isLanding() ? 'custom-' : '') + 'window'};
    return this.baseModal(LawInfoComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.registerEvent = registerEvent;
      componentInstance.references = references;
      componentInstance.selectReferenceList = selectReferenceList;
      componentInstance.selected = selected;
    });
  }

  shareInstance(concept:any, existingTask:any, from:any =''){
    return this.baseModal(ShareInstanceComponent, {class:'share-instance__window'}, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.existingTask = existingTask;
      componentInstance.from = from;
    });
  }

  relationshipReasonModal(text:any){
    return this.baseModal(RelationshipReasonModalComponent, {class:'relationship-reason-modal__window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.text = text;
    });
  }

  addAlert(configuration:any){
    let windowConfig = {class:'add-alert__window'};
    return this.baseModal(AddAlertComponent, windowConfig, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.configuration = configuration;
    });
  }

  expirationPeriod(concept:any){
    return this.baseModal(ExpirationPeriodComponent, {class:'expiration-period__window'}, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.concept = concept;
    });
  }

  setPattern(patternList: any, item: any, date: any = null){
    let windowConfig = {
      class:'establish-pattern__window' + (this.params['component'] != 'concept' ? ' establish-pattern__window--concept' : '')
    };
    return this.baseModal(EstablishPatternModalComponent, windowConfig, (modalRef:NgbModalRef) => {
      let componentInstance = modalRef.componentInstance;
      componentInstance.patternList = patternList;
      componentInstance.item = item;
      componentInstance.date = date;
    });
  }

  conceptInfo(concept:any, custom:any=null){
    let windowConfig = {class:'concept-info__' + (custom != null ? 'custom-' : '') + 'window'};
    let component = this.customLanding.isLanding()? ConceptInfoCustomComponent:ConceptInfoDefaultComponent;
    return this.baseModal(component, windowConfig, (modalRef:NgbModalRef) => {
      modalRef.componentInstance.concept = concept;
    });
  }

  helpModal(){
    let windowConfig = {class: 'help-modal__window', size: 'xl'};
    return this.baseModal(HelpModalComponent, windowConfig);
  }

  modalFormulaSettings(conceptFields:any, formulaItems:any, originForm:any){
    let windowConfig = {class: 'formula-settings-modal__window', size: 'xl'};
    return this.baseModal(FormulaSettingModalComponent, windowConfig, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.conceptFields = conceptFields;
      componentInstance.formulaItems = formulaItems;
      componentInstance.originForm = originForm;
    });
  }

  upDocument(type:any){
    return this.baseModal(UpDocumentComponent, {class: 'up-document'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.type = type;
    });
  }

  evaluationShare(evaluation:any){
    return this.baseModal(EvaluationShareComponent, {class: 'evaluation-share'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.evaluation = evaluation;
    });
  }

  finishBlockModal(configuration:any){
    return this.baseModal(FinishBlockModalComponent, {class: 'finish-block-modal__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.configuration = configuration;
    });
  }

  generateDocument(predefinedTemplate:any, concept:any){
    let windowConfig = {class: 'generate-document' + (this.customLanding.isLanding() ? ' generate-document--custom' : '')};
    let component = this.customLanding.isLanding()? GenerateDocumentCustomComponent : GenerateDocumentComponent;
    return this.baseModal(component, windowConfig, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.predefinedTemplate = predefinedTemplate;
      componentInstance.concept = concept;
    });
  }

  notificationModal(notification:any){
    let windowConfig = {class:this.customLanding.isLanding() ? 'notification-modal__custom' : 'notification-modal'};
    return this.baseModal(NotificationModalComponent, windowConfig, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.notification = notification;
    });
  }

  openVideo(configuration: any){
    return this.baseModal(VideoPlayerComponent, {class: 'video-player__modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  attachDocumentModal(item:any, list:any){
    return this.baseModal(AttachDocumentModalComponent, {class: 'attach-document-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.item = item
      componentInstance.list = list
    });
  }

  alertDetail(type:any, alert:any){
    return this.baseModal(AlertDetailComponent, {class: 'alert-detail_window'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.type = type
      componentInstance.alert = alert
    });
  }

  roleAssignmentModal(configuration:any){
    return this.baseModal(RoleAssignmentModalComponent, {class: 'role-assignment-modal'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.configuration = configuration;
    });
  }

  sendReminder(task:any){
    return this.baseModal(SendReminderComponent, {class: 'send-reminder__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.task = task;
    });
  }

  modalMoreInfo(configuration:any){
    let windowConfig = {class: 'modal-more-info', size: 'sm'};
    return this.baseModal(ModalMoreInfoComponent, windowConfig, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  createNewReport(evaluationList:any, filteredProduct: any){
    return this.baseModal(NewReportComponent, {class: 'new-report__window'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.evaluationList = evaluationList;
      componentInstance.filteredProduct = filteredProduct;
    });
  }

  newInstantVideoconference(){
    return this.baseModal(NewInstantVideoconferenceComponent, {class: 'new-instant-videoconference__window'});
  }

  newVersion(){
    return this.baseModal(NewVersionComponent, {class: 'new-version__window'});
  }

  documentHistoric(document:any){
    return this.baseModal(DocumentHistoricComponent, {class: 'document-historic__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.document = document;
    });
  }

  certifyDocumentModal(){
    return this.baseModal(CertifyDocumentComponent, {class: 'certify-document'});
  }

  requestTrackingModal(document:any){
    return this.baseModal(RequestTrackingComponent, {class: 'request-tracking'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.document = document;
    });
  }

  moveModal(itemSelected:any, concept:any, listDocument:any){
    return this.baseModal(MoveModalComponent, {class: 'move-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.itemSelected = itemSelected;
      componentInstance.concept = concept;
      componentInstance.listDocument = listDocument;
    });
  }

  duplicateDocument(document:any, list:any){
    return this.baseModal(DuplicateDocumentComponent, {class: 'duplicate-document__window'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.document = document;
      componentInstance.list = list;
    });
  }

  duplicateDocumentation(concept:any, listOriginDocuments:any, folderId:any){
    return this.baseModal(DuplicateDocumentationComponent, {class: 'duplicate-documentation'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.listOriginDocuments = listOriginDocuments;
      componentInstance.folderId = folderId;
    });
  }

  uploadReport(reportVersions:any){
    return this.baseModal(UploadReportComponent, {class: 'upload-report__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.reportVersions = reportVersions;
    });
  }

  roleErrorModal(configuration:any){
    return this.baseModal(RoleErrorModalComponent, {class: 'role-error-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.configuration = configuration;
    });
  }

  qrModal(url:any){
    let windowConfig = {class: 'qr-modal__window', size: 'sm'};
    return this.baseModal(QrModalComponent, windowConfig, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.url = url;
    });
  }

  exportResultReport(exportFieldsList: any, concept: any){
    return this.baseModal(ExportResultReportComponent, {class: 'export-result-report'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.exportFieldsList = exportFieldsList;
      componentInstance.concept = concept;
    });
  }

  viewExtendedReport(selectedField: any){
    return this.baseModal(ViewExtendedReportComponent, {class: 'view-extended-report'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.selectedField = selectedField;
    });
  }

  fieldAssignments(data:any) {
    return this.baseModal(FieldAssignmentsModalComponent, {class: 'field-assignments-modal__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.data = data;
    });
  }

  addAssignment(newCustomReportField:any) {
    return this.baseModal(AddAssignmentModalComponent, {class: 'add-assignment'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.newCustomReportField = newCustomReportField;
    });
  }

  processEdit(evaluationId:any, concept:any) {
    return this.baseModal(ProcessEditModalComponent, {class: 'process-edit-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.evaluationId = evaluationId;
      componentInstance.concept = concept;
    });
  }

  statesTaskHelp(){
    let windowClass = {class:'states-task-help__' + (this.customLanding.isLanding() ? 'custom-' : '') + 'window'};
    return this.baseModal(StatesTaskHelpModalComponent, windowClass);
  }

  reportExport(typeReport:any){
    return this.baseModal(ReportExportModalComponent, {class: 'report-export__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.typeReport = typeReport;
    });
  }

  linkModal(node:any, selectedContent:any){
    return this.baseModal(LinkModalComponent, {class: 'link-modal__window'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.node = node;
      modalRef.componentInstance.selectedContent = selectedContent;
    });
  }

  automaticPostProcess(concept:any, item:any){
    return this.baseModal(AutomaticPostProcessModalComponent, {class: 'automatic-post-process-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.item = item;
    });
  }

  blockedEditionModal(concept:any, processStatesSelected:any){
    return this.baseModal(BlockedEditionModalComponent, {class: 'blocked-edition-modal'}, (modalRef:NgbModalRef) =>{
      let componentInstance = modalRef.componentInstance;
      componentInstance.concept = concept;
      componentInstance.processStatesSelected = processStatesSelected;
    });
  }

  protectionDataModal(configuration:any){
    return this.baseModal(ProtectionDataModalComponent, {class: 'protection-data-modal'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.configuration = configuration;
    });
  }

  concursalForms(configuration:any) {
      return this.baseModal(ConcursalFormsModalComponent, {class: 'concursal-forms__modal-window'}, (modalRef:NgbModalRef) =>{
        let componentInstance = modalRef.componentInstance;
        componentInstance.configuration = configuration;
      });
  }

  changeState(evaluateConcept:any){
    let windowConfig = {class: 'change-state-modal', size: 'xl'};
    return this.baseModal(ChangeStateModalComponent, windowConfig, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.evaluateConcept = evaluateConcept;
    });
  }

  assignManager(count:any){
    return this.baseModal(AssignManagerComponent, {class: 'assign-manager'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.count = count;
    });
  }

  consultListsModal(configuration:any){
    return this.baseModal(ConsultListsModalComponent, {class: 'consult-lists-modal'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.configuration = configuration;
    });
  }

  recordAudioModal(concept:any){
    let component = this.customLanding.isLanding()? RecordAudioCustomModalComponent : RecordAudioModalComponent;
    let className = this.customLanding.isLanding()? 'record-audio-modal__window--landing' : 'record-audio-modal__window';
    return this.baseModal(component, {class: className, size: 'sm'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.concept = concept;
    });
  }

  listenRecord(voiceRecord:any, isLanding:boolean){
    return this.baseModal(ListenRecordComponent, { class: 'listen-record__window', size: 'xs'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.voiceRecord = voiceRecord;
      modalRef.componentInstance.isLanding = isLanding;
    });
  }

  formConfigurationModal(configuration:any){
    return this.baseModal(FormConfigurationModalComponent, { class: 'form-configuration-modal'}, (modalRef:NgbModalRef) =>{
      modalRef.componentInstance.configuration = configuration;
    });
  }

  close(){
    this.modalRefCollection.forEach((modalRef: any) => {
        modalRef.close({result: 'cancel'});
    });
  }
}
