import { Component, OnDestroy} from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { RecordAudioParentService } from "../utils/record-audio-parent.service";
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'record-audio-custom-modal',
  templateUrl: './record-audio-custom-modal.component.html'
})
export class RecordAudioCustomModalComponent implements OnDestroy{
  recordAudioModal: any = this.recordAudioParent.configureInit()
  custom: any = this.customLanding.getCustom();
  constructor(private activeModal: NgbActiveModal,
    private recordAudioParent: RecordAudioParentService,
    private customLanding: CustomLandingFactory) {
      this.recordAudioParent.initSubscribers(this)
    }
 
  acceptDistorsionTerms(){
    this.recordAudioParent.acceptDistorsionTerms(this)
  }

  recVoice(){
    !this.recordAudioModal.isRecording && !this.recordAudioModal.blobUrl? this.recordAudioParent.startRecording(this) : this.recordAudioParent.stopRecording(this);
  }

  clearRecordedData() {
    this.recordAudioModal.blobUrl = null;
  }

  playAudio(){
    this.recordAudioParent.playAudio(this);
  }

  discardVoiceRecord(){
    this.recordAudioParent.discardVoiceRecord(this)
  }

  handleAccept() {
    if(this.recordAudioModal.player !== null){
      this.recordAudioModal.player.pause();
      this.recordAudioModal.player.currentTime = 0;
    }
    this.activeModal.close({
      result: 'ok',
      player: this.recordAudioModal.player,
      text: this.recordAudioModal.audioToText,
      maxTime: this.recordAudioModal.maxTime,
      secondsRecord: this.recordAudioModal.secondsRecord,
      name: this.recordAudioModal.blobName,
      recordingFile: this.recordAudioModal.recordingFile,
      file: this.recordAudioModal.file
    });
  }

  handleDismiss() {
    this.recordAudioParent.handleDismiss(this)
  }

  ngOnDestroy(): void {
    this.recordAudioParent.abortRecording(this);
    this.recordAudioModal.recordingFailed.unsubscribe()
    this.recordAudioModal.getRecordedTime.unsubscribe()
    this.recordAudioModal.getRecordedBlob.unsubscribe()
  }
}
