<div class="export-format-modal">
    <div class="common__modal-header-block">
        <i class="lf-icon-send common__icon-header"></i>
        <span class="common__text-header">{{exportFormat.title}}</span>
        <i class="lf-icon-close export-format-modal__close-button" (click)="handleDismiss()"></i>
    </div>
    <div class="export-format-modal__body-container">
        <p class="common__title-block-modal" [afTranslate]="'export-format-modal.body-text'"></p>
        <div class="export-format-modal__formats-container">
            <div class="export-format-modal__format"
              *ngIf="configuration.exportToWord">
                <i class="export-format-modal__format-radio"
                  [ngClass]="exportFormat.selectedFormat === 'docx' ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"
                  (click)="selectFormat('docx')"></i>
                <div class="export-format-modal__format-body">
                    <span class="export-format-modal__format-title" [afTranslate]="'export-format-modal.format-titles.word'" (click)="selectFormat('docx')"></span>
                    <p class="export-format-modal__format-text">{{'export-format-modal.format-texts.word' | afTranslate}}</p>
                    <div class="export-format-modal__format-icon">
                      <i class="lf-icon-send-word"></i>
                    </div>
                </div>
            </div>
            <div class="export-format-modal__format">
                <i class="export-format-modal__format-radio"
                   [ngClass]="exportFormat.selectedFormat === 'xls' ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"
                   (click)="selectFormat('xls')"></i>
                <div class="export-format-modal__format-body">
                    <span class="export-format-modal__format-title" [afTranslate]="'export-format-modal.format-titles.title-1'" (click)="selectFormat('xls')"></span>
                    <p class="export-format-modal__format-text">{{'export-format-modal.format-texts.text-1' | afTranslate}}</p>
                    <div class="export-format-modal__format-icon">
                      <i class="lf-icon-send-excel"></i>
                    </div>
                </div>
            </div>
            <div class="export-format-modal__format">
                <i class="export-format-modal__format-radio"
                   [ngClass]="exportFormat.selectedFormat === 'pdf' ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"
                   (click)="selectFormat('pdf')"></i>
                <div class="export-format-modal__format-body">
                    <span class="export-format-modal__format-title" [afTranslate]="'export-format-modal.format-titles.title-2'" (click)="selectFormat('pdf')"></span>
                    <p class="export-format-modal__format-text">{{'export-format-modal.format-texts.text-2' | afTranslate}}</p>
                    <div class="export-format-modal__format-icon">
                      <i class="lf-icon-send-pdf"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="export-format-modal__buttons-container">
            <button class="common__button common__button--clean" [afTranslate]="'common.cancel'" (click)="handleDismiss()"></button>
            <button class="common__button common__button--active" [afTranslate]="'common.export'" [disabled]="!exportFormat.selectedFormat" (click)="handleAccept()"></button>
        </div>
    </div>
</div>
