import { Injectable } from '@angular/core';

import $ from 'jquery';
import moment from 'moment';
import { cloneDeep } from "lodash";
import { defer } from 'rxjs';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AppLanguageFactory } from '@shared/factories/app-language/app-language.factory';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { RegisterMixtapeService } from '../register-mixpanel/register-mixtape.service';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {
  constructor(public context: ContextFactory,
        private globalCfg: GlobalCfgFactory,
        private appLanguage: AppLanguageFactory,
        private translate: AfTranslateFactory,
        private customLanding: CustomLandingFactory,
        private route: ActivatedRoute,
        private userPermisions: UserPermisionsFactory,
        private registerEventService: RegisterMixtapeService,
        private convertNumbToName: ConvertNumToNameFactory,
        private stateManagement: StateManagementFactory,
        private router: Router) { }

    deleteElement(item:any, list:any, prop:string){
      let findIndex = list.findIndex((listItem: any)=>{return listItem[prop] === item[prop]});
      list.splice(findIndex, 1);
      return this.createNewArray(list);
    }

    createNewArray(list:any){
        let newList:any = [];
        list.forEach((item:any) => {
            let newItem: any = {}
            for (let [key, value] of Object.entries(item)) {
                newItem[key] = value;
            }
            newList.push(newItem);
        });
        return newList;
    }

    replaceElement(item: any, list: any, prop: string, newItem: object){
      let findIndex = list.findIndex((listItem: any)=>{return listItem[prop] === item[prop]});
      list.splice(findIndex, 1, newItem);
      return list;
    }

    replaceElementTree(item:any, list:any, prop:string){
        for(let i = 0; i < list.length; i++){
            if( item[prop] ===  list[i][prop]){
                list.splice(i, 1, item);
                break;
            }
            if(list[i].children && list[i].children.length){
                this.replaceElementTree(item, list[i].children, prop);
            }
        }
        return list;
    }

    addElement(list: any, newItem: object){
      return list.splice(list.length, 1, newItem);
    }

    editList(list: any, id: any, name: string){
      list.forEach((element: any) => {
        element.listValueId = element[id];
        element.text = element[name]
      });
      return list
    }

    createId(){
      let word = '';
      let firstWord='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let myIdWord='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
      for(let i = 0; i < 15; i++){
          if(i === 0){
            word += firstWord.charAt(Math.floor(Math.random()* firstWord.length));
          }else{
            word += myIdWord.charAt(Math.floor(Math.random()* myIdWord.length));
          }
      }
      return word;
    }

    configureFilials(list: any){
      list.forEach((child: any) => {
        if(child.parentId){
          this.findParent(child, list);
        }
      });
      return list;
    }


    findParent(child: any, list: any){
      list.forEach((element:any) => {
        if(child.parentId === element.clientId){
          child.parentName = element.parentId? element.parentName : element.name;
          element.children?element.children.push(child) : element.children = [child]
        }
      });
    }

    extractToArray(property: any, array: any, id:any){
      return array.filter((item:any)=> item[id] === property)[0];
        }

    extractInnerHTML(message:any){
      let temporalDiv = document.createElement("div");
      temporalDiv.innerHTML = message;
      let innerHTML = temporalDiv.textContent || temporalDiv.innerText || "";
      return innerHTML;
    }

    checkIsIntegerNumber(text: string){
      let numbers = '0123456789';
      for(let i = 0; i < text.length; i++){
          if(numbers.indexOf(text.charAt(i),0) === -1){
              return false;
          }
      }
      return true;
    }

    checkRequiredFields(concept:any, userPublic:any){
      let errors:any ={
          commonErrors: 0,
          blockedErrors: 0,
          validateErrors: 0
      }
      let isLanding: Boolean = this.customLanding.isLanding();
      if(this.globalCfg.skin === 'uk-steel-blue' && !isLanding){
          return errors;
      }
      concept.fieldContainers.forEach((fieldContainer: any) => {
          if(!fieldContainer.hidden){
            fieldContainer.fields.forEach((fieldContainerField: any) => {
                  if(concept.errorInForm?.length && fieldContainerField.errorInForm){
                      fieldContainerField.error = true;
                      errors.validateErrors++;
                  }else if(!concept.errorInForm?.length && concept.hasBlockerFields && !isLanding && fieldContainerField.blocker && this.checkField(fieldContainerField)){
                      fieldContainerField.error = true;
                      fieldContainerField.blockError = true;
                      errors.blockedErrors++;
                  }
              })
              fieldContainer.subContainers.forEach((subContainer: any) => {
                subContainer.fields.forEach((subcontainerField: any) => {
                      if(concept.errorInForm?.length && subcontainerField.errorInForm){
                          subcontainerField.error = true;
                          errors.validateErrors++;
                      }else if(!concept.errorInForm?.length && concept.hasBlockerFields && !isLanding && subcontainerField.blocker && this.checkField(subcontainerField)){
                          subcontainerField.error = true;
                          subcontainerField.blockError = true;
                          errors.blockedErrors++;
                      }
                  })
              })
          }
      })
      if(!errors.validateErrors && !errors.blockedErrors){
          errors.commonErrors =  this.checkCommonErrors(concept, userPublic)
      }
      return errors;
    }

    checkMultipleSubcontainer(subcontainer: any, concept: any){
        let errorsSubcontainer = 0;
        let subContainerMultiple = concept.subContainerMultiple.get(subcontainer.fieldContainerId);
        subContainerMultiple.forEach((subContainer: any) => {
          subContainer.fields.forEach((field:any) => {
            if(field.show){
              field.error = field.fieldContentTypeId === 'emai'?field.error:false;
              if((!field.notCurrentValue && field.required) && this.checkField(field)){
                  field.error = true;
                  errorsSubcontainer++;
              }
          }
          });
        });
        return errorsSubcontainer;
    }

    checkField(field:any):any{
        if(field.noEditable){
            return false;
        }else if(field.evaluatedValue){
            switch(field.fieldTypeId){
              case 'list':
              case 'listp':
                return !field.multiple? (field.evaluatedValue.floatvalue === null): (field.evaluatedValue.nvarcharValue == null || field.evaluatedValue.nvarcharValue.trim() == '')
              case 'user':
                  return field.evaluatedValue.nvarcharValue === null;
              case 'date':
                  return field.evaluatedValue.datetimeValue === null;
              case 'num':
              case 'money':
                  if(!(field.evaluatedValue.floatvalue === null || field.evaluatedValue.floatvalue === '')){
                    if(((field.minValue || field.minValue === 0) && field.evaluatedValue.floatvalue < field.minValue) || ((field.maxValue || field.maxValue === 0) && field.evaluatedValue.floatvalue > field.maxValue)){
                      return true;
                    }
                    return false;
                  }
                  return true;
              case 'l-co':
                return field.multiple? (field.evaluatedValue.nvarcharValue === null || field.evaluatedValue.nvarcharValue === '') : (field.evaluatedValue.floatvalue === null || field.evaluatedValue.floatvalue === '');
              case 'rich':
                  return (field.description == null || field.description.trim() === '');
              case 'text':
                  if(field.fieldContentTypeId === 'emai' && field.evaluatedValue.nvarcharValue){
                    return !(this.validateMail(field.evaluatedValue.nvarcharValue));
                  }
                  if(field.evaluatedValue.nvarcharValue === undefined){
                      field.evaluatedValue.nvarcharValue = null;
                  }
                  return (field.evaluatedValue.nvarcharValue === null || field.evaluatedValue.nvarcharValue === '');
              case 'file':
                  return !(field.evaluatedValue && field.evaluatedValue.documents && field.evaluatedValue.documents.length)
              case 'bool':
              case 'boolp':
                  return field.evaluatedValue.bitValue === null;
              case 'txchk':
                  return field.evaluatedValue.bitValue === null || field.evaluatedValue.bitValue === false;
            }
        }else{
            return true;
        }
    }

    checkCommonErrors(concept:any, userPublic:any){
      let errors:number = 0;
      let isLanding: Boolean = this.customLanding.isLanding();
      for(let i = 0; i < concept.fieldContainers.length; i++){
          let fieldContainer = concept.fieldContainers[i];
          if(!fieldContainer.hidden){
              for(let j = 0; j < fieldContainer.fields.length; j++){
                  let fieldContainerField = fieldContainer.fields[j];
                  let hiddenInLanding = fieldContainer.hiddenInLanding || fieldContainerField.hiddenInLanding
                  fieldContainerField.error = fieldContainerField.fieldContentTypeId === 'emai'?fieldContainerField.error:false;
                  if (!hiddenInLanding && !((isLanding && userPublic && (fieldContainer.private || fieldContainerField.private)) || !fieldContainer.show || !fieldContainerField.show || fieldContainerField.hidden)) {
                      if (!fieldContainerField.noEditable && (!fieldContainerField.notCurrentValue && fieldContainerField.required) && this.checkField(fieldContainerField)) {
                          fieldContainerField.error = true;
                          errors++;
                      }
                  }
              }
              for(let k = 0; k < fieldContainer.subContainers.length; k++){
                  let  subContainer = fieldContainer.subContainers[k];
                  for(let l = 0; l < subContainer.fields.length; l++){
                      let subcontainerField = subContainer.fields[l];
                      let subHiddenInLanding = fieldContainer.hiddenInLanding || subcontainerField.hiddenInLanding || subcontainerField.hiddenInLanding
                      subcontainerField.error = subcontainerField.fieldContentTypeId === 'emai'?subcontainerField.error:false;
                      if(!subHiddenInLanding && !((isLanding && userPublic && (fieldContainer.private || subcontainerField.private || subContainer.private)) || !fieldContainer.show || !subContainer.show || subcontainerField.hidden)){
                          if(!subcontainerField.noEditable && (!subcontainerField.notCurrentValue && subcontainerField.required) && subContainer.multiple){
                              errors += this.checkMultipleSubcontainer(subContainer, concept);
                          } else if (!subcontainerField.noEditable && (!subcontainerField.notCurrentValue && subcontainerField.required) && this.checkField(subcontainerField) && subcontainerField.show){
                              subcontainerField.error = true;
                              errors++;
                          }
                      }
                  }
              }
          }
      }
      return errors;
    }

    cleanFieldEvaluatedValue(field: any, concept: any){
      if(field.evaluatedValue && field.fieldTypeId !== 'rich'){
        let fieldValue = field.defaultValue?field.defaultValue:null;
        field.evaluatedValue.default = fieldValue;
        field.evaluatedValue.isModified = true;
        field.error = false;
        switch(field.fieldTypeId){
            case 'date':
                field.evaluatedValue.datetimeValue = fieldValue;
                field.errorInDateLock = false;
                field.errorInForm = false;
                this.deleteConceptErrorForm(field, concept);
                break;
            case 'file':
                field.evaluatedValue.documents = [];
                break;
            case 'bool':
            case 'boolp':
            case 'txchk':
                field.evaluatedValue.bitValue = fieldValue;
                break;
            case 'text':
            case 'user':
                field.evaluatedValue.nvarcharValue = fieldValue;
                if(field.fieldTypeFrontId === 'emai' || field.fieldTypeFrontId === 'link'){
                    field.errorInForm = false;
                    this.deleteConceptErrorForm(field, concept);
                }
                break;
            case 'num':
            case 'money':
                field.evaluatedValue.floatvalue = fieldValue;
                break;
            case 'list':
            case 'listp':
            case 'l-fo':
            case 'l-co':
            case 'l-sg':
                let value:any = !field.multiple? 'floatvalue' : 'nvarcharValue';
                field.evaluatedValue[value] = fieldValue;
                if(field.multiple){
                  field.listValues.forEach((element:any) => {
                    delete element.selected;
                  });
                }
                break;
            default:
                field.evaluatedValue.nvarcharValue = fieldValue;
                field.evaluatedValue.floatvalue = fieldValue;
                break;
        }
      }
    }

    configureConceptErrorForm(field: any, concept: any){
      if(concept){
        if(field.errorInForm){
            if(!concept.errorInForm?.length){
                concept.errorInForm = [];
                concept.errorInForm.push(field);
            }else if(!this.existsFieldErrorToConcept(field, concept)){
                concept.errorInForm.push(field);
            }
        }else if(concept.errorInForm?.length){
            this.deleteConceptErrorForm(field, concept);
        }
      }

    }

    deleteConceptErrorForm(field: any, concept: any){
      for(let i = concept.errorInForm?.length-1; i >= 0; i--){
          let errorInForm = concept.errorInForm[i];
          if(errorInForm.fieldId === field.fieldId){
              concept.errorInForm.splice(i, 1);
              break;
          }
      }
    }

    existsFieldErrorToConcept(field: any, concept: any){
      for(let i = 0; i < concept.errorInForm?.length; i++){
          let errorInForm = concept.errorInForm[i];
          if(errorInForm.fieldId === field.field){
              return true;
          }
      }
      return false;
    }

    validateField(field: any):boolean {
      if(field.fieldTypeId === 'text'){
        if(field.evaluatedValue){
          let value = field.evaluatedValue.nvarcharValue;
          if (value != null && value.trim() != '' && field.fieldContentTypeId === 'addr') {
              return this.validateURL(value);
          }
        }
        return true
      }
      return true;
    }

    validateMail(mail: string): boolean{
      if(mail?.length <= 320){
        // return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
                //Nombre del destinatario                                      //@//Nombre dominio//.//dominio
        return /^([^<>()\[\]\\À-ÿ,;:\s@"]{1,63})(([^<>()\[\]\\À-ÿ.,;:\s@"]){1})@(([A-z\-0-9]+\.)+([A-z]{2,}))+$/.test(mail);
      }else{
        return false;
      }
    }

    validateNoSpecialCharacters(text: string):boolean {
      // return new RegExp("[A-Za-zÀ-ȕ0-9()-_. ]+$").test(text);
      let regex = new RegExp('[¨~#!%&¡¿{}´><`;:\|\·\$\^\?\\+]', 'gi');
      return !(regex.test(text));
    }

    validateURL(url:string):boolean {
      return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(url);
    }

    getMobilePhoneList(list: any, type: any, type2: any): any{
      let listMobilePhone:any = [];
      list.forEach((contact:any) => {
        let phoneNumberSMS = '';
        phoneNumberSMS = this.checkMobilePhone(contact, type, type2);
        if(phoneNumberSMS !== ''){
          listMobilePhone.push(contact);
        }
      });
      return listMobilePhone;
    }

    checkMobilePhone(contact: any, type: any, type2: any){
      let regExpMobileSpain = new RegExp('^((\\+34|0034|34)?[-|\.]*(6|7[-|\.]*){1}([0-9][-|\.]*){8})$');
      let regExpMobileFrance = new RegExp('^((\\+33|0033|33)?[-|\.]*(0)?(6|7[-|\.]*){1}([0-9][-|\.]*){8})$');
      if(regExpMobileSpain.test(contact[type]) || regExpMobileFrance.test(contact[type])
          || regExpMobileSpain.test(contact[type2]) || regExpMobileFrance.test(contact[type2])){
              contact.phoneNumberSMS = contact[type2];
              if(regExpMobileSpain.test(contact[type]) || regExpMobileFrance.test(contact[type])){
                  contact.phoneNumberSMS = contact[type];
              }
              contact.phoneNumberSMS = contact.phoneNumberSMS.replace(/-/g,"");
              // contact.phoneNumberSMS = contact.phoneNumberSMS.replace(/\+/g,"");
              contact.phoneNumberSMS = contact.phoneNumberSMS.replace(/\./g,"");
              contact.phoneNumberSMS = contact.phoneNumberSMS.replace(/ /g,"");
              return contact.phoneNumberSMS;
      }
      return '';
    }

    configureReportRow(row: any, column:any, color: any=null){
            for(let i = 0; i < 29; i++){
                row['OFAFMVJfield'+i] ={
                    key:'OFAFMVJ-42',
                    value:'OFAFMVJ-42',
                    displayWidth:0,
                    margin: 0
                }
            }

            let acu = 0;
            for(let i = 0; i < column.length; i++){
                let showDependencies: boolean = false;
                if(column[i].fieldTypeId !== 'options'){
                  let value: any = row[column[i].id];
                  let elementsDependencies: any = [];
                  if(column[i].isHTMLDependencies && value){
                    let separatorDependencies: any = row[column[i].id].split(' ·');
                    value = separatorDependencies[0];
                    if(separatorDependencies.length > 1){
                      let listDependencies: any = separatorDependencies[1].split('Dependencias: ')[1];
                      if(listDependencies?.indexOf('|') !== -1){
                        elementsDependencies = listDependencies.split('|');
                      }else{
                        elementsDependencies = [listDependencies];
                      }
                      showDependencies = true;
                    }
                  }
                  row['OFAFMVJfield'+acu] = {
                      key: column[i].id,
                      value: value,
                      originalValue: row[column[i].id],
                      displayWidth:column[i].displayWidth,
                      margin:column[i].margin,
                      fieldTypeId: column[i].fieldTypeId,
                      backgroundColor:'',
                      backgroundIconColor:'',
                      hasColor: false,
                      hasOpacity: column[i].isHighLight,
                      isHTMLDependencies: showDependencies,
                      elementsDependencies: elementsDependencies,
                      idUnique: this.createId(),
                      columnOrder: i,
                  }
                  if(column[i].id && color){
                      this._getBackgroundColor(column[i], color, row['OFAFMVJfield'+acu]);
                  }

                  acu++;
                }

            }
        }

    _getBackgroundColor(column: any, color: any, row: any){
            if(color[column.id] && color[column.id].charAt(0)==='#'){
                let hex = parseInt(color[column.id].substring(1), 16);
                let rgb = {
                    r: hex >> 16,
                    g: (hex & 0x00FF00) >> 8,
                    b: (hex & 0x0000FF)
                }
                row.backgroundColor = 'rgba('+rgb.r+','+rgb.g+','+rgb.b+','+(column.isHighLight?'0.36':'1')+')';
                row.backgroundIconColor = 'rgba('+rgb.r+','+rgb.g+','+rgb.b+')';
                row.hasColor = true;
                row.hasOpacity = column.isHighLight;
            }
        }

    setMomentLanguage(){
      let userLanguage: any = this.getLanguage()
      let language = userLanguage.split('-')[0]
      if(userLanguage.indexOf('nl-BE') !== -1){
          language = userLanguage.toLowerCase()
      }
      if(moment.locale() !== language){
          moment.updateLocale(language, {
              week: {
                  dow: 1
              }
          });
      }
    }

    getLanguage(){
      let userLanguage: any = sessionStorage.getItem('selectLanguage');
      if(!userLanguage || userLanguage === "null"){
          userLanguage = (navigator.language);
          if(this.appLanguage?.languaje){
              userLanguage = this.appLanguage.languaje;
          }
      }
      return userLanguage;
    }

    getFooterDescription(translates: any){
        let footer = '';
        switch(this.globalCfg.skin){
            case 'dark-blue':
                footer = '<p class="compliance-footer__disclaimer-text">'
                        + '©'+ moment().format('YYYY') + ' Lefebvre. '
                        + '<span>'+translates.reservedRights+' </span>'
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://lefebvre.es/aviso-legal" target="_blank">'+translates.legalWarning+'</a>'
                        + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://lefebvre.es/politica-privacidad" target="_blank">'+translates.privacyPolicy+'</a>'
                        + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://lefebvre.es/politica-de-cookies" target="_blank">'+translates.cookiesPolicy+'</a>'
                    + '</p>';
                break;
            case 'steel-blue':
                let links = this.configureFooterLinks();
                footer =  '<p class="compliance-footer__disclaimer-text">'
                        + '©'+ moment().format('YYYY') + ' Larcier Intersentia. '
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="'+links.generalTerms+'" target="_blank">'+translates.generalTerms+'</a>'
                        + '<span> | </span>'
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="'+links.privacy+'" target="_blank">'+translates.privacy+'</a>'
                        + '<span> | </span>'
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="'+links.cookies+'" target="_blank">'+translates.cookies+'</a>'
                        + '<span> | </span>'
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="'+links.processingPersonalData+'" target="_blank">'+translates.processingPersonalData+'</a>'
                    + '</p>';
                break;
            case 'icam-red':
                footer = '<p class="compliance-footer__disclaimer-text">'
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.otrosi.net/aviso-legal" target="_blank">'+translates.legalWarning+'</a>'
                        + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.otrosi.net/politica-de-cookies" target="_blank">'+translates.cookiesPolicy+'</a>'
                    + '</p>';
                break;
            case 'com-de':
              footer = '<p class="compliance-footer__disclaimer-text">'
                        + '©'+ moment().format('YYYY') + ' Stollfuß Verlag – Zweigniederlassung der Lefebvre Sarrut GmbH. '
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf" target="_blank">'+translates.privacyPolicy+'</a>'
                        + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.stollfuss.de/Rechtliches/Alllgemeine-Geschaeftsbedingungen/" target="_blank">'+translates.generalTerms+'</a>'
                         + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.stollfuss.de/Rechtliches/Impressum/ target="_blank">'+translates.impressum+'</a>'
                    + '</p>';
                break;
            case 'com-fr':
              footer = '<p class="compliance-footer__disclaimer-text">'
                        + '©'+ moment().format('YYYY') + ' Lefebvre Dalloz Logiciels. '
                        + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf" target="_blank">'+translates.privacyPolicy+'</a>'
                        + '<span> | </span>'
                        +'<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"'
                        +    'href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf" target="_blank">'+translates.generalTerms+'</a>'
                    + '</p>';
                break;
            // case 'uk-steel-blue':
            //     footer =  '<p class="compliance-footer__disclaimer-text">'
            //             + 'Tax Essentials for Advisors is a product of Indicator - FL Memo Ltd · ©'+ moment().format('YYYY')
            //             + '<span> · </span>'
            //             + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link compliance-footer__disclaimer-text--'+skin+'"'
            //             +    'href="https://lefebvre.es/politica-privacidad" target="_blank">'+translates.privacy+'</a>'
            //             + '<span> · </span>'
            //             + '<a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link compliance-footer__disclaimer-text--'+skin+'"'
            //             +    'href="https://lefebvre.es/aviso-legal" target="_blank">'+translates.generalTerms+'</a>'
            //         + '</p>';
            //     break;
        }
        return footer;
    }

    configureFooterLinks(){
        let language = sessionStorage.getItem('selectLanguage');
        let links = {
            generalTerms: '',
            cookies: '',
            privacy: '',
            processingPersonalData: ''
        }
        switch(language){
        // es-ES, en-US, en-GB, fr-FR, fr-BE, nl-NL, nl-BE
            case 'fr-FR':
            case 'fr-BE':
                links.generalTerms = "https://cdn.lefebvre-sarrut.be/terms/fr.pdf";
                links.cookies ="https://cdn.lefebvre-sarrut.be/privacy/LSB/cookie_policy_FR.pdf ";
                links.privacy = "https://cdn.lefebvre-sarrut.be/privacy/LSB/charte-vie-privee.pdf ";
                links.processingPersonalData = "https://cdn.lefebvre-sarrut.be/privacy/LSB/Traitement-donnees-Electronic-products.pdf";
                break;
            case 'nl-NL':
            case 'nl-BE':
                links.generalTerms = "https://cdn.lefebvre-sarrut.be/terms/nl.pdf";
                links.cookies ="https://cdn.lefebvre-sarrut.be/privacy/LSB/cookie_policy_NL.pdf ";
                links.privacy = "https://www.larcier.com/media/wysiwyg/pdf/legal-nl/privacy.pdf ";
                links.processingPersonalData = "https://cdn.lefebvre-sarrut.be/privacy/LSB/Gegevensverwerking-Electronic-products.pdf";
                break;
            default:
                links.generalTerms = "https://cdn.lefebvre-sarrut.be/terms/en.pdf";
                links.cookies ="https://cdn.lefebvre-sarrut.be/privacy/LSB/cookie_policy_EN.pdf";
                links.privacy = "https://cdn.lefebvre-sarrut.be/privacy/LSB/privacy_EN.pdf";
                links.processingPersonalData = "https://cdn.lefebvre-sarrut.be/privacy/LSB/Dataprocessing-Electronic-products.pdf";
                break;
        }
        return links;
    }

    configureLogos(data: any){
        data.logo = this.setLogo(data.logo, data.logo);
        data.logoFooter = this.setLogo(data.logoFooter, data.logo);
        data.logoHeader = this.setLogo(data.logoHeader, data.logo);
        data.logoOther = this.setLogo(data.logoOther, data.logo);
        return data;
    }

    setLogo(logo: any, globalLogo: any=null){
        let newLogo= logo;
        if(newLogo === null ){
            newLogo = globalLogo;
        }
        if(newLogo && newLogo.indexOf('data:image') === -1){
            return "data:image/png;base64," + newLogo;
        }
        return newLogo;
    }

    baseToFormdata(base64URI: string, type: string='ico'): any{
        if(base64URI){
            let binary = atob(base64URI.split(',')[1]);
            let array = [];
            for(let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            let imageType = type === 'ico'?'image/vnd.microsoft.icon':'image/jpeg'
            return new Blob([new Uint8Array(array)], {type: imageType});
        }
    }

    addDocumentToField(newDocument: any, fieldIdSelected: any, concept: any){
        loopAddDocument:
        for(let i = 0; i < concept.fieldContainers.length;i++){
            let container = concept.fieldContainers[i];
            for(let j = 0; j < container.fields.length;j++){
                let field = container.fields[j];
                if(field.fieldId === fieldIdSelected){
                    if(!field.evaluatedValue.documents){
                        field.evaluatedValue.documents = [];
                    }
                    field.evaluatedValue.documents.splice(field.evaluatedValue.documents.length, 0, newDocument);
                    this.addDisplayDataToField(field, newDocument);
                    break loopAddDocument;
                }
            }
            for(let k = 0; k < container.subContainers.length; k++){
                let subContainer = container.subContainers[k];
                for(let l = 0; l < subContainer.fields.length; l++){
                    let fieldSubcontainer = subContainer.fields[l];
                    if(fieldSubcontainer.fieldId === fieldIdSelected){
                        if(!fieldSubcontainer.evaluatedValue.documents){
                            fieldSubcontainer.evaluatedValue.documents = [];
                        }
                        fieldSubcontainer.evaluatedValue.documents.splice(fieldSubcontainer.evaluatedValue.documents.length, 0, newDocument);
                        this.addDisplayDataToField(fieldSubcontainer, newDocument);
                        break loopAddDocument;
                    }
                }
            }
        }
    }

    addDisplayDataToField(field: any, newDocument: any){
        let displayDataField = {
            id: newDocument.documentObjectId,
            value: newDocument.name
        }
        field.evaluatedValue.displayData.push(displayDataField);
    }

    deleteDocumentToField(deleteDocument:any, concept: any){
        loopDeleteDocument:
        for(let i = 0; i < concept.fieldContainers.length;i++){
            let container = concept.fieldContainers[i];
            for(let j = 0; j < container.fields.length;j++){
                let field = container.fields[j];
                if(field.evaluatedValue && field.evaluatedValue.documents){
                    for(let m = 0; m < field.evaluatedValue.documents.length;m++){
                        let documentField = field.evaluatedValue.documents[m];
                        if(documentField.documentObjectId === deleteDocument.documentObjectId){
                            field.evaluatedValue.documents.splice(m, 1);
                            break loopDeleteDocument;
                        }
                    }
                }
            }
            for(let k = 0; k < container.subContainers.length; k++){
                let subContainer = container.subContainers[k];
                for(let l = 0; l < subContainer.fields.length; l++){
                    let fieldSubcontainer = subContainer.fields[l];
                    if(fieldSubcontainer.evaluatedValue && fieldSubcontainer.evaluatedValue.documents){
                        for(let p = 0; p < fieldSubcontainer.evaluatedValue.documents.length;p++){
                            let documentField = fieldSubcontainer.evaluatedValue.documents[p];
                            if(documentField.documentObjectId === deleteDocument.documentObjectId){
                                fieldSubcontainer.evaluatedValue.documents.splice(p, 1);
                                break loopDeleteDocument;
                            }
                        }
                    }
                }
            }
        }
    }

    configureAvailableLanguages(availableLanguages: any){
        let languages:any = [];
        availableLanguages.forEach((selectLanguage: any) => {
            languages.push({
                listValueId: selectLanguage,
                text: this.selectTextRegion(selectLanguage),
                specific: this.selectSpecificTextRegion(selectLanguage),
                appLanguage: selectLanguage
            })
        });
        return languages;
    }

    selectTextRegion(selectLanguage: string){
        return this.translate.instant(selectLanguage.substring(3,5).toLowerCase() ==='be' && selectLanguage.substring(0,2).toLowerCase() ==='nl'? 'app-languages.be' : 'app-languages.' + selectLanguage.substring(0,2).toLowerCase());
    }

    selectSpecificTextRegion(selectLanguage: string){
        let specificText = selectLanguage[1] === 'GB'
                            || selectLanguage[1] === 'US'
                            || (selectLanguage[1] === 'BE' && selectLanguage[0] ==='fr')
                            || (selectLanguage[1] ==='ES' && selectLanguage[0] ==='ca')
                            || (selectLanguage[1] === 'ES' && selectLanguage[0] === 'eu')
                            || (selectLanguage[1] === 'ES' && selectLanguage[0] === 'gl');
        return this.translate.instant('app-languages.' + (specificText?selectLanguage[0]:selectLanguage.substring(0,2).toLowerCase())+'-native');
    }

    setProductList(productList: any){
        productList.forEach((product: any) => {
            let name = product.name;
            if(this.globalCfg.skin === 'steel-blue' && product.productId === 'blanqueo'){
              product.shortName = 'ML';
            }else{
              if(this.globalCfg.skin === 'dark-blue' || this.globalCfg.skin === 'gm-law'){

                  let words = product.name.split(' ');
                  if(words[0].toLowerCase() === 'centinela'){
                      words.splice(0,1);
                  }
                  name = words.join(" ")
              }
              let secondName = name.substring(name.indexOf(" ")+1, name.length);
              let regExp = /[$%&@|<>#!¡¿?/\\()\{\}\[\]_.,;:'`´-]/;
              let shortName = name.charAt(0);
              let secondNameLetter = name.charAt(name.indexOf(" ")+1);
              if(name.indexOf(" ") === -1){
                  secondNameLetter = name.charAt(1);
              }else if(secondName.indexOf(" ") === 1 && regExp.test(secondName.substring(0, secondName.indexOf(" ")))){
                  secondNameLetter = secondName.charAt(secondName.indexOf(" ")+1);
              }
              product.shortName = (shortName + secondNameLetter).toUpperCase();
            }

        });

        productList.sort(this.orderProductList);
        return productList;
    }

    orderProductList(a: any, b: any) {
        return a.shortName > b.shortName ? 1 : a.shortName < b.shortName ? -1 : 0;
    }

    filterEvaluationByProduct(arrayToFilter: any, itemToFilter: any, isTask: boolean = false){
        let filterArray : any = [];
        if (arrayToFilter) {
          filterArray = arrayToFilter.filter((item: any)=>{
            return (itemToFilter && item.productId === itemToFilter.productId) || (isTask && item.productId === null)
            })
        }
        return filterArray;
    }

    filterEvaluationByClient(arrayToFilter: any, itemToFilter: any){
        return arrayToFilter.filter((item: any)=> (item.clientId === itemToFilter.clientId))
    }

    filterReportsByEvaluation(reports:any, filteredEvaluation:any){
        let filteredReports:any = [];
        if (filteredEvaluation != null && reports != null) {
            filteredEvaluation.forEach((filter:any) => {
                reports.forEach((report:any) => {
                    if (filter.evaluationId === report.evaluationId) {
                        filteredReports.push(report);
                    }
                });
            });
        }
        return filteredReports;
    }

    setLocaleNumberFormat(numberLocale: any){
            let newValue = '';
            let maxDecimal = 0;
            let language = this.getLanguageNumber();
            if(typeof numberLocale === 'number') {
                let newNumber = numberLocale;
                // return newNumber.toLocaleString(language);
                // maxDecimal = this.maxDecimalsToNumber(newNumber);
                maxDecimal = 3;
                return this.parseNumberToLocaleString(newNumber, maxDecimal, language);
            }
            newValue = numberLocale.replace(',','.');
            maxDecimal = this.maxDecimalsToNumber(newValue);
            return this.parseNumberToLocaleString(newValue, maxDecimal, language);
    }

    maxDecimalsToNumber(number:any){
      let numberString: string = number.toString();
      if(numberString.indexOf('.') !== -1){
          return numberString.substring(numberString.indexOf('.')+1,numberString.length).length;
      }
      return 0;
    }

    parseNumberToLocaleString(number: any, maxDecimal: any, language: any){
      if(maxDecimal){
          return parseFloat(number).toLocaleString(language, {maximumFractionDigits: maxDecimal});//minimumFractionDigits
      }else{
          return parseFloat(number).toLocaleString(language);
      }
    }

    getLanguageNumber(){
        return window.navigator.language === 'es-ES'?'ca':window.navigator.language;
    }

    replaceDecimals(value:any){
      let decimals: any =  (1000000.1).toLocaleString().substr((1000000.1).toLocaleString().lastIndexOf('1')-1,1);
      // let millar: any = (1000000.1).toLocaleString().substr((1000000.1).toLocaleString().indexOf('1')+1,1);
      if(decimals === ','){
          value = value.replaceAll('.','').replace(',','.');
      }else{
          value = value.replaceAll(',','');
      }
      return value;
    }

    getQuarters(){
            return {
                from: new Date(moment().startOf('quarter').format('MM-DD-YYYY')),
                to: new Date(moment().endOf('quarter').format('MM-DD-YYYY'))
            }
        }

    configureLoaderImg(){
            switch(this.globalCfg.skin){
                case 'steel-blue':
                case 'uk-steel-blue':
                    return '../assets/img/loader-steel-blue.gif';
                case 'icam-red':
                    return '../assets/img/loader-icam.gif';
                case 'gm-law':
                    return '../assets/img/loader.gif';
                default:
                    return '../assets/img/loader.gif';
            }
        }

    objectDeepCopy(obj: any) {
        return cloneDeep(obj);
    }

    configureAlertState(item: any){
            if(item.stepIsFinal || item.processingHours === null){
                item.alertState = '4-finish';
                item.colorAlert = 'grey';
            }else if(parseInt(item.processingHours) <= 24){
                item.alertState = '1-last-day';
                item.colorAlert = 'green';
            }else if(parseInt(item.processingHours) > 24 && parseInt(item.processingHours) <= 48){
                item.alertState = '2-two-days';
                item.colorAlert = 'yellow';
            }else if(parseInt(item.processingHours) > 48){
                item.alertState = '3-more-time';
                item.colorAlert = 'red';
            }
            return item;
        }

    convertShortDate(dateShort: any){
        let date = dateShort.split('/');
        return moment(date[1]+'-'+date[0]+'-'+date[2]);
    }

    configureCustomButtonsTiny(field: any, tinyMceOptions: any){
      tinyMceOptions.customButtons = [];
      if (field.bookmarksTag?.length) {
          let bookmarksTag = {
            type: 'bookmarksTag',
            list: field.bookmarksTag
          }
          tinyMceOptions.customButtons.push(bookmarksTag);
      }
      if (field.snippetsTag?.length) {
          let snippetsTag = {
            type: 'snippetsTag',
            list: field.snippetsTag
          }
          tinyMceOptions.customButtons.push(snippetsTag);
      }
      if (this.globalCfg.skin === 'icam-red' && field.fieldId === 'custom-notification-field') {
          let addNewFragment = {
            type: 'addNewFragment'
          }
          tinyMceOptions.customButtons.push(addNewFragment);
      }
    }

    showTooltip(keyElement:string): boolean{
      let parent: any = $('#'+keyElement)? $('#'+keyElement).width() : 0;
      let child: any = $('#span-'+keyElement)? $('#span-'+keyElement).width() : 0;
      return (Math.round(child)>Math.round(parent));
    }

    showTooltipHeight(element:string = '', index:string=''): boolean{
      let parent: any = $('#'+element+'-'+index)? $('#'+element+'-'+index).innerHeight() : 0;
      let child: any = $('#span-'+element+'-'+index)? $('#span-'+element+'-'+index).innerHeight() : 0;
      return (Math.round(child)>Math.round(parent));
    }

    jsonEncode(string: any) { // eslint-disable-line camelcase
      let escapeChars = [
          '\u0000-\u001f',
          '\u007f-\u009f',
          '\u00a1-\u00ff',
          '\u00ad',
          '\u0600-\u0604',
          '\u070f',
          '\u17b4',
          '\u17b5',
          '\u200c-\u200f',
          '\u2028-\u202f',
          '\u2060-\u206f',
          '\ufeff',
          '\ufff0-\uffff'
      ].join('');
      let meta: any = {
          // table of character substitutions
          '\b': '\\b',
          '\t': '\\t',
          '\n': '\\n',
          '\f': '\\f',
          '\r': '\\r',
          '"': '\\"',
          '\\': '\\\\'
      };
      let escapable = new RegExp('[\\"' + escapeChars + ']', 'g');
      escapable.lastIndex = 0;
      return escapable.test(string) ? string.replace(escapable,(a:any)=>{
          let c = meta[a]
          return typeof c === 'string' ? c : '\\u' + ('0000' + a.charCodeAt(0)
              .toString(16))
              .slice(-4)
        }):string;
    }

    // filterItemsLength(list:any, search:string, listToFilter:any, unique:string=''){
    //   return 5;
    // }

    getColors(item: any, type:string=''): unknown {
      switch(type){
        case 'icon-filter-list':
            return this.progressColors(item);
        default:
            return this.defaultColors(item);
      }
    }

    defaultColors(item: any){
      switch(item){
          case 3: //'En progreso'
          case 'En proceso':
          case 'progress':
              return "yellow";

          case 2: //'Stand by'
          case 'Descartada':
          case 'Atrasado':
              return "orange";

          case 1: //'Abierta'
          case 'Sin empezar':
              return "red";

          case 6://'Cerrada sin resolver'
              return "brown";

          case 5://'Cerrada'
              return "grey";

          default:
              return "green";
      }
    }

    progressColors(item:any){
        switch(item){
            case 'started':
            case '3-more-time':
                return 'red';
            case 'in-progress':
            case '2-two-days':
                return 'yellow';
            case 'advanced':
            case '1-last-day':
                return 'green';
            default:
                return 'grey';
        }
    }

    convertHexToRGB(hexColor: any){
      let red = parseInt(hexColor[1]+hexColor[2],16);
      let green = parseInt(hexColor[3]+hexColor[4],16);
      let blue = parseInt(hexColor[5]+hexColor[6],16);
      return red+','+green+','+blue;
    }

    configureSelectedTimeUnit(type: any, list: any, indexAdditional: any=0){
      switch(type){
          case 'd':
          case 'D':
              return list[0+indexAdditional];
          case 'w':
          case 'W':
              return list[1+indexAdditional];
          case 'm':
          case 'M':
              return list[2+indexAdditional];
          case 'y':
          case 'Y':
              return list[3+indexAdditional];
          case 'h':
          case 'H':
              return list[0];
          default:
              return type;
      }
    }

    checkAndDeletePreTag(description:string){
      if(description.indexOf('<pre')=== 0){
        let descriptionText:string =  description.replace('<pre', '<p');
        return descriptionText.substring(0, descriptionText.length-3)+'>';
      }else{
        return description;
      }

    }
    limitTo(list:any, begin:number, limitTo:number){
      return list.splice(begin, limitTo+begin)
    }

    getProductPermissions(productId:any, apiService:any){
      return new Promise((resolvePermissions: any, reject: any) => {
          try{
            apiService.get('company/dashboard/security/product', productId)
              .then((data:any)=> {
                this.userPermisions.setUserPermisions(data);
                resolvePermissions(data);
              }, (errorData:any)=>{reject()})
          }catch(errorData){
            reject();
          }
      });

    }
    isIE(){
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        return false;
    }

    clipboardText(text: any=''){
      navigator.clipboard.writeText(text)
      .then(() => {}, () => {});
    }

    getFileExtension(extension: any){
      switch (extension) {
          case 'doc':
          case 'docx':
          case 'docm':
          case 'dot':
          case 'dotx':
          case 'dotm':
          case 'odt':
          case 'rtf':
              return 'lf-icon-word-software';
          case 'xls':
          case 'xlsx':
          case 'xlm':
          case 'xlsm':
          case 'xlt':
          case 'xltx':
          case 'xltm':
          case 'xlsb':
          case 'xlam':
          case 'xlv':
          case 'csv':
          case 'ods':
              return 'lf-icon-excel-software'
          case 'bmp':
          case 'gif':
          case 'dib':
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tga':
          case 'tif':
          case 'tiff':
          case 'pcx':
          case 'pic':
          case 'emf':
          case 'ico':
          //internet
          case 'htm':
          case 'html':
          case 'css':
          case 'url':
          case 'xml':
          case 'eml':
          case 'msg':
          //audio
          case 'wav':
          case 'mp3':
          //video
          case 'avi':
          case 'mp4':
          case 'mov':
          case 'mpeg':
          case 'mpg':
          case 'divx':
          case 'wmv':
          case 'flv':
          case 'mkv':
          //comprimidos
          case 'rar':
          case 'zip':
          case '7z':
          //access
          case 'mdb':
          //texto
          case 'txt':
          case 'pdf':
          case 'wri':
          case 'log':
          case 'xps':
              return 'hide-icon';
          default:
              return 'lf-icon-powerpoint-software';
      }
    }

    registerEvent(type:string, product:string, objectid:any = 0, concept:any=null, from: string ='', source:string='dashboard'){
        let resgisterItem:any ={
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':type,
            'Centinela product': product
        }

        if(from==='report'){
            resgisterItem['Report Type'] = this.convertNumbToName.reportNumbToName(String(objectid));
            resgisterItem['Source']= source
        }else if(from ==='task'){
            resgisterItem['Task Type'] = this.convertNumbToName.reportNumbToName(String(objectid));
            resgisterItem['Concept Name'] = concept.breadCrumb +' > ' + concept.title;
            resgisterItem['Source']= source;
        }else if(from ==='implantation'){
            resgisterItem['Source']= source;
        }
        this.registerEventService.register(resgisterItem);
    }
    searchInUrl(type:string){
        let word: any = location.search.substring(location.search.indexOf(type)+(type).length + 1);
        if(word){
          // word = word.replace('%3d', '=')
          let textSearch = word.indexOf('%3D') !== -1? '%3D':word.indexOf('%3d') !== -1?'%3d':'';
          return type ==='access'?(word).substring(0, (word).indexOf(textSearch)) : (word).substring(4, (word).indexOf(textSearch))
        }else{
          return false;
        }
    }

    findByWord(items: any, find: any, words: any){
        let filtered: any = [];
        if(items && items.length){
            items.forEach((item: any)=> {
              if(find !==''){
                  loopWords:
                  for(let i=0; i < words.length; i++){
                      let word = words[i];
                      let characters: any={
                          "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
                          "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
                          "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
                          "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
                      let regExpCharacters=/[áàéèíìóòúùñ]/ig;
                      if(typeof word === 'object' && word !== null){
                        let wordArray = Object.keys(word);
                        for(let j=0; j < wordArray.length; j++){
                          for(let m=0; m < word[wordArray[j]].length; m++){
                            let wordChildren = word[wordArray[j]][m];
                            if(item[wordArray[j]]?.length){
                              for(let k=0; k < item[wordArray[j]].length; k++){
                                let itemChildren = item[wordArray[j]][k];
                                if(itemChildren[wordChildren] === null || !itemChildren[wordChildren]){
                                    itemChildren[wordChildren] = '';
                                }
                                let itemWord = itemChildren[wordChildren].toString();
                                let textWithoutAccent= itemWord.replace(regExpCharacters,(e:any)=>{return characters[e]});
                                let findWithoutAccent=find.replace(regExpCharacters,(e: any)=>{return characters[e]});
                                if(textWithoutAccent.toLowerCase().indexOf(findWithoutAccent.toLowerCase()) !== -1){
                                    filtered.push(item);
                                    break loopWords;
                                }
                              }
                            }
                          }
                        }
                      }else{
                        if(item[word] === null || !item[word]){
                            item[word] = '';
                        }

                        let itemWord = item[word].toString();
                        let textWithoutAccent= itemWord.replace(regExpCharacters,(e:any)=>{return characters[e]});
                        let findWithoutAccent=find.replace(regExpCharacters,(e: any)=>{return characters[e]});
                        if(textWithoutAccent.toLowerCase().indexOf(findWithoutAccent.toLowerCase()) !== -1){
                            filtered.push(item);
                            break;
                        }
                      }

                  }
              }else{
                  filtered.push(item);
              }
            });
        }
        return filtered;
    }

    paintNewItemArray(items: any){
        let filtered: any = [];
        if(items && items.length){
            items.forEach((item: any)=> {
              filtered.push(item);
            });
        }
        return filtered;
    }

    configureLogoHeader(){
        let locationHost = window.location?.origin?.indexOf('localhost') !== -1?'https://centinelappd.lefebvre.es':window.location.origin;
        switch(this.globalCfg.skin){
            case 'steel-blue':
                return locationHost +'/assets/img/logo-GoComply.png';
            case 'uk-steel-blue':
                return locationHost +'/assets/img/logo-TaxWin.png';
            case 'icam-red':
                return locationHost +'/assets/img/logo-icam-header.png';
            case 'gm-law':
                return '../assets/img/logo-segnala-header40.png';
            case 'com-de':
                return '../assets/img/logo-text-gocomply-de-138x40.png';
            case 'com-fr':
                return '../assets/img/logo-go-comply-fr.png';
            default:
                return 'https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-centinela-170x40.png';
        }
    }

  splitString(value:any){
    return value.indexOf('|') === -1? [value] : value.split('|')
  }

  goToStateUrl(){
    let url = this.stateManagement.getLastState();
    url?this.router.navigate([url]):this.router.navigate(['dashboard']);
    this.stateManagement.removeLastStateUrl();
  }

  toNgbCalendar(date:any){
    return {
        month: parseInt(moment(date).format('M')),
        day: parseInt(moment(date).format('D')),
        year: parseInt(moment(date).format('YYYY'))
    }
  }

  ngbToMoment(date:any){
    let day:any = date.day < 10? '0' + date.day : date.day;
    let month:any = date.month < 10? '0' + date.month : date.month;
    return moment(date.year+'-'+month+'-'+day);
  }

  checkResponsibles(responsibles:any, userId:any){
    for(let i = 0; i < responsibles.length; i++){
        if(responsibles[i].id === userId){
            return false;
        }
    }
    return true;
  }

  configureTaskResponsibleList(list:any){
    let responsiblesUniqueList:any = [];
    let responsiblesMap = new Map();

    list.forEach((taskProcess:any, index:any) => {
      taskProcess.responsibles.forEach((responsible:any) => {
        let uniqueId = responsible.type+'-'+responsible.id;
        if(!responsiblesMap.has(uniqueId)){
            responsiblesMap.set(uniqueId, index);
            responsible.uniqueId = uniqueId;
            responsiblesUniqueList.push(responsible);
        }
      });
    });
    return responsiblesUniqueList;
  }

  checkIsHexadecimalColor(color: any=''){
    let regExpHex =new RegExp('^#[0-9A-Fa-f]{6}$', 'i');
    // let regExpHex = /^#[0-9A-F]{6}$/i;
    // let regExpHex3Values = /^#([0-9A-F]{3}){1,2}$/i; //Para comprobar si tiene o 3 o 6
    return regExpHex.test(color);
  }

  locationSearchParam(param:any){
    new URL(location.href).searchParams.get(param)
    const params = new URL(location.href).searchParams;
    return params.get(param) === null? '' : params.get(param) ;
  }

  reloadNavigateUrlEvaluation(newPathName: any, evaluationId: any=undefined){
    let evaluationUrl: string = evaluationId?'evaluation/'+evaluationId+'/redirect':'evaluation';
    this.router.navigateByUrl(evaluationUrl, { skipLocationChange: true  }).then(() => {
        this.router.navigate([newPathName]);
    });
  }
  searchFormu(fieldContainers:any, concept: any){
    fieldContainers.forEach((fieldContainer:any) => {
      fieldContainer.active = concept.groupsAsList;
      let hasFormu: number = 0;
      fieldContainer.fields.forEach((field:any) => {
        if(this.itsFormu(field)){
          field.itsFormu = true;
          hasFormu++;
        }
      });
      fieldContainer.subContainers.forEach((subContainer:any) => {
        let subHasFormu: number = 0;
        subContainer.fields.forEach((subField:any)=>{
          if(this.itsFormu(subField)){
            subField.itsFormu = true;
            subHasFormu++;
            hasFormu++;
          }
        })
        subContainer.hasFormu = Boolean(subHasFormu);
      });
      fieldContainer.hasFormu = Boolean(hasFormu);
    });
    return fieldContainers;
  }

  itsFormu(field:any){
    return field.fieldTypeId === 'formu' || field.fieldTypeId === 'formp' || field.fieldTypeId === 'agreg' || field.fieldTypeId === 'agrep' || field.fieldTypeId === 'score';
  }

  OnDestroy(subscribers : any ){
    for (let key in subscribers) {
      subscribers[key].unsubscribe();
    }
  }

  uniqueValuesList(list:any, type:string){
    let keys: any = [];
    let uniqueList: any = [];
    if(list){
      list.forEach((item:any) => {
        let key:any = item[type];
        if (keys.indexOf(key) === -1) {
            keys.push(key);
            uniqueList.push(item);
        }
      });
    }
    return uniqueList;
  }
  findEvaluatedValue(evaluatedList:any, field:any){
    return evaluatedList.filter((evaluatedItem:any)=>{return evaluatedItem.fieldId === field.fieldId})[0]
  }

  toFloat(number:string){
    return parseFloat(this.replaceDecimals(number))
  }

}
