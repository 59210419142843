import moment from 'moment';

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'documentation-list',
  templateUrl: './documentation-list.component.html'
})
export class DocumentationListComponent implements OnInit, OnChanges, OnDestroy {
  skin: string = this.globalCfg.skin;
  subscribers : any = {};
  expandedMenu: any = ExpandMenu;
  userPermissions: any =  this.permissions.getPermissions();
  currentComponent: string = this.route.snapshot.data['componentName'];
  documentationList: any = {
    empty: [],
    hasProcesses: this.globalCfg.caps.processes,
    orderOperationDates: true,
    showTrash: false,
    showButtonTrash: false,
    documentChecked: 0,
    topSubmenu: 0,
    archivedCount: 0,
    nameWordFilter: '',
    dateOrder: 'created',
    breadcrumbList:[],
    filters: ['createdBy', 'modifiedBy', 'status', 'stateProcess', 'extension', 'source'],
    filterDocumentationList: [],
    filterDocumentationListDeleted:[],
    filterDocumentationAux: [],
    deleteBin: this.translate.instant('documentation-list.delete-bin'),
    checkMultiple: this.translate.instant('documentation-list.active') + ' ' + this.translate.instant('documentation-list.check-multiple').toLowerCase(),
    menuDocumentConcept: this.expandedMenu['menuDocumentConcept'].menu,
    menuDocumentViewEdit: this.expandedMenu['menuDocumentViewEdit'].menu,
    menuDocumentConfiguration: this.expandedMenu['menuDocumentConfiguration'].menu,
    menuDocumentShare: this.expandedMenu['menuDocumentShare'].menu,
    menuDocumentSignature: this.expandedMenu['menuDocumentSignature'].menu,
    menuRestore: this.expandedMenu['menuRestore'].menu,
    menuFolder: this.expandedMenu['menuFolder'].menu,
    menuMultipleChecked: this.expandedMenu['menuMultipleChecked'].menu,
    menuMultipleSignature: this.expandedMenu['menuDocumentSignature'].menu,
    sourceFilter: {
        name: this.translate.instant('common.origin')
    },
    extensionFilter:  {
        name: this.translate.instant('common.format')
    },
    createdByFilter: {
        name: this.translate.instant('common.author')
    },
    modifiedByFilter: {
        name: this.translate.instant('documentation-list.user-modification')
    },
    statusFilter: {
        name: this.translate.instant('common.state')
    },
    deletedByFilter: {
        name: this.translate.instant('documentation-list.user-deleted')
    },
    stateProcessFilter: {
        name: this.translate.instant('common.process-state')
    },
    nameFilter: {
      name: this.translate.instant('common.title'),
      orderCol: true,
      filterOrder: 'name',
      top: 29,
      width: '225px'
    }
  }

  constructor(private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private loader: LoaderFactory,
    private broadcastService: BroadcastService,
    private modalService : ModalServiceService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private filterColumnService: FilterColumnService,
    private context: ContextFactory,
    private expandedMenuService: ExpandedMenuService,
    private commonUtilsService: CommonUtilsService,
    private validateDocumentService: ValidateDocumentService,
    private registerService: RegisterMixtapeService,
    private downloadService: DownloadService,
    private exportService: ExportServiceService,
    private dateConverterService: DateConverterService,
    private documentActions: DocumentActionsService) { }

  @Input() concept: any = null;
  @Input() public: boolean = false;
  @Output() modifyConceptTab = new EventEmitter();
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.loader.openLoader('documentation-list-on-init');
    this.subscribers.openMenuBroadcast = this.broadcastService.subscribe('openMenu', (data:any)=>{
      this.documentationList.concept.closeMenu = data.closeMenu;
      let element: any = $('.documentation-list__block');
      if(element.scrollLeft() > 0){
        element.scrollLeft(0);
      }
    })
    this.getCountDeletedDocuments();
    this.configureDocumentation();
  }

  ngOnChanges(){
    let that:any = this.documentationList;
    that.templatesDocument = this.concept.templateList;
    that.concept = this.concept;
    let documentation:any = that.concept.documentation;
    if (documentation && documentation.length && documentation.documents && documentation.documents.length){
        documentation.documents.forEach((document:any) => {
        if(document.state){
          document.stateProcess = document.state.name;
        }
      });
    }
    if(documentation.documents && documentation.documents.length){
      documentation.documents.forEach((document:any) => {
        document.notForReadOnly = this.checkExtension(document)
      })

    }
  }

  dropNewDocument(e:any){
    let that:any = this.documentationList;
    let documentationList = cloneDeep(that.filterDocumentationList);
    documentationList.parentId = that.breadcrumbList[that.breadcrumbList.length - 1].folderId;
    this.documentationList.showDropZone = e.drag;
    this.modalService.uploadDocuments("dropFiles", e.files, documentationList, that.concept, that.concept.listStates)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.upDocumentProcess(result);
        }
      })
  }

  dragInABox(e: any){
    this.documentationList.showDropZone = e.drag;
  }

  getCountDeletedDocuments(){
      this.apiService.get('documentobjects/'+ this.documentationList.concept.conceptObjectId +'/count/1')
          .then((data:any)=>{
              this.documentationList.archivedCount = data === -1? 0 : data;
              if(data > 0 || data === -1){
                this.getDeletedDocuments();
              }else{
                this.documentationList.showButtonTrash = false;
              }
          },(errorData:any) => {})
  }

  getDeletedDocuments(){
      this.apiService.get('documentobjects/'+ this.documentationList.concept.conceptObjectId +'/recyclebin')
          .then((data:any)=>{
              data.documents.forEach((document:any) => {
                document.menu = this.documentationList.menuRestore;
              });

              data.subFolders = this.configureFolder(data.subFolders, 'recycle');
              this.documentationList.filterDocumentationListDeleted = [...data.documents, ...data.subFolders];
              this.documentationList.showButtonTrash = true;
          },(errorData:any)=>{})
  }

  configureDocumentation() {
    let that:any = this.documentationList
    if (that.concept.conceptObjectId !== 'view') {
        this.configureDocuments(that.concept.documentation.documents);
        that.concept.documentation.subFolders = this.configureFolder(that.concept.documentation.subFolders);
        that.filterDocumentationList = that.concept.documentation.documents;
        that.filterDocumentationList = that.filterDocumentationList.concat(that.concept.documentation.subFolders);
        that.filterDocumentationAux = that.filterDocumentationList;
        that.breadcrumbList = [];
        let initialRoute={
            name: this.translate.instant('common.documentation')
        }
        that.breadcrumbList.push(initialRoute);
    }else{
        that.filterDocumentationList = [];
    }
    this.getFilters();
  }

  configureFolder(folderList:any, type:any=''){
      folderList.forEach((folder:any)=>{
        folder.extension = 'folder';
        if(folder.confidential){
            this.setConfidential(folder);
        }
        folder.subFolders = this.configureFolder(folder.subFolders, type);
        this.configureDocuments(folder.documents);
      })
      return folderList;
  }

  configureDocuments(listDocuments:any){
    listDocuments.forEach((document:any) => {
      if(document.confidential){
        this.setConfidential(document);
      }
    });
  }

  setConfidential(item:any){
      let url = 'documentobjects/confidentialaccess';
      let id = item.documentObjectId;
      if(item.folderId){
          url = 'sharepointfolders/confidentialaccess';
          id = item.folderId;
      }
      this.apiService.get(url, id)
          .then((data:any)=>{ item.confidentialList = data; }
          ,(errorData)=>{})
  }

  getFilters(){
    let that:any = this.documentationList;
      that.filterDocumentationList.forEach((filterDocumentation:any) => {
        if(filterDocumentation.state){
          filterDocumentation.stateProcess = filterDocumentation.state.name;
        }
      });
      that.filters.forEach((filter:any)=>{
        that[filter+'List'] = this.filterColumnService.extractList(filter,  that.filterDocumentationList);
        that[filter+'ListFilter'] = [];
        if(filter === 'source'){
          that.sourceList.forEach((source:any) => {
            if(source.name === 'Template'){
              source.nameTranslate = this.translate.instant('new-document.template');
            }else if(source.name === 'User'){
                source.nameTranslate = this.translate.instant('common.user');
            }else if(source.name === 'Task'){
                source.nameTranslate = this.translate.instant('common.tasks');
            }else if(source.name === 'Process'){
                source.nameTranslate = this.translate.instant('dashboard.Process');
            }else{
                source.nameTranslate = source.name;
            }
          });
        }
      })
    this.loader.closeLoader(['documentation-list-on-init', 'upload-documentation-list']);
  }

  openDocumentationMenu(open:any, document:any){
      document.menu = [];
      let optionDocument = {
          processStates: this.documentationList.concept.processStates !== null,
          sibila: this.context.areas.sibila,
          canSignDocuments: this.context.user.canSignDocuments,
          multiple: this.documentationList.documentChecked > 1 && this.documentationList.multipleSelection,
          lockEdit: this.documentationList.concept.lockedEdit,
          public: this.public
      }

      if(open){
          if(document.extension !== 'folder' && this.documentationList.multipleSelection){
              this.checkedDocument(document);
          }
          if(this.documentationList.showTrash){
              document.menu = this.expandedMenuService.documentationDelete(this.documentationList.menuRestore, document, optionDocument);
          }else if(document.extension === 'folder'){
            document.menu = this.expandedMenuService.folderDocumentation(this.documentationList.menuFolder, document, optionDocument);
          }else if(this.documentationList.documentChecked > 1){
            document.menu = this.expandedMenuService.someDocumentation(this.documentationList.menuMultipleChecked, optionDocument);
          }else{
              let menuOptions = this.expandedMenuService.documentationPrincipal(this.documentationList.menuDocumentConcept, document, optionDocument);
              document.menu = menuOptions;
          }
          document.showMenu = true;
      }else{
          document.showMenu = false;
          document.showMoreMenu = false;
      }
  }

  orderBySearch(e:any, type:any){
      switch(e.action){
          case 'filter':
            this.documentationList[type+'WordFilter'] = e.newValue;
            this.filterDocumentation();
              break;
          case 'order':
            this.orderDate(type);
              break;

      }
  }

  filterDocumentation(){
      let filterList:any = [];
      filterList = this.filterColumnService.filterByWord(this.documentationList.nameWordFilter,  this.documentationList.filterDocumentationAux, 'name');
      this.documentationList.filters.forEach((filter:any) => {
        let filteredList:any = this.documentationList[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
      this.documentationList.filterDocumentationList = filterList;
  }

  selectTypeOperation(e:any, type:any){
      let item: any = e.source;
      let listFilter:any = this.documentationList[type+'ListFilter'];
      this.documentationList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      this.documentationList[type+'Filter'].hasFilter = this.documentationList[type+'ListFilter'].length;
      this.filterDocumentation();
  }

  newDocument(){
    let that: any = this.documentationList
    let isIE11:any = this.commonUtilsService.isIE();
    if(isIE11){
      this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
    }else{
        this.modalService.newDocument(that.templatesDocument, that.concept, 'documentation-list', that.filterDocumentationList, that.concept.listStates)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              if(this.validateDocumentService.duplicatedDocuments(result.name, that.concept.documentation)){
                let text: string = this.translate.instant('common.the-document')+"'"+result.name+"'"+this.translate.instant('documentation-list.is-already-added')
                this.modalService.adviceModal(text, 'error:accept:warning');

              }else{
                this.loader.openLoader('documentation-list-up-or-create')
                if(this.documentationList.breadcrumbList.length>1){
                    result.url = result.url+'/' + this.documentationList.breadcrumbList[this.documentationList.breadcrumbList.length-1].folderId;
                }
                this.createNewFile(result);
              }
            }
          })
    }
  }

  createNewFile(result:any){
      this.apiService.add(result.url, {name: result.name, stateId: result.stateId})
          .then((data:any) => {
              this.registerEvent('create document', data);
              if(this.documentationList.breadcrumbList.length > 1){
                  data.sharepointFolderId = this.documentationList.breadcrumbList[this.documentationList.breadcrumbList.length -1].folderId;
              }
              this.configureNewDocument(data, 'create', result);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-document') +"'"+ result.name + "'", 'error:accept:warning');
          });
  }

  configureNewDocument(document:any, type:any, result:any, lastDocument:any=false){
      if(!document.sourceName){
          document.sourceName= this.translate.instant('common.documentation');
      }
      if(result.stateId){
          document.stateProcess = result.nameState;
          document.state = {
              name: result.nameState,
              stateId: result.stateId
          }
      }
      let parentFolder = this.getFolder(this.documentationList.concept.documentation.subFolders, document.sharepointFolderId);
      parentFolder? parentFolder.documents.push(document) : this.documentationList.concept.documentation.documents.push(document)
      this.documentationList.concept.documentation.totalDocuments++;
      this.documentationList.filterDocumentationList.push(document);
      this.documentationList.filterDocumentationAux = this.documentationList.filterDocumentationList;
      this.getFilters();
      this.filterDocumentation();
      this.loader.closeLoader(['documentation-list-up-or-create']);
      if(type==='create' || result.files?.length === 1 || lastDocument){
        let text:string = this.translate.instant('common.the-document')+"'"+ document.name+"'"+this.translate.instant('documentation-list.add-ok');
        if(type==='create'){
            this.documentActions.doAction('open', document);
            text = this.translate.instant('common.the-document')+"'"+ document.name+"'"+this.translate.instant('documentation-list.created-ok');
        }else if(lastDocument && result.files?.length > 1){
            text = this.translate.instant('documentation-list.created-ok-all-document');
        }
        this.modalService.adviceModal(text, 'info:accept:check-round')
      }
      this.getFilters();
  }

  openQuestionDeletedSomeItem(){
      let documentsListSelected:any = [];
      let certificatedOrPublished:boolean = false;
      this.documentationList.filterDocumentationList.forEach((documentDeleted:any) => {
        let signatureOrCertification = documentDeleted.documentStatusId === 3 || documentDeleted.documentStatusId === 4 || documentDeleted.documentStatusId === 6 || documentDeleted.sendedAsCertifiedEmail || documentDeleted.sendedAsCertifiedSMS;
        if(documentDeleted.selectedDocument){
            documentsListSelected.push(documentDeleted);
        }
        if((signatureOrCertification || documentDeleted.publishedForApplicant) && documentDeleted.selectedDocument){
            certificatedOrPublished = (signatureOrCertification || documentDeleted.publishedForApplicant) && documentDeleted.selectedDocument;
        }
      });
        let text:string = certificatedOrPublished?this.translate.instant('documentation-list.delete-signature-or-certifcation-multiple'):this.translate.instant('documentation-list.question-remove-some-document').replace('{numberdocument}', this.documentationList.documentChecked);

        this.modalService.adviceModal(text, 'info:deleteOption:question').subscribe((result:any)=>{
          if(result.result!=='cancel'){
            this.loader.openLoader('documentation-list-delete-document');
            let documentsError = '';
            let defered:any = [];
            let completedPromises = 0;
            let errors = 0;
            let deferedRemove = new Promise((resolve:any)=>{
              for(let [index, documentSelected] of documentsListSelected.entries()){
                if(result.result === 'permanently-delete'){
                  defered[index] = this.deleteDocumentSelected(documentSelected, documentsListSelected.length)
                }else{
                  defered[index] = this.archiveDocumentSelected(documentSelected, documentsListSelected.length)
                }
              }
              for(let [index, documentsSelected] of documentsListSelected.entries()){
                  defered[index].then((value:any)=>{
                      if(value.errorArchive){
                          documentsError += errors?", '"+ value.name+ "'":"'"+ value.name+ "'";
                          errors++;
                      }
                      completedPromises++;
                      if(completedPromises===documentsListSelected.length){
                          resolve(documentsError);
                      }
                  })
              }
            });

            deferedRemove.then((value:any)=>{
                this.getFilters();
                this.documentationList.selectAllDocument = true;
                this.documentationList.documentChecked = 0;
                this.selectAllDocumentCheck();
                if(errors){
                    let text:string = "No ha sido posible completar la acción sobre los siguientes documentos: '{documents}'. Revisa las <button-link>restricciones de borrado</button-link>."
                    text = text.replace('{documents}', documentsError);
                    this.modalService.adviceModal(text, 'error:accept-link:warning:documentation');
                } else{
                    let text:string = result.result === 'permanently-delete'? 'documentation-list.delete-some-definiteve-ok' : 'documentation-list.document-some-trash';
                    this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
                }
                this.loader.closeLoader(['documentation-list-delete-document']);
            })
          }
      })

  }

  openQuestionDefinitiveMultiDelete(){
      let text:string = this.translate.instant('documentation-list.delete-some-definitive-question').replace('{deleteDocuments}', this.documentationList.documentChecked);
      let documentsListSelected:any = [];
      this.documentationList.filterDocumentationList.forEach((document:any) => {
        if(document.selectedDocument){
          documentsListSelected.push(document);
        }
      });

      this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('documentation-list-delete-document')
          let show = '';
          let defered:any = [];
          let promesasCumplidas = 0;
          let errors = 0;
          let deferedDeleted = new Promise((resolve:any)=>{
            for(let i = 0; i < documentsListSelected.length; i++){
              let documentSelected = documentsListSelected[i];
              defered[i] = this.deleteDocumentSelected(documentSelected);
            }
            for(let i = 0; i < documentsListSelected.length; i++){
                defered[i].then((value:any)=>{
                    if(value.errorArchive){
                        errors++;
                        show += ", '"+ value.name+ "'";
                    }
                    promesasCumplidas++;
                    if(promesasCumplidas===documentsListSelected.length){
                        resolve(show);
                    }
                })
            }
          });


          deferedDeleted.then((value:any)=>{
              this.getFilters();
              if(errors=== 0){
                  this.modalService.adviceModal(this.translate.instant('documentation-list.delete-some-definiteve-ok'), 'info:accept:check-round');
              }else if(errors === 1){
                  value = value.replace(', ','');
                  let text:string = this.translate.instant('documentation-list.error-some-documents').replace('{documents}', this.translate.instant('documentation-list.the-document') + value)
                  this.modalService.adviceModal(text, 'error:accept:warning');
              }else{
                  value = value.replace(', ','');
                  value = value.replace(/,([^,]*)$/, ' ' + this.translate.instant('common.and') + '$1');
                  let text:string = this.translate.instant('documentation-list.error-some-documents').replace('{documents}', value);
                  this.modalService.adviceModal(text, 'error:accept:warning');
              }
              this.documentationList.documentChecked = 0;
              this.loader.closeLoader(['documentation-list-delete-document'])
          })
        }
      })
  }

  openQuestionDeletedItem(document:any){
      let type:string = 'info:deleteOption:question';
      let signatureOrCertification:boolean = document.documentStatusId === 3 || document.documentStatusId === 4 || document.documentStatusId === 6 || document.sendedAsCertifiedEmail || document.sendedAsCertifiedSMS;
      let text:string = this.translate.instant('documentation-list.question-remove-document-definitely').replace('{document}', document.name);
      if(document.extension === 'folder'){
          text = this.translate.instant('documentation-list.delete-folder').replace('{folder}', document.name);
      }else if(signatureOrCertification){
          text = this.translate.instant('documentation-list.delete-signature-or-certifcation');
      }else if(document.publishedForApplicant){
          text = this.translate.instant('documentation-list.delete-published-for-applicant');
      }

      this.modalService.adviceModal(text, type).subscribe((result:any)=>{
        if(result.result !=='cancel'){
          this.loader.openLoader('documentation-list-delete-document')

          if(document.extension !=='folder'){
            result.result === 'permanently-delete'? this.deleteDocumentSelected(document) : this.archiveDocumentSelected(document);
          }else{
              if(result.result === 'permanently-delete'){
                  this.deleteFolderSelected(document, 'documentation-list');
              }else if(this.checkedName(this.documentationList.filterDocumentationListDeleted, document, 'folderId')){
                  this.archiveFolderSelected(document);
              }else{
                  this.loader.closeLoader(['documentation-list-delete-document']);
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-folder').replace('{name}', document.name), 'error:accept:warning');
              }
          }
        }
      })
  }

  openQuestionDefinitiveDelete(item:any){
    let that: any = this.documentationList
      let text = '';
      if(item && item.folderId){
          text = this.translate.instant('documentation-list.delete-definitive-folder').replace('{deleteFolder}',item.name);
      }else{
        text = that.documentChecked > 1? this.translate.instant('documentation-list.delete-some-definitive-question').replace('{deleteDocuments}', that.documentChecked): this.translate.instant('documentation-list.delete-definitive-question').replace('{deleteDocument}',item.name);
      }
      this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('documentation-list-delete-document');
          if(that.documentChecked > 1){
              that.filterDocumentationList.forEach((documentSelected:any) => {
                if(documentSelected.selectedDocument){
                  this.deleteDocumentSelected(documentSelected);
                }
              });
          }else if(item.folderId){
              this.deleteFolderSelected(item, 'trash');
          }else if(item.documentObjectId){
              this.deleteDocumentSelected(item);
          }
        }
      })
  }

  deleteFolderSelected(folder:any, from:string){
    let that: any = this.documentationList;
      this.apiService.delete('sharepointfolders', folder.folderId)
          .then((data:any)=>{
              that.filterDocumentationList = this.commonUtilsService.deleteElement(folder, that.filterDocumentationList, 'folderId');
              that.filterDocumentationAux = that.filterDocumentationList;
              if(from === 'trash'){
                  that.filterDocumentationListDeleted = that.filterDocumentationList;
              }else if(from === 'documentation-list'){
                  if(that.breadcrumbList.length > 1){
                      let folderConcept = this.getFolder(that.concept.documentation.subFolders,that.breadcrumbList[that.breadcrumbList.length-1].folderId);
                      folderConcept.subFolders.splice(folderConcept.subFolders.indexOf(folder),1);
                  }else{
                      that.concept.documentation.subFolders.splice(that.concept.documentation.subFolders.indexOf(folder),1);
                  }
              }
              this.loader.closeLoader(['documentation-list-delete-document']);
              let text:string = this.translate.instant('documentation-list.folder-delete-ok').replace('folder-delete-ok',folder.name);
              this.getCountDeletedDocuments();
              this.modalService.adviceModal(text, 'info:accept:check-round');

          },(errorData:any)=>{
            let text:any = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('documentation-list.error-delete-folder') +" '"+ folder.name + "'";
            this.modalService.adviceModal(text, 'error:accept:warning')
            this.loader.closeError();
          })
  }

  deleteItemInConcept(item:any){
    if(!item.sharepointFolderId && !item.parentId){
      if(item.documentObjectId){
        this.documentationList.concept.documentation.documents = this.commonUtilsService.deleteElement(item, this.documentationList.concept.documentation.documents, 'documentObjectId');
      }else{
        this.documentationList.concept.documentation.subFolders = this.commonUtilsService.deleteElement(item, this.documentationList.documentation.subFolders, 'folderId');
      }
  }else{
      this.searchItemLocation(item, this.documentationList.concept.documentation.subFolders);
  }
  }

  searchItemLocation(item:any, list:any){
    for(let folder of list){
      for(let document of folder.documents){
        if(item.sharepointFolderId){
          if(document.documentObjectId === item.documentObjectId && document.sharepointFolderId === item.sharepointFolderId){
              folder.documents = this.commonUtilsService.deleteElement(item, folder.documents, 'documentObjectId');
          }
        }else{
          if(folder.folderId === item.folderId && document.parentId === item.parentId){
              folder.subFolders = this.commonUtilsService.deleteElement(item, folder.subFolders, 'documentObjectId');
          }
        }
      }
      if(folder.subFolders.length){
          this.searchItemLocation(item, folder.subFolders);
      }
    }
  }

  deleteDocumentSelected(document:any, multiple:any = false){
      let that:any = this.documentationList
      let defer:any = new Promise((resolve:any)=>{
        this.apiService.delete('documentobjects', document.documentObjectId )
          .then((data:any)=>{
              that.filterDocumentationList = this.commonUtilsService.deleteElement(document, that.filterDocumentationList, 'documentObjectId');
              that.filterDocumentationAux = that.filterDocumentationList;
              if(that.showTrash){
                  that.filterDocumentationListDeleted = that.filterDocumentationList;
              }else{
                  that.concept.documentation.totalDocuments--;
                  this.deleteItemInConcept(document);
                  this.checkedFields(document);
              }
              this.loader.closeLoader(['documentation-list-delete-document']);
              let text:string = this.translate.instant('documentation-list.definited-deleted-ok').replace('definited-deleted-ok',document.name);
              that.archivedCount--;
              if(that.documentChecked < 2){
                this.modalService.adviceModal(text, 'info:accept:check-round')
              }
              this.registerEvent('download document', document);
              resolve(document);
          },(errorData:any)=>{
              if(!multiple){
                  if(errorData.status === 403 && errorData.description === "delete-error-permissions"){
                      this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');
                  }else{
                      let text:string = errorData.description.exceptionMessage === 'File is locked'?  this.translate.instant('common.the-document') +"'"+ document.name + "'" + this.translate.instant('documentation-list.file-lock') : this.translate.instant('documentation-list.error-remove-document') +"'"+ document.name + "'"
                      if(that.documentChecked < 2){
                        this.modalService.adviceModal(text, 'error:accept:warning');
                      }
                  }
              }
              document.errorArchive = true;
              resolve(document);
              this.loader.closeError();
          })
      });
    return defer;
  }

  checkedFields(document:any){
    this.documentationList.concept.evaluatedFields.forEach((field:any) => {
      if(field.fieldTypeId === 'file' || field.fieldTypeId === 'templ'){
        field.documents = this.commonUtilsService.deleteElement(document, field.documents, 'documentObjectId');
      }
    });
  }

  archiveDocumentSelected(document:any, multiple:any = false){
      let that: any = this.documentationList
      let deferArchive = new Promise((resolve:any)=>{
        if(this.checkedName(that.filterDocumentationListDeleted, document, 'documentObjectId') && !(document.anonymous && that.concept.lockedEdit)){
          this.apiService.delete('documentobjects', document.documentObjectId, 'torecyclebin')
              .then((data:any) => {
                  that.showButtonTrash = true;
                  this.checkedFields(document);
                  that.concept.documentation.totalDocuments--;
                  that.filterDocumentationList = this.commonUtilsService.deleteElement(document, that.filterDocumentationList, 'documentObjectId');
                  that.filterDocumentationAux = that.filterDocumentationList;
                  this.deleteItemInConcept(document);
                  if(!multiple){
                      that.selectAllDocument = true;
                      this.selectAllDocumentCheck();
                      this.getFilters();
                      this.loader.closeLoader(['documentation-list-delete-document']);
                      this.modalService.adviceModal(this.translate.instant('documentation-list.document-trash').replace('{document}',document.name), 'info:accept:check-round');
                  }
                  document.menu = that.menuRestore;
                  let deleteDate = new Date();
                  if (deleteDate !== null){
                    document.deleted =deleteDate.getTimezoneOffset() < 0? moment(deleteDate.setMinutes(Math.abs(deleteDate.getTimezoneOffset()) + deleteDate.getMinutes())).toISOString() : moment(deleteDate).toISOString();
                  }
                  document.deletedBy = this.context.user.fullName;
                  document.selectedDocument = false;
                  document.sharepointFolderId = null;
                  that.filterDocumentationListDeleted.push(document);
                  that.archivedCount++;
                  document.errorArchive = false;
                  resolve(document);
                  this.checkButtonShowTrash();
              }, (errorData:any)=>{
                  if(!multiple){
                      if(errorData.status === 403 && errorData.description === "delete-error-permissions"){
                        this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');
                      }else{
                          let text:string = errorData.description.exceptionMessage === 'File is locked'? this.translate.instant('common.the-document') +"'"+ document.name + "'" + this.translate.instant('documentation-list.file-lock') : this.translate.instant('documentation-list.error-remove-document') +"'"+ document.name + "'";
                          if(that.documentChecked < 2){
                              this.modalService.adviceModal(text, 'error:accept:warning');
                          }
                      }
                  }
                  document.errorArchive = true;
                  resolve(document);
                  this.loader.closeError();
              });
      }else{
          if(multiple){
              document.errorArchive = true;
              resolve(document);
          }else{
              this.loader.closeLoader(['documentation-list-delete-document']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-document').replace('{name}', document.name), 'error:accept:warning');
          }
      }
      });
      return deferArchive;
  }

  showSubMenu(e:any, option:any, documentSelected:any, index:any){
      let that:any = this.documentationList;
      if(!that.showTrash){
          that.topSubmenu = (index*47)+5;
          e.stopPropagation();
          e.preventDefault();
          if(documentSelected && documentSelected.showMoreMenu && index !== that.lastIndex && that.subMenuShow !== option.action){
              documentSelected.showMoreMenu = false;
              documentSelected.menu.forEach((select:any) => {
                select.selected = false;
              });
          }
          that.subMenuShow = option.action;
          that.lastIndex = index;
          let optionDocument:any = {
              processStates: that.concept.processStates !== null,
              sibila: this.context.areas.sibila,
              userPermissions: this.userPermissions,
              canSignDocuments: this.context.user.canSignDocuments,
              multiple: that.documentChecked > 1 && that.multipleSelection,
              lockEdit: that.concept.lockedEdit,
              public: that.public
          }
          if(!documentSelected.showMoreMenu){
              let type: string = '';
              switch(option.action){
                  case 'edit-view':
                    type = 'menuDocumentViewEdit'
                    break;
                  case 'signature-certification':
                    type = 'menuDocumentSignature';
                    break;
                  case 'share-options':
                    type = 'menuDocumentShare';
                    break;
                  case 'configuration':
                    type = 'menuDocumentConfiguration';
                    break;
              }
              if(type !== ''){
                documentSelected.menuExpanded = this.expandedMenuService.documentationPrincipal(that[type],  documentSelected, optionDocument);
                option.selected = true;
                documentSelected.showMoreMenu = !documentSelected.showMoreMenu;
              }
          }
      }
  }

  launchAction(e:any, type:any, documentSelected:any = null){
      if(type === 'view' && documentSelected?.extension?.toLowerCase() === 'tif'){
        type = 'download';
      }
      switch(type){
          case 'edit':
              let isIE11 = this.commonUtilsService.isIE();
              if(!isIE11){
                  this.documentActions.doAction('open', documentSelected);
                  this.apiService.add('documentobjects/logedit/'+documentSelected.documentObjectId).then(function(data){}, function(errorData){})
              }else{
                  this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
              }
              break;
          case 'view':
            this.registerEvent('open document', documentSelected);
              if(documentSelected.extension==="PDF" && this.globalCfg.caps.externalPdfViewer){
                  this.apiService.get('documentobjects/pdfviewerurl/'+documentSelected.documentObjectId)
                    .then((data:any)=>{
                        data.error==="true"? this.modalService.adviceModal(data.errorMessage,'error:accept:warning') : window.open(data.pdfUrl,"_blank");
                  }, (errorData:any)=>{});
              }else{
                  this.documentActions.doAction('open', documentSelected);
              }
              break;
          case 'edit-local':
              this.editLocal(documentSelected);
              break;
          case 'duplicate':
              this.registerEvent('duplicate document', documentSelected);
              this.duplicateDocumentSelected(documentSelected);
              break;
          case 'sibila':
              this.openSibila(documentSelected);
              break;
          case 'delete':
              this.openQuestionDeletedItem(documentSelected);
              break;
          case 'delete-some':
              this.openQuestionDeletedSomeItem();
              break;
          case 'definitive-delete':
              this.openQuestionDefinitiveDelete(documentSelected);
              break;
          case 'definitive-delete-some':
              this.openQuestionDefinitiveMultiDelete();
              break;
          case 'restore':
              this.openQuestionRestore(documentSelected);
              break;
          case 'restore-some':
              this.openQuestionMultiRestore(documentSelected);
              break;
          case 'new-version':
              this.createNewVersion(documentSelected);
              break;
          case 'publish':
              this.publishDocument(documentSelected);
              break;
          case 'anonymatum-document':{
              if(this.context.anonymatumService){
                  this.adviseAnonymatum(documentSelected);
              }else{
                  this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-adquired'), 'error:accept:warning',  '', [], '', true)
              }
              break;
          }
          case 'historic':
              this.openHistoric(documentSelected);
              break;
          case 'signature':
          case 'share':
          case 'share-some':
          case 'send-mail-certificate':
          case 'send-sms-certificate':
              this.openModalShare(type, documentSelected);
              break;
          case 'certify-document':
              this.registerEvent('cetify document', documentSelected);
              this.certifyDocumentModal(documentSelected);
              break;
          case 'request-tracking':
              this.openRequestTracking(documentSelected);
              break;
          case 'add-alert':
              this.openAddAlert(documentSelected, 'document');
              break;
          case 'download':
              this.registerEvent('download document', documentSelected);
              this.documentActions.doAction('download', documentSelected);
              break;
          case 'download-some':
              this.downloadDiversDocuments();
              break;
          case 'convert':
              this.openQuestionConvert(documentSelected);
              break;
          case 'confidential':
              this.openModalConfidential(documentSelected);
              break;
          case 'rename':
              this.openChangeNameDocument(documentSelected);
              break;
          case 'move':
              this.openMoveModal(documentSelected, []);
              break;
          case 'move-some':
              this.addSelectedDocument();
              break;
          case 'open-folder':
              this.openFolder(documentSelected);
              break;
          case 'new-subfolder':
              this.newFolder(documentSelected);
              break;
          case 'associate-process':
              this.associateDocument(documentSelected);
              break;
          case 'post-requester':
              this.postToRequester(documentSelected);
              break;
          default:
              e.stopPropagation();
              e.preventDefault();
              break;
      }
  }

  registerEvent(type:any, documentSelected:any){
    let resgisterItem:any ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event': type,
      'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.documentationList.concept.title,
      'Source':documentSelected.sourceName,
      'Template':(documentSelected.source === 'Template'),
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem);
  }

  publishDocument(document:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-publish-document')+"'"+document.name+"' ?", 'info:question:question')
        .subscribe((result:any)=> {
          if(result.result==='ok'){
            this.loader.openLoader('documentation-list-publish-document')
            this.publishDocumentservice(document)
          }
        })
  }

  publishDocumentservice(document:any){
      this.apiService.add('documentobjects/publish/'+document.documentObjectId,{} )
          .then((data:any)=>{
              this.modifyDocument(document, data);
              this.loader.closeLoader(['documentation-list-publish-document']);
              this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+ document.name+"'"+this.translate.instant('documentation-list.publicated-document'), 'info:accept:check-round');
          }, (errorData)=>{
              this.loader.closeError();
              let text: any = errorData.description === 'File is locked' ? this.translate.instant('common.the-document') + "'" + document.name + "'" + this.translate.instant('documentation-list.file-lock2') : this.translate.instant('documentation-list.error-publish-document') + "'" + document.name + "'";
              this.modalService.adviceModal(text, 'error:accept:warning');
          })
  }

  modifyDocument(document:any, data:any){
    let documentation:any = this.documentationList.concept.documentation
      data.sourceName = document.sourceName;
      data.canBeDeleted = document.canBeDeleted;
      data.fileSize = document.fileSize;
      data.sharepointFolderId = document.sharepointFolderId;
      data.publishedForApplicant = document.publishedForApplicant;
      data.confidential = document.confidential;
      data.confidentialList = document.confidentialList;

      if(!document.sharepointFolderId){
        documentation.documents = this.commonUtilsService.replaceElement( document, documentation.documents, 'documentObjectId', data);
      }else{
        this.replaceDocument(document, documentation.subFolders);
      }

      this.documentationList.filterDocumentationList = this.commonUtilsService.replaceElement( document, this.documentationList.filterDocumentationList, 'documentObjectId', data);
      this.documentationList.filterDocumentationAux = this.documentationList.filterDocumentationList;
      this.filterDocumentation();
  }

  replaceDocument(documentChange:any, list:any){
    for(let folder of list){
      for(let document of folder.documents){
          if(document.documentObjectId === documentChange.documentObjectId && document.sharepointFolderId === documentChange.sharepointFolderId){
              folder.documents = this.commonUtilsService.deleteElement(documentChange, folder.documents, 'documentObjectId');
          }
      }
      if(folder.subFolders.length){
          this.replaceDocument(documentChange, folder.subFolders);
      }
    }
  }

  createNewVersion(document:any){
      this.modalService.newVersion()
        .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.loader.openLoader('documentation-list-new-version');
              this.checkNameVersion(result.name, document);
            }
        })
  }

  checkNameVersion(name:any, document:any){
      this.apiService.add('documentobjects/existdraftfile/'+document.documentObjectId, {name: name})
          .then((data:any)=>{
              if(!data){
                  this.newVersion(document, name);
              }else{
                this.loader.closeLoader(['documentation-list-new-version'])
                this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+name+"'"+this.translate.instant('documentation-list.already-exists'), 'error:accept:warning');
              }
          }, (errorData:any)=>{
              this.loader.closeError()
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-new-version')+"'"+name+"'", 'error:accept:warning');
          })
  }

  newVersion(document:any, versionName:any){
    let concept: any = this.documentationList.concept
      this.apiService.add('documentobjects/newversion/'+document.documentObjectId,{name: versionName})
          .then((data:any)=> {
              data.sourceName = document.sourceName;
              data.fileSize = document.fileSize;
              data.sharepointFolderId = document.sharepointFolderId;
              concept.documentation.totalDocuments++;
              this.documentationList.filterDocumentationList.push(data);
              !document.sharepointFolderId?concept.documentation.documents.push(data) :  this.addDocumentInConcept(data, concept.documentation.subFolders);
              this.filterDocumentation();
              this.loader.closeLoader(['documentation-list-new-version']);
              this.getFilters();
              this.modalService.adviceModal(this.translate.instant('documentation-list.new-version-document')+"'"+ versionName+"'"+this.translate.instant('documentation-list.created'), 'info:accept:check-round');
          }, ()=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-version')+"'"+versionName+"'", 'error:accept:warning');
          })
  }

  addDocumentInConcept(documentAdd:any, list:any){
      for(let folder of list){
          if(documentAdd.sharepointFolderId === folder.folderId){
              folder.documents.push(documentAdd);
              break;
          }
          if(folder.subFolders.length){
              this.addDocumentInConcept(documentAdd, folder.subFolders);
          }
      }
  }

  orderDate(type:any, event:any = null) {
      if (event) {
          event.stopPropagation();
      }
      if(this.documentationList.dateOrder === type){
          this.documentationList.orderOperationDates = !this.documentationList.orderOperationDates;
      }else{
        this.documentationList.dateOrder = type;
        this.documentationList.orderOperationDates = type !== 'name';
      }
  }

  adviseAnonymatum(document:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-advise'), 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.anonymatumDocument(document);
        }
      })
  }

  anonymatumDocument(document:any){
      let itemSelected = {
          type: 'anonymatum-document',
          item: document,
      }
      this.modalService.informativeLoadingModal(itemSelected)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          result.item.sourceName = this.translate.instant('common.documentation');
          for(let i = 0; i < this.documentationList.filterDocumentationList.length; i++){
              if(result.item.documentObjectId === this.documentationList.filterDocumentationList[i].documentObjectId){
                  this.documentationList.filterDocumentationList.splice(i, 1, result.item)
                  break;
              }
          }
          this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-document-ok'), 'info:accept:check-round')
        }else if(result?.error?.description ==="anonymize_no_credits"){
          this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-credits'), 'error:accept:warning',  '', [], '', true)
        }else{
            this.modalService.adviceModal([this.translate.instant('documentation-list.anonymatum-document-error'), this.translate.instant('documentation-list.anonymatum-document-causes')], 'error:error-link:warning')
        }
      })

  }

  openHistoric(document:any){
      this.modalService.documentHistoric(document)
  }

  certifyDocumentModal(document:any){
      this.modalService.certifyDocumentModal().subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('documentation-list-certify-document');
          this.certifyDocument(document);
        }
      })

  }

  certifyDocument(document:any){
      this.apiService.update('documentobjects/certifydocument', document.documentObjectId, null)
          .then((data:any)=>{
              document.documentStatusId = 6;//Para que se quede bloqueado, preguntar para confirmar que este será su estado
              document.status = this.translate.instant('documentation-list.certificate');
              this.loader.closeLoader(['documentation-list-certify-document']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.confirm-certify'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let errorText = this.translate.instant('documentation-list.certified-error');
              if(errorData.description==='no_signatures_available' || errorData.description.exceptionMessage==='no_signatures_available'){
                  errorText = this.translate.instantSiteTerms('signature-limit');
              }
              this.modalService.adviceModal(errorText, 'error:accept:warning');
          })
  }

  openRequestTracking(document:any){
      this.modalService.requestTrackingModal(document);
  }

  openModalShare(type:any, document:any){
      let configuration:any = {
          type: type,
          addContacts: true,
          document: document
      }
      this.modalService.documentShare(configuration).subscribe((result:any)=>{
        if(result.result ==='ok'){
          if(type === 'share'){
            this.loader.openLoader('documentation-list-document-share')
            this.shareDocument(document, result.share);
          }else if(type === 'share-some'){
              this.loader.openLoader('documentation-list-document-share-shome')
              let listShareDocument:any = [];
              this.documentationList.filterDocumentationList.forEach((filterDocument:any) => {
                if(filterDocument.selectedDocument){
                  for(let shareDocument of result.share){
                      shareDocument.documentObjectId = filterDocument.documentObjectId;
                      listShareDocument.push(shareDocument);
                  }
                }
              });

              this.shareSomeDocument(listShareDocument);
          }else{
              this.loader.openLoader('documentation-list-share-signature')
              let event = 'send to signature';
              if(type === 'send-mail-certificate'){
                  event = 'send certified email'
              }else if(type === 'send-sms-certificate'){
                  event = 'send certified SMS'
              }
              this.registerEvent(event, document);
              this.sendSignature(result.share, document, type);
          }
        }
      })

  }

  shareSomeDocument(share:any){
      this.apiService.add('documentobjects/sharedocuments', share)
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-document-share-shome']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.shared-ok'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  shareDocument(document:any, share:any){
      this.apiService.add('documentobjects/sharedocument/'+ document.documentObjectId, share)
      .then((data:any)=>{
          this.loader.closeLoader(['documentation-list-document-share']);
          this.modalService.adviceModal(this.translate.instant('common.the-document')+ "'"+document.name+"'"+this.translate.instant('documentation-list.shared-document'), 'info:accept:check-round');
      }, (errorData:any)=>{
          this.loader.closeError();
      })
  }

  sendSignature(share:any, document:any, type:any){
      let recipientsid:any = [];
      share.forEach((instance:any) => {
        type === 'send-sms-certificate'?recipientsid.push(instance.phoneNumberSMS) : recipientsid.push(instance.email);
      });

      let DocumentToSign:any = {
          recipientsid:recipientsid
      }
      let url:string = 'documentobjects/signdocument';
      if(type === 'send-mail-certificate'){
          url = 'documentobjects/certifiedemaildocument';
      }else if(type === 'send-sms-certificate'){
          url = 'documentobjects/certifiedsmsdocument';
      }
      this.apiService.update(url, document.documentObjectId, DocumentToSign)
          .then((data:any)=>{
              window.open(data, '_blank');
              document.extension = 'PDF';
              document.name = document.name.substr(0, document.name.lastIndexOf('.')+1) + document.extension.toLowerCase();
              document.editPath = document.editPath.substr(0, document.editPath.lastIndexOf('.')+1) + document.extension.toLowerCase();

              let text:string = type === 'send-sms-certificate'? 'documentation-list.document-sms-certificate' : 'documentation-list.document-mail-certificate';

              if(type === 'signature'){
                  text = 'documentation-list.document-signature';
                  document.status = this.translate.instant('documentation-list.signature-proccess');
                  document.documentStatusId = 3;
              }else{
                  if(type === 'send-mail-certificate'){
                      document.sendedAsCertifiedEmail = true;
                  }else if(type === 'send-sms-certificate'){
                      document.sendedAsCertifiedSMS = true;
                  }
                  if(document.documentStatusId === 1){
                      document.documentStatusId = 2;
                      document.status = this.translate.instant('common.published');
                  }
              }
              this.loader.closeLoader(['documentation-list-share-signature']);
              this.getFilters();
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
              let errorText:string = 'documentation-list.signature-error';
              if(errorData.description==='no_signatures_available' || errorData.description.exceptionMessage==='no_signatures_available'){
                  errorText = 'signature-limit';
                    this.modalService.adviceModal(this.translate.instantSiteTerms(errorText), 'error:accept:warning')
                return
              }else if(type === 'send-mail-certificate'){
                  errorText = 'documentation-list.mail-certificate-error';
              }else if(type === 'send-sms-certificate'){
                  errorText = 'documentation-list.sms-certificate-error';
              }
              this.modalService.adviceModal(this.translate.instant(errorText), 'error:accept:warning')
          })
  }

  openModalConfidential(document:any){
      this.modalService.manageConfidentialModal(document)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('documentation-list-confidential-document')
          this.confidentDocument(document, result);
        }
      })
  }

  confidentDocument(document:any, resultModal:any) {
      let list:any = resultModal.share;
      let confidential:any = resultModal.confidential.toString();
      let confidentialOfUserConnected:any = resultModal.confidentialOfUserConnected;
      let url:string = document.folderId?'sharepointfolders/' +document.folderId : 'documentobjects/' +document.documentObjectId;

      this.apiService.patch(url, confidential, list)
          .then((data:any)=>{
              document.confidential = resultModal.confidential;
              let text:string = document.confidential? 'manage-confidential-modal.confident-ok' : 'manage-confidential-modal.confident-uncheck';
              if(!document.confidential){
                  document.confidentialList = [];
              }
              if(document.confidential && !confidentialOfUserConnected){
                  let typeId:string = document.documentObjectId? 'documentObjectId' : 'folderId';
                  this.documentationList.filterDocumentationList = this.commonUtilsService.deleteElement(document, this.documentationList.filterDocumentationList, typeId);
                  this.documentationList.filterDocumentationAux = this.documentationList.filterDocumentationList;
                  if(document.documentObjectId){
                      this.documentationList.concept.documentation.totalDocuments--;
                      this.deleteItemInConcept(document);
                  }else{
                      this.removeDocumentsToFolder(document);
                  }
              }
              this.loader.closeLoader(['documentation-list-confidential-document']);
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
          },(errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning');
          })
  }

  removeDocumentsToFolder(folder:any){
      if(folder.documents && folder.documents.length){
          folder.forEach((documentDeleted:any)=>{
            this.documentationList.concept.documentation.totalDocuments--;
            this.checkedFields(documentDeleted);
          })
      }
      if(folder.subFolders && folder.subFolders.length){
        folder.subFolders.forEach((subFolder:any)=>{
          this.removeDocumentsToFolder(subFolder);
        })
      }
  }

  addSelectedDocument(){
      let listDocumentSelected:any = [];
      let document:any = null;
      this.documentationList.filterDocumentationList.forEach((documentSelected:any) => {
        if(documentSelected.selectedDocument){
          listDocumentSelected.push(documentSelected);
          document = documentSelected;
        }
      });
      this.openMoveModal(document, listDocumentSelected);
  }

  openMoveModal(document:any, listDocumentSelected:any){
      this.modalService.moveModal(document, this.documentationList.concept, listDocumentSelected)
      .subscribe((result:any)=>{
        let that: any = this.documentationList
        this.loader.closeLoader(['move-item']);
        that.selectAllDocument = true;
        this.selectAllDocumentCheck();
        switch(result.result){
          case 'ok':
              that.breadcrumbList = result.breadcrumbList;
              this.moveModalOk(result)
              break;
          case 'ok-with-errors':
              this.moveModalWithErrors(result);
              break;
          case 'error-name':
          case 'error-folder':
              let type: string = result.result === 'error-folder'? 'folder':'document';
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-'+type).replace('{name}', document.name), 'error:accept:warning');
              break;
          case 'error-all-name':
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-all-move-documents'), 'error:accept:warning');
              break;
          default:
              if(result.addedFolder){
                  let folderList:any = that.breadcrumbList.length > 1? that.breadcrumbList[that.breadcrumbList.length - 1] : that.concept.documentation;
                  this.updateList(folderList);
              }
              break;
        }

        that.filterDocumentationAux = that.filterDocumentationList;
      })
  }

  moveModalWithErrors(result:any){
    let that:any = this.documentationList
    let breadcrumb:any = result.breadcrumbList;
    let errorDocument: string = '';
    if(result.documentErrors.length === 1){
        errorDocument = this.translate.instant('documentation-list.error-some-documents').replace('{documents}', this.translate.instant('documentation-list.the-document') + result.documentErrors[0].name);
    }else if(result.documentErrors.length > 1){
        errorDocument = this.translate.instant('documentation-list.error-some-documents').replace('{documents}',  this.translate.instant('documentation-list.the-documents'));
        for(let i = 0; i <  result.documentErrors.length; i++){
          let errorName = result.documentErrors[i].name
          if(i === result.documentErrors.length - 2){
            errorDocument = errorDocument.concat("'"+errorName+"'" + " "+ this.translate.instant('common.and') + " ");
          }else if(i === result.documentErrors.length - 1){
              errorDocument = errorDocument.concat("'"+errorName+"'" + '.');
          }else{
              errorDocument = errorDocument.concat("'"+errorName+"'" + ', ');
          }
        }
    }
    this.modalService.adviceModal(errorDocument, 'error:accept:warning')
      .subscribe((result:any)=>{
        that.breadcrumbList = breadcrumb;
        this.moveModalOk(result)
    })
  }
  moveModalOk(result:any){
    let that:any = this.documentationList
    if(result.folderSelected){
        this.openFolder(result.folderSelected);
    }else{
        that.filterDocumentationList = that.breadcrumbList[0].subFolders;
        that.selectAllDocument = true;
        this.selectAllDocumentCheck();
        that.documentChecked = 0;
    }
  }

  updateList(list:any){
    let that: any = this.documentationList
      list.subFolders = this.configureFolder(list.subFolders);
      that.filterDocumentationList = list.documents;
      that.filterDocumentationList = [...that.filterDocumentationList, ...list.subFolders];
      that.filterDocumentationAux = that.filterDocumentationList;
      this.getFilters();
  }

  openChangeNameDocument(document:any){
      let lastPoint:any = document.name.lastIndexOf('.');
      if(document.extension !== 'folder'){
        if(lastPoint !== -1){
          this.documentationList.lastExtension = document.name.substring(lastPoint, document.name.length);
        }else{
          document.name + this.documentationList.lastExtension;
        }
      }

      let newItem:any = {
          title:  this.translate.instant('documentation-list.rename-document'),
          type: 'document',
          titleConcept: document.name,
          conceptId: null,
          conceptObjectId: document.conceptObjectId,
          listFolders: [],
          rename: true
      }
      if(document.extension==='folder'){
          newItem.title = this.translate.instant('documentation-list.rename-folder');
          newItem.extension = document.extension;
          newItem.type = 'folder';
      }

      this.modalService.renameModal(newItem, this.documentationList.filterDocumentationList).subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('folder-rename-modal');
          this.changeName(document, result.name);
        }
      })
  }

  changeName(document:any, name:any){
      let url: string = 'documentobjects'
      let id: any = document.documentObjectId;

      if(document.extension==='folder'){
          url = 'sharepointfolders/rename'
          id = document.folderId;
      }

      let newDocument:any = {
          name: name
      };
      this.apiService.patch(url, id, newDocument)
          .then((data:any)=>{
              document.name = name;
              if(document.extension !=='folder'){
                document.name = name +'.'+ document.extension.toLowerCase();
                document.editPath = document.editPath.substring(0, document.editPath.lastIndexOf('/')+1) + newDocument.name +'.'+ document.extension.toLowerCase()
              }
              this.loader.closeLoader(['folder-rename-modal']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.rename-ok-message'), 'info:accept:check-round');
          }, ()=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-rename-document')+"'"+document.name+"'", 'error:accept:warning');
          })
  }

  openAddAlert(document:any, type:any){
      let configuration:any = {
          from: 'documentationList',
          type: type,
          concept: this.documentationList.concept
      }
      this.modalService.addAlert(configuration)
          .subscribe((result:any)=>{
              if(result.result==='ok'){
                  this.loader.openLoader('documentation-list-send-alert')
                  this.sendAlert(document, result, type)
              }
          })
  }

  sendAlert(document:any, result:any, type:any){
      let resgisterItem:any ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':'create alert',
          'Alert Type':type,
          'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.documentationList.concept.title,
          'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem);
      this.apiService.add('documentalerts/'+document.documentObjectId, result.alert)
          .then((data:any)=>{
              let text = this.translate.instant('alert-concept.the-alert') +"'"+ result.alert.title +"'"+ this.translate.instant('documentation-list.in-document') +"'"+ document.name+"'"+this.translate.instant('documentation-list.created');
              if((result.share && result.share.length) || !result.alert.omitNotifyToCreator){
                this.sendShare(data, result.share, text);
              }else{
                this.closeCreateAlert(text);
              }
          }, (errorDate:any)=>{
              this.loader.closeError();
          })
  }

  sendShare(alert:any, share:any, text:any){
      this.apiService.add('documentalerts/sharealert/' + alert.documentAlertId + '/omit-notify-creator/' + alert.omitNotifyToCreator, share)
          .then((data:any)=>{
              this.closeCreateAlert(text);
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  closeCreateAlert(text: any){
    this.loader.closeLoader(['documentation-list-send-alert']);
    this.modalService.adviceModal(text, 'info:accept:check-round');
    this.modifyConceptTab.emit();
  }

  downloadDiversDocuments(){
      this.documentationList.filterDocumentationList.forEach((document:any) => {
          if(document.selectedDocument){
              this.registerEvent('upload document', document);
              this.documentActions.doAction('download', document);
          }
      });
      this.documentationList.selectAllDocument = true;
      this.selectAllDocumentCheck();
  }

  openQuestionConvert(document:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-convert')+"'"+document.name+"' ?", 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result ==='ok'){
              this.loader.openLoader('documentation-list-convert-document')
              this.convertDocument(document);
          }
      })
  }

  editLocal(document:any) {
      let protocol:any = null;
      switch (document.extension) {
          case 'DOC':
          case 'DOCX':
              protocol = 'ms-word';
              break;
          case 'XLS':
          case 'XLSX':
              protocol = 'ms-excel';
              break;
          case 'PPT':
          case 'PPTX':
              protocol = 'ms-powerpoint';
              break;
      }
      if (protocol) {
          let spSite = document.sharepointSiteUrl || document.DepotUri
          let appUri = protocol + ':ofe|u|' + spSite + document.documentLibraryName + '/' + document.editPath;
          window.open(appUri);
          this.apiService.add('documentobjects/logedit/'+document.documentObjectId).then(function(data){}, function(errorData){})
      } else {
          console.log("Local edit: Unknow extension: " + document.extension);
      }
  }

  duplicateDocumentSelected(document:any){
      this.modalService.duplicateDocument(document, this.documentationList.filterDocumentationAux)
          .subscribe((result:any)=>{
              if(result.result==='ok'){
                  this.loader.openLoader('documentation-list-duplicate-document');
                  this.createDuplicateDocument(document, result.name);
              }
          })
  }

  createDuplicateDocument(document:any, name:any){
      this.apiService.add('documentObjects/duplicatedocument/'+document.documentObjectId, {name: name})
          .then((data:any)=> {
              data.sourceName = this.translate.instant('common.documentation');
              data.fileSize = document.fileSize;
              data.sharepointFolderId = document.sharepointFolderId;
              this.documentationList.concept.documentation.totalDocuments++;
              this.documentationList.filterDocumentationList.push(data);
              document.sharepointFolderId? this.addDocumentInConcept(data, this.documentationList.concept.documentation.subFolders) : this.documentationList.concept.documentation.documents.push(data);
              this.filterDocumentation();
              this.getFilters();
              this.loader.closeLoader(['documentation-list-duplicate-document']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.duplicated-document-ok').replace('{document}', name), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let text:string = this.translate.instant('documentation-list.error-duplicate-document').replace('{document}', document.name);
              if(errorData && errorData.description && errorData.description.exceptionMessage && errorData.description.exceptionMessage==="File already exists"){
                  text = this.translate.instant('documentation-list.document-name-exists-published').replace('{document}', name);
              }
              this.modalService.adviceModal(text, 'error:accept:warning')
          })
  }

  convertDocument(document:any){
      this.apiService.add('documentobjects/convert/'+document.documentObjectId)
          .then((data:any)=>{
              this.modifyDocument(document, data);
              this.loader.closeLoader(['documentation-list-convert-document']);
              let text = this.translate.instant('common.the-document') +"'"+ document.name +"'"+ this.translate.instant('documentation-list.converted-document');
              this.modalService.adviceModal(text, 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  exportList() {
      let that: any = this.documentationList
      this.modalService.exportFormatModal({})
          .subscribe((formatResult:any) => {
              if(formatResult.result !== 'cancel'){
                let configuration: any = {
                  list: that.concept.documentation.documents,
                  hasPrevious: true,
                  extension: formatResult.selectedFormat
                }
                this.modalService.exportModal(configuration)
                  .subscribe((result:any) => {
                      if(result.result==='ok'){
                          let documentationList = this.getExportDocument(that.concept.documentation);
                          let config:any = {
                            idPrint: 'pdf-documentation-concept-list',
                            typeColumns: ['name', 'sourceName', 'createdIso', 'createdBy', 'modifiedIso', 'modifiedBy', 'status', 'stateName'],
                              conceptObjectId: that.concept.conceptObjectId,
                              list: documentationList,
                              uploadFile: true,
                              landscape: true,
                              columns: {
                                  name: this.translate.instant('common.title'),
                                  sourceName: this.translate.instant('common.origin'),
                                  createdIso: this.translate.instant('common.creation'),
                                  createdBy: this.translate.instant('common.author'),
                                  modifiedIso: this.translate.instant('common.modification'),
                                  modifiedBy: this.translate.instant('documentation-list.user-modification'),
                                  status: this.translate.instant('common.state'),
                                  stateName: this.translate.instant('common.process-state')
                              },
                              successCallback: (uploadResponse:any, blob:any) => {
                                  delete that.configurationPdf;
                                  this.modalService.adviceModal(this.translate.instant('documentation-list.export-list-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
                                  that.concept.documentation.documents.push(uploadResponse);
                                  that.concept.documentation.totalDocuments++;
                                  if(that.breadcrumbList.length === 1){
                                      that.filterDocumentationList.push(uploadResponse);
                                      this.getFilters();
                                  }
                                  if (result.downloadFile) {
                                      this.downloadService.triggerDownload(blob, uploadResponse.name);
                                  }
                                  if (config.successCallback2){
                                      config.successCallback2(uploadResponse, blob);
                                  }
                              }
                          };
                          switch (formatResult.selectedFormat) {
                              case 'xls':
                                  config.fileName = result.filename;
                                  config.title = this.translate.instant('common.documentation');
                                  config.successCallback2 = (uploadResponse:any) => {
                                      if (result.openFile){
                                          this.launchAction(null, 'edit', uploadResponse);
                                      }
                                  };
                                  this.exportService.toXls(config);
                                  break;
                              case 'pdf':
                                  config.exportDate = this.dateConverterService.setTimezone(new Date());
                                  config.title = that.concept.title;
                                  config.subtitle = this.translate.instant('common.documentation');
                                  config.nameDocument = result.filename+'.pdf';
                                  config.orderType = 'created';
                                  config.orderAsc = true;
                                  config.successCallback2 = (uploadResponse:any, blob:any) => {
                                    if(result.openFile){
                                        window.open(URL.createObjectURL(blob), '_blank');
                                    }
                                  };
                                  that.configurationPdf = config;
                                  let thisTimeout = this;
                                  setTimeout(function () {
                                      let htmlPrint = $('#pdf-documentation-concept-list').html();
                                      config.htmlPrint = htmlPrint;
                                      thisTimeout.exportService.toPdf(config);
                                  });
                                  break;
                          }
                      }else if(result.result ==='previous'){
                          this.exportList();
                      }
                  });
              }
          });
  }

  getExportDocument(listDocuments: any){
      let docs = cloneDeep(listDocuments.documents);
      docs.forEach((doc:any) => {
        doc.createdIso = this.dateConverterService.convertDate(doc.created, 'without-time');
        doc.modifiedIso = this.dateConverterService.convertDate(doc.modified, 'without-time');
        doc.stateName = doc.state?.name;
      });

      if (listDocuments.subFolders && listDocuments.subFolders.length) {
          for (let subfolder of listDocuments.subFolders) {
              docs = [...docs, ...this.getExportDocument(subfolder)];
          }
      }
      return docs;
  }

  updateDocumentationList(){
      let concept: any = this.documentationList.concept
      this.loader.openLoader('upload-documentation-list')
      this.apiService.get('documentobjects/'+concept.conceptObjectId)
      .then((data:any)=>{
          concept.documentation = data;
          if(this.documentationList.breadcrumbList.length === 1){
              this.configureDocumentation();
          }else{
              this.configureDocuments(concept.documentation.documents);
              concept.documentation.subFolders = this.configureFolder(concept.documentation.subFolders);
              this.loader.closeLoader(['upload-documentation-list']);
          }
      }, (errorData:any)=>{
          this.loader.closeError();
      })
  }

  duplicateDocument() {
      let that: any = this.documentationList;
      let last:any = that.breadcrumbList.length - 1;
      let folderId: any = that.breadcrumbList[last].folderId? that.breadcrumbList[last].folderId : null;

      this.modalService.duplicateDocumentation(that.concept, that.filterDocumentationList, folderId)
          .subscribe((result:any)=>{
              if(result.result==='ok'){
                  if(result.documentsAdded.length){
                      result.documentsAdded.forEach((newDocument:any) => {
                          that.concept.documentation.totalDocuments++;
                          that.filterDocumentationList.push(newDocument);
                          newDocument.sharepointFolderId? this.addDocumentInConcept(newDocument, that.concept.documentation.subFolders) : that.concept.documentation.documents.push(newDocument);
                      });
                      this.loader.closeLoader('copy-documents');
                  }
                  switch(result.result){
                      case 'ok':
                          this.modalService.adviceModal(this.translate.instant('documentation-list.copy-documents-ok'), 'info:accept:check-round');
                          break;
                      case 'error-all-documents':
                          this.modalService.adviceModal(this.translate.instant('documentation-list.error-all-documents'), 'error:accept:warning');
                          break;
                      case 'error-in-document':
                          this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-document').replace('{name}', result.documentErrors[0].name), 'error:accept:warning');
                          break;
                      case 'ok-with-errors':
                          let errorDocument:string = this.translate.instant('documentation-list.error-some-documents').replace('{documents}', this.translate.instant('documentation-list.the-document') + result.documentErrors[0].name);
                          if(result.documentErrors.length > 1){
                              errorDocument = this.translate.instant('documentation-list.error-some-documents').replace('{documents}',  this.translate.instant('documentation-list.the-documents'));
                              for(let i = 0; i < result.documentErrors.length; i++){
                                  let documentName:any = result.documentErrors[i].name;
                                  if(i === result.documentErrors.length - 2){
                                      errorDocument = errorDocument.concat("'"+documentName+"'" + " "+ this.translate.instant('common.and') + " ");
                                  }else if(i === result.documentErrors.length - 1){
                                      errorDocument = errorDocument.concat("'"+documentName+"'" + '.');
                                  }else{
                                      errorDocument = errorDocument.concat("'"+documentName+"'" + ', ');
                                  }
                              }
                          }
                          this.modalService.adviceModal(errorDocument, 'error:accept:warning');
                          break;
                  }
              }
          })
  }

  upDocument(){
      let that: any = this.documentationList
      let documentationList = cloneDeep(that.filterDocumentationList);
      documentationList.parentId = that.breadcrumbList[that.breadcrumbList.length - 1].folderId;
      this.modalService.uploadDocuments("addNewDocuments", [], documentationList, that.concept, that.concept.listStates)
        .subscribe((result:any)=>{
            if(result.result==='ok'){
                this.upDocumentProcess(result);
            }
        })
  }

  upDocumentProcess(result:any){
      result.files.forEach((document:any, index: any) => {
          this.registerEvent('upload document', document);
          this.configureNewDocument(document, 'add', result, index+1===result.files.length);
      });
  }

  viewArchived(){
      let that: any = this.documentationList
      that.showTrash = !that.showTrash;
      that.breadcrumbList = [];
      if(that.showTrash){
          that.breadcrumbList.push({
              name: this.translate.instant('documentation-list.deleted-documentation')
          });
          that.listOrigin = that.filterDocumentationList;
          that.filterDocumentationAux = that.filterDocumentationList;
          that.filterDocumentationList = that.filterDocumentationListDeleted;
      }else{
          that.breadcrumbList.push({
              name: this.translate.instant('common.documentation')
          });
          that.filterDocumentationList = that.concept.documentation.documents;
          this.configureDocuments(that.concept.documentation.documents);
          that.concept.documentation.subFolders = this.configureFolder(that.concept.documentation.subFolders);
          that.filterDocumentationList = [...that.filterDocumentationList, ...that.concept.documentation.subFolders];
      }
      that.filterDocumentationAux = that.filterDocumentationList;
      that.multipleSelection = false;
      this.multipleSelectionCheck();
      this.getFilters();
      that.documentChecked = 0;
  }

//#region Administrar papelera
  restoreDocument(document:any, multiple:any= false){
      let that: any = this.documentationList
      let defer: any = new Promise((resolve:any)=>{
          this.apiService.patch('documentobjects/fromrecyclebin', document.documentObjectId)
              .then((data:any)=>{
                  that.filterDocumentationList = this.commonUtilsService.deleteElement(document, that.filterDocumentationList, 'documentObjectId');
                  let folder = this.getFolder(that.filterDocumentationListDeleted, document.sharepointFolderId);
                  if(folder){
                      folder.documents = this.commonUtilsService.deleteElement(document, folder.documents, 'documentObjectId');
                  }
                  that.filterDocumentationAux = that.filterDocumentationList;
                  that.concept.documentation.documents.push(document)
                  that.archivedCount--;
                  that.concept.documentation.totalDocuments++;
                  this.loader.closeLoader(['documentation-list-restore-document'])

                  multiple? defer.resolve(document) : this.modalService.adviceModal(this.translate.instant('documentation-list.restore-document-ok').replace('{document}', document.name), 'info:accept:check-round');
              },(errorData:any)=>{

                  this.loader.closeError();
                  if(!multiple){
                      let type: string = errorData.description.exceptionMessage==="File already exists"? '-already-exists' : ''
                      this.modalService.adviceModal(this.translate.instant('documentation-list.restore-error'+type).replace('restore-error'+type, document.name), 'error:accept:warning');
                  }else{
                      document.error = true;
                      defer.resolve(document)
                  }
              })
      });

      return defer;
  }

  openQuestionRestore(document:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-restore'), 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result==='ok'){
              this.loader.openLoader('documentation-list-restore-document')
              let type: string = 'folderId'
              let typeText: string = 'folder'
              if(document.documentObjectId){
                  type= 'documentObjectId'
                  typeText = 'document'
              }
              if(this.checkedName(this.documentationList.listOrigin, document, type)){
                  this.restoreDocument(document);
              }else{
                  this.loader.closeLoader(['documentation-list-restore-document'])
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-'+typeText).replace('{name}', document.name), 'error:accept:warning')
              }
          }
      })
  }

  checkedName(list:any, item:any, type:any){
      for(let itemList of list){
          if(itemList[type] && itemList.name == item.name){
              return false;
          }
      }
      return true;
  }

  restoreFolder(folder:any){
      let that: any = this.documentationList
      this.apiService.patch('sharepointfolders/restorefromrecyclebin', folder.folderId)
          .then((data:any)=>{
              that.concept.documentation.totalDocuments = that.concept.documentation.totalDocuments + data;
              that.archivedCount = that.archivedCount - data;
              this.loader.closeLoader('documentation-list-restore-document')
              that.filterDocumentationList = this.commonUtilsService.deleteElement(folder, that.filterDocumentationList, 'folderId');
              that.concept.documentation.subFolders.push(folder);
              that.listOrigin.push(folder);
              let text = this.translate.instant('documentation-list.restore-folder-ok').replace('title-folder', folder.name);
              this.modalService.adviceModal(text, 'info:accept:check-round');
          },(errorData:any)=>{
              this.loader.closeError();
              let type: string = errorData.description.exceptionMessage==="File already exists"? '-already-exists' : ''
              this.modalService.adviceModal(this.translate.instant('documentation-list.restore-error'+type).replace('restore-error'+type, folder.name), 'error:accept:warning');
          })
  }

  fullDelete(){
      this.modalService.adviceModal(this.translate.instant('documentation-list.delete-bin-question'), 'info:question:question').subscribe((result:any)=>{
          this.loader.openLoader('documentation-full-delete-document')
          this.fullDeleteDocuments();
      })
  }

  fullDeleteDocuments(){
      let that:any = this.documentationList;
      let show:string = '';
      let defered:any = [];
      let lengthDeleted:number = that.filterDocumentationListDeleted.length;
      let completedPromises:number = 0;
      let errors:number = 0;
      let errorPermissions:boolean = false;
      let deferedFullDeleted = new Promise((resolve: any)=>{
          for(let [index, documentSelected] of that.filterDocumentationListDeleted.entries()){
              defered[index] = this.deleteAllItemsTrash(documentSelected);
          }
          for(let [index, filterDocumentation] of that.filterDocumentationListDeleted.entries()){
              defered[index].then((value:any)=>{
                  if(value.errorDelete){
                      errors++;
                      show += ", '"+ value.name+ "'";
                  }
                  if(value.errorPermissions){
                      errorPermissions = true;
                  }
                  completedPromises++;
                  if(completedPromises === lengthDeleted){
                      resolve(show);
                  }
              })
          }
      });


      deferedFullDeleted.then((value:any)=>{
          this.getFilters();
          that.selectAllDocument = true;
          that.documentChecked = 0;
          this.selectAllDocumentCheck();
          if(errorPermissions){
              this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');

          }else if(errors === 0){
              this.modalService.adviceModal(this.translate.instant('documentation-list.trash-empty'), 'info:accept:check-round');
          }else if(errors === 1){
              this.modalService.adviceModal(this.translate.instant(this.translate.instant('documentation-list.error-remove-document') +"'"+ value.replace(', ','') + "'"), 'error:accept:warning');
          }else{
              value = value.replace(', ','');
              value = value.replace(/,([^,]*)$/, ' ' + this.translate.instant('common.and') + '$1');
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-remove-documents').replace('{documents}', value), 'error:accept:warning');
          }
          this.getCountDeletedDocuments();
          this.loader.closeLoader(['documentation-full-delete-document']);
      })
  }

  deleteAllItemsTrash(item:any){
      return new Promise((resolve:any)=>{
          item.errorDelete = false;
          item.errorPermissions = false;
          item.errorLocked = false;
          let id = item.documentObjectId;
          let url = 'documentobjects';
          let type = 'documentObjectId';
          if(item.extension ==='folder'){
              id = item.folderId;
              url = 'sharepointfolders';
              type = 'folderId';
          }
          this.apiService.delete(url, id)
              .then((data:any)=>{
                  this.documentationList.filterDocumentationList = this.commonUtilsService.deleteElement(item, this.documentationList.filterDocumentationList, type);
                  this.documentationList.filterDocumentationListDeleted = this.documentationList.filterDocumentationList;
                  this.documentationList.filterDocumentationAux = this.documentationList.filterDocumentationList;
                  resolve(item);
              },(errorData:any)=>{
                  if(errorData.status === 403 && errorData.description === "delete-error-permissions"){
                      item.errorPermissions = true;
                  }
                  item.errorDelete = true;
                  resolve(item);
              })

      })
  }

  openQuestionMultiRestore(document:any){
      let documents:any=[];
      this.documentationList.filterDocumentationList.forEach((filterDocument:any) => {
          if(filterDocument.selectedDocument){
              documents.push(filterDocument);
          }
      });
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-restore'), 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result==='ok'){
              this.loader.openLoader('documentation-list-restore-some-document')
              let show:any = '';
              let restoreDefered$ = new Promise((resolve:any)=>{
                  let defered = [];
                  let promiseResolve:number = 0;
                  for(let i = 0; i < documents.length;i++){
                      defered[i] = this.restoreDocument(documents[i], documents.length);
                  }

                  for(let i = 0; i<documents.length;i++){
                      defered[i].then((value:any)=>{
                          if(value.error){
                              show += ", '"+ value.name+ "'";
                          }
                          promiseResolve++;
                          if(promiseResolve===documents.length){
                              resolve(show);
                          }
                      })
                  }
              });

              restoreDefered$.then((value:any)=>{
                  this.getFilters();
                  this.loader.closeLoader(['documentation-list-restore-some-document'])
                  if(value.length=== 0){
                      this.modalService.adviceModal(this.translate.instant('documentation-list.restore-ok-multiple'), 'info:accept:check-round');
                  }else{
                      value = value.replace(', ','');
                      value = value.replace(/,([^,]*)$/, ' ' + this.translate.instant('common.and') + '$1');
                      this.modalService.adviceModal(this.translate.instant('documentation-list.restore-error-multiple').replace("'restore-error-multiple'",value), 'error:accept:warning');
                  }
                  this.documentationList.documentChecked = 0;
              })
          }

      })
  }

  checkButtonShowTrash(){
      this.documentationList.showButtonTrash = this.documentationList.archivedCount> 0;
  }

//#region administrar carpetas (folders)
  newFolder(folder:any = null){
      let that: any = this.documentationList;
      let title = !folder && that.breadcrumbList.length===1? this.translate.instant('expanded-menu.new-folder') : this.translate.instant('expanded-menu.new-subfolder');
      folder = that.breadcrumbList.length > 1 && !folder? that.breadcrumbList[that.breadcrumbList.length - 1] : folder;

      let newItem:any = {
          title:  title,
          type: 'folder',
          conceptId: null,
          listFolders: [],
          rename: false,
          icon: 'lf-icon-folder-new'
      }
      this.modalService.renameModal(newItem, this.documentationList.filterDocumentationList)
          .subscribe((result:any)=>{
              if(result.result==='ok'){
                  this.loader.openLoader('documentation-create-folder');
                  let sendFolder:any ={
                      name: result.name
                  };
                  if(folder){
                      sendFolder.parentId = folder.folderId;
                  }
                  this.createNewFolder(sendFolder, folder);
              }
          })
  }

  createNewFolder(folder:any, parentFolder:any){
      let that: any = this.documentationList
      this.apiService.add('sharepointfolders/' + that.concept.conceptObjectId, folder)
          .then((data:any)=> {
              let createdFolder:any = {
                  folderId: data.sharepointFolderId,
                  name: data.name,
                  subFolders: [],
                  documents: [],
                  extension: 'folder',
                  created: data.creationDate,
                  createdBy: this.context.user.fullName,
                  level: 1
              }
              if (data.parentId !== null) {
                  createdFolder.level = that.breadcrumbList[that.breadcrumbList.length - 1].level + 1;
                  createdFolder.parentId = data.parentId;
                  that.filterDocumentationList.push(createdFolder);
                  if (!that.breadcrumbList[that.breadcrumbList.length - 1].subFolders && parentFolder) {
                      that.breadcrumbList.push(parentFolder);
                      createdFolder.level = parentFolder.level + 1;
                      createdFolder.parentId = parentFolder.folderId;
                  }
                  that.breadcrumbList[that.breadcrumbList.length - 1].subFolders.push(createdFolder);
              } else {
                  that.concept.documentation.subFolders.push(createdFolder);
              }
              this.loader.closeLoader(['documentation-create-folder'])
              this.openFolder(createdFolder);
              this.modalService.adviceModal(this.translate.instant('documentation-list.folder-ok').replace('folder-ok', createdFolder.name), 'info:accept:check-round');
          }, (errorData:any)=> {
              this.loader.closeError()

              this.modalService.adviceModal(this.translate.instant('documentation-list.error-new-version'), 'error:accept:warning');
          });
  }

  associateDocument(document:any){
      let stateOld:any = document.state;
      this.modalService.associatedDocumentation(document.state, this.documentationList.concept, this.documentationList.concept.listStates, 'documentation', document)
          .subscribe((result:any)=>{
              if(result.result ==='ok'){
                  let partTranslate:any = this.translate.instant('associated-documentation.associate-document-ok').replace('{titleName}', document.name);
                  let text:any = partTranslate.replace('{titleStep}', document.stateProcess);
                  if(document.state === null){
                      partTranslate = this.translate.instant('associated-documentation.desassociate-document').replace('{titleName}', document.name);
                      text = partTranslate.replace('{titleStep}', stateOld.name);
                  }else if(stateOld !== null){
                      text = this.translate.instant('associated-documentation.modificate-process-document');
                  }
                  this.apiService.getWithTranslate('processesdata/'+ this.documentationList.concept.conceptObjectId, 'summary')
                      .then((data:any)=> {
                          this.documentationList.concept.processStates = data;
                      }, ()=>{});
                  this.modalService.adviceModal(text, 'info:accept:check-round');
              }
          })
  }

  postToRequester(document:any){
      this.loader.openLoader('documentation-post-to-requester');
      let published = document.publishedForApplicant? 0 : 1;
      this.apiService.patchInt('documentobjects/publishforapplicant/'+ document.documentObjectId, published)
      .then(()=>{
          document.publishedForApplicant = !document.publishedForApplicant;
          let text = document.publishedForApplicant? 'documentation-list.publish-request-ok': 'documentation-list.unpublish-request-ok';
          this.loader.closeLoader(['documentation-post-to-requester']);
          this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
      }, (error:any)=>{});
  }

  searchDocuments(folder:any){
      if(folder.documents && folder.documents.length){
          folder.documents.forEach((documentDeleted:any) => {
              this.checkedFields(documentDeleted);
          });

      }
      if(folder.subFolders && folder.subFolders.length){
          folder.documents.forEach((subFolder:any) => {
              this.searchDocuments(subFolder)
          });
      }
  }

  archiveFolderSelected(document:any){
      let that:any = this.documentationList
      this.apiService.delete('sharepointfolders/torecyclebin', document.folderId)
          .then((data:any)=>{
              that.showButtonTrash = true;
              that.concept.documentation.totalDocuments = that.concept.documentation.totalDocuments - data;
              that.archivedCount = that.archivedCount + data;
              this.loader.closeLoader('documentation-list-delete-document');
              if(document.documents.length || document.subFolders.length){
                  this.searchDocuments(document);
              }
              document.subFolders = that.configureFolder(document.subFolders, 'recycle');
              that.configureDocuments(document.documents);
              that.filterDocumentationList.splice(that.filterDocumentationList.indexOf(document),1);
              if(that.breadcrumbList.length > 1){
                  let folder = this.getFolder(that.concept.documentation.subFolders,that.breadcrumbList[that.breadcrumbList.length-1].folderId);
                  folder.subFolders.splice(folder.subFolders.indexOf(document),1);
              }else{
                  that.concept.documentation.subFolders.splice(that.concept.documentation.subFolders.indexOf(document),1);
              }
              this.deleteItemInConcept(document);
              let documentDeleted = document;
              documentDeleted.menu = that.menuRestore;
              documentDeleted.parentId = null;
              that.filterDocumentationListDeleted.push(documentDeleted);
              this.modalService.adviceModal(this.translate.instant('documentation-list.folder-trash').replace('{folder}',document.name), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
          if(errorData.status === 403 && errorData.description === "delete-error-permissions"){

              this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');
          }else{
              let text: any =errorData.description.exceptionMessage === 'File is locked'? this.translate.instant('common.the-document') +"'"+ document.name + "'" + this.translate.instant('documentation-list.file-lock') : this.translate.instant('documentation-list.error-remove-document') +"'"+ document.name + "'";
              this.modalService.adviceModal(text, 'error:accept:warning');
          }
      });
  }

  openFolder(folder:any){
      let that:any = this.documentationList
      if(folder.extension ==='folder'){
          that.selectAllDocument = true;
          this.selectAllDocumentCheck();
          that.filterDocumentationAux = that.filterDocumentationList;
          that.filterDocumentationList = folder.documents;
          that.filterDocumentationList = that.filterDocumentationList.concat(folder.subFolders);
          that.breadcrumbList.push(folder);
          that.filterDocumentationAux = that.filterDocumentationList;
          this.filterDocumentation();
          this.getFilters();
          that.documentChecked = 0;
          let height:any = $('.documentation-list__block-breadcrumbs').height();
          $('.documentation-list__miga').animate({ scrollTop: height }, 'slow');
      }
  }

  selectFolder(folder:any){
      let that:any = this.documentationList
      that.selectAllDocument = true;
      this.selectAllDocumentCheck();
      that.breadcrumbList.splice(that.breadcrumbList.indexOf(folder)+1);
      that.documentChecked = 0;
      if(folder.folderId){
          that.filterDocumentationList = folder.documents;
          that.filterDocumentationList = that.filterDocumentationList.concat(folder.subFolders);
      }else if(that.showTrash){
          that.filterDocumentationList =  that.filterDocumentationListDeleted;
      }else{
          that.filterDocumentationList = that.concept.documentation.documents;
          that.filterDocumentationList = [...that.filterDocumentationList, ...that.concept.documentation.subFolders];
      }
      this.getFilters();
      that.filterDocumentationAux = that.filterDocumentationList;
  }

  getFolder(subFolders:any, folderId:any){
      for(let subFolder of subFolders){
          if(subFolder.folderId === folderId){
              return subFolder;
          }else if(subFolder.subFolders){
              let folder:any = this.getFolder(subFolder.subFolders, folderId);
              if(folder){
                  return folder;
              }
          }
      }
  }

  toggleSwitchMultiple(e:any) {
      this.documentationList.multipleSelection = e.value;
      this.multipleSelectionCheck();
  }

  multipleSelectionCheck(){
      let that:any = this.documentationList
      if(that.multipleSelection){
          that.checkMultiple = that.checkMultiple.replace(this.translate.instant('documentation-list.active'), this.translate.instant('documentation-list.unactive'));
      }else{
          that.selectAllDocument = true;
          this.selectAllDocumentCheck();
          that.checkMultiple = that.checkMultiple.replace(this.translate.instant('documentation-list.unactive'), this.translate.instant('documentation-list.active'));
      }
      $('.documentation-list__block').animate({ scrollLeft:0 }, 'slow');
  }

  selectAllDocumentCheck(){
      let that:any = this.documentationList
      that.selectAllDocument = !that.selectAllDocument;
      that.filterDocumentationList.forEach((document:any) => {
          if(document.extension !== 'folder' && document.selectedDocument !== that.selectAllDocument){
              document.selectedDocument = that.selectAllDocument;
              if(document.selectedDocument){
                  that.documentChecked++;
              }else if(that.documentChecked > 0){
                  that.documentChecked--;
              }
          }
      });
  }

  selectMultipleDocument(document:any){
      document.selectedDocument = !document.selectedDocument;
      this.documentationList.selectedFromOption = false;
      if(document.selectedDocument){
          this.documentationList.documentChecked++;
      }else{
          this.documentationList.selectAllDocument = false;
          this.documentationList.documentChecked--;
      }
  }

  checkedDocument(document:any){
      let that:any = this.documentationList
      if(that.multipleSelection && that.documentChecked === 0 && !document.selectedDocument && document.documentObjectId){
          document.selectedDocument = true;
          that.documentChecked++;
          that.selectedFromOption = true;
      }else if(that.multipleSelection && that.documentChecked > 0 && !document.selectedDocument && document.documentObjectId && !that.selectedFromOption){
          document.selectedDocument = true;
          that.documentChecked++;
          that.selectedFromOption = false;
      }else if(that.multipleSelection && (that.documentChecked === 1) && !document.selectedDocument && document.documentObjectId && that.selectedFromOption){
          that.filterDocumentationList.forEach((documentElement:any) => {
              if(documentElement.extension !== 'folder' && documentElement.documentObjectId !==  document.documentObjectId){
                  documentElement.selectedDocument = false;
              }
          })
          document.selectedDocument = true;
          that.selectedFromOption = true;
          that.documentChecked = 1;
      }
      that.textDocumentSelected = this.translate.instant('documentation-list.selected-documents').replace('{documents}', that.documentChecked);
  }

  openSibila(document:any) {
      this.loader.openLoader('documentation-list-sibila');
      this.apiService.get('documentobjects', document.documentObjectId + '/text')
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-sibila']);
              this.documentActions.doAction('sibila', { text: this.commonUtilsService.jsonEncode(data.text) });
              this.registerEvent('analyze with Sibila',document);
          }).catch(()=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
          });
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id);
  }

  hoverRowAction(document:any, hover:any){
      document.hoverRow = hover;
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  openDropdownConfidential(index:any, document:any){
      let that:any = this.documentationList
      if(that.filterDocumentationList.length > 6){
          let position = that.filterDocumentationList.length - index;
          if(that.filterDocumentationList.length === 7){
              position+=3;
          }else if(that.filterDocumentationList.length === 8){
              position+=2;
          }else if(that.filterDocumentationList.length === 9){
              position+=1;
          }
          document.classUpDropdown = false;
          switch(position){
              case 1:
              case 2:
                  document.classUpDropdown = true;
                  break;
              case 3:
                  if(document.confidentialList.length > 1){
                      document.classUpDropdown = true;
                  }
                  break;
              case 4:
                  if(document.confidentialList.length > 3){
                      document.classUpDropdown = true;
                  }
                  break;
              default:
                  break;
          }
      }
      document.showDropdownConfidential = true;
  }

  checkExtension(document:any){
    let videoExtensions: any = ['AVI', 'FLV', 'MP4', 'MKV', 'MOV', 'MPEG', 'MPG', 'DIVX', 'WMV', 'RAR', 'ZIP', '7Z'];
    let excelExtensions: any = [/* 'XLSX' */, 'XLM', 'XLSM', 'XLT', 'XLTX', 'XLTM', 'XLSB', 'XLAM', 'XLV', 'CSV', 'ODS'];
    let wordExtensions: any = [/* 'DOCX' */, 'DOCM', 'DOT', 'DOTX', 'DOTM', 'ODT', 'RTF'];
    let powerpointExtensions: any = [/* 'PPTX' */, 'PPTM', 'POT', 'POTX', 'POTM', 'PPA', 'PPAM', 'PPS', 'PPSX', 'PPSM', 'SLDX', 'SLDM', 'THMX', 'ODP'];
    // let officeOldExtensions: any = ['DOC', 'XLS', 'PPT'];
    let officeOnline: any = ['DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX'];
    let inVideo:any = videoExtensions.findIndex((extension:any)=>{return extension === document.extension})
    let inExcel:any = excelExtensions.findIndex((extension:any)=>{return extension === document.extension})
    let inWord:any = wordExtensions.findIndex((extension:any)=>{return extension === document.extension})
    let inPPT:any = powerpointExtensions.findIndex((extension:any)=>{return extension === document.extension})
    let inOfficeOnline: any = officeOnline.findIndex((extension:any)=>{return extension === document.extension});
    // let inOld:any = officeOldExtensions.findIndex((extension:any)=>{return extension === document.extension})
    return !(inVideo === -1 && inExcel === -1 && inWord === -1 && inPPT === -1 || inOfficeOnline !== -1 /* && inOld === -1 */)
  }

  ngOnDestroy(): void {
      this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
