<div class="dashboard-panel dashboard-panel--v2">
    <div *ngIf="!changeProductFromAML002">
        <header-nav></header-nav>
        <div class="dashboard-panel__container-block-v2">
            <div [ngShow]="skin !== 'icam-red'"
                class="dashboard-panel__container-menu-left-v2 dashboard-panel__container-menu-left-v2--color">
                <div class="dashboard-panel__button-clients-v2 dashboard-panel__button-clients-v2--color"
                    (click)="goToOrganizations()">
                    <i class="lf-icon-label-user dashboard-panel__icon-clients-v2 dashboard-panel__icon-clients-v2"></i>
                    <span class="dashboard-panel__text-organizations-v2"
                        [afTranslate]="'dashboard.organizations-management'">Organizations management</span>
                </div>
                <div *ngFor="let product of dashboardPanel.productList | orderBy:'shortName'"
                    [class]="'dashboard-panel__product-block-v2 dashboard-panel__product-block-v2--'+product.color"
                    [ngClass]="{'dashboard-panel__product-block-v2--selected': product.selected}"
                    (click)="changeProduct(product)"
                    matTooltip="{{product.name}}"
                    matTooltipPosition="right"
                    matTooltipClass="right">
                    <div [class]="'dashboard-panel__product-block-selected--'+product.color"
                        [ngClass]="{'dashboard-panel__product-block-selected': product.selected}"></div>
                    <span class="notranslate">{{product.shortName}}</span>
                </div>
            </div>
            <div class="dashboard-panel__container-new-version">
                <div class="dashboard-panel__principal-block-new-version"
                    *ngIf="dashboardPanel.typeDashboard === 'normal'">
                    <div class="dashboard-panel__block-header-title-product">
                        <div class="dashboard-panel__block-title-product">
                            <span class="dashboard-panel__text-title-product">{{dashboardPanel.productSelected.name}}</span>
                        </div>
                        <filter-header [ngShow]="skin !== 'icam-red'"
                            [clientList]="dashboardPanel.clientList"
                            (filterClient)="addFilterClientOn($event)">
                        </filter-header>
                        <div class="dashboard-panel__block-right-header">
                            <button class="dashboard-panel__button-show-documentation"
                                (click)="gotToAllDocuments(dashboardPanel.productSelected.productId)">
                                <span [afTranslate]="'dashboard.show-all-documents'">_Ver todos los documentos</span>
                                <i class="lf-icon-documentation dashboard-panel__icon-button-header"></i>
                            </button>
                            <button class="dashboard-panel__button-access-evaluation"
                                (click)="openEvaluation()"
                                [ngShow]="skin === 'icam-red'">
                                <span [afTranslate]="'front-login.access'">_Acceder</span>
                                <i class="lf-icon-double-arrow-right dashboard-panel__icon-button-header"></i>
                            </button>
                        </div>
                        <button class="dashboard-panel__reload-button"
                            [ngShow]="skin === 'icam-red'">
                            <i class="lf-icon-refresh"
                                (click)="reloadAll()"></i>
                        </button>
                    </div>
                    <dashboard-roles-obligated-subject *ngIf="dashboardPanel.productSelected && skin !== 'icam-red'"
                        [product]="dashboardPanel.productSelected"></dashboard-roles-obligated-subject>
                    <div class="dashboard-panel__block-graph-reports-v2"
                        *ngIf="dashboardPanel.viewReportGraphic">
                            <dashboard-reports [evaluationList]="dashboardPanel.filteredEvaluationList"
                                [client]="dashboardPanel.filterClients"
                                [userPermissions]="dashboardPanel.userPermissions"
                                [productSelect]="dashboardPanel.productSelected.productId"
                                [from]="'dashboard'"
                                (launchActionEmit)="launchActionOn($event)">
                            </dashboard-reports>
                    </div>

                    <div class="dashboard-panel__block-evaluations-and-instances-list-v2">
                        <div class="dashboard-panel__evaluations-list"
                            [ngClass]="{'dashboard-panel__evaluations-list--no-templates': (skin === 'dark-blue' || skin === 'gm-law') && dashboardPanel.noHasTemplatesReports}">
                            <evaluations-list *ngIf="skin !== 'icam-red'"
                                [filteredEvaluations]="dashboardPanel.filteredEvaluationList"
                                [deletedEvaluations]="dashboardPanel.filteredDeletedEvaluations"
                                [canCreate]="dashboardPanel.managePermissions"
                                [canModify]="dashboardPanel.modifyEvaluation"
                                (launchAction)="launchAction($event)"
                                (copyEvaluation)="duplicateEvaluation($event.newEvaluation)"
                                [productSelected]="dashboardPanel.productSelected">
                            </evaluations-list>
                            <concept-instances-list-icam *ngIf="skin === 'icam-red'"
                                [filterInstancesList]="dashboardPanel.filterInstancesList">
                            </concept-instances-list-icam>
                        </div>
                        <div class="dashboard-panel__instances-list dashboard-panel__instances-list--no-templates"
                            *ngIf="dashboardPanel.noHasTemplatesReports && dashboardPanel.productSelected.productId !== 'blanqueo'">
                            <reports-list [filterReportsList]="dashboardPanel.filterReportsList"
                                [productSelected]="dashboardPanel.productSelected"
                                [noHasTemplatesReports]="dashboardPanel.noHasTemplatesReports"
                                [userPermissions]="dashboardPanel.userPermissions"
                                (launchAction)="launchActionOn($event)">
                            </reports-list>
                        </div>
                    </div>
                    <div class="dashboard-panel__schedule-list-new-version"
                        *ngIf="dashboardPanel.noHasTemplatesReports">
                        <schedule [typeView]="'normal'"></schedule>
                    </div>
                    <div class="dashboard-panel__block-right-reports-schedule"
                        *ngIf="!dashboardPanel.noHasTemplatesReports && dashboardPanel.chargedTemplatesReports">
                        <clients-tracking-dashboard *ngIf="dashboardPanel.productSelected.productId === 'blanqueo'"
                            [product]="dashboardPanel.productSelected"
                            [queryProduct]="dashboardPanel.amlQuery"
                            [evaluationList]="dashboardPanel.filteredEvaluationList"
                            (launchAction)="launchAction($event)"></clients-tracking-dashboard>
                        <concept-instances-list *ngIf="skin === 'steel-blue' && dashboardPanel.productSelected.productId !== 'blanqueo'"
                            class="dashboard-panel__instances-list"
                            [elementsView]="11"
                            [filterInstancesList]="dashboardPanel.filterInstancesList">
                        </concept-instances-list>
                        <reports-list *ngIf="(skin === 'dark-blue' || skin === 'icam-red' || skin === 'gm-law') && dashboardPanel.productSelected.productId !== 'blanqueo'"
                            class="dashboard-panel__instances-list"
                            [filterReportsList]="dashboardPanel.filterReportsList"
                            [productSelected]="dashboardPanel.productSelected"
                            [noHasTemplatesReports]="dashboardPanel.noHasTemplatesReports"
                            [userPermissions]="dashboardPanel.userPermissions"
                            (launchAction)="launchActionOn($event)">
                        </reports-list>
                        <schedule-next-event></schedule-next-event>

                    </div>
                    <div class="dashboard-panel__block-tasks-list-v2 dashboard-panel__block-tasks-list-v2--denunc"
                        *ngIf="dashboardPanel.productSelected.productId ==='denunc'">
                        <concept-instances-list
                            [elementsView]="5"
                            [filterInstancesList]="dashboardPanel.filterInstancesList"
                            [productId]="dashboardPanel.productSelected.productId">
                            </concept-instances-list>
                    </div>
                    <div class="dashboard-panel__block-tasks-list-v2">
                        <task-list [tasksLists]="dashboardPanel.filteredTaskList"
                            [userPermissions]="dashboardPanel.userPermissions"
                            [product]="dashboardPanel.productSelected"
                            (changeTaskList)="changeTaskList($event)"
                            (launchActionEmit)="launchActionOn($event)">
                        </task-list>
                    </div>
                </div>
                <onboarding *ngIf="dashboardPanel.chargedClients && dashboardPanel.chargedUserPermissions && dashboardPanel.typeDashboard === 'onboarding'"
                    [product]="dashboardPanel.productSelected"
                    [clientList]="dashboardPanel.clientList"
                    (launchAction)="launchAction($event)"></onboarding>
            </div>
        </div>
        <compliance-footer [ngShow]="dashboardPanel.allLoad">
        </compliance-footer>
    </div>
</div>