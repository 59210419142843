import { Injectable } from '@angular/core';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class ConceptInfoParentService {

  constructor(private modalService: ModalServiceService,
    private context: ContextFactory) { }

  extractRelatedOrReferences(type:any, description:any){
    if(description.indexOf('<a') !== -1){
        let aSplit = description.split('<a ')
        for(let i = 0; i < aSplit.length; i++){
            let aTag = aSplit[i];
            if(aTag.indexOf(type) !== -1){
                aTag = aTag.replace('href="#"','');
                aTag = aTag.replace(type,'ng-click="conceptInfo.openRelated(');
                aTag = aTag.replace('}">','})"><span class="common__tiny-link-span">');
                aTag = aTag.replace('style="color: #551a8b; text-decoration: underline;"', '')
                aSplit[i] = aTag.replace('</a>','</span><i class="lf-icon-open-window common__tiny-link-icon"></i></a> ');
            }
        }
       return aSplit.join('<a ')
    }else{
        return description;
    }
  }

  openRelated(item:any){
    this.modalService.relatedInformationModal([item])
  }

  openReferences(item:any, child:any){
    let registerItem ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'open legal docs',
        'Concept Name': sessionStorage.getItem('breadcrumbConcept') + child.conceptInfo.concept.title,
        'Centinela product': this.context.settings.defaultProduct
    }
    this.modalService.lawInfoModal(registerItem, [item],[item])
  }
}
