import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'export-format-modal',
  templateUrl: './export-format-modal.component.html'
})
export class ExportFormatModalComponent implements OnInit{
  constructor(private translate:AfTranslateFactory,
    private activeModal:NgbActiveModal){}

  @Input() configuration:any;

  exportFormat: any = {
    title: '',
    selectedFormat: ''
  }

  ngOnInit(): void {
    this.exportFormat.title = this.configuration.title?this.configuration.title:this.translate.instant('common.export-excel');
  }

  selectFormat(format:string) {
    this.exportFormat.selectedFormat = format;
  }

  handleAccept(){
    this.activeModal.close({
      result: 'ok',
      selectedFormat: this.exportFormat.selectedFormat
    });
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
