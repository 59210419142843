import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MultilanguageService } from '@shared/services/multilanguage-service/multilanguage.service';

@Component({
  selector: 'global-customization',
  templateUrl: './global-customization.component.html'
})
export class GlobalCustomizationComponent implements OnInit {
  skin: string =  this.globalCfg.skin;
  limitPasswordChar: any = 100;
  globalCustomization:any = {
    company: this.cleanCompany(),
    showPreview: false,
    previousCustomization: false,
    itsConfigured: false,
    description: '',
    mailAuth: '',
    customTypeSelect: '0',
    errorLimitPassword: this.translate.instant('corporative-information-modal.pass-error').replace('{characters}', this.limitPasswordChar),
    tinyMceOptions:{
      noEditable: false,
      toolbar: 'bold italic underline forecolor backcolor alignleft aligncenter alignright link unlink',
      placeholder: this.translate.instant('global-customization.placeholder'),
      height: 150,
      typeView: 'white'
    },
    clientLogo: {
        id: 'logo-global-customization',
        label: this.translate.instant('corporative-information-modal.logo'),
        description: null,
        required: true
    },
    clientFavicon: {
        id: 'favicon-global-customization',
        label: this.translate.instant('corporative-information-modal.favicon-label'),
        description: this.translate.instant('corporative-information-modal.favicon-description'),
        accept:'.png,.ico,.jpg,.jpeg'
    },
    mainColor: {
        id: 'principal-color-customization',
        label: this.translate.instant('global-customization.principal-color'),
        required: true
    },
    textColor: {
        id: 'text-color-customization',
        label: this.translate.instant('global-customization.foreground-color'),
        required: true
    },
    designFooter: {
        label: this.translate.instant('global-customization.design-footer'),
        multiple: false,
        required: true
    },
    youtubeLink: {
        id: 'youtube-link-customization',
        label: 'Youtube',
        type: 'link'
    },
    twitterLink: {
        id: 'twitter-link-customization',
        label: 'Twitter',
        type: 'link'
    },
    facebookLink: {
      id: 'facebook-link-customization',
      label: 'Facebook',
      type: 'link'
    },
    linkedinLink: {
      id: 'linkedin-link-customization',
      label: 'Linkedin',
      type: 'link'
    },
    phoneReference: {
      id: 'phone-reference-customization',
      label: this.translate.instant('global-customization.reference-phone')
    },
    sMTPServerField: {
      id: 'incoming-server-input-customization',
      label: this.translate.instant('corporative-information-modal.output-server'),
      required: true
    },
    sMTPPortField: {
      id: 'port-server-imap-customization',
      label: this.translate.instant('corporative-information-modal.port'),
      fieldTypeId: 'num',
      required: true,
      positive: true
    },
    sMTPUserField: {
      id: 'user-mail-customization',
      label:  this.translate.instant('corporative-information-modal.user-smpt'),
      required: true
    },
    sMTPPasswordField: {
      id: 'user-pass-customization',
      label: this.translate.instant('login-block.password'),
      required: true,
      visible: false
    },
    sMTPFromField: {
      id: "user-from-customization",
      label:  this.translate.instant('corporative-information-modal.mail-from'),
      required: true
    },
    identityInfo: {
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true,
      label: this.translate.instant('global-customization.global-corporate-identity'),
      description: this.translate.instant('global-customization.description-info')
    },
    authMailField: {
        required: true,
        label: this.translate.instant('common.email-account')
    },
    designFooterList: [
      {
          text: this.translate.instant('global-customization.footer-white'),//'_Fondo blanco con texto e iconos negros.',
          listValueId: 1
      },{
          text: this.translate.instant('global-customization.footer-black'),//'_Fondo negro con texto e iconos blancos.',
          listValueId: 2
      },{
          text: this.translate.instant('global-customization.footer-principal-color'),//'_Fondo del color principal con texto e iconos en blanco o negro.',
          listValueId: 3
      }
    ],
    customEmailTypeOptions:[
      {
          listValueId:'0',
          text: this.translate.instant('corporative-information-modal.smtp')
      },{
          listValueId:'1',
          text: this.translate.instant('corporative-information-modal.office-auth')
      }
    ],
    customEmailType: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('corporative-information-modal.configuration-type')
    },
    globalLanguagesSelected:[],
    globalLanguagesApp: [],
    globalDataTreatment: {
      fieldId: 'global-configuration-data-treatment',
      fieldTypeId: 'rich',
      isEditable: true,
      label: this.translate.instant('corporative-information-modal.footer-description')
    }
  }
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown
  constructor(private globalCfg: GlobalCfgFactory,
      private context: ContextFactory,
      private loader: LoaderFactory,
      private apiService: ApiServiceService,
      private commonUtilsService: CommonUtilsService,
      private translate: AfTranslateFactory,
      private modalService: ModalServiceService,
      private apiProperties: ApiPropertiesFactory,
      private multilanguageService: MultilanguageService) { }

  ngOnInit(): void {
      this.loader.openLoader('open-global-customization-window-loader');
      this.configureCompany();
  }

  configureCompany(){
    this.apiService.get('company/globalcustomization', this.context.user.companyId)
      .then((data:any) => {
          if(data !== null){
            data.mainColor = data.color;
            data.footerDescription = data.footer;
            if(!data.textColor){
                data.textColor = '#ffffff';
                if(data.color.toLowerCase() === '#fff201' || data.color.toLowerCase() === '#ffb001'){
                    data.textColor = '#000000';
                }
            }
            this.globalCustomization.company = data;
            if(this.globalCustomization.company.logo){
                this.globalCustomization.company.allLogos = this.configureLogos(this.globalCustomization.company);
            }
            this.globalCustomization.company.logoFile =  this.convertToFormData(this.globalCustomization.company.allLogos)

            if(this.globalCustomization.company.footerDesignType){
                this.globalCustomization.previousCustomization = true;
            }else{
                this.globalCustomization.previousCustomization = false;
            }
            if(this.globalCustomization.company.smtpUser && !this.globalCustomization.company.smtpFrom && this.commonUtilsService.validateMail(this.globalCustomization.company.smtpUser)){
                this.globalCustomization.company.smtpFrom = cloneDeep(this.globalCustomization.company.smtpUser);
            }

            if (this.globalCustomization.company.customEmail && this.globalCustomization.company.oauthUser !== '' && this.globalCustomization.company.oauthUser !== null){
              this.globalCustomization.company.initAuthProcess = true;
              this.globalCustomization.customTypeSelect = '1';
              this.globalCustomization.mailAuth = this.globalCustomization.company.oauthUser;
              this.globalCustomization.itsConfigurate = !this.globalCustomization.company.oauthReconfigNeeded;
              if(this.globalCustomization.company.oauthReconfigNeeded){
                this.globalCustomization.company.oauthUser = ''
              }

            }
            this.checkShowPreview();
            this.configureDescription();
            this.getFooterTranslates()
        }else{
            this.emptyConfiguration();
        }
        this.loader.closeLoader(['open-global-customization-window-loader','front-login-login']);
      }, (errorData:any)=>{
          this.emptyConfiguration();
          this.loader.closeError();
      });
  }

  emptyConfiguration(){
    this.cleanCustomization();
    this.globalCustomization.previousCustomization = false;
  }

  configureLogos(data:any){
      return {
          logo: this.commonUtilsService.setLogo(data.logo, data.logo),
          logo320X55: this.commonUtilsService.setLogo(data.logoFooter,data.logo),
          logo260X45: this.commonUtilsService.setLogo(data.logoHeader,data.logo),
          logo290X50: this.commonUtilsService.setLogo(data.logoOther,data.logo)
      }
  }

  convertToFormData(logos:any) {
      let fd = new FormData();
      let logo = this.commonUtilsService.baseToFormdata(logos.logo, logos.logo);
      let logo320X55 = this.commonUtilsService.baseToFormdata(logos.logo320X55);
      let logo260X45 = this.commonUtilsService.baseToFormdata(logos.logo260X45);
      let logo290X50 = this.commonUtilsService.baseToFormdata(logos.logo290X50);
      fd.append('file', logo);
      fd.append('file', logo320X55);
      fd.append('file', logo260X45);
      fd.append('file', logo290X50);
      return fd;
  }

  checkShowPreview(){
      let mainColorValidate = this.commonUtilsService.checkIsHexadecimalColor(this.globalCustomization.company.mainColor);
      let textColorValidate = this.commonUtilsService.checkIsHexadecimalColor(this.globalCustomization.company.textColor);
      if(this.globalCustomization.company.logo && mainColorValidate && textColorValidate && this.globalCustomization.company.footerDesignType){
          this.globalCustomization.showPreview = true;
      }else{
          this.globalCustomization.showPreview = false;
      }
  }

  configureDescription(){
      if(this.globalCustomization.company.footerDescription === '' || this.globalCustomization.company.footerDescription === null){
          this.globalCustomization.description = this.translate.instant('global-customization.placeholder');
      }else{
        this.globalCustomization.description = cloneDeep(this.globalCustomization.company.footerDescription);
      }
  }

  cleanCustomization(){
    this.globalCustomization.company = this.cleanCompany();
    this.configureDescription();
    this.globalCustomization.mailAuth = '';
    this.globalCustomization.showPreview = false;
  }

  getFooterTranslates(){
    let that: any = this.globalCustomization
    this.apiService.get(`publicconceptcfg/${that.company.sharedPublicConceptConfigurationId}`, 'footers')
      .then((data:any)=>{
        if(data.length === 0){
          this.multilanguageService.setDataWhenZero(data, that.company.sharedPublicConceptConfigurationId)
        }
        let configuration: any = this.multilanguageService.setListLanguages(data)
        that.globalLanguagesSelected = configuration.languagesSelected,
        that.globalLanguagesApp =  configuration.appLanguages,
        that.globalDataTreatment.description = configuration.languageSelected.textDescription
      }, (errorData)=>{})
  }

  cleanCompany(){
    return {
        logo: null,
        mainColor: '',
        textColor: '',
        faviconName: '',
        favIcon: '',
        faviconFile: null,
        footerDesignType: null,
        footerDescription: '',
        youtube: '',
        twitter: '',
        facebook: '',
        linkedin: '',
        phone: '',
        showLogoInFooter: false,
        customEmail: false,
        smtpServer: '',
        smtpPort: null,
        smtpUser: '',
        smtpPassword: '',
        smtpFrom: '',
        smtpssl: false,
        logoFile: null,
        oauthUser: '',
        oauthAccessToken: ''
    }
  }

  changeViewWindow(){
    this.commonUtilsService.goToStateUrl();
  }

  showPreviewNotification() {
      let data: any = {
          customizations: this.getModel()
      };
      data.customizations.footer = data.customizations.footerDescription;
      delete data.customizations.footerDescription;
      data.customizations.color = data.customizations.mainColor;
      delete data.customizations.mainColor;
      data.customizations.logoDataUri = this.globalCustomization.company.allLogos.logo;
      data.customizations.logoHeaderDataUri = this.globalCustomization.company.allLogos['logo260X45'];
      data.customizations.logoFooterDataUri = this.globalCustomization.company.allLogos['logo320X55'];
      this.modalService.notificationPreview('preview', 'company', this.context.user.companyId, data);
  }

  showPreviewCustomization(){
      let company: any = this.getModel();
      company.color = this.globalCustomization.company.mainColor;
      company.logoHeader = this.getLogo('header');
      company.logoFooter =  this.getLogo('footer');
      sessionStorage.setItem('companyPreviewCustomization', JSON.stringify(company));
      window.open('/preview-customization', '_blank');
  }

  getModel() {
      let footerDescription = '';
      let company: any = this.globalCustomization.company
      let description = this.commonUtilsService.extractInnerHTML(this.globalCustomization.description);
      if (description !== this.translate.instant('global-customization.placeholder')) {
          footerDescription = this.globalCustomization.description;
      }
      return {
          mainColor: company.mainColor,
          textColor: company.textColor,
          faviconName: company.faviconName,
          favIcon: company.favIcon,
          faviconFile: company.faviconFile,
          footerDesignType: company.footerDesignType,
          footerDescription: footerDescription,
          youtube: company.youtube,
          twitter: company.twitter,
          facebook: company.facebook,
          linkedin: company.linkedin,
          phone: company.phone,
          showLogoInFooter: company.showLogoInFooter,
          customEmail: company.customEmail,
          smtpServer: company.smtpServer,
          smtpPort: parseInt(company.smtpPort),
          smtpUser: company.smtpUser,
          smtpPassword: company.smtpPassword,
          smtpFrom: company.smtpFrom,
          smtpssl: company.smtpssl,
          oauthUser: company.oauthUser,
          oauthAccessToken: company.oauthAccessToken
      };
  }

  getLogo(type:any){
    if(this.globalCustomization.company.listLogos){
        if(type === 'header'){
            return this.globalCustomization.company.listLogos[3].html.toDataURL('image/png');
        }else{
            return this.globalCustomization.company.listLogos[2].html.toDataURL('image/png');
        }
    }else{
        if(type === 'header'){
            return this.globalCustomization.company.allLogos['logo260X45'];
        }else{
            return this.globalCustomization.company.allLogos['logo320X55'];
        }
    }
  }

  itemSelected(e:any, type:any){
    let newValue: any = e.newValue;
    switch(type){
        case 'logo':
            this.globalCustomization.clientLogo.error = false;
            this.globalCustomization.company.logo = newValue.img;
            this.globalCustomization.company.name = newValue.name;
            this.globalCustomization.company.logoFile = newValue.files;
            this.globalCustomization.company.listLogos = newValue.listLogos;
            this.globalCustomization.company.allLogos ={
                logo:newValue.listLogos[0].html.toDataURL('image/png'),
                logo320X55: newValue.listLogos[2].html.toDataURL('image/png'),
                logo260X45: newValue.listLogos[3].html.toDataURL('image/png'),
                logo290X50: newValue.listLogos[1].html.toDataURL('image/png'),
            }
            this.checkShowPreview();
            this.commonUtilsService.setLogo(newValue.img);
            break;
        case 'favIcon':
            this.globalCustomization.company.faviconName = newValue.name;
            this.globalCustomization.company.favIcon = newValue.img;
            this.globalCustomization.company.faviconFile = newValue.file;
            break;
        case 'smtpServer':
        case 'smtpPort':
        case 'smtpUser':
        case 'smtpPassword':
        case 'smtpFrom':
            if(type === 'smtpServer'){
                this.globalCustomization.sMTPServerField.error = false;
                this.globalCustomization.errorServer = newValue?.length > 50?this.globalCustomization.errorServer:false;
            }else if(type === 'smtpPort'){
                this.globalCustomization.sMTPPortField.error = false;
                this.globalCustomization.errorPort = newValue.toString()?.length > 5?this.globalCustomization.errorPort:false;
              }else if(type === 'smtpUser'){
                this.globalCustomization.sMTPUserField.error = false;
                this.globalCustomization.errorUser = newValue?.length > 50?this.globalCustomization.errorUser:false;
                if(!this.globalCustomization.company.smtpFrom && this.commonUtilsService.validateMail(newValue)){
                    this.globalCustomization.company.smtpFrom = cloneDeep(newValue);
                    this.globalCustomization.sMTPFromField.error = false;
                    this.globalCustomization.errorFrom = newValue?.length > 50?this.globalCustomization.errorFrom:false;
                    this.globalCustomization.sMTPFromField.errorField = !newValue || newValue?.length > 50?this.globalCustomization.sMTPFromField.errorField:false;
                }
            }else if(type === 'smtpPassword'){
                this.globalCustomization.sMTPPasswordField.error = false;
                this.globalCustomization.errorPass = newValue?.length > this.limitPasswordChar?this.globalCustomization.errorPass:false;
            }else if(type === 'smtpFrom'){
                this.globalCustomization.errorFrom = newValue?.length > 50?this.globalCustomization.errorFrom:false;
                this.globalCustomization.sMTPFromField.errorField = !newValue || newValue?.length > 50?this.globalCustomization.sMTPFromField.errorField:false;

            }
            this.globalCustomization.company[type] = newValue;
            break;
        case 'mainColor':
        case 'textColor':
            this.globalCustomization.company[type] = newValue.value.hex;
            if(this.globalCustomization.company[type] !== '' || this.globalCustomization.company[type] !== null || this.globalCustomization.company[type] < 7){
                this.globalCustomization[type].error = false;
            }
            break;
        case 'footerDesignType':
            this.globalCustomization.company[type] = newValue;
            this.globalCustomization.designFooter.error = false;
            this.checkShowPreview();
            break;
        default:
            this.globalCustomization.company[type] = newValue;
        break;
    }
  }

  saveCustomization(){
    let validateField = this.validateField();
    let validateCustomEmail = true;
    if(this.globalCustomization.company.customEmail){
      if(this.globalCustomization.customTypeSelect ==='0'){
        validateCustomEmail = this.validateFieldSMTP();
      }else{
        if(this.globalCustomization.mailAuth ==='' && this.globalCustomization.company.oauthUser === ''){
           this.globalCustomization.authMailField.error = true;
           validateCustomEmail = false;
        }else  if(this.globalCustomization.company.oauthAccessToken ==='' || this.globalCustomization.company.oauthAccessToken === null || this.globalCustomization.company.oauthReconfigNeeded){
          this.globalCustomization.authAccessTokenError = true;
          validateCustomEmail = false;
        }
      }
    }

    if(validateField && validateCustomEmail){
        this.loader.openLoader('save-global-customization-loader');
        let company:any = this.getModel();
        if (company.customEmail) {
          if(this.globalCustomization.customTypeSelect ==='0'){
              company.oauthUser = null;
              company.oauthAccessToken = null;
              this.checkSMTP(company);
          }else{
            if(company.oauthUser){
                company.smtpServer = null;
                company.smtpPort = null;
                company.smtpUser = null;
                company.smtpPassword = null;
                company.smtpFrom = null;
                this.saveGlobalCustomization(company);
            }
          }
        } else {
            this.saveGlobalCustomization(company);
        }
    }
  }

  validateField(){
    let that = this.globalCustomization;
    that.clientLogo.error = Boolean(!that.company.logo);
    that.mainColor.error = Boolean(!(this.commonUtilsService.checkIsHexadecimalColor(that.company.mainColor)));
    that.textColor.error = Boolean(!(this.commonUtilsService.checkIsHexadecimalColor(that.company.textColor)));
    that.designFooter.error = Boolean(!that.company.footerDesignType);
    this.checkLanguagesSelected()

    return !this.globalCustomization.clientLogo.error && !this.globalCustomization.mainColor.error && !this.globalCustomization.textColor.error && !this.globalCustomization.designFooter.error;
  }

  validateFieldSMTP() {
    let that: any = this.globalCustomization;
    let company: any = this.globalCustomization.company;
    if(company.customEmail){
        if(that.customTypeSelect ==='0'){
            if(company.smtpServer !== '' || company.smtpPort || company.smtpUser !== '' ||  company.smtpPassword !== ''){
              that.sMTPServerField.error = company.smtpServer?.length > 50;
              that.errorServer = (that.sMTPServerField.error && company.smtpServer?.length > 50);
              that.sMTPPortField.error = !(company.smtpPort && parseInt(company.smtpPort) < 65535);
              that.errorPort = (that.sMTPPortField.error && company.smtpPort && parseInt(company.smtpPort)>= 65535);
              that.sMTPUserField.error = !(company.smtpUser !== null && company.smtpUser !== '' && 50 > company.smtpUser.length);
              that.errorUser = (that.sMTPUserField.error && company.smtpUser?.length > 50);
              that.sMTPPasswordField.error = company.smtpPassword?.length > this.limitPasswordChar;
              that.errorPass = (that.sMTPPasswordField.error && company.smtpPassword?.length > this.limitPasswordChar);
              that.sMTPFromField.error = !this.commonUtilsService.validateMail(company.smtpFrom);
              that.errorFrom = company.smtpFrom?.length > 50;
              that.sMTPFromField.errorField = !company.smtpFrom || that.errorFrom?true:false;
              let errorFrom = that.sMTPFromField.error || that.sMTPFromField.errorField
              return (!that.sMTPServerField.error && !that.sMTPPortField.error && !that.sMTPUserField.error && !that.sMTPPasswordField.error && !errorFrom);
          }else if(company.smtpServer === '' && !company.smtpPort && company.smtpUser === '' &&  company.smtpPassword === ''){
              this.changeBoolFieldSMTP(true);
              return false;
          }else{
              this.changeBoolFieldSMTP(false);
              return true;
          }
        }else{
          return company.oauthUser !== '';
        }
    }else{
        this.changeBoolFieldSMTP(false);
        return true;
    }
  }

  changeBoolFieldSMTP(option: any){
      this.globalCustomization.errorServer = option;
      this.globalCustomization.errorPort = option;
      this.globalCustomization.errorUser = option;
      this.globalCustomization.errorPass = option;
      this.globalCustomization.errorFrom = option;
      this.globalCustomization.sMTPServerField.error = option;
      this.globalCustomization.sMTPPortField.error = option;
      this.globalCustomization.sMTPUserField.error = option;
      this.globalCustomization.sMTPPasswordField.error = option;
      this.globalCustomization.sMTPFromField.errorField = option;
  }

  checkSMTP(company:any) {
    let model = {
        server: company.smtpServer,
        port: company.smtpPort,
        login: company.smtpUser,
        password: company.smtpPassword,
        from: company.smtpFrom,
        ssl: company.smtpssl
    };

    this.apiService.update('publicconceptcfg/smtp-check', undefined, model)
      .then((error:any) => {
          if (error !== null) {
            this.showSmtpError(error)
          }else{
            this.saveGlobalCustomization(company);
          }
      }, (errorData:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('global-customization.smtp-validation.unexpected'));
      });
  }

  showSmtpError(error:any){
    let message: string = ''
    if(error.exceptionMessage.indexOf('535 5.7.139') !== -1){
      message = this.translate.instant('global-customization.smtp-validation.basic-authentication');
    }else if(error.exceptionMessage.indexOf('5.7.0') !== -1){
      message = this.translate.instant('global-customization.smtp-validation.client-not-authenticated');
    }else{
      this.loader.closeLoader('save-global-customization-loader');
      let errorCode = error.errorCode.substr(0, 1).toLowerCase() + error.errorCode.substr(1);
      message = this.translate.instant('global-customization.smtp-validation.' + errorCode);
      if (!message) {
        message = this.translate.instant('global-customization.smtp-validation.unexpected');
      }
    }
    this.loader.closeLoader(['save-global-customization-loader']);
    this.errorModal(message);
  }

  saveGlobalCustomization(company: any){
    this.apiService.patch('company/setglobalcustomization', this.context.user.companyId, company)
      .then((data:any) => {
          if(this.globalCustomization.company.logoFile){
              this.addLogo();
          }else{
              if(this.globalCustomization.company.faviconFile){
                  this.addFavicon();
              }else if(this.globalCustomization.company.favIcon){
                  this.openInfoSavedModal();
              }else{
                  this.removeFavicon();
              }
          }
          this.saveMultilanguageFooter();
      }, (errorData:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('global-customization.error-save-customization'));
      });
  }

  addLogo(){
    this.apiService.attachFilePatch('company/setglobalcustomizationlogos/' + this.context.user.companyId, this.globalCustomization.company.logoFile)
      .then((data:any) => {
          if(this.globalCustomization.company.faviconFile){
              this.addFavicon();
          }else if(this.globalCustomization.company.favIcon){
              this.openInfoSavedModal();
          }else{
              this.removeFavicon();
          }
      }, (errorData:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('global-customization.error-save-customization'));
      });
  }

  addFavicon(){
    this.apiService.attachFilePatch('company/setglobalcustomizationfavicon/' + this.context.user.companyId, this.globalCustomization.company.faviconFile)
      .then((data:any) => {
          this.openInfoSavedModal();
      }, (errorData:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('global-customization.error-save-customization'));
      });
  }

  removeFavicon(){
    this.apiService.patch('company/removefavicon', this.context.user.companyId)
      .then((data:any) => {
          this.openInfoSavedModal();
      }, (errorData:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('global-customization.error-save-customization'));
      });
  }

  openInfoSavedModal(){
    this.loader.closeLoader(['save-global-customization-loader']);
    let text = this.translate.instant('global-customization.confirm-create-customization');
    if(this.globalCustomization.previousCustomization){
        text = this.translate.instant('global-customization.confirm-save-customization');
    }
    this.globalCustomization.previousCustomization = true;
    this.openInfoModal(text);
  }

  saveMultilanguageFooter(){
    this.apiService.update(`publicconceptcfg/${this.globalCustomization.company.sharedPublicConceptConfigurationId}`,'footers', this.globalCustomization.listSelectedLanguages)
      .then((data:any)=>{},(errorData:any)=>{})
  }

  deleteCustomization(){
    this.loader.openLoader('can-delete-global-customization-loader');
    this.getCanRemoveCustomization();
  }

  getCanRemoveCustomization(){
    this.apiService.get('company/canremovegc', this.context.user.companyId)
      .then((data:any) => {
          this.loader.closeLoader(['can-delete-global-customization-loader']);
          if(data === true){
              this.modalDeleteGlobalCustomization();
          }else{
            this.modalService.adviceModal(this.translate.instant('global-customization.no-remove-gc'), 'error:accept:warning');
          }
      }, (errorData:any)=>{
          this.loader.closeError();
      });
  }

  modalDeleteGlobalCustomization(){
    this.modalService.adviceModal(this.translate.instant('global-customization.question-delete'), 'info:question:question')
      .subscribe((result:any) => {
          if(result.result === 'ok'){
            this.loader.openLoader('delete-global-customization-loader');
            this.deleteGlobalCustomization();
          }
      });
  }

  deleteGlobalCustomization(){
    this.apiService.delete('company/deleteglobalcustomization', this.context.user.companyId)
      .then((data:any) => {
          this.cleanCustomization();
          this.globalCustomization.previousCustomization = false;
          this.loader.closeLoader('delete-global-customization-loader');
          this.openInfoModal(this.translate.instant('global-customization.confirm-delete-customization'));
      }, (errorData:any)=>{
          this.loader.closeError();
          this.modalService.adviceModal(this.translate.instant('global-customization.no-remove-gc'), 'error:accept:warning');
      });
  }

  openInfoModal(text: any){
      this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  errorModal(text: any){
      this.modalService.adviceModal(text, 'error:accept:warning');
  }

  selectEmail(e:any){
    this.globalCustomization.mailAuth = e.newValue
  }

  initAuth(){
    this.globalCustomization.authMailField.error = false;
    this.globalCustomization.init = false;
    if(this.commonUtilsService.validateMail(this.globalCustomization.mailAuth)){
      this.globalCustomization.initAuthProcess = true;
      this.globalCustomization.state = uuidv4()
      if(!this.globalCustomization.init){
        window.addEventListener("message", (event:any)=>{
          if(!this.globalCustomization.init){
            this.globalCustomization.init = true;
            this.messageReceived(event)
          }
        });
      }
      let oauthFlowUrl:string = this.apiProperties.apiURL + "/utils/oauth/flow?state=" + this.globalCustomization.state + "&user=" + this.globalCustomization.mailAuth;
      window.open(oauthFlowUrl, "CentinelaOauthDialog", "popup=1, width=600px, height=500px");
    }else{
      this.globalCustomization.authMailField.error = true;
    }
  }


  messageReceived(event:any) {
    let that: any = this.globalCustomization;
    let apiOrigin = this.apiProperties.apiURL.replace("/api/", "");
    if (event.origin == apiOrigin && this.globalCustomization.state === event.data.state) {
        setTimeout(()=>{
          that.company.oauthUser = cloneDeep(that.mailAuth);
          that.itsConfigurate = true;
          that.company.oauthScope = event.data.scope;
          that.company.oauthAccessToken = event.data.access_token;
          that.company.oauthRefreshToken = event.data.refresh_token;
          that.mailAuth = '';
          that.authAccessTokenError = false;
          that.company.oauthReconfigNeeded = false;
        });
    }
  }

  deleteOauth(){
    this.globalCustomization.company.oauthUser = '';
    this.globalCustomization.company.oauthScope = '';
    this.globalCustomization.company.oauthAccessToken = '';
    this.globalCustomization.company.oauthRefreshToken = '';
    this.globalCustomization.mailAuth = '';
    this.globalCustomization.itsConfigurate = false;
  }
  selectcustomType(newValue:any){
    this.globalCustomization.customTypeSelect = newValue
  }

  changeActiveLanguagesSelected(selectedLanguage:any, addSelected?:any){
    this.multilanguageService.changeActiveLanguagesSelected(selectedLanguage, addSelected, this.globalCustomization, 'global')
  }
  
  removeActiveLanguage(selectedLanguage:any){
    this.multilanguageService.removeActiveLanguage(selectedLanguage, this.globalCustomization, 'global');
  }

  selectLanguage(selectedLanguage:any, textDefault?:any){
    this.multilanguageService.selectLanguage(selectedLanguage, this.globalCustomization, 'global', textDefault)
    this.dropdown.close();
  }

  notSelectLanguage(language:any){
    return this.multilanguageService.notSelectLanguage(language, this.globalCustomization, 'global')
    
  }

  checkLanguagesSelected(){
    let that = this.globalCustomization;
    let configuration: any = this.multilanguageService.checkLanguages(that, false, 'global');
    that.listSelectedLanguages = configuration.listSelectedLanguages;
  }

  checkSelectedTab(){
    let that = this.globalCustomization;
    if(!Boolean(that.globalLanguagesSelected.filter((languageSelected:any)=>{ return languageSelected.active}).length)){
      that.globalLanguagesSelected[0].active = true;
    };
  
  }
}
