<div class="public-link-poll">
  <div class="public-link-poll__block-info-concept"
      [ngClass]="publicLinkPoll.showInfo ? 'public-link-poll__block-info-concept--show' : 'public-link-poll__block-info-concept--noshow'">
      <i class="lf-icon-link public-link-poll__icon-link" [ngClass]="{'public-link-poll__icon-link--compiled': !publicLinkPoll.showInfo}"></i>
      <div class="public-link-poll__text-info-block" [ngShow]="publicLinkPoll.showInfo">
          <h5 class="public-link-poll__title-info" [afTranslate]="'public-link-poll.what-is'"></h5>
          <p  class="public-link-poll__text-info" [afTranslate]="'public-link-poll.info-poll'"></p>
          <h5 class="public-link-poll__title-info" [afTranslate]="'public-link.what-is-for'"></h5>
          <p class="public-link-poll__text-info" [afTranslate]="'public-link-poll.uses'"></p>
          <h5 class="public-link-poll__title-info" [afTranslate]="'public-link.configuration'"></h5>
          <p class="public-link-poll__text-info" [afTranslate]="'public-link-poll.info-recomendation'"></p>
          <h5 class="public-link-poll__title-info" [afTranslate]="'public-link.link-activate'"></h5>
          <p  class="public-link-poll__text-info" [afTranslate]="'public-link-poll.info-activate'"></p>
          <h5 class="public-link-poll__title-info" [afTranslate]="'expanded-menu.expiration-period'"></h5>
          <p class="public-link-poll__text-info" [afTranslate]="'public-link-poll.info-expiration'"></p>
      </div>
  </div>
  <div class="public-link-poll__block-total-steps"
      *ngIf="publicLinkPoll.step === 1"
      [ngClass]="{'public-link-poll__block-total-steps--no-show': !publicLinkPoll.showInfo}">
      <div class="public-link-poll__copy-link-block public-link-poll__copy-link-block--init"
          [ngShow]="publicLinkPoll.published">
          <p class="public-link-poll__link-text"
              [ngShow]="!(skin === 'icam-red' && poll?.namedKey === 'Encuesta')">{{publicLinkPoll.url}}</p>
          <button class="common__button-border-none public-link-poll__button-unpublish-link"
              (click)="unpublishPoll()">
              <i class="common__icon-button-underlined common__icon-button-underlined--top-4 lf-icon-unlink"></i>
              <span class="common__text-button-underlined"
                  [afTranslate]="'expanded-menu.unpublish'">_Despublicar</span>
          </button>
          <button class="common__button-border-none public-link-poll__button-unpublish-link"
              ngbTooltip="{{'common.copied' | afTranslate}}"
              triggers="manual"
              #thisTooltip="ngbTooltip"
              (click)="copyText(thisTooltip, publicLinkPoll.url)"
              container="body"
              [ngShow]="!(skin === 'icam-red' && poll?.namedKey === 'Encuesta')">
              <i class="common__icon-button-underlined lf-icon-paste"></i>
              <span class="common__text-button-underlined" [afTranslate]="'common.copy-link'"></span>
          </button>
          <button class="common__button-border-none public-link-poll__button-unpublish-link"
              [ngShow]="skin !== 'icam-red'"
              (click)="generateQr(publicLinkPoll.url)">
              <i class="common__icon-button-underlined common__icon-button-underlined--top-4 lf-icon-qr"></i>
              <span class="common__text-button-underlined" [afTranslate]="'channel-configuration.generate-qr'"></span>
          </button>
      </div>
      <p class="public-link-poll__title-block-steps" [ngShow]="!publicLinkPoll.published" [afTranslate]="'public-link-poll.title-steps'"></p>
      <i class="lf-icon-close public-link-poll__icon-close" (click)="handleDismiss()"></i>
      <div class="public-link-poll__block-steps-scroll"
          [ngClass]="{'public-link-poll__block-steps-scroll--published':publicLinkPoll.published,
            'public-link-poll__block-steps-scroll--show-info': publicLinkPoll.showInfo}">
          <div class="public-link-poll__steps">
              <wizard-steps *ngIf="publicLinkPoll.steps.length !== 1" [steps]="publicLinkPoll.steps" [state]="publicLinkPoll.stepState"></wizard-steps>
          </div>

          <!-- Bloque informacion corporativa -->
          <div class="corporative-information-modal__steps-block">
              <form class="public-link-poll__form-block-corporative" autocomplete="off">
                  <div *ngIf="publicLinkPoll.stepState === 1">
                      <div class="corporative-information-modal__link-block" [ngShow]="publicLinkPoll.published || publicLinkPoll.showInfoGlobal">
                          <i class="lf-icon-information public-link-poll__icon" [ngShow]="(publicLinkPoll.published || publicLinkPoll.showInfoGlobal)"></i>
                          <div class="public-link-poll__info-text" [ngClass]="{'public-link-poll__info-text--with-global': publicLinkPoll.showInfoGlobal}">
                              <div [ngClass]="{'corporative-information-modal__custom-config': publicLinkPoll.showInfoGlobal}">{{publicLinkPoll.titleAlert}}</div>
                              <button class="common__button-border-none corporative-information-modal__custom-config-right"
                                  [ngShow]="publicLinkPoll.showInfoGlobal"
                                  (click)="glConfig()">
                                  <i class="common__icon-button-underlined lf-icon-money-world"></i>
                                  <span class="common__text-button-underlined" [afTranslate]="'corporative-information-modal.apply-global-identy'"></span>
                              </button>
                          </div>
                      </div>
                      <field-type-logo
                          [field]="publicLinkPoll.clientLogo"
                          [type]="'new'"
                          [preSelectItem]="publicLinkPoll.company.allLogos"
                          [logoInfo]="publicLinkPoll.company"
                          (itemSelected)="itemSelectedOn($event, 'logo')">
                      </field-type-logo>
                      <field-type-logo
                          [field]="publicLinkPoll.clientFavicon"
                          [type]="'favicon'"
                          [logoInfo]="publicLinkPoll.company"
                          [preSelectItem]="publicLinkPoll.company.faviconImg"
                          (itemSelected)="itemSelectedOn($event, 'favicon')">
                      </field-type-logo>
                      <field-type-color class="global-customization__block-picker-color"
                          [field]="publicLinkPoll.color"
                          [preSelectItem]="publicLinkPoll.company.corporativeInformation.color"
                          (itemSelectedEmit)="itemSelectedOn($event, 'color')">
                      </field-type-color>
                      <field-type-color class="global-customization__block-picker-color"
                        [field]="publicLinkPoll.textColor"
                        [preSelectItem]="publicLinkPoll.company.corporativeInformation.textColor"
                        (itemSelectedEmit)="itemSelectedOn($event, 'textColor')">
                      </field-type-color>
                      <field-type-list-extended [field]="publicLinkPoll.footerDesignType"
                          [preSelectItem]="publicLinkPoll.company.corporativeInformation.footerDesignType"
                          [listValues]="publicLinkPoll.footerDesignTypeList"
                          (itemSelected)="itemSelectedOn($event, 'footerDesignType')">
                      </field-type-list-extended>
                  </div>
                  <div *ngIf="publicLinkPoll.stepState === 2">

                      <div class="corporative-information-modal__block-company">
                        <div class="protection-data-modal__block-fields protection-data-modal__block-fields--no-top">
                            <div class="protection-data-modal__block-translations"
                                [ngClass]="{'protection-data-modal__block-translations--error': publicLinkPoll.errorActive}">
                                <button class="protection-data-modal__block-translation-option"
                                    [ngClass]="{'protection-data-modal__block-translation-option--selected': languageSelected.active}"
                                    *ngFor="let languageSelected of publicLinkPoll.linkPollLanguagesSelected"
                                    (click)="linkPollChangeActiveLanguagesSelected(languageSelected)">
                                    <span [ngShow]="publicLinkPoll.linkPollLanguagesSelected.length < 6">{{languageSelected.text}}</span>
                                    <span [ngShow]="publicLinkPoll.linkPollLanguagesSelected.length >= 6">{{languageSelected.appLanguage}}</span>
                                    <i class="lf-icon-close protection-data-modal__icon-close-language"
                                        [ngShow]="!languageSelected.defaultOption"
                                        (click)="linkPollRemoveActiveLanguage(languageSelected)"></i>
                                    <svg class="protection-data-modal__triangle-block"
                                        [ngClass]="{'protection-data-modal__triangle-block--selected': languageSelected.active,
                                          'protection-data-modal__triangle-block--selected-error': languageSelected.active && languageSelected.error,
                                          'protection-data-modal__triangle-block--error': !languageSelected.active && languageSelected.error}">
                                        <polygon points="0,0 21,30 0,30" />
                                    </svg>
                                </button>
                                <div ngbDropdown 
                                    [ngHide]="publicLinkPoll.linkPollLanguagesSelected.length === publicLinkPoll.linkPollLanguagesApp.length">
                                    <button class="protection-data-modal__select-language-button" ngbDropdownToggle>
                                        <span [afTranslate]="'protection-data-modal.add-translation'"></span>
                                        <i class="lf-icon-translate protection-data-modal__icon-translate"></i>
                                    </button>
                                    <div class="protection-data-modal__menu-select-languages dropdown-menu" ngbDropdownMenu>
                                        <div class="common__block-option-list header-nav__block-option-list">
                                            <div class="common__language-item"
                                                *ngFor="let language of publicLinkPoll.linkPollLanguagesApp"
                                                [ngShow]="linkPollNotSelectLanguage(language)"
                                                (click)="linkPollSelectLanguage(language.listValueId)">
                                                <span>{{language.text}}</span>
                                                <span>({{language.specific}})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <field-type-rich-text-editable
                                [field]="publicLinkPoll.linkPollDataTreatment"
                                (launchKeyPressAction)="checkSelectedTab()">
                            </field-type-rich-text-editable>
                        </div>
                        <div class="corporative-information-modal__configure-company-block"
                            [ngShow]="publicLinkPoll.showOtherFields">
                            <div class="corporative-information-modal__configure-company-inner-block"> 
                                <div class="corporative-information-modal__half-block">
                                    <field-type-text-simple [field]="publicLinkPoll.youtubeLink"
                                        [preSelectItem]="publicLinkPoll.company.corporativeInformation.youtube"
                                        (itemSelected)="itemSelectedOn($event, 'youtube')">
                                    </field-type-text-simple>
                                </div>
                                <div class="corporative-information-modal__half-block">
                                    <field-type-text-simple [field]="publicLinkPoll.twitterLink"
                                    [preSelectItem]="publicLinkPoll.company.corporativeInformation.twitter"
                                    (itemSelected)="itemSelectedOn($event, 'twitter')">
                                  </field-type-text-simple>
                                </div>
                            </div>
                            <div class="corporative-information-modal__configure-company-inner-block"> 
                                <div class="corporative-information-modal__half-block">
                                    <field-type-text-simple [field]="publicLinkPoll.facebookLink"
                                    [preSelectItem]="publicLinkPoll.company.corporativeInformation.facebook"
                                    (itemSelected)="itemSelectedOn($event, 'facebook')">
                                  </field-type-text-simple>
                                </div>
                                <div class="corporative-information-modal__half-block">
                                    <field-type-text-simple [field]="publicLinkPoll.linkedinLink"
                                        [preSelectItem]="publicLinkPoll.company.corporativeInformation.linkedin"
                                        (itemSelected)="itemSelectedOn($event, 'linkedin')">
                                    </field-type-text-simple>
                                </div>
                            </div>
                            <div class="corporative-information-modal__configure-company-inner-block"> 
                                <div class="corporative-information-modal__half-block">
                                    <field-type-text-simple [field]="publicLinkPoll.phoneReference"
                                        [preSelectItem]="publicLinkPoll.company.corporativeInformation.phone"
                                        (itemSelected)="itemSelectedOn($event, 'phone')">
                                    </field-type-text-simple>
                                </div>
                                <div class="corporative-information-modal__half-block">
                                    <field-type-switch [label]="'corporative-information-modal.show-logo'|afTranslate"
                                        [checked]="publicLinkPoll.company.corporativeInformation.showLogoInFooter"
                                        (callback)="itemSelected('showLogoInFooter', $event.value)">
                                    </field-type-switch>
                                </div>
                            </div>
                          </div>
                          
                      </div>
                    
                  </div>
              </form>
          </div>
      </div>
      <div class="public-link-poll__check-block"
          [ngClass]="{'public-link-poll__check-block--show-info': publicLinkPoll.showInfo,
              'public-link-poll__check-block--published':publicLinkPoll.published,
              'public-link-poll__check-block--show-info-published':publicLinkPoll.published && publicLinkPoll.showInfo}">
          <field-type-switch [label]="'public-link-poll.show-info'|afTranslate"
                              [checked]="publicLinkPoll.showInfo"
                              (callback)="changeShowInfo()">
          </field-type-switch>
      </div>
      <div class="public-link-poll__buttons-block float-end">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'"></button>
          <button type="submit"
              [ngShow]="publicLinkPoll.stepState === 2"
              class="common__button common__button--clean"
              (click)="publicLinkPoll.stepState = 1"
              [afTranslate]="'common.previous'"></button>
          <button type="submit"
              [ngShow]="publicLinkPoll.stepState === 1"
              class="common__button common__button--active"
              (click)="nextStep()"
              [afTranslate]="'common.next'"></button>
          <button type="submit"
              [ngShow]="publicLinkPoll.published && publicLinkPoll.stepState === 2"
              class="common__button common__button--active"
              (click)="savePoll()">{{publicLinkPoll.accept}}</button>
          <button type="submit"
              [ngShow]="publicLinkPoll.stepState === 2 && !publicLinkPoll.published"
              class="common__button common__button--active"
              (click)="publishPoll()"
              [afTranslate]="'expanded-menu.publish'">_Publicar</button>
      </div>
  </div>
  <div class="public-link-poll__block-total-steps"
      *ngIf="publicLinkPoll.step === 2"
      [ngClass]="{'public-link-poll__block-total-steps--no-show': !publicLinkPoll.showInfo}">
      <i class="lf-icon-close public-link-poll__icon-close" (click)="handleDismiss()"></i>
      <div class="public-link-poll__block-finish-steps">
          <div class="corporative-information-modal__steps-block">
              <div class="public-link-poll__block-link-description">
                  <i class="lf-icon-check-round public-link-poll__icon-step-done"></i>
                  <span class="public-link-poll__link-description">{{publicLinkPoll.confirmPublish}}</span>
                  <span class="public-link-poll__link-description" [afTranslate]="'public-link-poll.confirm-publish-2'"></span>
              </div>
              <div class="public-link-poll__copy-link-block"
                  [ngShow]="skin !== 'icam-red'">
                  <p class="public-link-poll__link-text public-link-poll__link-text--step-1">{{publicLinkPoll.url}}</p>
              </div>
              <button class="common__button-border-none public-link-poll__button-copy-link public-link-poll__button-copy-link--no-first"
                  ngbTooltip="{{'common.copied' | afTranslate}}"
                  triggers="manual"
                  #thisTooltip="ngbTooltip"
                  (click)="copyText(thisTooltip, publicLinkPoll.url)"
                  [ngShow]="skin !== 'icam-red'"
                  container="body">
                  <i class="common__icon-button-underlined lf-icon-paste"></i>
                  <span class="common__text-button-underlined" [afTranslate]="'common.copy-link'"></span>
              </button>
                <!-- Añadir codigo qr-->
                <button class="common__button-border-none public-link-poll__button-copy-link"
                  [ngShow]="skin !== 'icam-red'"
                  (click)="generateQr(publicLinkPoll.url)">
                  <i class="common__icon-button-underlined lf-icon-qr"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'channel-configuration.generate-qr'">_codigo qr</span>
              </button>
              <div class="public-link-poll__contacts-block">
                <field-type-contacts-multiple [field]="publicLinkPoll.contactsField"
                    (sendContacts)="receiveContacts($event.contacts)">
                </field-type-contacts-multiple>
              </div>
              <div class="public-link-poll__textarea-link-block">
                  <rich-editor class="common__input-textarea-special"
                      [tinyMceOptions]="publicLinkPoll.tinyMceOptionsStep2"
                      [modelText]="publicLinkPoll.messageLink"
                      (emitValue)="publicLinkPoll.messageLink = $event.model">
                  </rich-editor>
              </div>
              <div class="public-link-poll__block-check-notification">
                  <field-type-switch [label]="'public-link.no-copy'|afTranslate"
                                      [checked]="publicLinkPoll.checkCopyNotification"
                                      (callback)="publicLinkPoll.checkCopyNotification = $event.value">
                  </field-type-switch>
              </div>
          </div>
      </div>
      <div class="public-link-poll__buttons-block float-end">
          <button type="submit"
              class="common__button common__button--active"
              (click)="sharePublicPoll()"
              [afTranslate]="'common.finish'"></button>
      </div>
  </div>
</div>
