import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { EvaluationInfoFactory } from '@shared/factories/evaluation-info/evaluation-info.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { SharepointLogoutService } from '@shared/services/sharepoint-logout/sharepoint-logout.service';
import { TargetService } from '@shared/services/target/target.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JwtAuthGuard implements CanActivate {
  route: ActivatedRouteSnapshot;
  state: RouterStateSnapshot;
  constructor(private router: Router,
    private apiService: ApiServiceService,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private apiProperties:ApiPropertiesFactory,
    private translate: AfTranslateFactory,
    private targetService: TargetService,
    private evaluationInfo: EvaluationInfoFactory,
    private sharepointLogoutService: SharepointLogoutService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.route = route;
        this.state = state;
        if(!(this.apiProperties.loaded && this.globalCfg.loaded)){
          return this.getProperties();
        }else{
          return this.configureCanActivate();
        }
  }

  configureCanActivate(){
    let route = this.route;
    let state = this.state;
    this.evaluationInfo.delete();
    let retVal:boolean = true;
    let callInProcess:boolean = false;
    let callGetToken:boolean = false;
    let callGetSsoLogin:boolean = false;
    let loginContext:any;
    let objectLogin: any;
    let ssoToken: any;
    let typeCallError: any = 'loginCheckError';
    let typeLogin: any ='public';
    let oldAccess: any = sessionStorage.getItem('oa');
    let access: string = route.queryParams['access'];
    if(this.globalCfg.skin === 'icam-red' && !route.data['isLanding']){
        document.title = 'Gestión del Conocimiento';
    }
    
    if(this.globalCfg.skin !== 'icam-red' && oldAccess && access && access !== oldAccess){
      this.sharepointLogoutService.logout();
      this.context.logout();
    }

    if (!(this.context.user && this.context.jwtToken)) {
      ssoToken = route.queryParams['sso'] || route.queryParams['access_token'] || route.queryParams['code'];
      let targetStateName:any = route.data['name'];
      let conceptId: any = route.params['conceptId'];
      if (access) {
        if (targetStateName.substring(0, 12) === 'root.shared.' || targetStateName === 'root.public.monitoring') {
          if (!this.globalCfg.externalPublicLoginUrl) {
            this.navigateRoute('contact-login', access);
          } else {
            let loginContext:any = this.getICAMPublicLoginContext(conceptId);
            //Para arreglar que el ICAM no gestiona bien los + en el access y nos los cambia por un blanco
            if (loginContext != null) {
              access = access.replace(/\s/g, '+');
            }
            if(loginContext !== 'redirecting'){
              callInProcess = true;
              objectLogin = { login: 'external', password: access, context: loginContext };
            }else{
              return false;
            }
          }
        } else if (targetStateName.substring(0, 12) === 'root.public.' || targetStateName === 'change-password') {
          if (targetStateName.substring(0, 12) === 'root.public.') {
            loginContext = this.getICAMPublicLoginContext(conceptId);
            //Para arreglar que el ICAM no gestiona bien los + en el access y nos los cambia por un blanco
            if (loginContext != null) {
              access = access.replace(/\s/g,'+');
            }
          }
          if (loginContext !== 'redirecting') {
            callInProcess = true;
            typeCallError ='resetPassLoginCheckError';
            objectLogin = { login: 'anonymous', password: access, context: loginContext };
          } else {
            return false;
          }
        } else {
          this.navigateRoute('login', access);
        }
        sessionStorage.setItem('oa', access);
      } else if (ssoToken) {
        if (this.globalCfg.caps.ssoLogin) {
            callGetSsoLogin = true;
        }
      } else if (this.context.jwtToken) { //check stored login
              //check if stored jwtToken is valid and get updated user data
            callInProcess = true;
            objectLogin = this.context.jwtToken;
            typeLogin = 'check';
      } else {
          //No user and no stored login --> preview?

        let preview = window.location.search === '?preview';
        if (preview) {
          callGetToken = true;
        } else {
          //redirect to login
          if (!this.globalCfg.caps.ssoLogin || this.globalCfg.caps.credentialsLogin) {
              if(location.pathname.indexOf('/login;target') !== -1){
                this.router.navigate([location.pathname]);
              }else if(location.pathname !== '/'){
                this.navigateRoute('login');
              }else{
                this.router.navigate(['login']);
              }
          } else if(this.globalCfg.skin === 'steel-blue' && (location.hostname === 'localhost' || location.hostname === 'becentinelappd.lefebvre.es')){
              this.router.navigate(['BE_EZAccess']);
          }else{
              let loginUrl:any = this.globalCfg.ssoLoginUrl;
              if (targetStateName !== 'dashboard' || location.hostname === 'localhost' ) {
                loginUrl += loginUrl.indexOf('?')!== -1? '&' :'?';
                loginUrl += 'redirect=' + encodeURIComponent(window.location.href);
              }
              window.location.href = loginUrl;
          }
        }
      }
    }
    if(callGetToken){
      return this.getCallToken();
    }else if(callInProcess){
      return this.getApiServiceLogin(objectLogin, typeLogin, typeCallError);
    }else if(callGetSsoLogin){
      return this.getSsoLogin(ssoToken);
    }else{
      return retVal;
    }
  }

  getProperties(){
    return this.apiService.getConfig().then((data)=>{
        this.apiProperties.setApiProperties(data);
        return this.getGlobalCfg();
    },(errorData:any)=>{
        return false;
    })
  }

  getGlobalCfg(){
    return this.apiService.getGlobal()
        .then((data:any)=>{
            this.globalCfg.setGlobalCfg(data.data);
            return this.configureCanActivate();
        },(errorData:any)=>{
            return false;
        })
  }

  navigateRoute(url:any, access:any=null){
      if(access){
        this.router.navigate([url],{ queryParams: { target: location.pathname , access: access} });
      }else{
        this.router.navigate([url],{ queryParams: { target: location.pathname } });
      }
  }

  getICAMPublicLoginContext(conceptId:any) {
      let context:any;
      let access: any = this.route.queryParams['access'];
      let sso_token: any = this.route.queryParams['sso_token'];
      let sso_timestamp: any = this.route.queryParams['sso_timestamp'];
      let sso_hash: any = this.route.queryParams['sso_hash'];
      let sso_perfil: any = this.route.queryParams['sso_perfil'];
      let sso_username: any = this.route.queryParams['sso_username'];
      let sso_situacion: any = this.route.queryParams['sso_situacion'];
      let sso_estado: any = this.route.queryParams['sso_estado'];

      if (sessionStorage.getItem("redirectedFrom") == access.replace(/\s/g,'+') //para arreglar que el ICAM no gestiona bien los + en el access y nos los cambia por un blanco
          && sso_token && sso_timestamp && sso_hash && sso_perfil
          && sso_username && sso_situacion && sso_estado) {
          context = {
              sso_token: sso_token,
              sso_timestamp: sso_timestamp,
              sso_hash: sso_hash,
              sso_perfil: sso_perfil,
              sso_username: sso_username,
              sso_situacion: sso_situacion,
              sso_estado: sso_estado
          };
      } else if (this.globalCfg.externalPublicLoginUrl && parseInt(conceptId) !== this.globalCfg.encuestaFrontConceptId) {
          this.redirectToExternalPublicLogin(conceptId);
          return 'redirecting';
      }
      return context;
  }

  redirectToExternalPublicLogin(conceptId:any) {
      let access: any = this.route.queryParams['access'];
      sessionStorage.setItem("redirectedFrom", access.replace(/\s/g, '+'));
      let urlHref: any = this.globalCfg.externalPublicLoginUrl === 'http://localhost:60703/Icam_Access.html'?window.location.origin+'/Icam_Access':this.globalCfg.externalPublicLoginUrl;
      let url: any = urlHref+'?access='+encodeURIComponent(access);
      if (conceptId) {
        url += '&conceptId='+encodeURIComponent(conceptId);
      }
      window.location.href = url;
  }

  getApiServiceLogin(objectLogin: any, typeLogin: any, type: any){
    return this.apiService.login(objectLogin, typeLogin)
      .then((data:any)=>{
        let time: any = data.user.lastLoginTime !== null? data.user.lastLoginTime : '';
        sessionStorage.setItem('lastLogin', data.user.lastLoginTime);
        this.loginCheckOk(data, this);
        return true;
      }, (errorData:any)=>{
        if(type === 'loginCheckError'){
          this.loginCheckError(errorData, this);
        }else if(type === 'resetPassLoginCheckError'){
          this.resetPassLoginCheckError(errorData, this);
        }
        return true;
      })
  }

  loginCheckOk(data:any, child:any) {
      child.context.setLogin(data);
      return true;
  }

  loginCheckError(err:any, child:any) {
      console.error('Error validating authorization token');
      console.error(err);
      child.context.logout();
      document.body.classList.remove('compliance__landing-custom');
      document.body.classList.add('compliance__'+this.globalCfg.skin);
      child.globalCfg.externalPublicLoginUrl? child.router.navigate(['login', { err: 'Se ha producido un error al validar al usuario'}]) : child.router.navigate(['login']);
  }

  resetPassLoginCheckError(err:any, child:any) {
      return err.status === 410? child.router.navigate(['change-password-error']) : this.loginCheckError(err, child);
  }

  getSsoLogin(ssoToken: any) {
    let gu = this.route.queryParams['gu'];
    return this.apiService.login(ssoToken, 'sso', gu === 'true')
      .then((data:any)=>{
        let time: any = data.user.lastLoginTime !== null? data.user.lastLoginTime : '';
        sessionStorage.setItem('lastLogin', data.user.lastLoginTime);
        return this.ssoLoginOk(data, this);
      }, (errorData:any)=>{
         return this.ssoLoginError(errorData, this);
      })

  }

  ssoLoginOk(data:any, child:any) {
      child.context.setLogin(data);
      if (data.user && !data.user.hasPermissions) {
          return child.router.navigate(['login', '*no-permissions-warning*']);
      } else {
          if (data.user.isAdmin && this.globalCfg.skin === 'steel-blue') {
              console.log('Updating user list');
              child.apiService.add("frontusers/synchronize")
                .then((data:any)=>{
                  typeof data == 'string'? console.log(data) : console.log('Users updated:' + data.updatedUsers + ' in ' + data.elapsedSeconds + ' seconds');
                  return true;
                });
          }else{
            return true;
          }
      }
  }

  ssoLoginError(err:any, child:any) {
      this.loginCheckError(err, child);
      if (child.globalCfg.ssoErrorNoAccessUrl || child.globalCfg.ssoErrorUrl) {
          let errorRedirectUrl = child.globalCfg.ssoErrorNoAccessUrl && err.status === 403 && err.description === 'no-products' ? child.globalCfg.ssoErrorNoAccessUrl : child.globalCfg.ssoErrorUrl;
          let language = child.translate.use();
          if (language) {
              language = language.split('-')[0];
          }
          errorRedirectUrl = errorRedirectUrl.replace('{language}', language)
          window.location.href = errorRedirectUrl;
      } else {
          return child.router.navigate(['login', err ]);
      }
  }
  getCallToken(){
    return this.getToken().then((token:any)=>{
          return this.getApiServiceLogin(token, 'check', 'loginCheckError');
      });
  }

  getToken() {
    let backofficeUrl:any = this.apiProperties.backofficeUrl;
    let defered = new Promise((resolve, reject)=>{
      window.addEventListener('message', _onMessage, false);
      try {
        window.opener.postMessage({ message: 'get-token' }, backofficeUrl);
      } catch (e) {
          window.removeEventListener('message', _onMessage, false);
          reject(e);
      }

      function _onMessage(e:any) {
          if (backofficeUrl.toLowerCase() === e.origin.toLowerCase()) {
              if (e.data && e.data.message === 'token') {
                  window.removeEventListener('message', _onMessage, false);
                  if (e.data.data && e.data.data.jwtToken) {
                      resolve(e.data.data.jwtToken);
                  } else {
                      reject('invalid callback response');
                  }
              }
          }
      }
    })
    return defered;
  }
}
