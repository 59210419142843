import moment from 'moment';

import { Injectable } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';


@Injectable({
  providedIn: 'root'
})

export class TaskEditParentService {

  constructor(private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private modalService: ModalServiceService,
    private dateConverterService: DateConverterService,
    private convertNumbToName: ConvertNumToNameFactory,
    private context: ContextFactory,
    private registerService: RegisterMixtapeService) { }

    configureTaskSelect(child:any){
      let that: any = child.taskEdit;
      that.isNew = false;
      that.task = that.taskSelect;
      that.userLogin = that.task.createdBy;
      that.task.taskEvaluation = that.taskSelect.evaluation;
      that.task.taskEvaluationId = that.taskSelect.evaluationId;
      that.task.taskClient = that.taskSelect.client;
      that.task.taskClientId = that.taskSelect.clientId;
      if(that.task.taskTypeId === 6 ){
          that.task.type = that.task.repeatPattern?.frecuencyId? this.translate.instant('task-edit.periodic-management') : this.translate.instant('task-edit.punctual-management');
      }
      if(that.task.expirationDate){
          that.taskDate = new Date(moment(that.task.expirationDate).set({hour:23,minute:59,second:59}).valueOf());
          this.expiratedDate(child);
      }
      if (!child.taskEdit.concept && !child.taskSelect.state){
        that.task.state = child.taskSelect.state;
      }
      that.creationDate = that.task.creationDate;
      that.task.typeText = child.skin === 'uk-steel-blue'? that.task.type+' '+ this.translate.instant('dashboard.Task').toLowerCase() : this.translate.instant('task-edit.task-of')+' '+that.task.type;
      this.configureTaskType(child);
    }

    attributeWatcher(attr:any, child:any) {
        this.broadcastService.broadcast(attr);
    }

    configureTaskType(child:any){
        this.getHistoric(child);
        if(child.taskEdit.task.taskTypeId === 7){
            child.taskEdit.typeStatesProcess = 'processTask';
        }
    }

    getHistoric(child:any){
        this.apiService.get('tasks/historic', child.taskEdit.task.taskId)
            .then((data:any)=>{
                child.taskEdit.historicList = data;
                this.loader.closeLoader(['dashboard-panel-generic-task', 'dashboard-panel-concept-task']);
            }, (errorData:any)=>{
              child.taskEdit.historicList = [];
            });
    }

    closeLoader(child:any){
      this.loader.openLoader('front-login-public-login')
    }

    configureNewTask(child:any){
      child.taskEdit.task = {
          taskTypeId: child.taskTypeIdSelect || null,
          title: '',
          description: '',
          participants: [],
          expirationDate: '',
          location: '',
          beginHour: null,
          endHour: null
      };
      child.taskEdit.userLogin = this.context.user.fullName;
      child.taskEdit.creationDate = new Date().toISOString();
      child.taskEdit.isNew = true;
      child.shared = false;
      child.taskEdit.disabledTaskEvaluation = child.taskEdit.task !== null
      this.loader.closeLoader(['dashboard-panel-generic-task', 'dashboard-panel-concept-task']);
    }


    changeState(task:any, child:any){
        child.taskEdit.task = task;
        if(child.taskEdit.task.taskStateId === 5 || child.taskEdit.task.taskStateId === 6){
          this.broadcastService.broadcast('hasOpenEvaluationTask', {evaluationTask: false});
        }
        this.broadcastService.broadcast('changeCloseTaskState');
        this.getHistoric(child);
    }

    changeResponsible(responsible:any, child:any) {
        if (child.taskEdit.task.participants && child.taskEdit.task.participants.length) {
            let participant:any = child.taskEdit.task.participants[0];
            participant.id = responsible.responsibleId;
            participant.fullName = (responsible.name + ' ' + responsible.surname).trim();
        }
    }

    openQuestionModal(text:any, child:any) {
      child.taskEdit.typeModal === 'confirm:continue:check-round'? this.openModalAdvice(text, child.taskEdit.typeModal, child) : this.openModalAdvice(text, 'info:question:question',child, child.taskEdit.typeModal);
    }

    openModalAdvice(text:string, typeModal:string, child:any, option:string = ''){
        this.modalService.adviceModal(text, typeModal).subscribe((result:any)=>{
            if(result.result ==='ok' && child.isLanding){
              let taskObject: any = option === 'confirm'? {}: {exitType:'save-task', taskId: child.taskId};
              sessionStorage.setItem('exit-landing', JSON.stringify(taskObject));
              child.router.navigate(['shared/exit'])
            }else if(option === 'confirm' && result.result === 'cancel'){
              let to: string = child.taskEdit.field?'cancel' : 'task';
              child.changeView.emit({to:to, task: child.taskEdit.taskSelect});
            }
        })
    }

    saveTask(task:any, child:any) {
        this.loader.openLoader('task-edit-save-task')
        child.taskEdit.taskSave = task;
        this.apiService.patch('tasks', task.taskId, child.taskEdit.taskSave)
            .then((data:any)=>{
                child.taskEdit.taskSelect.documentsShareType = parseInt(task.documentsShareType);
                child.taskEdit.taskSelect.sharedDocumentObjectsInEvaluationTask = task.sharedDocumentObjectsInEvaluationTask;
                let text:any = this.translate.instant('task-edit.the-task') +"'"+ child.taskEdit.taskSave.title +"'"+ this.translate.instant('task-edit.update-task-ok');
                if (child.currentComponent === 'landingPage'){
                    text = this.translate.instant('task-edit.finish-landing');
                    child.taskEdit.typeModal = 'confirm:continue:check-round';
                }
                this.saveForm(child.taskEdit.taskSave, text, 'save', child);
            }, (errorData:any)=> {
                this.loader.closeError();
                this.modalService.adviceModal(this.translate.instant('task-edit.error-save-task') +"'"+ child.taskEdit.task.title + "'", 'error:accept:warning');
            })
    }

    getTitleText(task:any, child:any){
        switch(task.taskTypeId){
            case 1:
              return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-info-request') : this.translate.instant('task-edit.periodic-request');
            case 3:
              return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-evaluation') : this.translate.instant('common.evaluation');
            case 4:
              return task.videoconference? this.translate.instant('task-edit.meeting-online') : task.type;
            case 5:
              return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-comunication') : this.translate.instant('law-info.comm');
            case 6:
              return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-management') : this.translate.instant('task-edit.punctual-management');
            default:
              return task.type;

        }
    }

    saveForm(task:any, text:any, typeAction:any, child:any){
      let that: any = child.taskEdit;
      if(that.field){
        that.field.selectTask = task;
        child.changeView.emit({to:'save-and-exit-field', task: task, action: typeAction});
      }else{
        that.taskCopy = cloneDeep(task);
        this.getHistoric(child);
        this.openQuestionModal(text, child);
        this.expiratedDate(child);
        child.modifyTaskList.emit({action:'saveForm', task:task});
        this.loader.closeLoader(['task-edit-save-task']);
      }
      let classForm:string = child.isLanding? '.task-edit--custom' :  '.concept-form';

      let htmlContent:any = {
          taskId: task.taskId,
          htmlContent: $(classForm).html()
      };
      this.apiService.patch('tasks/savepdf', task.taskId, htmlContent)
          .then((data:any)=>{},()=>{ this.loader.closeError();});
    }

    expiratedDate(child:any) {
        if(child.taskEdit.task.expirationDate !== null){
            child.taskEdit.taskExpirated = this.dateConverterService.compareDates(child.taskEdit.task.expirationDate);
            child.taskEdit.dayText = child.taskEdit.taskExpirated === 1? this.translate.instant('add-alert.day') : this.translate.instant('add-alert.days');
        }
    }

    createTask(task:any, child:any){
      let that: any = child.taskEdit
      this.loader.openLoader('task-edit-save-task');
      task.documents = that.temporalDocuments && that.temporalDocuments.length? that.temporalDocuments : [];
      let resgisterItem:any ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event': child.taskEdit.concept?.conceptObjectId? 'create task concept' : 'create task dashboard',
        'Concept Name': child.taskEdit.concept? sessionStorage.getItem('breadcrumbConcept') + child.taskEdit.concept.title:'dashboard',
        'Task Type': this.convertNumbToName.taskNumbToName(String(task.taskTypeId)),
        'repetir tarea periódicamente': task.repeatPattern? '1' : '0',
        'Source': child.taskEdit.concept? 'implantation' : 'dashboard',
        'Centinela product': this.context.settings.defaultProduct
      }

      this.registerService.register(resgisterItem);
      let url:string = this.getUrlCreateTask(child);
      this.apiService.add(url, task)
        .then((data:any)=>{
            this.attributeWatcher("disableTaskTypeInput", child);
            this.configureState(data, child);
            that.isNew = false;
            let text = this.translate.instant('task-edit.the-task') +"'"+ task.title +"'"+ this.translate.instant('task-edit.created-ok');
            data.typeText = this.translate.instant('task-edit.task-of') +' '+ data.type;
            if(child.skin === 'uk-steel-blue'){
                data.typeText = data.type +' '+ this.translate.instant('dashboard.Task').toLowerCase();
            }
            if(child.currentComponent !== 'genericTask'){
                data.type = this.getTitleText(task, child);
            }
            if(task.taskTypeId === 4){
                data.videoconference = that.task.videoconference;
                data.virtualRoomWithPassword = that.task.virtualRoomWithPassword;
            }

            that.task = data;
            if(that.temporalDocuments && that.temporalDocuments.length){
                if(!data.errorInDocuments){
                    this.getAttachmentsDocument(child);
                }else{
                    if(child.currentComponent !== 'genericTask'){
                        this.getConceptDocumentation(child);
                    }
                    this.modalService.adviceModal(this.translate.instant('task-edit.error-up-documents'), 'error:accept:warning');
                }
            }
            this.saveForm(that.task, text, 'create', child);
            if(child.currentComponent !== 'genericTask'){
                if(task.taskTypeId === 3 && child.taskEdit.concept.conceptObjectId){
                    this.broadcastService.broadcast('hasOpenEvaluationTask', {evaluationTask: true})
                }
                child.modifyTaskList.emit({action:'reloadList', task:data});
            }
        }, ()=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('task-edit.error-create-task') +"'"+ that.task.title + "'", 'error:accept:warning');
        });
    }

    getUrlCreateTask(child:any){
      let that: any = child.taskEdit
      let concept: any = child.taskEdit.concept;
        if(child.currentComponent === 'genericTask'){
          if(that.task.clientId && ((concept.conceptObjectId === undefined) || (concept && concept.productId === 'blanqueo' && concept.conceptNamedKey === 'Cliente'))){
            return 'tasks/byclientid/' + that.task.clientId
          }else{
            return 'tasks/byevaluationid/'+that.task.evaluationId;
          }
        }else{
            return child.field?'tasks/byconceptdataid/'+ concept.conceptObjectId +'/'+ child.field.evaluatedValue.conceptDataId : 'tasks/'+ concept.conceptObjectId;
        }
    }

    configureState(task:any, child:any){
      task.state = task.taskTypeId === 2? this.translate.instant('task-edit.in-progress') : this.translate.instant('customize.open');
    }


    getConceptDocumentation(child:any){
        this.apiService.get('documentobjects/'+child.taskEdit.concept.conceptObjectId)
        .then((data:any)=>{
          child.taskEdit.concept.documentation = data;
          this.getAttachmentsDocument(child);
        }, (errorData:any)=>{
            this.loader.closeError()
        })
    }

    getAttachmentsDocument(child:any){
      let that: any = child.taskEdit;
        this.apiService.get('documentobjects/task/' + that.task.taskId)
            .then((data:any)=>{
              that.attachmentListDocument = data;
                if( that.concept){
                  that.concept.documentation.documents = [...that.concept.documentation.documents, ...that.attachmentListDocument];
                  that.concept.documentation.totalDocuments += that.attachmentListDocument.length;
                }
            }, (errorData:any) => {
              that.attachmentListDocument = [];
            });
    }

    cancelCreateTask(child:any) {
      let that: any = child.taskEdit;
        if(that.taskSelect && that.taskCopy){
          that.taskCopy.state = that.taskSelect.state;
          that.taskCopy.taskStateId = that.taskSelect.taskStateId;
          that.taskSelect = that.taskCopy;
        }else{
          that.taskSelect = {};
        }

        child.changeView.emit({to:'cancel', task: that.taskSelect});
    };

    createAlert(alert:any, child:any){
        this.apiService.add('datealerts/fromtask/'+ child.taskEdit.task.taskId, this.configureAlert(alert.alert))
            .then((data:any)=>{
                let text:string =  this.translate.instant('task-edit.generate-alert') +"'"+ alert.nameField + "'.";
                if(alert.share){
                    this.sendShare(data, alert.share, text, child);
                }else{
                    this.loader.closeLoader('create-alert-field-item');
                    this.modalService.adviceModal(text, 'info:accept:check-round');
                    this.broadcastService.broadcast('addNew', {});
                }
            }, (errorData:any)=>{
                this.loader.closeError();
        })
    }

    sendShare(alert:any, share:any, text:string, child:any){
        this.apiService.add('datealerts/sharealert/' + alert.dateAlertId + '/omit-notify-creator/' + alert.omitNotifyToCreator, share)
            .then((data:any)=>{
                this.loader.closeLoader('create-alert-field-item');
                this.modalService.adviceModal(text, 'info:accept:check-round');
                this.broadcastService.broadcast('addNew', {});
            }, (errorDate:any)=>{
                this.loader.closeError();
            })
    }

    configureAlert(alert:any){
        if(alert.documentAlertTypeId){
            alert.dateAlertTypeId = alert.documentAlertTypeId
            delete alert.documentAlertTypeId;
        }
        return alert;
    }



    launchAction(action:any, child:any){
        switch(action.type){
          case 'addCommentToTask':
            if (action.comments && action.comments !== "") {
                this.broadcastService.broadcast('addNewComment', {
                    commentText: action.comments
                })
            }
            break;
          case 'saveTask':
            this.saveTask(action.task, child);
            break;
          case 'createTask':
          case 'createGenericTask':
            this.createTask(action.task, child);
            break;
          case 'addDocumentToTaskNew':
            child.taskEdit.temporalDocuments = child.taskEdit.temporalDocuments.concat(action.list);
            break;
          case 'deleteDocumentToTaskNew':
            child.taskEdit.temporalDocuments = action.list;
            this.loader.closeLoader('attachments-template-delete');
            break;
          default:
            this.updateTask(action.task, child);
            break;
        }
    }

    updateTask(task:any, child:any) {
        child.taskEdit.task = task;
    }
}
