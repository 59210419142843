import { Component, Input, OnInit } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';

@Component({
  selector: 'tooltip-info',
  templateUrl: './tooltip-info.component.html'
})
export class TooltipInfoComponent implements OnInit {
  custom: any = this.customLanding.getCustom();
  isLanding: boolean = this.customLanding.isLanding();
  skin:string = this.globalCfg.skin;
  loginInfo:any = JSON.parse(sessionStorage['loginInfo']);
  tooltipInfo: any = {
    showInfo: false,
    descriptionTooltip: ''
  }
  constructor(private route: ActivatedRoute,
    private globalCfg:GlobalCfgFactory,
    private modalService:ModalServiceService,
    private apiService:ApiServiceService,
    private registerService:RegisterMixtapeService,
    private customLanding: CustomLandingFactory,
    private context: ContextFactory
    // private sanitize: DomSanitizer
  ) { }

  @Input() field:any;
  @Input() concept:any;
  @Input() description:string;
  @Input() id:string;
  @Input() isSequentialPoll:string;

  ngOnInit(){
    //_selectTemplate();
    // this.tooltipInfo.descriptionTooltip = this.sanitize.bypassSecurityTrustHtml(this.descriptionTooltip);

    if(this.description){
        let description = this.extractRelatedOrReferences('data-related="', this.description);
        this.tooltipInfo.descriptionTooltip = this.extractRelatedOrReferences('data-references="', description);
    }
  }

  extractRelatedOrReferences(type:string, description:string){
    if(description.indexOf('<a') !== -1){
        let aSplit = description.split('<a ')
        for(let i = 0; i < aSplit.length; i++){
            let aTag = aSplit[i];
            if(aTag.indexOf(type) !== -1){
                aTag = aTag.replace('href="#"','');
                aTag = aTag.replace(type,'(click)="openRelated(');
                aTag = aTag.replace('}">','})"><span class="common__tiny-link-span">');
                aTag = aTag.replace('style="color: #551a8b; text-decoration: underline;"', '')
                aSplit[i] = aTag.replace('</a>','</span><i class="lf-icon-open-window common__tiny-link-icon"></i></a> ');
            }
        }
       return aSplit.join('<a ')
    }else{
        return description;
    }
  }

  openRelated(item:any){
    if(item.modal === 'related-information'){
      this.modalService.relatedInformationModal(item);
    }else{
      this.openReferences(item)
    }
  }

  openReferences(item:any){
    if(this.concept && this.concept.title){
        this.registerEvent(this.concept.title, item);
    }else if(this.field && this.field.conceptTitle){
        this.registerEvent(this.field.conceptTitle, item);
    }else{
        this.getConcept(item);
    }
  }

  getConcept(item: any){
    let evaluationId = this.field && this.field.evaluationId?this.field.evaluationId: this.route.snapshot.params['evaluationId'];
    this.apiService.get("fields/getconceptbyfieldid/" + evaluationId + "/", this.field.fieldId)
      .then((data:any) => {
          this.registerEvent(data.title, item);
      }, (error:any) => {
        console.error(error);
      });
  }

  registerEvent(title:any, item: any){
      let registerItem = {
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':'open legal docs',
          'Concept Name': sessionStorage['breadcrumbConcept'] + title,
          'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(registerItem);
      this.modalService.lawInfoModal(registerItem, [item], [item]);
  }

  // showTooltipInfo(e:any){
  //   e.stopPropagation();
  //   e.preventDefault();

  //   this.tooltipInfo.showInfo = !this.tooltipInfo.showInfo;
  // }

  close(){
      this.tooltipInfo.showInfo = !this.tooltipInfo.showInfo;
  }
}
