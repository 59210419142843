import {Injectable } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class ClientsListParentService {
  skin:string =  this.globalCfg.skin;
  constructor(
    private globalCfg:GlobalCfgFactory,
    private loader:LoaderFactory,
    private apiService:ApiServiceService,
    private translate:AfTranslateFactory,
    private modalService:ModalServiceService,
    private registerService:RegisterMixtapeService,
    private commonUtilsService:CommonUtilsService,
    private downloadService:DownloadService,
    private context:ContextFactory
  ) {}

  OnInit(child: any): void {
    if(Boolean(child.clientList?.length)){
      this.ConfigureClientType(child);
    }else{
      child.clientsList.noClientsList = true;
    }
    this.loader.closeLoader(['front-login-login'])
  }

  editClient(client:any, child:any){
    this.ShowModalClient(client, child);
  }

  ShowModalClient(client:any, child: any){
    let configuration = {
      type: 'edit',
      organization: client,
      listOrganization: child.clientList,
      onlyAffiliated: child.permissions.canCreateOnlyAffiliates,
      disabledField: false,
      createClients:child.permissions.canCreateClients
    }
    this.modalService.organizationModal(configuration)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
            this.saveClient(result, client, child);
        }
      });
  }

  saveClient(result:any, client:any, child: any){
    if(this.skin === 'uk-steel-blue'){
        result.client.observations = result.client.observationsContact.join('-|-');
    }
    this.loader.openLoader('save-client');
    this.apiService.patch('clients', result.client.clientId, result.client)
      .then((data:any) => {
          this.ConfigureClient(result.client, client);
          this.SetClient('editClient', 'modify', result, client, child);
          this.loader.closeLoader(['save-client']);
      },(error:any) => {
          console.error(error);
          this.loader.closeError();
          this.ErrorModal(this.translate.instant('clients-list.error-edit'));
      });
  }

  ConfigureClient(fromClient:any, toClient:any) {
    toClient["address"      ] = fromClient["address"      ];
    toClient["cif"          ] = fromClient["cif"          ];
    toClient["city"         ] = fromClient["city"         ];
    toClient["country"      ] = fromClient["country"      ];
    toClient["email"        ] = fromClient["email"        ];
    toClient["name"         ] = fromClient["name"         ];
    toClient["phoneNumber"  ] = fromClient["phoneNumber"  ];
    toClient["postalCode"   ] = fromClient["postalCode"   ];
    toClient["province"     ] = fromClient["province"     ];
    toClient["logo"         ] = fromClient["logo"         ];
    toClient["parentId"     ] = fromClient["parentId"     ];
    toClient["percentage"   ] = fromClient["percentage"   ];
    toClient["legalFormId"   ] = fromClient["legalFormId"   ];
    if(this.skin === 'uk-steel-blue'){
        toClient["reference2"   ] = fromClient["reference2"   ];
        toClient["clientTypeId"   ] = fromClient["clientTypeId"   ];
        toClient["type"   ] = fromClient["type"   ];
    } else {
      toClient["observations"] = fromClient["observations"];
    }
  }

  SetClient(action:string, type:string, result:any, client:any, child: any){
    if(result.logo){
        this.AddLogoToClient(result, client, type, action, child);
    } else {
        this.ConfirmModal(client.name, type, child);
        this.AddClientToList(action, client, child);
    }
  }

  AddLogoToClient(result:any, client:any, type:string, action:string, child: any){
    this.loader.openLoader('add-logo');
    this.apiService.attachFilePatch('clients/'+client.clientId+'/logos', result.logo)
      .then((data:any) => {
            client.hasLogo = true;
            this.ConfirmModal(client.name, type, child);
            this.AddClientToList(action, client, child);
            this.loader.closeLoader('add-logo');
        },(error:any) => {
          console.error(error);
          this.ErrorModal(this.translate.instant('clients-list.error-logo'));
        });
  }

  ConfirmModal(name:string, type:string, child: any){
    if(child.clientList?.length){
        child.clientsList.noClientsList = false;
    }
    let text : string = this.translate.instant('dashboard.client') + "'" + name + "'" + this.translate.instant('dashboard.ok-'+type);
    this.OpenInfoModal(text);
  }

  AddClientToList(action:string, client:any, child: any){
    if(action === 'editClient'){
        let clientList : any = cloneDeep(this.commonUtilsService.replaceElement(client, child.clientList, 'clientId', client));
        child.clientList = clientList;
        if(this.skin === 'uk-steel-blue'){
            child.launchAction.emit({
                action: 'changeInSelectedClient',
                client: client
            });
        }
    }else if(action === 'newClient'){
        child.clientList.push(client);
        child.clientList = [...child.clientList];
        if(this.skin === 'uk-steel-blue'){
            child.launchAction.emit({action: action});
        }
    }
    this.ResetHolding(child);
    this.ConfigureClientType(child);
  }

  ResetHolding(child: any){
    child.clientList.forEach((client: any) => {
        delete client.holding;
    });
  }

  ConfigureClientType(child: any){
    let that = child.clientsList;
    if(this.skin != 'uk-steel-blue'){
        child.clientList.forEach((client: any) => {
            delete client.holding;
            if(client.parentId){
                client.holding = this.translate.instant('dashboard.holding-filial');
                this.FindParent(client, child);
            }
        })
    }
    if(child.clientList && child.clientList.length){
        that.noClientsList = false;
    }
  }

  FindParent(client:any, child: any){
    for(let clientItem of child.clientList){
        if(clientItem.clientId === client.parentId && !clientItem.parentId){
            clientItem.holding = this.translate.instant('dashboard.holding-matriz');
            break;
        }
    }
  }

  NewClient(child: any){
    if(child.permissions.canCreateClients){
        let configuration = {
          type: 'new',
          organization: {},
          listOrganization: child.clientList,
          onlyAffiliated: child.permissions.canCreateOnlyAffiliates,
          disabledField: false,
          createClients:child.permissions.canCreateClients
        }
        this.modalService.organizationModal(configuration)
          .subscribe((result:any) => {
              if(result.result === 'ok'){
                if(this.skin === 'uk-steel-blue'){
                    result.client.observations = result.client.observationsContact.join('-|-');
                }
                if(result != null){
                  this.CreateNewClient(result, child);
                }
              }

          });
    }
  }

  CreateNewClient(clientNew:any, child: any){
    let itemSelected = {
        type: 'create-client',
        item: clientNew.client
    }

    this.modalService.informativeLoadingModal(itemSelected)
      .subscribe((result:any) => {
          if(result.result !== 'cancel'){
              result.item.canAdmin = true;
              this.ConfigureClient(result.item, clientNew.client);
              this.SetClient('newClient', 'created', clientNew, result.item, child);
              this.registerService.register({
                'Distinct ID': this.context.user.externalId,
                'Client ID': this.context.user.companyExternalId,
                'event': 'create organization',
                'Centinela product': this.context.settings.defaultProduct
              });
          }
      });
  }

  OpenMigrationModal(child: any){
    this.modalService.migrationData(true)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          switch(result.optionSelect){
              case 'import':
                  this.OpenImportModal(child);
                  break;
              case 'export':
                  this.exportData();
                  break;
              default:
                  this.exportTemplate();
                  break;
          }
        }
      });
  }

  OpenImportModal(child: any){
    this.modalService.importModal('clients').subscribe((result:any) => {
      if(result.result === 'ok'){
        this.UploadClients(result, child);
      }
    });
  }

  UploadClients(result:any, child:any){
    let importClients : any = result.validateFields.concat(result.incompleteFields)
    this.ConfigureImportClients(importClients);
    this.loader.openLoader('upload-clients');
    this.apiService.add('clients/import-csv', importClients)
      .then((data:any) => {
          data.forEach((client: any) => {
            child.clientList.push(client);
          })
          this.ConfigureClientType(child);
          this.loader.closeLoader('upload-clients');
          this.modalService.adviceModal(this.translate.instant('clients-list.import-clients-success'), 'info:accept:warning', 'concepts', result.errorFields);
        },(error:any) => {
            console.error(error);
            this.loader.closeError();
        });
  }

  ConfigureImportClients(clients:any[]){
    clients.forEach((client: any) => {
        delete client.errorList;
        delete client.type;
        delete client.$$hashKey;
        delete client.nameComplete;
    })
  }

  exportData(){
    this.loader.openLoader('export-data');
    this.apiService.getBlob('clients/export-csv/data', 'true')
      .then((data:any) => {
        this.downloadService.triggerDownload(data.blob, data.fileName);
        this.loader.closeLoader('export-data');
        this.modalService.adviceModal(this.translate.instant("clients-list.export-data"), 'info:accept:check-round');
      },(error:any) => {
          console.error(error);
      });
  }

  exportTemplate(){
    let url : any = this.skin === 'uk-steel-blue'?'uk/taxwin/clients/download-template':'clients/export-csv/data/false';
    this.loader.openLoader('export-template');
    this.apiService.getBlob(url)
      .then((data:any) => {
          this.downloadService.triggerDownload(data.blob, data.fileName);
          this.loader.closeLoader('export-template');
          this.modalService.adviceModal(this.translate.instant("clients-list.export-template"), 'info:accept:check-round');
        },(error:any) => {
          console.error(error);
          this.loader.closeError();
        });
  }

  DeleteClientModal(client:any, child: any){
    this.apiService.get('evaluations/client', client.clientId)
      .then((data:any)=>{
        let text: any = data.length > 0
          ? this.translate.instant('clients-list.error-delete-organization-title') + " " + this.translate.instant('clients-list.error-delete-organization-desc')
          : this.translate.instant('clients-list.delete-organization-question');
        let textInfo = {
          text: text,
          icon: data.length > 0?'error:accept:warning':'info:question:question',
          option: 'goDeleteClient',
          item: client
        }
        this.modalService.adviceModal(textInfo.text, textInfo.icon)
          .subscribe((result:any) => {
              if(result.result === 'ok'){
                this.DeleteClientSelected(textInfo.item, child);
              }
          });
      }, (errorData:any)=>{})
   
  }
 
  DeleteClientSelected(client:any, child: any){
    this.loader.openLoader('delete-client');
    this.apiService.delete('clients', client.clientId).then(
      (data:any) => {
        child.clientList = this.commonUtilsService.deleteElement(client,  child.clientList, 'clientId')
        let text : string = this.translate.instant('clients-list.delete-organization-ok');
        this.ResetHolding(child);
        this.ConfigureClientType(child);
        this.OpenInfoModal(text);
        if(!child.clientList?.length){
            child.clientsList.noClientsList = true;
        }
      },
      (error:any) => {
        console.error(error);
        this.ErrorModal(this.translate.instant('dashboard.error-delete-client') + '"' + client.name + '"');
      }
    ).finally(() => {
      this.loader.closeLoader('delete-client');
    });
  }

  ToggleSearch(child: any) {
    let that = child.clientsList;
    that.search = !that.search;
    if(that.search){
        that.oldCurrentPage = cloneDeep(that.currentPage);
        that.oldPageSize = cloneDeep(that.pageSize);
        that.pageSize = 1000;
        that.currentPage = 1;
    }else{
        that.currentPage = cloneDeep(that.oldCurrentPage);
        that.pageSize = cloneDeep(that.oldPageSize);
        that.searchClient = '';
    }
  }

  OpenInfoModal(text:string){
    this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  ErrorModal(text:string){
    this.modalService.adviceModal(text, 'error:accept:warning');
  }
}
