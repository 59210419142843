import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DashboardPanelParentService } from '../utils/dashboard-panel-parent.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';



@Component({
  selector: 'dashboard-panel',
  templateUrl: './dashboard-panel.component.html'
})

export class DashboardPanelComponent implements OnInit{
    skin: any = this.globalCfg.skin;
    changeProductFromAML002: any = this.context.settings.defaultProduct === 'aml002'
    forUk: boolean = false;
    noBeAndUk: boolean = false;
    noAmlBE: boolean = false;
    noMenuProducts: boolean = false;
    // oneProduct: boolean = false;
    finishAllCalls: number = 0;
    wsController: string = 'company';
    hasDenuncProp: boolean = false;
    urlPart: string = '';
    urlSecondPartInstances:string ='';
    instaceBox: boolean = false;
    adviceModalSubject: any;
    informativeModalSubject: any;
    dashboardPanel:any = this.dashboardPanelParent.configureDashboardPanel(this);
    

    constructor(private globalCfg: GlobalCfgFactory,
        private context: ContextFactory,
        private loader: LoaderFactory,
        private commonUtilsService: CommonUtilsService,
        private router: Router,
        private dashboardPanelParent: DashboardPanelParentService,
        private registerService: RegisterMixtapeService) {
    }

    ngOnInit(): void {
        this.dashboardPanelParent.init(this);
        if(this.skin === 'dark-blue'){
          this.dashboardPanelParent.checkOauth();
        }
        
    }

    addFilterProduct(product:any, changeProduct:boolean = false){
        this.dashboardPanelParent.addFilterProduct(product, this, changeProduct);
    }

    addFilterClient(client:any){
        this.dashboardPanelParent.addFilterClient(client, this);
    }

    changeProduct(productSelected: any){
        this.dashboardPanelParent.changeProduct(productSelected, this);
    }

    changeTaskList(e: any){
        this.dashboardPanelParent.changeTaskList(e.view, this);
    }

    launchActionOn(e:any){
        this.dashboardPanelParent.launchAction(e.action, this);
    }

    launchAction(action: any){
        this.dashboardPanelParent.launchAction(action, this);
    }

    duplicateEvaluation(newEvaluation:any){
        this.dashboardPanelParent.duplicateEvaluation(newEvaluation, this);
    }

    goToOrganizations(){
        this.loader.openLoader('open-list-organizations');
        this.router.navigate(["organizations"]);
    }

    openEvaluation(){
        this.loader.openLoader('dashboard-panel-create-evaluation');
        let evaluation = this.dashboardPanel.evaluationList[0];
        this.dashboardPanelParent.isUpgradeable(evaluation, this);
    }

    gotToAllDocuments(id:string){
        var resgisterItem ={
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':'show all documents',
            'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(resgisterItem)
       
        this.loader.openLoader('front-login-login');
        this.router.navigate(['documentation/', id ]);
    }

    reloadAll(){
        this.dashboardPanelParent.reloadAll(this);
    }

    addFilterClientOn(e:any){
        this.dashboardPanelParent.addFilterClient(e.client, this);
    }
}
