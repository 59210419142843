import $ from 'jquery';

import { Injectable } from '@angular/core';
import { GlobalCfgFactory } from '../global-cfg/global-cfg.factory';
import { ContextFactory } from '../context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class OpenChatFactory {
  hasScript:boolean = Boolean($('#chatBubble').length);
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory) { }

  launchScript(){
      if(!this.hasScript && (this.globalCfg.skin === 'dark-blue') && sessionStorage.getItem('landing') !== 'true' ){
          let src = window.location.href.indexOf('lefebvre') !== -1 && window.location.href.indexOf('ppd') === -1? "https://atenea.lefebvre.es/api/chat/script": "https://led-dev-atenea-dev.eu.els.local/api/chat/script"
        $('body').append('<script id="lf-chatservice-lefebvre" data-o="centinela" data-u="' + this.context.user.externalId +'" data-s="Chat_SAC_CENTINELA" src="'+src+'" data-hb="0" data-hm="0" data-align="br"></script>');
          this.hasScript = true;
      }else if(this.hasScript){
        $('#chatBubble').show()
      }
  };

  logOut(){
    $('#modalChatLefebvre').remove();
    $('#chatBubble').hide()
  }
}
