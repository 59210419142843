import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-report',
  templateUrl: './field-type-report.component.html'
})
export class FieldTypeReportComponent implements OnChanges, OnInit {
  skin:string = this.globalCfg.skin;
  fieldTypeReport: any ={
    confidentialLocked: false
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private broadcast: BroadcastService) { }
  @Input() field: any = null;
  @Output() launchActionEmit = new EventEmitter();

  ngOnInit(): void {
    this.broadcast.subscribe('refreshReports', (data:any) => {
        this.getReports();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field'] && changes['field'].currentValue) {
        this.fieldTypeReport.field = changes['field'].currentValue;
        let field: any = this.fieldTypeReport.field;
        field.id = !field.id? this.commonUtilsService.createId() : field.id
        field.description = this.commonUtilsService.checkAndDeletePreTag(field.description)
        this.getReports();
    }
  }

  getReports() {
      this.apiService.get('reportobjects/byreportid/' + this.field.reportId + '/' + this.field.evaluationId)
        .then((data:any) => {
            this.fieldTypeReport.reportList = data.reports;
            this.fieldTypeReport.reportName = data.name;
            this.fieldTypeReport.confidentialLocked = !data.hasConfidentialAccess;
            this.fieldTypeReport.reportObjects = this.fieldTypeReport.reportList;
            let lastId = 0;
            for (let index in this.fieldTypeReport.reportList) {
                let report = this.fieldTypeReport.reportList[index];
                if (report.reportObjectId > lastId) {
                    lastId = report.reportObjectId;
                }
            }
            if(lastId > 0){
              this.getLastReport(lastId);
            }
        }, (error:any) => {
            console.error(error);
        });
  }

  getLastReport(lastId: number){
    this.apiService.get('reportobjects', lastId)
      .then((data:any) => {
          data.extension = data.editPath.substring(data.editPath.indexOf('.') + 1, data.editPath.length).toUpperCase();
          this.fieldTypeReport.lastReport = data;
          this.addReportToDisplayData(this.fieldTypeReport.lastReport);
      }, (error:any) => {
          console.error(error);
      });
  }

  addReportToDisplayData(report:any){
      this.fieldTypeReport.field.evaluatedValue.displayData = {
          id: this.field.fieldId,
          value: report.title,
          valueText: report.title
      }
  }

  launchActionReport(type:string) {
      let action:any = { type: type };

      switch (type) {
          case 'generateReport':
              action.report = {
                  reportId: this.fieldTypeReport.field.reportId,
                  reportTitle: this.fieldTypeReport.reportName,
                  reportObjects: this.fieldTypeReport.reportObjects,
                  predefined: true,
                  // generateCallback: this.getReports
              };
              break;
          case 'goToFolder':
              action.folderId = 'reports';
              break;
          case 'viewReport':
              action.report = this.fieldTypeReport.lastReport;
              break;
      }
      this.launchActionEmit.emit({
          action: action
      });
  }
}


