<div class="field-type-contacts">
  <div class="field-type-contacts__label-block">
      <label class="common__input-block"
          [ngClass]="{'common__input-block--show-references': fieldTypeContacts.references && field.evaluatedValue.conceptDataId !== null,
              'common__show-alert': field.error,
              'common__input-no-editable': field.noEditable}"
          [attr.disabled]="field.noEditable?true:null">
          <div class="common__label-block common__label-block--one-icon"
            [ngClass]="{'common__label-block--one-icon-bubble': fieldTypeContacts.contactListSelected.length && field.typeConfiguration !== 'sign' && field.typeConfiguration !== 'meeting',
                  'common__label-block--four-icon-bubble': field.typeConfiguration === 'sign' || field.typeConfiguration === 'meeting'}">
              <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error,
                          'common__input-text--no-editable': field.noEditable}">
                          <span>{{fieldTypeContacts.titleField}}</span>
                          <span *ngIf="field.label2"
                            class="common__input-text--secondary">{{field.label2}}</span>
                      </span>
              <span class="common__text-required"
                  [ngShow]="field.required">*</span>
              <div class="common__info-icon-block common__info-icon-block"
                  [ngHide]="field.noEditable || !field.description">
                  <tooltip-info [description]="field.description"
                      [field]="field"></tooltip-info>
              </div>
              <div class="common__tooltip-law-block"
                  [ngShow]="field.references && field.references.length && !field.noEditable">
                  <tooltip-law [references]="field.references"
                      [field]="field"></tooltip-law>
              </div>
              <div class="common__tooltip-law-block"
                  [ngShow]="field.relatedInfo && field.relatedInfo.length">
                  <i class="lf-icon-search-text field-item__icon-related-info"
                    (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                    matTooltip="{{'common.related-info' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                  </i>
              </div>
              <div class="common__info-icon-block common__info-icon-block--in-report"
                *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
                <tooltip-in-report [inReport]="fieldTypeContacts.inReport"></tooltip-in-report>
              </div>
          </div>
          <div class="field-type-contacts__block-contacts">
              <div class="float-end">
                  <i class="lf-icon-connected-data field-type-text__icon-references"
                      *ngIf="field.evaluatedValue && field.evaluatedValue.hasReferences"
                      (click)="showReference($event)"
                      matTooltip="{{'common.see-dependencies' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </i>
                  <i class="lf-icon-user common__input-icon common__input-icon-active"
                      [ngClass]="{'common__input-icon-active--disabled': field.noEditable}"
                      (click)="openContactList()"></i>
                  <div class="common__bubble-count common__bubble-count--top-7"
                      *ngIf="fieldTypeContacts.contactListSelected.length && field.typeConfiguration !== 'sign' && field.typeConfiguration !== 'meeting'"
                      [ngClass]="{'common__bubble-count--disabled':  field.noEditable}">{{fieldTypeContacts.contactListSelected.length}}</div>
                  <div class="field-type-contacts__block-icon-sign"
                          *ngIf="field.typeConfiguration === 'sign' || field.typeConfiguration === 'meeting'">
                      <div class="field-type-contacts__icon-size field-type-contacts__icon-size--all"
                          (click)="selectSign('all')">
                          {{fieldTypeContacts.all}}
                      </div>
                      <div class="field-type-contacts__icon-size field-type-contacts__icon-size--pending"
                          (click)="selectSign('pending')">
                          {{fieldTypeContacts.pending}}
                      </div>
                      <div class="field-type-contacts__icon-size field-type-contacts__icon-size--cancel"
                          (click)="selectSign('cancel')">
                          {{fieldTypeContacts.cancel}}
                      </div>
                      <div class="field-type-contacts__icon-size field-type-contacts__icon-size--signed"
                          (click)="selectSign('confirm')">
                          {{fieldTypeContacts.confirm}}
                      </div>
                      <div class="field-type-contacts__select-list"
                          [ngShow]="fieldTypeContacts.showSigned">
                          <div>
                              <i class="lf-icon-close field-type-contacts__icon-close"
                                  (click)="fieldTypeContacts.showSigned = !fieldTypeContacts.showSigned">
                              </i>
                          </div>
                          <div class="field-type-contacts__title-box"
                              [afTranslate]="field.typeConfiguration === 'meeting'?'contacts.invitations':'contacts.required-signatures'"></div>
                          <div [class]="'field-type-contacts__title-icon field-type-contacts__title-icon--'+fieldTypeContacts.selectedSign.color"
                              ngbDropdown
                              id="box-dropdow-statecontacts">
                              <button class="lf-icon-filter-2 field-type-contacts__title-icon-icon-filter"
                                  ngbDropdownToggle>
                              </button>
                              <span class="field-type-contacts__title-icon-icon-quantity">{{fieldTypeContacts.selectAssists}}</span>
                              <span class="field-type-contacts__title-icon-title">{{fieldTypeContacts.selectedSign.title}}</span>
                              <div class="field-type-contacts__subline-box"></div>
                              <ul class="field-type-contacts__box-dropdown"
                                  ngbDropdownMenu>
                                  <li class="field-type-contacts__elementlist-dropdown"
                                      *ngFor="let status of fieldTypeContacts.stateContacts"
                                      (click)="selectSign(status.id, 'dropdown')">
                                      <span>{{status.name}}</span> [{{fieldTypeContacts[status.id]}}]
                                      <i class="lf-icon-check-round-full field-type-contacts__select-icon-dropdown"></i>
                                  </li>
                              </ul>
                          </div>
                          <div class="field-type-contacts__select-list-item">
                              <div class="field-type-contacts__select-item"
                                  *ngFor="let participant of fieldTypeContacts.participantList | afOrderBy: fieldTypeContacts.byWord:false">
                                  <i class="lf-icon-user field-type-contacts__icon field-type-contacts__icon--{{participant.color}}"
                                      [ngClass]="participant.confirm===true?'field-type-contacts__signed-user-icon':'field-type-contacts__no-signed-user-icon'"></i>
                                  <span [ngClass]="{'common__line-through':participant.isDeleted}"
                                      matTooltip="{{participant.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="!participant.isDeleted">
                                      {{participant.fullName}}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="field-type-contacts__block-elements"
                  [ngClass]="{'field-type-contacts__block-elements--simple': field.fieldType === 'simple'}">
                  <ul class="field-type-contacts__multiselect-block">
                      <li class="common__multiselect-item"
                          [ngClass]="{'common__multiselect-item--contacts': field.typeConfiguration === 'field-user' || field.typeConfiguration === 'roles' || field.typeConfiguration === 'user-role'}"
                          *ngFor="let contact of fieldTypeContacts.contactListSelected; let index = index">
                          <div *ngIf="field.typeConfiguration !== 'field-user' && field.typeConfiguration !== 'roles' && field.typeConfiguration !== 'user-role'">
                              <p class="field-type-contacts__item-selected field-type-contacts__item-selected--multiple"
                                  [ngClass]="{'common__line-through': contact.isDeleted}"
                                  matTooltip="{{contact.fullName + (contact.isDeleted ? ' ('+ ('common.inactive-user' | afTranslate)+')' : '')}}"
                                  matTooltipPosition="below"
                                  matTooltipClass="below"
                                  [matTooltipDisabled]="showTooltip('field-type-contacts-multiple-'+index, contact)"
                                  id="{{'field-type-contacts-multiple-'+ index }}">
                                  <span id="{{'span-field-type-contacts-multiple-'+index}}">{{contact.fullName}}</span>
                              </p>
                              <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple"
                                  (click)="deleteContact($event, index)"
                                  [ngShow]="!field.noEditable">
                              </i>
                          </div>
                          <div *ngIf="field.typeConfiguration === 'field-user' || field.typeConfiguration === 'roles' || field.typeConfiguration === 'user-role'">
                              <div class="field-type-contacts__block-contact-name">
                                  <p class="common__multiselect-text common__multiselect-text--no-padding-left"
                                      [ngClass]="{'common__line-through':contact.isDeleted}"
                                      matTooltip="{{contact.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="!contact.isDeleted">
                                      {{contact.fullName}}
                                  </p>
                              </div>
                              <div class="field-type-contacts__block-contact-name">
                                <i class="lf-icon-mail field-type-contacts__icon-selected"
                                  [ngShow]="contact.email"></i>
                                <p class="common__multiselect-text common__multiselect-text--email">{{contact.email}}</p>
                              </div>
                              <div class="field-type-contacts__block-contact-phone">
                                <i class="lf-icon-call field-type-contacts__icon-selected"
                                  [ngShow]="contact.phoneNumber1"></i>
                                <p class="common__multiselect-text common__multiselect-text--email">{{contact.phoneNumber1}}</p>
                              </div>
                              <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple common__multiselect-delete-icon--contacts"
                                  (click)="deleteContact($event, index)"></i>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </label>
      <div class="field-type-contacts__contact-block"
          [ngClass]="{'field-type-contacts__contact-block--user':fieldTypeContacts.state === 'addNew',
              'field-type-contacts__contact-block--roles':field.typeConfiguration === 'roles'}"
          *ngIf="fieldTypeContacts.openUserList">
          <div class="field-type-contacts__new-user-contact"
              [ngShow]="fieldTypeContacts.state ==='addNew'">
              <div class="field-type-contacts__add-new-title-block">
                  <i class="lf-icon-user-new field-type-contacts__add-new-icon"></i>
                  <p class="field-type-contacts__add-new-title-text"
                    [afTranslate]="'common.new-contact'">Nuevo contacto</p>
              </div>
              <new-contact [valueId]="fieldTypeContacts.originalValueId"
                  [option]="fieldTypeContacts.option"
                  (changeView)="changeView()"
                  (addContact)="addContact('contact', $event)">
              </new-contact>
          </div>
          <div class="field-type-contacts__header-nav"
              [ngShow]="fieldTypeContacts.state !=='addNew'">
                <div class="field-type-contacts__selected-icons field-type-contacts__selected-icons--multiple">
                    <div class="field-type-contacts__selection-icons">
                        <button class="lf-icon-user-new common__button-checked"
                            *ngIf="field.addContacts && fieldTypeContacts.canCreateContacts"
                            (click)="changeState($event, 'addNew')"></button>
                        <button class="lf-icon-close common__button-close"
                            [ngClass]="{'span__auto-ajust': field.addContacts}"
                            (click)="resetContacts()"></button>
                        <button class="lf-icon-check common__button-checked"
                            (click)="selectedContacts()"></button>
                    </div>
                </div>
              <!-- A partir de aqui es donde va el componente de simple o multiple -SIMPLE EN ESTE CASO- -->
              <!-- <div ng-include
                src="fieldTypeContacts.getTemplateUrl(child.field.fieldType)"></div> -->

                <div class="field-type-contacts__block-header-multiple">
                  <div class="field-type-contacts__block-header-section"
                      (click)="changeState($event, 'list')"
                      [ngClass]="{'field-type-contacts__block-header-section--active': fieldTypeContacts.state === 'list'}">
                      <div class="field-type-contacts__text-list">
                          <span [afTranslate]="'contacts.list'">Listado</span>
                          <span class="span__auto-ajust"
                              [afTranslate]="'contacts.contacts'"
                              *ngIf="fieldTypeContacts.showTitleContact"> contactos</span>
                      </div>
                      <div class="field-type-contacts__icons-contact"
                          *ngIf="!fieldTypeContacts.hiddenButtonsHeader">
                          <div *ngFor="let optionHeader of fieldTypeContacts.buttonsShow; let index = index; let first = first"
                              class="field-type-contacts__icon-button"
                              [ngClass]="{'field-type-contacts__icon-button--first': first && fieldTypeContacts.buttonsShow.length !== 1,
                                  'field-type-contacts__icon-button--last': index === fieldTypeContacts.buttonsShow.length-1 && fieldTypeContacts.buttonsShow.length !== 1,
                                  'field-type-contacts__icon-button--unique': fieldTypeContacts.buttonsShow.length === 1,
                                  'field-type-contacts__icon-button--active':fieldTypeContacts.viewItems.id === optionHeader.optionAction,
                                  'field-type-contacts__icon-button--no-parent-active':fieldTypeContacts.state !== 'list'}"
                              (click)="showOnly(optionHeader.optionAction, $event)"
                              matTooltip="{{optionHeader.tooltip | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i [class]="'field-type-contacts__icon-option '+ optionHeader.icon"></i>
                          </div>
                      </div>
                  </div>

                  <div class="field-type-contacts__block-header-section field-type-contacts__block-header-section--selection"
                      (click)="changeState($event, 'selection')"
                      [ngClass]="{'field-type-contacts__block-header-section--active': fieldTypeContacts.state === 'selection'}">
                      <div class="field-type-contacts__text-list">
                          <span [afTranslate]="'picker-visibility.selected-view'">_Selección</span>
                          <div class="field-type-contacts__select-box"
                              [ngClass]="{'field-type-contacts__select-box--active': fieldTypeContacts.state === 'selection'}" >{{fieldTypeContacts.contactListSelected.length}}</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="field-type-contacts__body-contacts">
              <div class="field-type-contacts__contacts"
                  [ngShow]="fieldTypeContacts.state !=='addNew'">
                  <input type="text"
                      class="field-type-contacts__search-multiple"
                      [placeholder]="'common.search'| afTranslate"
                      [(ngModel)]="fieldTypeContacts.searchContact">
                  <i class="lf-icon-search field-type-contacts__list-find-icon"
                      [ngClass]="fieldTypeContacts.searchContact ===''?'lf-icon-search':'lf-icon-close-search'"
                      (click)="resetSearch($event)"></i>
                  <p class="field-type-contacts__title-contact-list">
                      <span [ngShow]="fieldTypeContacts.searchContact !==''"
                          [afTranslate]="'contacts.results-of-search'">Resultados de la busqueda en</span>
                      <span>{{fieldTypeContacts.viewItems.label}}</span>
                  </p>
              </div>
              <div class="field-type-contacts__inner-box field-type-contacts__inner-box--multiple"
                  [ngClass]="{'field-type-contacts__inner-box--sms': fieldTypeContacts.showInfoSMS,
                          'field-type-contacts__inner-box--roles':field.typeConfiguration === 'roles'}">
                  <div *ngIf="fieldTypeContacts.state === 'list'">
                      <div [ngShow]="fieldTypeContacts.groupsList.length || fieldTypeContacts.usersAndContactsFilList.length || fieldTypeContacts.productRolesList.length">
                          <ul class="field-type-contacts__contact-book">
                              <li class="field-type-contacts__toggle-all"
                                  *ngIf="(fieldTypeContacts.productRolesList.length + fieldTypeContacts.groupsList.length + fieldTypeContacts.usersAndContactsFilList.length) > 10"
                                  (click)="toggleAll($event)">
                                  <i class="field-type-contacts__checkbox-icon-all"
                                    [ngClass]="fieldTypeContacts.markAll ? 'lf-icon-check-full' : 'lf-icon-box-inactive'"></i>
                                  <span class="field-type-contacts__text-all" [afTranslate]="'common.toggle-all'"></span>
                              </li>
                              <li *ngFor="let productRole of fieldTypeContacts.productRolesList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: 'fullName':false"
                                  class="field-type-contacts__contact-list-to-share"
                                  [ngClass]="{'field-type-contacts__contact-list-to-share--disabled': productRole.disabled}"
                                  [ngShow]="fieldTypeContacts.viewItems.id === 4 || fieldTypeContacts.viewItems.id === 0"
                                  (click)="addOrDeleteMultipleItem(productRole, 'productRole', $event)">
                                  <i class="field-type-contacts__checkbox-size-icon"
                                      [ngClass]="productRole.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                  <span
                                      matTooltip="{{productRole.usersList}}"
                                      matTooltipPosition="right"
                                      matTooltipClass="right">
                                      <i class="lf-icon-lead field-type-contacts__icon-contact"></i>
                                      <span class="field-type-contacts__text-contact">{{productRole.fullName}}</span>
                                  </span>
                              </li>
                              <li *ngFor="let group of fieldTypeContacts.groupsList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: 'fullName':false"
                                  class="field-type-contacts__contact-list-to-share"
                                  [ngClass]="{'field-type-contacts__contact-list-to-share--disabled': group.disabled}"
                                  [ngShow]="fieldTypeContacts.viewItems.id === 1 || fieldTypeContacts.viewItems.id === 0"
                                  (click)="addOrDeleteMultipleItem(group, 'group', $event)">
                                  <i class="field-type-contacts__checkbox-size-icon"
                                      [ngClass]="group.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                  <i class="lf-icon-group field-type-contacts__icon-contact"></i>
                                  <span class="field-type-contacts__text-contact">{{group.fullName}}</span>
                              </li>
                              <li *ngFor="let user of fieldTypeContacts.usersAndContactsFilList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: 'fullName':false | unique: 'uniqueValueId'"
                                  class="field-type-contacts__contact-list-to-share"
                                  [ngClass]="{'field-type-contacts__contact-list-to-share--disabled': user.disabled}"
                                  [ngShow]="(user.contactId && fieldTypeContacts.viewItems.id === 3) || (user.userId && fieldTypeContacts.viewItems.id===2) || fieldTypeContacts.viewItems.id === 0"
                                  (click)="addOrDeleteMultipleItem(user,user.userId ? 'user' : 'contact', $event)">
                                  <i class="field-type-contacts__checkbox-size-icon"
                                      [ngClass]="user.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                  <i [ngClass]="{'lf-icon-user-special':user.userId, 'lf-icon-user':user.contactId}"
                                      class="lf-icon-user-special field-type-contacts__icon-contact"></i>
                                  <span class="field-type-contacts__text-contact"
                                          [ngClass]="{'common__line-through':user.isDeleted}"
                                          matTooltip="{{user.fullName + ' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below"
                                          [matTooltipDisabled]="!user.isDeleted">
                                          {{user.fullName}}
                                  </span>
                              </li>
                          </ul>
                      </div>
                      <div [ngHide]="fieldTypeContacts.groupsList.length || fieldTypeContacts.usersAndContactsFilList.length || fieldTypeContacts.productRolesList.length">
                          <p class="field-type-contacts__error-text"
                              [afTranslate]="'common.no-results-found'">_No se encuentran resultados</p>
                      </div>
                  </div>
                  <div *ngIf="fieldTypeContacts.state === 'selection'">
                      <ul class="field-type-contacts__contact-book">
                          <li *ngFor="let contact of fieldTypeContacts.contactListSelected | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: 'fullName':false"
                              class="field-type-contacts__contact-list-to-share"
                              (click)="whoIs(contact,$event)">
                              <i class="field-type-contacts__checkbox-size-icon"
                                  [ngClass]="contact.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                              <i class="field-type-contacts__icon-contact"
                                  [ngClass]="{'lf-icon-group':contact.groupId,
                                      'lf-icon-user-special':contact.userId,
                                      'lf-icon-user':contact.contactId,
                                      'lf-icon-lead': contact.productRoleId}"></i>
                              <span class="field-type-contacts__text-contact"
                                  [ngClass]="{'common__line-through':contact.isDeleted}"
                                  matTooltip="{{contact.fullName + ' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                  matTooltipPosition="below"
                                  matTooltipClass="below"
                                  [matTooltipDisabled]="!contact.isDeleted">
                                  {{contact.fullName}}
                              </span>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="field-type-contacts__block-info-message"
                  *ngIf="fieldTypeContacts.showInfoSMS">
                  <i class="lf-icon-information field-type-contacts__icon-alert"></i>
                  <span class="field-type-contacts__text-alert"
                      [afTranslate]="'field-type-contacts.info-contacts-sms'">_Por requisitos del trámite, solo se muestran los usuarios y contactos que tienen asociado un teléfono móvil. </span>
                  <i class="lf-icon-close field-type-contacts__icon-close-header"
                      (click)="fieldTypeContacts.showInfoSMS = !fieldTypeContacts.showInfoSMS"></i>
              </div>
          </div>
      </div>
  </div>
</div>
