import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import _ from 'lodash';

@Component({
  selector: 'process-ops-modal',
  templateUrl: './process-ops-modal.component.html'
})
export class ProcessOpsModalComponent implements OnInit {
  skin: string = this.globalCfg.skin === 'uk-steel-blue'?'steel-blue': this.globalCfg.skin
  automaticPostProcessModal: any;
  processOpsModal:any = {
    automaticProcess: [],
    processTitle: null,
    states: null,
    alerts: [],
    processBlockedList: [],
    task: {
      responsibles: [],
      responsiblesList: []
    },
    destinationModal: null,
    canEditProcess: false,
    configuredButtonIcon: false,
    processButtonIcon: 'lf-icon-visible',
    userPermissions: this.userPermissions.getPermissions(),
  };

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private context: ContextFactory,
    private registerService: RegisterMixtapeService,
    private userPermissions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal) { }

  @Input() evaluationInfo: any = null;
  @Input() concept: any = null;
  @Input() from: string = '';

  ngOnInit(): void {
    let that: any = this.processOpsModal;
    that.concept = this.concept;
    that.processId = that.concept?.processId;
    that.task = that.concept?.taskProcess;
    if (that.concept?.processId) {
      if(this.from==='folder' || this.from==='public-link'){
          this.loadTask();
      }
      if(this.processOpsModal.concept?.allowPublicShare){
          if(this.skin === 'icam-red'){
            this.loadAutomaticProcess();
          }
          this.loadProcessBlocked();
      }
      this.loadAlerts();
      this.loadProcessData();
    } else {
      that.processButtonIcon = 'lf-icon-add-round-full';
      that.processTitle = this.translate.instant('process.ops-modal.no-process');
      that.configuredButtonIcon = true;
    }
  }

  loadTask() {
    let that: any = this.processOpsModal;
    this.apiService.get('processgeneraltasks/process', this.processOpsModal.processId).then(
      (data:any)=>{
        if(data){
          that.task = data;
          this.configureTaskResponsibleList();
          that.concept.taskProcess = that.task;
        }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  configureTaskResponsibleList(){
    let that: any = this.processOpsModal;
    that.task.responsiblesList=[];
    if(that.task.responsibles?.length){
        that.task.responsiblesList = that.task.responsibles;
    }else{
      let listResponsibles = that.task.classificatorResponsibles?.length?that.task.classificatorResponsibles:that.task.states;
      let responsiblesMap = new Map();
      listResponsibles.forEach((element:any, index:any) => {
        element.responsibles.forEach((responsible:any) => {
          responsible.uniqueValueId = responsible.uniqueValueId?responsible.uniqueValueId:responsible.type+'-'+responsible.id;
          let uniqueId = responsible.uniqueValueId;
          if(!responsiblesMap.has(uniqueId)){
              responsiblesMap.set(uniqueId, index);
              that.task.responsiblesList.push(responsible);
          }
        });
      });
    }
  }

  unexpectedError(error:any) {
    console.error(error);
    this.loader.closeError();
    this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
  }

  loadAutomaticProcess() {
    this.apiService.get('processgeneralstateautos/publish/process', this.processOpsModal.processId).then(
      (data:any)=>{
        this.processOpsModal.automaticProcess = data;
        if(this.processOpsModal.concept.channelView){
          this.processOpsModal.concept.automaticProcess = data;
        }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  loadProcessBlocked() {
    if(this.processOpsModal.concept.lockedEdit){
      let allProcess:any = {
          stateName: this.translate.instant('blocked-edition-modal.all-process-states'),
          stateId: 'all'
      }
      this.processOpsModal.processBlockedList = [allProcess];
    }else{
        this.apiService.get('processgeneralstateautos/lock/process', this.processOpsModal.processId).then(
          (data:any) => {
            this.processOpsModal.processBlockedList = data;
          },
          (error:any) => {
            this.unexpectedError(error);
          }
        );
    }
  }

  loadAlerts() {
    this.apiService.get('processgeneralalerts/process', this.processOpsModal.processId).then(
      (data:any)=>{
        this.processOpsModal.alerts = data;
        if(this.processOpsModal.concept.channelView){
            this.processOpsModal.concept.alertsListProcess = data;
        }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  loadProcessData() {
    this.apiService.get('processes', this.processOpsModal.processId + '/processopsdata').then(
      (data:any)=>{
        this.processOpsModal.processTitle = data.title || this.translate.instant('process.ops-modal.no-process-title');
        if (!data.hasBackProcess) {
          this.processOpsModal.canEditProcess = true;
          this.processOpsModal.processButtonIcon = 'lf-icon-editable-form';
        }
        this.processOpsModal.configuredButtonIcon = true;
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  processButtonClick() {
    if (this.processOpsModal.processId) {
      if (this.processOpsModal.canEditProcess) {
        this.openModalProcessEdit();
      } else {
        let configuration = {
          evaluationId: this.evaluationInfo.evaluationId,
          concept: this.concept,
          preview: true
        }
        this.modalService.processModal(configuration).subscribe((result:any)=>{
            if(result.result==='ok'){
              this.onProcessUpdated(result.process)
            }
        })
      }
    } else {
      this.openModalProcessEdit();
    }
  }

  openModalProcessEdit(){
    this.modalService.processEdit(this.evaluationInfo.evaluationId, this.concept)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.onProcessUpdated(result.data);
        }
      })
  }

  onProcessUpdated(process:any) {
    if (process === null) {
        if (this.processOpsModal.processId) {
            this.processOpsModal.concept.processId = this.processOpsModal.processId = null;
            this.processOpsModal.processButtonIcon = 'lf-icon-add-round-full';
            this.processOpsModal.processTitle = this.translate.instant('process.ops-modal.no-process');
            if(this.processOpsModal.concept.identifierFieldId === 0){
                this.processOpsModal.concept.identifierFieldId = null;
            }
        }
    } else if (process.processId) {
        if (!this.processOpsModal.processId) {
          this.processOpsModal.concept.processId = process.processId
          this.processOpsModal.processId = process.processId;
          this.processOpsModal.processButtonIcon = 'lf-icon-editable-form';
          this.processOpsModal.processTitle = this.translate.instant('process.ops-modal.no-process');
          if(this.processOpsModal.concept.identifierFieldId === null){
              this.processOpsModal.concept.identifierFieldId = 0;
          }
        }
        this.processOpsModal.canEditProcess = true;
        this.processOpsModal.processTitle = process.title || this.translate.instant('process.ops-modal.no-process-title');
    }
  }

  addTask() {
    let task = {
        processId: this.processOpsModal.processId,
        title: this.translate.instant('process.general-task.default-title-prefix') + ' ' + this.processOpsModal.concept.title,
        description: this.processOpsModal.concept.communicationChannel ? this.translate.instant('process.general-task.communication-channel-description') : '',
        expirationUnits: this.processOpsModal.concept.communicationChannel ? 90 : null,
        expirationTimeUnit: 'D'
    };

    this.modalService.taskModal(task, this.processOpsModal.concept).subscribe((result:any) => {
        this.taskAddedEdited(result);
    });
}

  editTask() {
    if (this.processOpsModal.concept.communicationChannel && !this.processOpsModal.task.expirationConfigured) {
      this.processOpsModal.task.description = this.translate.instant('process.general-task.communication-channel-description');
      this.processOpsModal.task.expirationUnits = 90;
      this.processOpsModal.task.expirationTimeUnit = 'D';
    }

    this.modalService.taskModal(this.processOpsModal.task, this.processOpsModal.concept).subscribe((result:any) => {
        this.taskAddedEdited(result);
    });
  }

  taskAddedEdited(result:any) {
    if (result && result.result === 'ok') {
        this.processOpsModal.task = result.task;
        if (result.task.responsible) {
          this.processOpsModal.task.responsible = result.task.responsible[0];
        }
        this.processOpsModal.concept.hasProcessOps = true;
        this.processOpsModal.concept.taskProcess = result.task;
        this.configureTaskResponsibleList();
    }
  }

  deleteTask() {
    if((this.concept.allowPublicShare || this.concept.anonymousAccess) && this.concept.publicShared){
      this.modalService.adviceModal(this.translate.instant('process.ops-modal.error-delete-task'), 'error:accept:warning')
    }else if(Boolean(this.concept.amountEvaluatedConcept || this.concept.archivedAmount)){
      this.modalService.adviceModal(this.translate.instant('process.ops-modal.error-delete-task-with-instances'), 'error:accept:warning')
    }else{
      this.modalService.adviceModal(this.translate.instant('process.general-task.delete-confirm'), 'info:question:question').subscribe((result:any) => {
        result.result === 'ok' ? this.doDeleteTask() : this.loader.closeLoader(['process-ops-delete-task']);
      });
    }
  }

  doDeleteTask() {
    this.loader.openLoader('process-ops-modal');
    this.apiService.delete('processgeneraltasks', this.processOpsModal.task.processGeneralTaskId).then(
      (data:any)=>{
        this.processOpsModal.task = null;
        this.processOpsModal.concept.taskProcess = null;
        this.loader.closeLoader(['process-ops-modal']);
        this.modalService.adviceModal(this.translate.instant('process.general-task.delete-ok'), 'info:accept:check-round').subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.checkNoProcessOps();
          }
        });
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  addAlert() {
    this.processOpsModal.concept.alertList = this.processOpsModal.alerts;
    let configuration = {
      from: 'processOpsModal',
      type: 'ProcessGeneral',
      concept: this.concept,
      evaluationInfo: this.evaluationInfo
    }
    this.modalService.addAlert(configuration).subscribe((result:any) => {
      if(result.result === 'ok'){
        this.registerEvent('create alert', 'ProcessGeneral');
        this.addAlertToProcess(result);
      }else{
        this.checkNoProcessOps();
      }
    });
  }

  registerEvent(e:string, alerType:string){
    let resgisterItem ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':e,
      'Alert Type':alerType,
      'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.processOpsModal.concept.title,
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem);
  }

  addAlertToProcess(result:any){
    this.apiService.add('processgeneralalerts', result.alert).then(
      (data:any)=>{
        this.processOpsModal.concept.hasProcessOps = true;
        let alert:any = result.alert;
        alert.alertTypeId = alert.processAlertTypeId;
        delete alert.processAlertTypeId;
        alert.processGeneralAlertId = data.processGeneralAlertId;
        alert.createdBy = data.createdBy;
        alert.creationDate = data.creationDate;
        alert.modifiedBy = data.modifiedBy;
        alert.modificationDate = data.modificationDate;
        alert.date = this.translate.instant('process.ops-modal.date-ocurrence');
        alert.creator = data.creator;
        alert.id = data.processGeneralAlertId;
        alert.source = 'ProcessGeneral';

        if (result.share) {
            this.addToShareAlert(result, alert, data, true)
        } else {
            alert.destination = result.share;
            this.processOpsModal.alerts.push(alert);
            if(this.processOpsModal.concept.channelView){
                this.processOpsModal.concept.alertsListProcess = this.processOpsModal.alerts;
            }
        }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  addToShareAlert(result:any, alert:any, data:any, toProcess: boolean = false){
    let id = this.processOpsModal.isConceptObject ? data.processAlertId : data.processGeneralAlertId;
    this.apiService.add('processgeneralalerts/sharealert/' + id + '/omit-notify-creator/' + data.omitNotifyToCreator, result.share).then(
      (data:any)=>{
        alert.destination = result.share;
        if(toProcess){
          this.processOpsModal.alerts.push(alert);
          if(this.processOpsModal.concept.channelView){
            this.processOpsModal.concept.alertsListProcess = this.processOpsModal.alerts;
          }
        }else{
          this.showSuccessAlert(alert);
        }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  editAlert(alert:any) {
    this.concept.alertList = this.processOpsModal.alerts;
    let configuration = {
        from: 'processOpsModal',
        type: alert.source,
        alert: alert,
        concept: this.concept,
        evaluationInfo: this.evaluationInfo
    };

    const mapShare = (share:any) => {
      let mapped:any = {};
      switch (share.type) {
          case 'U':
              mapped.userId = share.id || share.userId;
              break;
          case 'G':
              mapped.groupId = share.id || share.groupId;
              break;
          case 'C':
              mapped.contactId = share.id || share.contactId;
              break;
          case 'R':
              mapped.productRoleId = share.id || share.productRoleId;
              break;
          default:
              throw 'Unknown share type';
      }
      mapped.comments = share.comments;
      return mapped;
    };

    this.modalService.addAlert(configuration)
      .subscribe((result:any) => {
        if(result.result !== 'cancel'){
          this.registerService.register({
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':'edit alert',
            'Alert Type':alert.source,
            'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.concept.title,
            'Centinela product': this.context.settings.defaultProduct
          });

          this.loader.openLoader('process-ops-modal');
          this.apiService.patch('processgeneralalerts', alert.id, result.alert).then(
            (data:any) => {
                _.assign(alert, result.alert);
                alert.alertTypeId = alert.processAlertTypeId;
                delete alert.processAlertTypeId;
                if (result.share) {
                    let shareModels = _.map(result.share, mapShare);
                    this.apiService.add('processgeneralalerts/sharealert/' + alert.id + '/omit-notify-creator/' + alert.omitNotifyToCreator, shareModels).then(
                      () => {
                        alert.destination = result.share;
                        this.showSuccessAlert(alert);
                      },
                      (error:any) => {
                        this.unexpectedError(error);
                      }
                    );
                } else {
                    this.showSuccessAlert(alert);
                }
            },
            (error:any) => {
              console.error(error);
              this.loader.closeLoader('process-ops-modal');
            }
          );
        }
      });
  }

  addToGeneral(alert:any, result:any){
    this.apiService.patch('processgeneralalerts', alert.id, result.alert).then(
      (data:any)=>{
          _.assign(alert, result.alert);
          alert.alertTypeId = alert.processAlertTypeId;
          delete alert.processAlertTypeId;
          if (result.share) {
              this.addToShareAlert(result, alert, data, false)
          } else {
              this.showSuccessAlert(alert);
          }
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }

  mapShare(share:any) {
    let mapped:any = {};
    switch (share.type) {
        case 'U':
            mapped.userId = share.id || share.userId;
            break;
        case 'G':
            mapped.groupId = share.id || share.groupId;
            break;
        case 'C':
            mapped.contactId = share.id || share.contactId;
            break;
        case 'R':
            mapped.productRoleId = share.id || share.productRoleId;
            break;
        default:
            throw 'Unknown share type';
    }
    mapped.comments = share.comments;
    return mapped;
}


  showSuccessAlert(alert:any) {
    this.loader.closeLoader('process-ops-modal');
    let text = this.translate.instant('alert-concept.the-alert') +"'"+ alert.title +"'"+ this.translate.instant('alert-concept.alert-edit');
    this.modalService.adviceModal(text, 'info:accept:check-round')
  }

  deleteAlert(alert:any) {
    this.modalService.adviceModal(this.translate.instant('alert-concept.question-delete-alert') +"'"+ alert.title + "' ?", 'info:question:question').subscribe((result:any)=>{
      result.result === 'ok'? this.doDeleteAlert(alert) : this.loader.closeLoader(['alert-concept-delete-alert']);
    });
  }

  doDeleteAlert(alert:any) {
    this.loader.openLoader('process-ops-modal');
    this.registerEvent('delete alert', 'processgeneral');
    this.apiService.delete('processgeneralalerts', alert.id).then(
      (data: any)=>{
        _.remove(this.processOpsModal.alerts, alert);
        if(this.processOpsModal.concept.channelView){
            this.processOpsModal.concept.alertsListProcess = this.processOpsModal.alerts;
        }
        this.loader.closeLoader(['process-ops-modal']);
        let text = this.translate.instant('alert-concept.the-alert') +"'"+ alert.title +"'"+ this.translate.instant('alert-concept.alert-has-delete');
        this.modalService.adviceModal(text, 'info:accept:check-round').subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.checkNoProcessOps();
          }
        });
      },
      (error:any) => {
        this.unexpectedError(error);
      }
    );
  }


  addOrEditAutomaticProcess(process:any = null){
    let automaticProcess = process === null ? {} : process;

    this.concept.automaticProcess = this.processOpsModal.automaticProcess;
    this.modalService.automaticPostProcess(this.concept, automaticProcess).subscribe((result:any)=>{
      if(result.result === 'ok'){
        let processConfigurate:any = {
          autoTypeId: 'publish',
          processGeneralStateAutoId: process ? process.processGeneralStateAutoId:0,
          stateId: result.stateId
        }

        this.loader.openLoader('add-automatic-process-general');
        if(process){
            this.apiService.update('processgeneralstateautos', null, processConfigurate).then(
              (data:any)=>{
                this.addAutomaticProcessGeneral(data, process)
              },
              (error:any) => {
                console.error(error);
              }
            ).finally(() => {
              this.loader.closeLoader('add-automatic-process-general');
            });
        }else{
            this.apiService.add('processgeneralstateautos', processConfigurate).then(
              (data:any)=>{
                this.addAutomaticProcessGeneral(data, process)
              },
              (error:any) => {
                console.error(error);
              }
            ).finally(() => {
              this.loader.closeLoader('add-automatic-process-general');
            });
        }
      }
    });
  }

  addAutomaticProcessGeneral(data:any, process:any){
    let automaticProcess: any = this.processOpsModal.automaticProcess;
    automaticProcess = !process? automaticProcess.splice(0, 0, data) :  this.commonUtilsService.replaceElement(process, automaticProcess, 'stateId', data);
    if(this.processOpsModal.concept.channelView){
      this.processOpsModal.concept.automaticProcess = this.processOpsModal.automaticProcess;
    }
    this.loader.closeLoader(['add-automatic-process-general']);
  }


  deleteAutomaticProcess(process:any) {
    let text:string = this.translate.instant('process.ops-modal.question-delete-automatic-process').replace('{process}', process.stateName);
    this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
      if(result.result === 'ok'){
        this.loader.openLoader('delete-automatic-process-general');
        this.deleteAutomaticProcessGeneral(process);
      }
    });
  }

  deleteAutomaticProcessGeneral(process:any){
    this.apiService.delete('processgeneralstateautos', process.processGeneralStateAutoId)
      .then((data:any)=>{
          _.remove(this.processOpsModal.automaticProcess, process);
          if(this.processOpsModal.concept.channelView){
            this.processOpsModal.concept.automaticProcess = this.processOpsModal.automaticProcess;
          }

          this.loader.closeLoader('delete-automatic-process-general');
          this.modalService.adviceModal(this.translate.instant('process.ops-modal.delete-automatic-process-ok'), 'info:accept:check-round')

      }, (error:any)=>{
          this.loader.closeError();
          this.unexpectedError(error);
      });
  }

  checkNoProcessOps() {
    this.processOpsModal.concept.hasProcessOps = (this.processOpsModal.task !== null ||this.processOpsModal.alerts.length)
  }



  configureBlockedEdition(){
    this.configureSelectedStatesId();
    this.modalService.blockedEditionModal(this.processOpsModal.concept, this.processOpsModal.blockedEditionSelected)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('configure-blocked-edition');
          if(result.processStatesSelected === 'all'){
            this.apiService.patch('processgeneralstateautos/lock/process', this.processOpsModal.processId + '/all')
              .then((data:any)=>{
                this.callProcessBlockedEdition(result.processStatesSelected, data)
              })
          }else{
            let listStatesId:any = Boolean(result.processStatesSelected && !Number.isInteger(result.processStatesSelected) && result.processStatesSelected.indexOf('|') !==-1)? result.processStatesSelected.split('|') : [result.processStatesSelected];
            this.apiService.patch('processgeneralstateautos/lock/process', this.processOpsModal.processId, listStatesId)
              .then((data:any)=>{
                this.callProcessBlockedEdition(result.processStatesSelected, data)
              })
          }
        }
      })
  }

  configureSelectedStatesId(){
    if(this.processOpsModal.processBlockedList?.length && !this.processOpsModal.blockedEditionSelected){
      this.processOpsModal.blockedEditionSelected = '';
      for(let i = 0; i < this.processOpsModal.processBlockedList.length; i++){
        let stateId = this.processOpsModal.processBlockedList[i].stateId;
        if(i > 0){
            stateId = '|' + stateId;
        }
        this.processOpsModal.blockedEditionSelected += stateId;
      }
    }
  }

  callProcessBlockedEdition(processStatesSelected:any, data: any){
    if(processStatesSelected === 'all'){
        this.processOpsModal.concept.lockedEdit = true;
    }else{
        this.processOpsModal.processBlockedList = data;
        this.processOpsModal.concept.lockedEdit = false;
    }
    this.processOpsModal.blockedEditionSelected = processStatesSelected;
    this.loader.closeLoader(['configure-blocked-edition']);
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        concept: this.processOpsModal.concept
    })
  }

}
