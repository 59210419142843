import moment from 'moment';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { orderBy } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'finish-block-modal',
  templateUrl: './finish-block-modal.component.html'
})
export class FinishBlockModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  finishBlockModal: any = {
    showCalendar: false,
    selectedOption: {},
    configurationModal: {}
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private dateConverterService: DateConverterService,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal) { }
  @Input()  configuration: any = null;
  ngOnInit(): void {
    this.configureInit();
  }

  configureInit(){
      let that: any = this.finishBlockModal;
      switch(this.configuration.from){
          case 'innerEvaluation':
              that.buttonDate = 'finish-block-modal.select-date';
              that.configurationModal = {
                  title: 'finish-block-modal.manage-evaluation',
                  iconTitle: 'lf-icon-certificate',
                  info: 'finish-block-modal.info-header-manage-evaluation',
                  from: 'innerEvaluation',
                  options: [
                      {
                          type: 'block-data',
                          icon: 'lf-icon-double-lock',
                          infoSelected: this.configuration.evaluationInfo.isFinished?this.translate.instant('finish-block-modal.lock-finish-info-evaluation-finished'):this.translate.instant('finish-block-modal.lock-finish-info-evaluation'),
                          infoNoSelected: this.translate.instant('finish-block-modal.lock-finish-info-evaluation-no-selected'),
                          title: 'finish-block-modal.lock-data',
                          selected: true,
                          noEditable: this.configuration.evaluationInfo.isFinished
                      },
                      {
                          type: 'generate-report',
                          icon: 'lf-icon-textbooks',
                          infoSelected: this.translate.instant('finish-block-modal.generate-report-finish-info-evaluation'),
                          infoNoSelected: this.translate.instant('finish-block-modal.generate-report-finish-info-evaluation-no-selected'),
                          title: 'finish-block-modal.generate-report',
                          center: true,
                          selected: true
                      },
                      {
                          type: 'schedule',
                          icon: 'lf-icon-calendar-check',
                          infoSelected: this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation'),
                          infoNoSelected: this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-no-selected'),
                          title: 'finish-block-modal.schedule-follow-up',
                          selected: this.configuration.evaluationInfo.isClientRecurrent && this.skin !== 'steel-blue',
                          noEditable: this.configuration.evaluationInfo.isClientRecurrent && this.skin !== 'steel-blue'
                      }
                  ]
              }
              this.configureSelectedOption();
              break;
          case 'reactivateClient':
              that.configurationModal = {
                  title: 'concept-form.reactivate-relationship',
                  iconTitle: 'lf-icon-refresh',
                  info: 'finish-block-modal.info-header-reactivate-client',
                  from: 'reactivateClient',
                  options: [
                      {
                          icon: 'lf-icon-unlock',
                          infoNoSelected: this.translate.instant('finish-block-modal.unlock-editing-info-client'),
                          title: 'finish-block-modal.unlock-editing'
                      },
                      {
                          icon: 'lf-icon-calendar-cross',
                          infoNoSelected: this.translate.instant('finish-block-modal.delete-alert-info-client'),
                          title: 'finish-block-modal.delete-alert',
                          center: true
                      },
                      {
                          icon: 'lf-icon-calendar-check',
                          infoNoSelected: this.skin === 'steel-blue'? this.translate.instant('finish-block-modal.schedule-follow-up-info-client-be') : this.translate.instant('finish-block-modal.schedule-follow-up-info-client'),
                          title: 'finish-block-modal.schedule-follow-up'
                      }
                  ]
              }
              that.configuratedOptions = true;
              break;
          default:
              that.configurationModal = {
                  title: 'expanded-menu.permanently-block',
                  iconTitle: 'lf-icon-alert',
                  info: 'finish-block-modal.info-header-client',
                  from: 'clientConcept',
                  options: [
                      {
                          icon: 'lf-icon-double-lock',
                          infoNoSelected: this.translate.instant('finish-block-modal.lock-editing-info-client'),
                          title: 'finish-block-modal.lock-editing'
                      },
                      {
                          icon: 'lf-icon-textbooks',
                          infoNoSelected: this.translate.instant('finish-block-modal.generate-report-info-client'),
                          title: 'finish-block-modal.generate-report',
                          center: true
                      },
                      {
                          icon: 'lf-icon-calendar-cross',
                          infoNoSelected: this.translate.instant('finish-block-modal.end-relationship-info-client'),
                          title: 'finish-block-modal.end-relationship'
                      }
                  ]
              }
              that.configuratedOptions = true;
              break;
      }
  }

  configureSelectedOption(){
    let finished: boolean = !!(this.configuration.evaluationInfo.isFinished)
      let that: any = this.finishBlockModal
      switch(this.skin){
          case 'steel-blue':
              that.selectedOption = {
                  lockedData: finished,
                  generateReport: true,
                  schedule: true
              }
              that.configurationModal.options[0].selected = finished;
              that.configurationModal.options[2].selected = true;
              that.configurationModal.options[2].showOpenCalendar = true;
              that.buttonDate = 'finish-block-modal.modify-date';
              this.configureBlockAlertBE(that.configurationModal.options[2]);
              break;
          default:
              that.selectedOption = {
                  lockedData: true,
                  generateReport: true,
                  schedule: this.configuration.evaluationInfo.isClientRecurrent
              }
              if(this.configuration.evaluationInfo.isClientRecurrent){
                  this.configureBlockAlert(that.configurationModal.options[2]);
              }
              break;
      }

      if(this.configuration.evaluationInfo.operationNotSupported){
          that.configurationModal.info = 'finish-block-modal.info-header-manage-evaluation-locked';
          that.selectedOption.lockedData = false;
          that.selectedOption.generateReport = false;
          that.configurationModal.options[0].selected = true;
          that.configurationModal.options[0].noEditable = true;
          that.configurationModal.options[0].infoSelected = this.translate.instant('finish-block-modal.lock-finish-info-evaluation-not-supported');
          that.configurationModal.options[1].selected = true;
          that.configurationModal.options[1].noEditable = true;
      }
      this.getDateAlerts(that.configurationModal.options[2]);
  }

  configureBlockAlertBE(option:any){
      let reviewFrequency:any = this.configuration.evaluationInfo.reviewFrequency;
      if(reviewFrequency === 3 && this.configuration.evaluationInfo.totalScore && this.configuration.evaluationInfo.totalScore.riskCode > 2){
          reviewFrequency = 1;
      }
      let newDateAlert:any = new Date();
      newDateAlert = newDateAlert.setFullYear(newDateAlert.getFullYear()+reviewFrequency);
      let newDateAlertModified:any = new Date(newDateAlert);
      this.finishBlockModal.dateAlert = this.dateConverterService.resetDateFirstSecond(newDateAlertModified);
      let text = this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-date-be').replace('{date}', moment.utc(this.finishBlockModal.dateAlert).format('DD/MM/YYYY'));
      option.infoSelected = text;
  }

  configureBlockAlert(option:any){
    let evaluationInfo: any = this.configuration.evaluationInfo;
      option.clientRecurrentLow = evaluationInfo.isClientRecurrent && evaluationInfo.totalScore && evaluationInfo.totalScore.riskCode <= 2;
      option.clientRecurrentHigh = evaluationInfo.isClientRecurrent && evaluationInfo.totalScore && evaluationInfo.totalScore.riskCode > 2;
      option.showOpenCalendar = !option.clientRecurrentHigh && this.finishBlockModal.selectedOption.schedule;// && !option.noEditable;
      if(option.clientRecurrentLow){
          option.infoSelected = this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-low-risk');
      }else if(option.clientRecurrentHigh){
          this.configureClientRecurrentHigh(option);
      }
  }

  configureClientRecurrentHigh(option:any){
      option.showAdditionalInfo = true;
      option.textAdditionalInfo = this.finishBlockModal.previousAlert?'finish-block-modal.schedule-follow-finish-evaluation-additional-info-change':'finish-block-modal.schedule-follow-finish-evaluation-additional-info';
      let text:string = this.finishBlockModal.previousAlert?this.translate.instant('finish-block-modal.reschedule-alert-recurrent') : this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-high-risk');
      let newDateAlert: any = new Date();
      newDateAlert = newDateAlert.setFullYear(newDateAlert.getFullYear()+1);
      let newDateAlertModified:any = new Date(newDateAlert);
      this.finishBlockModal.dateAlert = this.dateConverterService.resetDateFirstSecond(newDateAlertModified);
      option.infoSelected = text.replace('{date}', moment.utc(this.finishBlockModal.dateAlert).format('DD/MM/YYYY'));
  }

  getDateAlerts(option:any){
    this.apiService.get('blanqueo/datealerts', this.configuration.evaluationInfo.evaluationId)
        .then((data:any)=>{
            if(data.length){
                data = orderBy(data, 'creationDate');
                let lastAlert:any = data[0];
                if(this.skin === 'dark-blue'){
                  let diffDate:any = this.dateConverterService.compareFutureDates(lastAlert.referenceDate);
                  if(diffDate > 0){
                      this.finishBlockModal.dateAlert = lastAlert.referenceDate;
                      this.finishBlockModal.dateAlertId = lastAlert.dateAlertId;
                      this.finishBlockModal.previousAlert = true;
                      this.finishBlockModal.previousAlertDate = lastAlert.referenceDate;
                      if(this.configuration.evaluationInfo.isClientRecurrent){
                          if(option.clientRecurrentHigh && lastAlert.risk <= 2){
                              this.configureClientRecurrentHigh(option);
                          }else if(this.configuration.evaluationInfo.isClientRecurrent && lastAlert.risk > 2){
                              option.showAdditionalInfo = true;
                              option.textAdditionalInfo = 'finish-block-modal.schedule-follow-finish-evaluation-additional-info';
                              option.infoSelected = this.translate.instant('finish-block-modal.exist-alert').replace('{date}', moment.utc(this.finishBlockModal.dateAlert).format('DD/MM/YYYY'));
                          }else{
                              this.configureModificationDateClient(option);
                          }
                      }else{
                          option.selected = true;
                          this.finishBlockModal.selectedOption.schedule = true;
                          option.showOpenCalendar = !option.clientRecurrentHigh && this.finishBlockModal.selectedOption.schedule;
                          this.configureModificationDateClient(option);
                      }
                  }
                }else if(this.skin === 'steel-blue'){
                    this.finishBlockModal.configurationModal.options[2].modifiedDate = lastAlert.referenceDate.substring(0,10) !== this.finishBlockModal.dateAlert.substring(0,10);
                    this.finishBlockModal.dateAlertId = lastAlert.dateAlertId;
                    this.finishBlockModal.previousAlert = true;
                }
              }
            this.finishBlockModal.configuratedOptions = true;
        }, (errorData:any)=>{
        })
  }


  configureModificationDateClient(option:any){
      this.finishBlockModal.buttonDate = 'finish-block-modal.modify-date';
      let text:string = this.configuration.evaluationInfo.isClientRecurrent?this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-date-recurrent') : this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-date');
      option.infoSelected = text.replace('{date}', moment.utc(this.finishBlockModal.dateAlert).format('DD/MM/YYYY'));
  }

  selectOption(selected:any, option:any){
      if(!option.noEditable){
          option.selected = selected;
          this.configureTextOption(option);
      }
  }

  configureTextOption(option:any){
      switch(option.type){
          case 'block-data':
              this.finishBlockModal.selectedOption.lockedData = option.selected;
              break;
          case 'generate-report':
              this.finishBlockModal.selectedOption.generateReport = option.selected;
              break;
          case 'schedule':
              if(!option.selected){
                  this.finishBlockModal.dateAlert = null;
              }
              this.finishBlockModal.selectedOption.schedule = option.selected;
              option.showOpenCalendar = !option.clientRecurrentHigh && this.finishBlockModal.selectedOption.schedule;// && !option.noEditable;
              break;
      }
  }

  openCalendar(option:any){
      option.optionShow = 'calendar';
      option.showInfoCalendar = this.skin === 'dark-blue';
      option.showCalendar = true;
      this.finishBlockModal.showCalendar = true;
  }

  selectDate(e:any){
      this.finishBlockModal.selectedDate = e.newValue;
  }

  applyCalendarDate(option:any){
      if(this.finishBlockModal.selectedDate){
          this.finishBlockModal.dateAlert = this.dateConverterService.toIso(this.finishBlockModal.selectedDate);
          option.error = false;
      }
      option.modifiedDate = true;
      let text:string =''
      if(this.skin === 'steel-blue'){
          text = this.translate.instant('finish-block-modal.schedule-follow-finish-evaluation-date-be');
      }else{
          text = this.finishBlockModal.previousAlert?this.translate.instant('finish-block-modal.reschedule-text-date') : this.translate.instant('finish-block-modal.schedule-text-date');
      }
      option.infoSelected = text.replace('{date}', moment.utc(this.finishBlockModal.dateAlert).format('DD/MM/YYYY'));
      this.closeCalendar(option);
  }

  closeCalendar(option:any){
      delete this.finishBlockModal.selectedDate;
      option.showInfoCalendar = false;
      option.showCalendar = false;
      option.optionShow = 'normal';
      this.finishBlockModal.showCalendar = false;
  }

  validateConfiguration(){
      let configuration = {
          selectedOption: this.finishBlockModal.selectedOption
      }
      this.configuration.from === 'innerEvaluation'? this.configurationAcceptInnerEvaluation(configuration) : this.handleAccept(configuration);

  }

  configurationAcceptInnerEvaluation(configuration:any){
      let that: any = this.finishBlockModal;
      if(that.selectedOption.schedule){
          if(that.dateAlert){
              that.configurationModal.options[2].error = false;
              configuration.actionAlert = that.previousAlert? 'modify' : 'create';
              configuration.modifiedAlert = !!(that.previousAlert && that.configurationModal.options[2].modifiedDate);
              configuration.dateAlertId = that.previousAlert? that.dateAlertId : null;
              configuration.alert = {
                  referenceDate: that.dateAlert,
                  title: 'relationship-tracking',
                  risk: this.configuration.evaluationInfo.totalScore.riskCode,
                  editable: !(this.configuration.evaluationInfo.totalScore.riskCode > 2),
                  dateAlertTypeId: 1
              }
              configuration.withoutSaving = (that.configurationModal.options[0].noEditable || !that.selectedOption.lockedData) && !that.selectedOption.generateReport && (that.previousAlert && !configuration.modifiedAlert);
              this.handleAccept(configuration);
          }else{
              that.configurationModal.options[2].error = true;
          }
      }else{
          if(that.previousAlert){
              configuration.actionAlert = 'delete';
              configuration.dateAlertId = that.dateAlertId;
          }
          configuration.withoutSaving = (that.configurationModal.options[0].noEditable || !that.selectedOption.lockedData) && !that.selectedOption.generateReport && !that.previousAlert;
          this.handleAccept(configuration);
      }
  }

  handleAccept(configuration:any) {
    this.activeModal.close({
        result: 'ok',
        configuration: configuration
    });
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

}
