import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { MultilanguageService } from '@shared/services/multilanguage-service/multilanguage.service';
import { cloneDeep } from "lodash";

@Component({
  selector: 'protection-data-modal',
  templateUrl: './protection-data-modal.component.html'
})
export class ProtectionDataModalComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private translate:AfTranslateFactory,
    private multilanguageService: MultilanguageService
  ) { }

  @Input() configuration:any;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown
  skin:string = this.globalCfg.skin;
  protectionDataModal:any = {
   
    protectionModalDataTreatment: {
      fieldId: 'data-treatment',
      fieldTypeId: 'rich',
      isEditable: true,
      required: true
    }
  };

  ngOnInit(){
    let protectionDataModal = this.protectionDataModal;
    protectionDataModal.protectionModalLanguagesApp = cloneDeep(this.configuration.languagesApp);
    protectionDataModal.protectionModalLanguagesSelected = cloneDeep(this.configuration.selectedLanguages);
    protectionDataModal.protectionModalDataTreatment.description = cloneDeep(this.configuration.languageSelected.textDescription);
    this.configureModalWindow()
  }

  configureModalWindow(){
    let protectionDataModal = this.protectionDataModal;
    switch(this.configuration.aditionalInformation){
      case 'additional':
        protectionDataModal.icon = 'lf-icon-information';
        protectionDataModal.title = this.translate.instant('channel-configuration.additional-info');
        protectionDataModal.description = this.translate.instant('protection-data-modal.additional-info-description');
        protectionDataModal.protectionModalDataTreatment.label = this.translate.instant('protection-data-modal.want-know');
        break;
      case 'protection':
        protectionDataModal.icon = 'lf-icon-shield';
        protectionDataModal.title = this.translate.instant('protection-data-modal.data-protection-information');
        protectionDataModal.description = this.translate.instant('protection-data-modal.info-header-modal');
        protectionDataModal.protectionModalDataTreatment.label = this.translate.instant('protection-data-modal.processing-personal-data');
        break;
      default:
        protectionDataModal.icon = 'lf-icon-legal-reference';
        protectionDataModal.title = this.translate.instant('channel-configuration.legal-info');
        protectionDataModal.description = this.translate.instant('protection-data-modal.legal-description-info');
        protectionDataModal.protectionModalDataTreatment.label = this.translate.instant('protection-data-modal.apply-regulations');
        break;
    }
  }

  changeActiveLanguagesSelected(selectedLanguage:any, addSelected?:any){
    this.multilanguageService.changeActiveLanguagesSelected(selectedLanguage, addSelected, this.protectionDataModal, 'protectionModal')
  }
  
  removeActiveLanguage(selectedLanguage:any){
    this.multilanguageService.removeActiveLanguage(selectedLanguage, this.protectionDataModal, 'protectionModal');
  }

  selectLanguage(selectedLanguage:any, textDefault?:any){
    this.multilanguageService.selectLanguage(selectedLanguage, this.protectionDataModal, 'protectionModal', textDefault)
    this.dropdown.close();
  }

  notSelectLanguage(language:any){
    return this.multilanguageService.notSelectLanguage(language, this.protectionDataModal, 'protectionModal')
    
  }

  checkSelectedTab(){
    let that = this.protectionDataModal;
    if(!Boolean(that.protectionModalLanguagesSelected.filter((languageSelected:any)=>{ return languageSelected.active}).length)){
      that.protectionModalLanguagesSelected[0].active = true;
    };
  }

  validateFields(){
    let that = this.protectionDataModal;
    let errors = 0;
    let listSelectedLanguages: any = [];
    that.protectionModalDataTreatment.error = false;
    that.protectionModalLanguagesSelected.forEach((language:any, index: any) => {
        if(language.active){
            language.textDescription = that.protectionModalDataTreatment.description;
        }
        language.error = !language.textDescription;
        that.errorActive = language.active && language.error;
        errors += language.error?1:0;
        if(!errors){
            let languageSelected = {
                conceptId: this.configuration.concept.conceptId,
                language: language.appLanguage,
                numOrder: index+1,
                text: language.textDescription
            }
            listSelectedLanguages.push(languageSelected);
        }
    });
    if(!that.protectionModalDataTreatment.description){
        errors++;
        that.protectionModalDataTreatment.error = true;
    }
    if(!errors){
        this.handleAccept(listSelectedLanguages);
    }
  }

  handleAccept(listSelectedLanguages:any) {
    this.activeModal.close({
      result:'ok',
      selectedLanguages: listSelectedLanguages
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
