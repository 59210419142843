import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';


@Component({
  selector: 'report-table-default',
  templateUrl: './report-table-default.component.html'
})
export class ReportTableDefaultComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  reportTable: any = {
    type: 'dynamic'
  }
  constructor(private globalCfg: GlobalCfgFactory) {}

  @Input() report:any = null;
  @Input() configurateReport:any = null;

  @Output() updateList = new EventEmitter();
  @Output() exportReport = new EventEmitter();

  ngOnChanges(): void {
    let that: any = this.reportTable
    if(this.report){
      that.report = this.report;
      if (this.skin === 'icam-red') {
        that.type = that.report.reportObjectId > 13? 'personalizado': that.report.reportObjectId;
      }
    }
    that.configurateReport = this.configurateReport
  }

  updateListOn() {
    this.updateList.emit();
  }

  exportReportOn(event: any){
    this.exportReport.emit({
      onlyRename: event.onlyRename, 
      list: event.list,
      filters: event.filters
    });
  }
}
