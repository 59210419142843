
import { cloneDeep } from 'lodash';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { UploadDocumentParentService } from '../utils/upload-document-parent.service';


@Component({
  selector: 'upload-documents-custom-modal',
  templateUrl: './upload-documents-custom-modal.component.html'
})
export class UploadDocumentsCustomModalComponent implements OnInit {
  conceptObjectId: any = this.route.snapshot.params['conceptObjectId'];
  isLanding: boolean = true;
  custom: any = this.customLanding.getCustom();
  uploadDocuments: any = this.uploadDocumentParent.configureUploadDocuments(this)

  constructor(private uploadDocumentParent: UploadDocumentParentService,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory) {
  }

  @Input() component: any= {};
  @Input() type: string = '';
  @Input() files: any = [];
  @Input() documentationList: any = null;
  @Input() itemSelected: any = null;
  @Input() listSelect: any = null;
  @Output() uploadDocumentsEmit = new EventEmitter();

  ngOnInit(): void {
    this.uploadDocuments.newConceptLanding = this.isLanding && this.itemSelected && !this.itemSelected.conceptObjectId;
    this.uploadDocuments.showProcess = this.type === 'addNewDocumentsFromProcessModal' || ((this.type === 'addNewDocuments'  || this.type === 'addNewDocumentsToFields') && this.listSelect && this.listSelect.length);
    this.uploadDocuments.processSteps.required = this.type === 'addNewDocumentsFromProcessModal' && this.uploadDocuments.showProcess;
    this.uploadDocuments.component = cloneDeep(this.component);
    this.uploadDocuments.documentationList = cloneDeep(this.documentationList);

    if(this.files.length){
      this.uploadDocumentParent.dropNewDocument(this.files, this);
    }
  }

  dropNewDocument(e:any){
    this.uploadDocumentParent.dropNewDocument(e.files, this)
    this.uploadDocumentsEmit.emit({uploadDocuments:this.uploadDocuments.upDocuments})
  }

  uploadFiles(){
    this.uploadDocumentParent.uploadFiles(this)
  }

  addDocument(document:any, firstLoad:any){
    this.uploadDocumentParent.addDocument(document, firstLoad, this, false)
  }

  loadFiles(event:any){
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.uploadDocumentParent.loadFiles(files, this)
  }

  itemSelectedList(e:any, item:string){
    this.uploadDocumentParent.itemSelectedList(e.value, item, this)
  }

  openFileSearch(){
   this.uploadDocumentParent.openFileSearch()
  }

  deleteDocument(index:any){
    this.uploadDocumentParent.deleteDocument(index, this)
  }

  openTextareaModal(){
    this.uploadDocumentParent.openTextareaModal(this)
  }

  renameDocument(document:any){
    this.uploadDocumentParent.renameDocument(document, this)
  }
  deleteUploadDocument(document:any){
   this.uploadDocumentParent.deleteUploadDocument(document, this)
  }


  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      files: this.uploadDocuments.filesUpdate,
      comments: this.uploadDocuments.comments,
      failed: this.uploadDocuments.failedUploads,
      stateId: this.uploadDocuments.stateId,
      nameState: this.uploadDocuments.nameState
    })
  }

  handleDismiss() {
    if(this.uploadDocuments.filesUpdate.length === 0){
      this.activeModal.close({result:'cancel'});
    }else{
        this.handleAccept();
    }
  }
}
