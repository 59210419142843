import moment from "moment";
import { find, cloneDeep } from "lodash";
import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import ExpandMenu from '../../../../../assets/data/expanded-menu.json';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { VisibilityService } from '@shared/services/visibility/visibility.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateLockFactory } from '@shared/factories/date-lock/date-lock.factory';
import { SnapShotsService } from '@shared/services/snap-shots/snap-shots.service';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { Router } from '@angular/router';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ConceptUtilsService } from '@shared/services/concept-utils/concept-utils.service';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ConceptFormParentService {

  subscribers: any = {};
  constructor(private translate: AfTranslateFactory,
    private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory,
    private userPermissions: UserPermisionsFactory,
    private context: ContextFactory,
    private broadcastService: BroadcastService,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private visibilityService: VisibilityService,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private dateLock: DateLockFactory,
    private snapShotsService: SnapShotsService,
    private conceptState: ConceptStateFactory,
    private registerService: RegisterMixtapeService,
    private router: Router,
    private dateConverterService: DateConverterService,
    private documentActions: DocumentActionsService,
    private conceptUtilsService: ConceptUtilsService,
    private rolesUtilsService: RolesUtilsService) { }

  configureConceptFormObject(child:any){
    return {
        configuredVisibility: false,
        sendConceptButton: this.translate.instant('common.save'),
        hasProcesses: this.globalCfg.caps.processes,
        fullScreenBox: '',
        newTitle: '',
        custom: this.customLanding.getCustom(),
        userPermissions: this.userPermissions.getPermissions(),
        openLaw: false,
        changeListSequential: false,
        icamConceptObject: (this.globalCfg.skin === 'icam-red' && child.isLanding && this.context && this.context.view && this.context.view.extraData && this.context.view.extraData.masterConceptId && this.context.view.extraData.masterConceptObjectId),
        viewEditConcept: child.locationName === 'root.shared.concept-preview' || child.locationName === 'root.public.concept-preview',
        // userPublic: (this.context.user && !this.context.user.email),
        userPublic: child.route.snapshot.data['public'],
        productTypeId: '',
        hideHistoric: false,
        changeProgressSequential: 0,
        designViewLanding: 1,
        sequentialFieldsVisibility: [],
        predefinedTemplates: [],
        evaluationProperties: [],
        temporalDocuments: [],
        clearButton: this.translate.instant('common.clean'),
        conceptSections: this.translate.instant('concept-form.sections-form'),
        enabledButtonManageBlock: false,
        rolesLevel: []
    }
  }

  onInit(child:any){
      let that: any = child.conceptForm;
      if(that.viewEditConcept){
          child.concept.conceptObjectId = 'viewEdit';
      }
      this.openWatchers(child);
  }

  onChanges(child:any){
      let that: any = child.conceptForm;
      if(child.concept){
          that.configuredVisibility = false;
          that.concept = child.concept;
          that.productTypeId = that.concept.productTypeId;
          that.conceptSections = that.productTypeId === '4'?this.translate.instant('concept-form.sections-consult') : this.translate.instant('concept-form.sections-form');
          if ((that.concept && that.concept.poll) || child.isLanding) {
            that.sendConceptButton = this.translate.instant('common.send');
          }
          if(!that.concept.documentation){
              that.concept.documentation = {
                  documents: [],
                  subFolders: [],
                  totalDocuments: 0
              }
          }
          if(that.concept.conceptNamedKey==='Cliente'){
              this.getExpandedMenu(child);
          }
          child.params['evaluationId']? this.getEvaluationProperties(child) : this.getPredefinedTemplate(child);
          if(that.icamConceptObject && ((child.locationName === 'root.public.monitoring' && !that.concept.conceptObjectId) || child.locationName === 'root.public.concept')){
              that.designViewLanding = 2;
          }
          if(child.conceptForm.concept?.fieldContainers){
            if(this.globalCfg.skin !== 'steel-blue' && (child.conceptForm.concept.productId === 'denunc' || child.conceptForm.concept.productId === 'whistle') && child.conceptForm.concept.conceptNamedKey === 'ChannelView'){
              that.additionalGroupChannelView = child.conceptForm.concept?.fieldContainers.length > 1 && child.conceptForm.concept?.fieldContainers[0].conceptId === child.conceptForm.concept?.fieldContainers[1].conceptId?false:true;
            }
            this.configureSubcontainersMultiple(child);
            that.nothingPrivateField = this.nothingPrivate(child);
          }
          this.configureOptionsConcept(child);
      }
  }

  openWatchers(child:any){
      this.subscribers.saveConceptBroadcast = this.broadcastService.subscribe('saveConcept', (data:any)=>{
        let typeExit = 'exit';
        if(child.conceptForm.concept.productId === 'blanqueo' && ((child.conceptForm.concept.conceptNamedKey === 'Cliente' && child.conceptForm.concept.addToScoring && child.conceptForm.concept.addToScoring.valueRisk === 4 && child.conceptForm.concept.clientStateId === 1) || child.conceptForm.concept.conceptNamedKey === 'Operacion')){
            typeExit = child.conceptForm.concept.conceptNamedKey === 'Cliente'?'exitWarning':typeExit;
            this.loader.openLoader('concept-form-save-concept');
            child.conceptForm.concept.saved = true;
        } else if (this.globalCfg.skin === 'icam-red' && child.isLanding) {
            let hasErrors = this.commonUtilsService.checkRequiredFields(child.conceptForm.concept, child.conceptForm.userPublic);
            if(hasErrors.commonErrors){
              typeExit = 'error';
              this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-public-fields'), 'error:accept:warning');
            }else{
              child.conceptForm.concept.saved = true;
              typeExit = 'exitNew';
            }
        }else{
            child.conceptForm.concept.saved = true;
            this.broadcastService.broadcast('savedConceptExit');
        }
        if(child.conceptList && child.conceptList.length > 1){
            this.saveAllConceptFields(child, 'exit');
        }else if(typeExit !== 'error'){
            let configuration:any = {
                concept: child.conceptForm.concept,
                finished: true,
                from: 'exit',
                actionFrom: typeExit,
                child: child
            }
            this.saveConceptFields(configuration);
        }
      })
      this.subscribers.updateRefereceValueBroadcast = this.broadcastService.subscribe('updateRefereceValue', (data:any)=>{
        this.changesInEvaluatedConcept(data.field, child)
      })
    }

    getExpandedMenu(child:any) {
      child.conceptForm.menuBlockClient = ExpandMenu["menuBlockClient"].menu;
      child.conceptForm.menuManageBlockClient = ExpandMenu['menuManageBlock'].menu;
      child.conceptForm.menuBlockClientShow = child.conceptForm.concept.clientStateId === 2? child.conceptForm.menuManageBlockClient : child.conceptForm.menuBlockClient;
    }

    configureSubcontainersMultiple(child:any){
      child.conceptForm.concept.subContainerMultiple = new Map();
      child.conceptForm.concept.fieldContainers.forEach((fieldContainer:any) => {
        fieldContainer.subContainers.forEach((subContainer:any) => {
          if(subContainer.multiple){
            subContainer.subcontainerDuplicated = [];
          }
        });
      });

    }

    nothingPrivate(child:any){
        for(let fieldContainer of child.conceptForm.concept.fieldContainers){
            if(fieldContainer.private){
                return false;
            }
            for(let fieldContainerField of fieldContainer.fields){
                if(fieldContainerField.private){
                    return false;
                }
            }
            for(let subContainer of fieldContainer.subContainers){
                if(subContainer.private){
                    return false;
                }
                for(let subContainerField of subContainer.fields){
                    if(subContainerField.private){
                        return false;
                    }
                }
            }
        }
        return true;
    }

    configureFromView(child:any, clearFields:any = false){
        child.conceptForm.viewConcept = 'general';
        if(!child.conceptForm.concept.groupsAsList){
            this.calculateTotalSteps(child);
            child.conceptForm.viewConcept = 'steps';
            if(child.conceptForm.filterContainers.length > 1){
              child.conceptForm.clearButton = this.translate.instant('concept-form.clear-all');
            }
            child.conceptForm.changeStepsConcept = {
                step: 1,
                action: clearFields?'changeStep':'configureSteps'
            }
        }
    }

    calculateTotalSteps(child:any){
        let concept: any = child.conceptForm.concept;
        child.conceptForm.filterContainers = [];
        let filterContainers:any = [];
        for(let [index, fieldContainer] of child.conceptForm.concept.fieldContainers.entries()){
            concept.fieldContainers[index].active = false;
            if(!concept.fieldContainers[index].hidden && !(child.isLanding && child.conceptForm.userPublic && concept.fieldContainers[index].private)){
                filterContainers.push( concept.fieldContainers[index]);
            }
            concept.fieldContainers[index].last = Boolean(index === concept.fieldContainers.length - 1);
        }
        filterContainers[0].active = !!(filterContainers.length);
        filterContainers[filterContainers.length - 1].last = !!(filterContainers.length);
        child.conceptForm.filterContainers = filterContainers;
    }

    getEvaluationProperties(child:any){
        this.apiService.get('evaluations/'+child.params['evaluationId'],'propertiesvalues' )
            .then((data:any)=>{
              child.conceptForm.evaluationProperties = data;
              this.getPredefinedTemplate(child);
            }, (errorData:any)=> {})
    }

    getPredefinedTemplate(child:any) {
      let concept: any = child.conceptForm.concept;
      if (concept.conceptObjectId !== 'view' && concept.conceptObjectId !== 'viewEdit' && concept.conceptObjectId) {
          this.apiService.get('documentobjects/compliancetemplates', concept.conceptObjectId)
              .then((data:any)=>{
                data.forEach((item:any) => {
                  item.predefinedName = item.name.substr(0, item.name.lastIndexOf('.'))
                });
                concept.templateList = data;
                this.extractVisibility(child);
              }, (errorData:any)=>{
                  concept.templateList = [];
                  this.extractVisibility(child);
              })
      } else {
        concept.templateList = [];
        this.extractVisibility(child);
      }
    }

    hasFullScreen(box:any, child:any){
      //  child.conceptForm.fullScreenBox = child.conceptForm.fullScreenBox === box? '' : box;
      child.conceptForm.fullScreenBox = '';
    }

    changesInEvaluatedConcept(field:any, child:any){
      let concept:any = child.conceptForm.concept;
      if(concept.productId === 'blanqueo' && field.addToScoring){
          concept.addToScoring = {
              riesgo: field.evaluatedValue.nvarcharValue,
              color: field.color,
              valueRisk: field.evaluatedValue.orderEncodinSelected
          }
      }
      if(field !== null){
          for(let [index, evaluatedField] of concept.evaluatedFields.entries()){
              if(evaluatedField.fieldId === field.evaluatedValue.fieldId){
                concept.evaluatedFields[index] = field.evaluatedValue;
                  break;
              }
          }
      }
      this.modifyEvaluatedConcept(field, child);
    }

    changesInEvaluatedConceptAdditional(field:any, child:any) {
        if(child.isLanding && !child.conceptForm.concept.poll){
            child.launchAction.emit({
                type: 'changeConceptAdditional',
                field: field
            })
        }
    }

    changesInMultipleConcept(field:any, child:any){
        let concept: any = child.conceptForm.concept;
        if(field !== null){
            for(let [index, evaluatedField] of concept.evaluatedFields.entries()){
                if(field.evaluatedValue && evaluatedField.fieldId === field.evaluatedValue.fieldId && evaluatedField.numSubContainer === field.evaluatedValue.numSubContainer){
                    concept.evaluatedFields[index] = field.evaluatedValue;
                    break;
                }
            }
        }
        this.modifyEvaluatedConcept(field, child);
    }

    modifyEvaluatedConcept(field:any, child:any){
        let that: any = child.conceptForm;
        if(that.conceptObjectIdSelected === that.concept.conceptObjectId){
            if(that.fieldWithVisibility){
                this.visibilityService.configureListVisbleValue(that.fieldWithVisibility, that.evaluatedMap, that.concept);
            }
            this.broadcastService.broadcast('changes-in-concept', {});
            if(that.concept.templateList && that.templatesWithVisibility && that.templatesWithVisibility.length){
                this.visibilityService.configureListVisbleValue(that.templatesWithVisibility, that.evaluatedMap, that.concept);
            }
        }
        if(field !== null){
            // if(field.fieldTypeId === 'text' || field.fieldTypeId === 'money' || field.fieldTypeId === 'num' || field.fieldTypeId === 'boolp' || field.fieldTypeId === 'listp' || field.fieldTypeId === 'l-co' || field.fieldTypeId === 'l-sg' || field.fieldTypeId === 'list' || field.fieldTypeId === 'treep'){
            if(field.fieldTypeId === 'text' || field.fieldTypeId === 'money' || field.fieldTypeId === 'num' || field.fieldTypeId === 'boolp' || field.fieldTypeId === 'listp' || field.fieldTypeId === 'l-co' || field.fieldTypeId === 'l-sg' || field.fieldTypeId === 'treep'){
              this.broadcastService.broadcast('modifyFormu');
            }else if(field.fieldTypeId === 'file'){
              this.broadcastService.broadcast('addAttachmentsDocument', {
                  document: field.deletedFile && field.deletedFile.deleted? field.deletedFile :field.newAddDocument
              });
            }
            if((field.fieldTypeId ==='text' || field.fieldTypeId ==='rich') && field.isVoiceRecord ){
              if(field.voiceRecordFile?.size){
                field.newAddDocument = {
                  documentObjectId: this.commonUtilsService.createId(),
                  name: field.voiceRecordName,
                  size: field.voiceRecordFile.size,
                  fileSize: field.voiceRecordFile.size
                }
                this.broadcastService.broadcast('addAttachmentsDocument', {
                  document: field.newAddDocument
                });
              }else if(field.voiceRecordFile === null && field.newAddDocument){
                field.deletedFile = field.newAddDocument;
                field.deletedFile.deleted = true;
                delete field.newAddDocument;
                this.broadcastService.broadcast('addAttachmentsDocument', {
                  document: field.deletedFile
                });
              }
            }
        }else{
            this.broadcastService.broadcast('modifyFormu');
        }
        if(that.concept.modifyFormu){
            delete that.concept.modifyFormu;
            this.broadcastService.broadcast('modifyFormu');
        }
        if(that.concept.poll){
            if(that.concept.sequentialView && that.sequentialFieldsVisibility.length){
                let sequentialTemporalVisibility:any = this.extractSequentialListField(child);
                let equals:any = sequentialTemporalVisibility.every((x:any, i:any)=>{ return x === child.conceptForm.sequentialFieldsVisibility[i] } );
                child.conceptForm.changeListSequential = false;
                if(sequentialTemporalVisibility.length !== child.conceptForm.sequentialFieldsVisibility.length || !equals){
                    child.conceptForm.changeListSequential = true;
                    child.conceptForm.sequentialFieldsVisibility = sequentialTemporalVisibility;
                }
                this.selectLastFieldVisible(child);
            }
        }
        if(child.conceptForm.viewConcept === 'steps' && child.conceptForm.concept.actualStep){
            child.conceptForm.changeStepsConcept = {
                step: child.conceptForm.concept.actualStep,
                action: 'changeEvaluatedConcept'
            }
        }
    }

    extractVisibility(child:any, clearFields:any = false){
        let that: any = child.conceptForm
        that.conceptObjectIdSelected = that.concept.conceptObjectId;
        that.fieldWithVisibility = this.visibilityService.extractVisibility(that.concept);
        that.fieldWithProperties = this.visibilityService.extractPropertiesVisibility(that.concept);
        if(that.concept && that.concept.templateList){
            that.templatesWithVisibility = this.visibilityService.extractTemplatesVisibility(that.concept.templateList);
        }
        if(child.currentComponent !== 'editConcept'){
            that.evaluatedMap = this.visibilityService.evaluatedFieldsMap(that.concept);
            this.visibilityService.configureListVisbleValue(that.fieldWithVisibility, that.evaluatedMap, that.concept);
            this.visibilityService.configureEvaluationProperties(that.fieldWithProperties, that.evaluationProperties);
            if(that.concept.templateList && that.templatesWithVisibility.length){
                this.visibilityService.configureListVisbleValue(that.templatesWithVisibility, that.evaluatedMap, that.concept);
            }
        }
        this.configureFromView(child, clearFields);
        if(that.concept.sequentialView && child.isLanding && !that.sequentialFieldsVisibility.length){
            that.sequentialFieldsVisibility = this.extractSequentialListField(child);
            this.selectLastFieldVisible(child);
        }
        that.configuredVisibility = true;
        localStorage.getItem('anchorID')? this.openGroup(child) : this.closeLoaders(child);
    }

    closeLoaders(child:any){
      this.loader.closeLoader(['change-concept-evaluated', 'landing-page-on-init']);
    }

    extractSequentialListField(child:any){
      let sequentialFieldsVisibility:any = [];
      for(let [index, container] of child.concept.fieldContainers.entries()){

          let numb:any = index < 9? "0" : "";
          if(container.show){
            container.fields.forEach((fieldContainer:any) => {
              if(fieldContainer.show){
                fieldContainer.nameGroup = container.name;
                fieldContainer.isHighlightGroup = container.isHighlight;
                fieldContainer.numberGroup = numb+ (index + 1).toString()+".";
                sequentialFieldsVisibility.push(fieldContainer);
              }
            });
            container.subContainers.forEach((subContainer:any) => {
              if(!subContainer.multiple && subContainer.show){
                subContainer.fields.forEach((fieldSubContainer:any) => {
                  if(fieldSubContainer.show){
                    fieldSubContainer.nameGroup = container.name;
                    fieldSubContainer.isHighlightGroup = container.isHighlight;
                    fieldSubContainer.nameSubgroup = subContainer.name;
                    fieldSubContainer.isHighlightSubgroup = subContainer.isHighlight;
                    fieldSubContainer.numberGroup = numb+(index + 1).toString()+".";
                    sequentialFieldsVisibility.push(fieldSubContainer);
                  }
                });
              }
            });
          }
      }
      return sequentialFieldsVisibility;
    }

    selectLastFieldVisible(child:any){
      let lastField:any = false;
      for(var i = child.conceptForm.sequentialFieldsVisibility.length-1 ; i >= 0 ; i--){
        let field = child.conceptForm.sequentialFieldsVisibility[i];
          field.lastField = false;
          if(field.show && field.showWithProperty && !field.hidden){
              if(!lastField){
                  lastField = true;
                  field.lastField = true;
              }
          }
      }
      this.countFieldsVisibles(child);
    }

    countFieldsVisibles(child:any){
      let count:any = 0;
      child.conceptForm.countFieldVisibles = 0;
      for(let [index, field] of child.conceptForm.sequentialFieldsVisibility.entries()){
        child.conceptForm.sequentialFieldsVisibility[index].countFieldVisible = null;
          if(field.show && field.showWithProperty && !field.hidden){
              count++;
              child.conceptForm.sequentialFieldsVisibility[index].countFieldVisible = count;
              child.conceptForm.countFieldVisibles++;
          }
      }
    }

    clear(child:any){
        let textInformation = this.translate.instant('concept-form.clear-form');
        if (child.conceptForm.viewConcept === 'steps') {
            textInformation = this.translate.instant('concept-form.warning-message');
        } else if (child.conceptForm.concept.poll) {
            textInformation = this.translate.instant('concept-form.clear-poll');
        } else if (child.fieldContainerId) {
            textInformation = this.translate.instant('concept-form.clear-shared-group');
        }
        let type = child.isLanding? 'info:question:question-full':  'info:question:question';

        this.modalService.adviceModal(textInformation, type)
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.clearFields(child);
            }
          })
    }

    clearFields(child:any){
        let subContainerMultiple:any = child.conceptForm.concept.subContainerMultiple;
        let concept = cloneDeep(child.conceptForm.concept);
        child.conceptForm.concept = null;
        concept.fieldContainers.forEach((fieldContainers:any) => {
          fieldContainers.fields.forEach((field:any) => {
            if(concept.lockedEdit){
              if(field.evaluatedValue && (fieldContainers.private || field.private)){
                  this.resetfield(field, concept, child);
              }
            }else{
              if(field.evaluatedValue){
                  this.resetfield(field, concept, child);
              }
            }
          });
          fieldContainers.subContainers.forEach((subcontainer:any) => {
            if(subcontainer.multiple ){
              this.resetMultipleSubcontainer(subcontainer.subcontainerDuplicated, concept, child);
            }else{
              subcontainer.fields.forEach((subContainerField:any) => {
                if(concept.lockedEdit){
                  if(subContainerField.evaluatedValue && (fieldContainers.private || subcontainer.private || subContainerField.private)){
                      this.resetfield(subContainerField, concept, child);
                  }
                }else{
                    if(subContainerField.evaluatedValue){
                        this.resetfield(subContainerField, concept, child);
                    }
                }
              });
            }
          });
        });

        child.conceptForm.concept = concept;
        child.conceptForm.concept.subContainerMultiple = subContainerMultiple;
        if(child.conceptForm.concept.poll && child.isLanding){
            let changeProgress =  0;
            child.launchAction.emit({
                type:'changeProgress',
                item: changeProgress
            });
        }
        this.extractVisibility(child, true);
    }

    resetMultipleSubcontainer(container:any, concept: any, child:any){
      container.forEach((subContainer:any) => {
        subContainer.fields.forEach((field:any) => {
          this.resetfield(field, concept, child);
        });
      });
    }

    resetfield(field:any, concept: any, child:any){
        if(field && field.evaluatedValue && field.fieldTypeId !== 'rich'){
            this.commonUtilsService.cleanFieldEvaluatedValue(field, concept);
            if(field.fieldTypeId === 'list' && field.isHighlight){
              field.evaluatedValue.forEach((item:any) => {
                item.selected = null;
              });
            }else if(field.fieldTypeId === 'list' && field.isTypeButton){
                field.listValues.forEach((value:any) => {
                  value.selected = false;
                });
            }else{
                this.resetListField(field.evaluatedValue.listValues);
                this.resetListField(field.listValues);
            }

        }
    }

    resetListField(list:any){
      list.forEach((item:any) => {
        item.selected = false;
      });
    }

    saveCreateConcept(type:any, child:any){
        if(type === 'saveBeforeSubcontainer'){
          child.conceptForm.concept.namedKey === 'Consulta'? this.modalSaveICAM(type, child) : this.saveConcept(type, child);
        }
    }

    modalSaveICAM(type:any, child:any){
        this.modalService.adviceModal(this.translate.instant('concept-form.question-save-concept-icam'), 'info:question:question-full')
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              child.conceptForm.fromType = 'saveBeforeSubcontainerICAM';
              this.saveConcept(type, child);
            }
          })

    }

    saveConcept(type:any, child:any) {
      !child.isLanding && child.conceptList && child.conceptList.length > 1? this.saveAllConcepts(child) : this.saveSelectedConcept(child.conceptForm.concept, type, child);
    }

    saveAllConcepts(child:any){
        let hasErrors:any = 0;
        child.conceptList.forEach((concept:any) => {
          this.cleanFieldEvaluatedValueHidden(concept.fieldContainers, concept, child);
          hasErrors += this.commonUtilsService.checkRequiredFields(concept, child.conceptForm.userPublic).commonErrors;
        });

        if (!hasErrors) {
            this.loader.openLoader('concept-form-save-concept');
            return this.saveAllConceptFields(child);
        }else{
            return this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-fields'), 'error:question:mandatory-field')
              .subscribe((result:any)=>{
                if(result.result ==='ok'){
                  this.loader.openLoader('concept-form-save-concept');
                  return this.saveAllConceptFields(child);
                }
              })
        }
    }

    saveAllConceptFields(child:any, exit:any = ''){
        for(let [index, concept] of child.conceptList.entries()){
            this.changeTypeDate(concept, 'toUFC');
            let last = index === child.conceptList.length-1;
            let configuration = {
                concept: concept,
                finished: last,
                from: exit,
                child: child
            }
            this.saveConceptFields(configuration);
        }
    }

    saveSelectedConcept(concept:any, type:any, child:any){
        this.cleanFieldEvaluatedValueHidden(concept.fieldContainers, concept, child);
        child.conceptForm.voiceRecorder = []
        let hasErrors = this.commonUtilsService.checkRequiredFields(concept, child.conceptForm.userPublic);
        let hasErrorAcceptAcceptation = false;
        if(hasErrors.validateErrors){
          this.modalService.adviceModal(this.translate.instant('concept-form.no-validate-fields'), 'error:accept:warning');
        }else if (hasErrors.blockedErrors) {
          this.modalService.adviceModal(this.translate.instant('concept-form.has-blocked-fields'), 'error:accept:warning');
        }else if (!hasErrors.commonErrors) {
            for(let i:number = 0; i < concept.fieldContainers.length; i++){
              let fieldContainer: any =  concept.fieldContainers[i];
              for(let j:any = 0; j < fieldContainer.fields.length; j++){
                let field: any = fieldContainer.fields[j];
                if (!this.commonUtilsService.validateField(field)) {
                  field.error = true;
                  this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-field'), 'error:accept:warning');
                }else if(field.isVoiceRecord && field.voiceRecordFile){
                  if(!field.acceptAceptation){
                    field.errorInAcceptAceptation = true;
                    hasErrorAcceptAcceptation = true;
                  }else{
                    let formData = new FormData();
                    formData.append('file', field.voiceRecordFile, field.voiceRecordName);

                    child.conceptForm.voiceRecorder.push({
                      field: field,
                      formData: formData,
                      name: field.voiceRecordName
                    })
                  }
                }
              }
              for(let k:number = 0; k < fieldContainer.subContainers.length; k++){
                let subContainer: any = fieldContainer.subContainers[k];
                for(let l:any = 0; l < subContainer.fields.length; l++){
                  let subField: any = subContainer.fields[l];
                  if (!this.commonUtilsService.validateField(subField)) {
                    subField.error = true;
                    this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-field'), 'error:accept:warning');
                  }else if(subField.isVoiceRecord && subField.voiceRecordFile){
                    if(!subField.acceptAceptation){
                      subField.errorInAcceptAceptation = true;
                      hasErrorAcceptAcceptation = true;
                    }else{
                      let fd = new FormData();
                      fd.append('file', subField.voiceRecordFile, subField.voiceRecordName);
                      child.conceptForm.voiceRecorder.push({
                        field:subField,
                        isSubContainer: true,
                        formData: fd,
                        name: subField.voiceRecordName
                      })
                    }
                  }
                }
              }
            }

            if(!hasErrorAcceptAcceptation){
              this.changeTypeDate(concept, 'toUFC');
              if (!concept.conceptObjectId) {
                if(concept.poll){
                    this.savePoll(child);
                }else if(child.conceptForm.newTitle === '' && (concept.publicLite || concept.namedKey === 'Consulta')){
                    this.checkInquiriesToday(child);
                    this.configureNewInstance(child);
                }else if(child.conceptForm.newTitle === ''){
                    this.renamePublicConcept(type, child);
                }else{
                    this.loader.openLoader('concept-form-save-concept');
                    this.savePublicConcept(child);
                }
              }else {
                  this.loader.openLoader('concept-form-save-concept');
                  let configuration:any = {
                      concept: child.conceptForm.concept,
                      finished: true,
                      from: type,
                      child: child
                  }
                  this.saveConceptFields(configuration);
              }
            }

        } else {
            if (!(child.public && child.isLanding) && !concept.poll){
                this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-fields'), 'error:question:mandatory-field')
                  .subscribe((result:any)=>{
                    if(result.result ==='ok'){
                      this.loader.openLoader('concept-form-save-concept');
                        let configuration = {
                            concept: child.conceptForm.concept,
                            finished: true,
                            child: child
                        }
                        this.saveConceptFields(configuration);
                    }
                  })
            }else{
              this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-public-fields'), 'error:accept:warning');
            }
        }
    }

    cleanFieldEvaluatedValueHidden(containers:any, concept:any, child:any){
      containers.forEach((container:any) => {
        container.fields.forEach((field:any) => {
          if(!field.show || !container.show){
            this.commonUtilsService.cleanFieldEvaluatedValue(field, concept);
          }
        });
        if(container.subContainers && container.subContainers.length){
          this.cleanFieldEvaluatedValueHidden(container.subContainers, concept, child);
        }
      });
    }

    addTitleToFields(containers:any, child:any){
        this.loader.openLoader('concept-form-save-concept');
        containers.forEach((container:any) => {
          container.fields.forEach((field:any) => {
            if(field.fieldContentTypeId === 'titl' && field.evaluatedValue.nvarcharValue !== null){
              if(child.conceptForm.newTitle){
                child.conceptForm.newTitle += ' ';
              }
              child.conceptForm.newTitle += field.evaluatedValue.nvarcharValue;
            }
          })
          if(container.subContainers && container.subContainers.length){
            this.addTitleToFields(container.subContainers, child);
          }
        })
    }

    saveConceptFields(configuration:any): any{
        let concept:any = configuration.concept;
        let finished:any = configuration.finished;
        let from:any = configuration.from;
        let actionFrom:any = configuration.actionFrom;
        let child:any = configuration.child;
        let historicJson:any;
        if(this.dateLock.hasErrors()){
            this.loader.closeError();
            let optionModal = child.isLanding?'error:accept:close-round-full':'error:accept:warning';
            this.modalService.adviceModal(this.translate.instant('field-type-date.error-message'), optionModal);
            return;
        }else{
            if(!concept.sequentialView){
              historicJson = this.snapShotsService.getConceptJson(concept);
            }
            let url:string = 'conceptdata';
            let id:any = concept.conceptObjectId;
            if(child.fieldContainerId){
                url = 'conceptdata/'+concept.conceptObjectId;
                id = child.fieldContainerId;
            }
            return this.apiService.patch(url, id,  concept.evaluatedFields)
                .then((data:any)=>{
                    if(child.conceptForm.voiceRecorder?.length && !child.isLanding){
                      this.saveVoiceRecords(child)
                    }
                    if(concept.conceptNamedKey === 'Operacion' && data === 'HighRiskOperation/MessageLocked'){
                        child.conceptForm.lockedOperation = true;
                        actionFrom = from==='exit'?'exitWarning':actionFrom;
                    }
                    if(concept.conceptNamedKey === 'Cliente' && data === 'HighRiskClient/MessageLocked'){
                        child.conceptForm.lockedClient = true;
                    }
                    if(this.globalCfg.skin === 'icam-red' && concept.conceptNamedKey === 'Usuario' && data && data.alertType === 'Warning' && data.message && child.locationName !== 'landingPage'){
                        this.closeLoadersSaveConcept(child);
                        this.modalService.adviceModal(data.message, 'info:accept:question')
                          .subscribe((result:any)=>{
                              if(result.result !== 'ok'){
                                  this.goBack(child)
                              }
                          })
                    }else{
                        let wait:boolean = false;
                        if(concept.new === true && concept.fieldsWidthDocuments && concept.fieldsWidthDocuments.length){
                            wait = true;
                            concept.conceptObjectId && (concept.fieldsWidthDocuments.length > 1 || concept.fieldsWidthDocuments[0].documents.length > 1)? this.createFolderToDocuments(concept, concept.new, child) : this.checkDocuments(concept, concept.new, child);
                        }
                        if (!child.isLanding){
                            if(from === 'lock' || from === 'unlock'){
                                finished = false;
                                let stateId = from === 'lock'? 2 : 1;
                                this.changeStateConcept(from, stateId, child);
                            }else if((from === 'end-relationship' || from ==='permanently-block') && this.globalCfg.skin === 'dark-blue'){
                                finished = false;
                                let stateId = from ==='permanently-block'? 3 : 4;
                                this.generateGlobalCustomerIdentificationReport(from, stateId, child);
                            }else if(from === 'generateDocument'){
                              this.loader.closeLoader('concept-form-save-concept');
                            }else{
                              $('#compliance-view-body').scrollTop(0);
                            }
                            this.changeTypeDate(concept, '');
                            child.launchAction.emit({
                                type:'hasDraft',
                                item: concept
                            })
                            if(!child.fieldContainerId){
                                this.snapShotsService.saveForConcept(concept.conceptObjectId, historicJson);//, historicHtml
                            }
                            if(finished){
                                this.closeLoadersSaveConcept(child);
                                if(actionFrom === 'exitWarning'){
                                  let text = concept.conceptNamedKey === 'Cliente'?this.translate.instant('concept-form.block-client-concept'):this.translate.instant('concept-form.info-locked-operation');
                                  this.modalService.adviceModal(text, 'error:accept:alert')
                                    .subscribe((result:any)=>{
                                        if(concept.conceptNamedKey === 'Cliente'){
                                            concept.clientStateId = 2;
                                        }else if(child.conceptForm.lockedOperation){
                                            if(child.currentComponent === 'concept'){
                                              this.broadcastService.broadcast('lockedOperationToEvaluation');
                                            }
                                            delete child.conceptForm.lockedOperation;
                                        }
                                        this.broadcastService.broadcast('savedConceptExit');
                                    });
                              }else if(from === 'exit' && concept.conceptNamedKey === 'Operacion'){
                                this.broadcastService.broadcast('savedConceptExit');
                              }else if(from !== 'exit' && from !== 'generateDocument'){
                                    this.configureModalSaved(child);
                              }
                            }

                        }else if(concept.poll){
                            if(!concept.sequentialView){
                                if (!child.fieldContainerId) {
                                    this.snapShotsService.saveForConcept(concept.conceptObjectId, historicJson);//, historicHtml
                                }
                                this.closeLoadersSaveConcept(child);
                                this.openQuestionModal(this.translate.instant('concept-form.send-poll-ok'), "confirm:acceptContinue:check-round-full", child);
                            }else{
                                this.closeLoadersSaveConcept(child);
                                child.conceptForm.finishedPoll = true;
                                concept.finishedPoll = true;
                            }
                        }else{
                            this.getPredefinedTemplate(child);
                          if (!child.fieldContainerId) {
                            this.snapShotsService.saveForConcept(concept.conceptObjectId, historicJson);//, historicHtml
                          } else {
                            this.snapShotsService.saveForContainer(concept.conceptObjectId, child.fieldContainerId, historicJson);//, historicHtml
                          }
                            if(concept.new && !wait){
                                if(child.conceptForm.fromType === 'saveBeforeSubcontainerICAM' && concept.namedKey === 'Consulta'){
                                    delete child.conceptForm.fromType;
                                    this.closeLoadersSaveConcept(child);
                                }else{
                                    this.changeConceptName(child);
                                }
                            }else if(!wait){
                              this.closeLoadersSaveConcept(child);
                              if(concept && concept.publicLite){
                                  child.launchAction.emit({
                                      type:'closeSaveConceptUserProfile',
                                      actionFrom: 'exitNew'
                                  });
                              } else {
                                if (actionFrom !== 'exitNew') {
                                  let modalText:string = 'concept-form.finish-landing';
                                  let modalType:string = 'confirm:remain:check-round';
                                  if (this.globalCfg.skin === 'icam-red' && child.isLanding) {
                                      modalText = 'concept-form.send-query-ok';
                                      modalType = 'confirm:seeAll:check-round-full';
                                  }
                                  this.openQuestionModal(modalText, modalType, child);
                                }else if(actionFrom === 'exitNew' && child.isLanding){
                                  this.broadcastService.broadcast('createNewQueryLanding');
                                }
                              }
                            }
                        }
                        this.conceptState.removeAll();
                    }
            }, (errorData:any)=>{
                this.loader.closeError();
                let text = concept && concept.title? this.translate.instant('concept-form.error-patch-concept') +"'"+ concept.title + "'" : this.translate.instant('concept-form.error-patch-concept');
                if(concept.poll){
                    text = this.translate.instant('concept-form.error-add-poll');
                }
                if(this.globalCfg.skin === 'icam-red' && concept.conceptNamedKey === 'Usuario' && errorData.description && errorData.description.message && errorData.description.alertType === 'Error' && child.isLanding){
                    text = errorData.description.message;
                }
                this.modalService.adviceModal(text, 'error:accept:warning');
            });
        }

    }

    saveVoiceRecords(child:any, conceptObjectId = null){
      for(let i = 0; i < child.conceptForm.voiceRecorder.length; i++){
        let file: any = child.conceptForm.voiceRecorder[i];
        let field: any = file.field;
        let conceptObject: any = conceptObjectId === null? file.field.evaluatedValue.conceptObjectId : conceptObjectId
        let url = file.isSubContainer? 'conceptdata/uploadtomultiplesubgroup/' + field.fieldId + '/' + conceptObject + '/' + field.evaluatedValue.numSubContainer : 'conceptdata/upload/' + field.fieldId + '/' + conceptObject;
        this.apiService.attachFile(url, file.formData)
        .then((data:any)=>{
            field.evaluatedValue.documents.push({
              name: file.name
            })
            if(child.isLanding){
              this.broadcastService.broadcast('changeDocumentToList', {
                document: data,
                newConcept: true
            })
          }
          child.conceptForm.voiceRecorder.splice(i, 1);

          if(child.conceptForm.voiceRecorder.length === 0){
            child.launchAction.emit({
              type:'modifyConceptTab'
            });
          }
        }, (errorData:any)=>{})

      }
    }

    closeLoadersSaveConcept(child:any){
      this.loader.closeLoader(['concept-tab-rename-modal', 'concept-form-save-concept', 'save-concept-to-manage-lock-evaluation']);
    }

    createFolderToDocuments(concept:any, newConcept:any, child:any){
        this.apiService.add('documentobjects/premultiupload/'+ concept.conceptObjectId, {})
            .then((data:any)=>{
                this.checkDocuments(concept, newConcept, child);
            }, ()=>{})
    }

    checkDocuments(concept:any, newConcept:any, child:any){
      for(let i = 0; i < concept.fieldsWidthDocuments.length; i++){
        let fieldWidthDocument = concept.fieldsWidthDocuments[i];
        let url = 'conceptdata/upload/'+fieldWidthDocument.fieldId+'/'+concept.conceptObjectId;
        let countDocuments = 0;
        for(let j = 0; j < fieldWidthDocument.documents.length; j++){
          let documentToField = fieldWidthDocument.documents[j];
          this.apiService.attachFile(url, documentToField.document)
            .then((data:any)=>{
              countDocuments++;
              this.broadcastService.broadcast('changeListFieldDocuments', {
                  document: data,
                  fieldId: data.fieldId,
              });
              this.broadcastService.broadcast('changeDocumentToList', {
                  document: data,
                  newConcept: newConcept
              })
              if(countDocuments === fieldWidthDocument.documents.length){
                this.changeConceptName(child);
              }
            }, (error:any)=>{
                this.loader.closeError();
            })
        }
      }
    }

    configureModalSaved(child:any){
        let concept: any = child.conceptForm.concept
        let typeModal:string =  'info:continue:question';
        let textModal:string = this.translate.instant('concept-form.save-question');
        let actionConfirm:string = 'none';
        if(child.conceptForm.concept.productId === 'blanqueo'){
            typeModal =  'info:accept:check-round';
            switch (concept.conceptNamedKey){
                case 'SujetoObligado':
                    if (concept.saved) {
                          textModal = 'concept-form.save-ok-subject';
                    } else {
                          textModal = 'concept-form.save-ok-new-subject';
                          concept.saved = true;
                    }
                    break;
                case 'Cliente':
                    if (concept.saved) {
                        textModal = 'concept-form.save-ok-client';
                    } else {
                        textModal = 'concept-form.save-ok-new-client';
                        concept.saved = true;
                    }
                    actionConfirm = child.conceptForm.lockedClient?'lock':'none';
                    break;
                case 'Operacion':
                    textModal = 'concept-form.save-ok-operation';
                    break;
                case 'ExamenEspecial':
                    textModal = 'concept-form.save-ok-special-test';
                    break;
                case 'DiligenciasReforzadas':
                    textModal = 'concept-form.save-ok-errands';
                    break;
            }
        }else if(concept.productTypeId === 2 && child.parentConcept && !child.parentConcept.parentId){
            typeModal =  'info:accept:question';
            textModal = 'concept-form.save-ok';
        }

        this.openQuestionModal(textModal, typeModal, child, actionConfirm);
    }

    closeConceptUserProfile(child:any){
        child.launchAction.emit({
            type:'closeConceptUserProfile'
        });
    }

    checkSavePoll(child:any){
      let that:any = child.conceptForm;
      if(!that.viewEditConcept && that.fieldSelected && that.fieldSelected.required && !that.fieldSelected.evaluatedValue.completePoll){
          that.fieldSelected.error = true;
      }else{
          this.savePoll(child);
      }
    }

    savePoll(child:any){
      this.openQuestionModal('concept-form.survey-sent', 'info:question:question-full', child)
    }

    isPrivateScope(fieldId:any, child:any) {
        let privateGroup;
        let privateSubgroup;
        let privateField;
        let fieldContainers = child.conceptForm.concept.fieldContainers;
        for (let i in fieldContainers) {
            let fieldContainer = fieldContainers[i];
            privateGroup = fieldContainer.private;

            let containerFields = fieldContainer.fields;
            for (let j in containerFields) {
                let containerField = containerFields[j];
                if (containerField.fieldId === fieldId) {
                    privateField = containerField.private;
                    break;
                }
            }

            if (privateField == null) {
                let subContainers = fieldContainer.subContainers;
                for (let j in subContainers) {
                    let subContainer = subContainers[j];
                    privateSubgroup = subContainer.private;

                    let subContainerFields = subContainer.fields;
                    for (let k in subContainerFields) {
                        let subContainerField = subContainerFields[k];
                        if (subContainerField.fieldId === fieldId) {
                            privateField = subContainerField.private;
                            break;
                        }
                    }
                }
            } else {
                break;
            }
        }

        return (privateGroup || privateSubgroup || privateField);
    }

    savePublicConcept(child:any) {
      let that: any = child.conceptForm
        let concept: any = that.concept
        let changeConcept:any = {
          title: that.newTitle
        };
        if(that.temporalDocuments && that.temporalDocuments.length){
          changeConcept.documents = that.temporalDocuments
        }
        return this.apiService.add('conceptobjects/' + concept.conceptId, changeConcept)
            .then((data:any)=>{
                concept.conceptObjectId = data.conceptObjectId;
                if(child.conceptForm.voiceRecorder?.length){
                  this.saveVoiceRecords(child, data.conceptObjectId)
                }

                if(concept.new && !concept.poll){
                    data.documentObjects.forEach((documentAdded:any) => {
                      this.broadcastService.broadcast('changeDocumentToList', {
                        document: documentAdded,
                        newConcept: concept.new
                      })
                    });

                    if(child.isLanding){
                      this.broadcastService.broadcast('addNewCommentComunicationChannel', {
                        conceptObjectId: data.conceptObjectId
                      })
                    }
                }
                concept.evaluatedFields.forEach((evaluated:any) => {
                  let fieldId = evaluated.fieldId;
                  let element:any = data.conceptData.filter((item:any)=>item.fieldId === fieldId)[0]
                  evaluated.conceptDataId = element.conceptDataId;
                  evaluated.conceptObjectId = element.conceptObjectId;
                    if (child.isLanding && evaluated.floatvalue == null && element.floatvalue != null && this.isPrivateScope(fieldId, child)) {
                        evaluated.floatvalue = element.floatvalue;
                    }
                });

                if(this.globalCfg.skin==='icam-red' && child.isLanding && !concept.conceptObjectId ){
                  this.registerEvent(child)
                }

                let configuration = {
                    concept: concept,
                    finished: true,
                    child: child
                }
                this.saveConceptFields(configuration);
            }, (errorData:any)=>{
                this.loader.closeError();
                let text:string = concept.poll? this.translate.instant('concept-form.error-add-poll') : this.translate.instant('concept-form.error-add-concept') + "'" + concept.title + "'";
                this.modalService.adviceModal(text, 'error:accept:warning')
            });
    }

    registerEvent(child:any){
      let resgisterItem ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'create instance',
        'Concept Name': sessionStorage.getItem('breadcrumbConcept') + child.conceptForm.concept.title,
        'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem);
    }

    changeTypeDate(concept:any, type:any){
      concept.evaluatedFields.forEach((evaluatedField:any) => {
        if(evaluatedField.datetimeValue === ''){
          evaluatedField.datetimeValue = null;
        }else if(evaluatedField.fieldTypeId === 'date' && evaluatedField.datetimeValue !== null){
          evaluatedField.datetimeValue = type ==='toUFC'? new Date(evaluatedField.datetimeValue).toISOString() : new Date(evaluatedField.datetimeValue);
        }
      });
    }

    openQuestionModal(text:any, type:any, child:any, actionConfirm:any =''){
        let that:any = child.conceptForm
        this.modalService.adviceModal(this.translate.instant(text), type)
            .subscribe((result:any)=>{
              if(result.result==='ok' || result.result==='continue'){
                let expired = false;
                let newConcept = that.concept.new;
                delete that.concept.new;
                if (type === "confirm:remain:check-round" || type === "confirm:continue:check-round" || type === "confirm:acceptContinue:check-round-full" || type === "confirm:seeAll:check-round-full"){
                    if(!that.concept.poll){
                        if(child.public){
                            if(that.icamConceptObject){
                                that.newTitle = '';
                                child.launchAction.emit({
                                    type:'closeSaveExitConceptICAM',
                                    new: newConcept
                                });
                            }else{
                                this.exitLanding("concept-public", child);
                            }
                        }else{
                            let exitType:string = child.locationName === 'root.shared.taskconcept' ? 'taskConcept-shared' : 'concept-shared';
                            this.exitLanding(exitType, child);
                        }
                    }else{
                        expired = that.concept.pollExpirationDate &&(moment(new Date).valueOf() > moment(that.concept.pollExpirationDate).valueOf())
                        let text:string;
                        if(child.public){
                            text = expired? "poll-expired-public" : "poll-public"
                        }else{
                          text = expired? "poll-expired-shared" : "poll-shared"
                        }
                        this.exitLanding(text, child)
                    }

                }else if(that.concept.poll && (type === "info:question:question" || type === "info:question:question-full")){
                    this.loader.openLoader('concept-tab-rename-modal')
                    let configuration = {
                        concept: that.concept,
                        finished: true,
                        child: child
                    }
                    that.concept.conceptObjectId? this.saveConceptFields(configuration) : this.savePublicConcept(child);
                }else{
                    if(that.concept.conceptObjectId === parseInt(child.conceptObjectId)){
                        this.loader.openLoader('folder-on-init');
                        if(this.globalCfg.skin ==='icam-red' && sessionStorage.getItem('icamUser')){
                          let users:any = sessionStorage.getItem('icamUser');
                          let icamUsers : any = JSON.parse(users);
                          icamUsers.forEach((user:any) => {
                            let concept = user.to;
                            if(child.params['evaluationId'] === concept.evaluationId && child.params['conceptId'] === concept.conceptId && child.params['conceptObjectId'] === concept.conceptObjectId){
                              sessionStorage.removeItem('icamUser');
                              let urlUser: any = 'evaluation/'+user.from.evaluationId+'/folder/'+user.from.folderId+'/concept/'+user.from.conceptId+'/concept-evaluated/'+user.from.conceptObjectId;
                              this.commonUtilsService.reloadNavigateUrlEvaluation(urlUser, user.from.evaluationId);
                            }
                          });
                        }else{
                          let folderId = !that.concept.productTypeId || that.concept.productTypeId !== 2? that.concept.folderId : child.parentConcept.parentId;
                          let evaluationId = child.params['evaluationId'];
                          this.router.navigateByUrl('evaluation/'+evaluationId+'/folder/'+folderId);
                        }
                    }else{
                        child.launchAction.emit({
                            type:'closeConcept',
                            item: that.concept
                        });
                    }
                }
              }else{
                if(that.newTitle !== undefined && that.newTitle !== ""){
                  that.concept.title = that.newTitle;
                  child.launchAction.emit({
                      type:'modifyConceptTab',
                      item: that.newTitle
                  });
                }
                if(actionConfirm === 'lock'){
                    child.conceptForm.menuBlockClientShow = child.conceptForm.menuManageBlockClient;
                    child.conceptForm.concept.clientStateId = 2;
                    this.modalService.adviceModal(this.translate.instant('concept-form.block-client-concept'), 'error:accept:alert');
                    // this.ChangeStateConcept('lock', 2, child);
                }else if(that.lockedOperation && type === 'info:accept:check-round' && that.concept.conceptNamedKey === 'Operacion'){
                    delete that.lockedOperation;
                    if(child.currentComponent === 'concept'){
                      this.broadcastService.broadcast('lockedOperationToEvaluation');
                    }
                    this.modalService.adviceModal(this.translate.instant('concept-form.info-locked-operation'), 'error:accept:alert');
                }else if (type === "confirm:seeAll:check-round-full" && !that.concept.poll && child.public && that.icamConceptObject){
                    child.launchAction.emit({
                        type:'closeSaveConceptICAM'
                    });
                }
              }
            });

    }

    checkInquiriesToday(child:any){
        let that:any = child.conceptForm;
        if(that.concept.namedKey === 'Consulta'){
            let todaySum = 0;
            child.formalitiesList.forEach((queryRequest:any) => {
              if(this.dateConverterService.todayDate(queryRequest.creationDate)){
                  todaySum++;
              }
            });

            if(todaySum >= 3){
                this.alertMessageInquiries(child);
            }else{
                this.addTitleToFields(that.concept.fieldContainers, child);
                this.savePublicConcept(child);
            }
        }else{
            this.addTitleToFields(that.concept.fieldContainers, child);
            this.savePublicConcept(child);
        }
    }

    alertMessageInquiries(child:any){
        this.modalService.adviceModal(this.translate.instant('concept-form.warning-limit-concept-icam'), 'info:cancelContinue:exclamation-full')
            .subscribe((result:any)=>{
              if(result.result==='continue'){
                this.addTitleToFields(child.conceptForm.concept.fieldContainers, child);
                this.savePublicConcept(child);
              }
            });
    }

    renamePublicConcept(type:any, child:any){
        let that:any = child.conceptForm
        let newItem:any = {
            title: that.concept.title,
            type: 'public',
            titleConcept: '',
            conceptId: that.concept.conceptId,
            conceptObjectId: that.concept.conceptObjectId,
            listFolders: [],
            rename: true,
            icon:'lf-icon-compliance'
        }
        if(type){
            newItem.subtype = type;
        }else if(child.isLanding && that.concept.communicationChannel){
            newItem.subtype = 'saveComunicationChannel';
        }
        this.modalService.renameModal(newItem, null)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              let hasErrors:any = {
                commonErrors: 0
              };
              if(type === 'generateTemplateFromFieldPublic'){
                  hasErrors = this.commonUtilsService.checkRequiredFields(that.concept, that.userPublic);
              }
              if(result.result === 'ok' && !hasErrors.commonErrors){
                  this.loader.openLoader('concept-tab-rename-modal');
                  that.newTitle = result.name;
                  // vm.concept.title = vm.newTitle;
                  this.configureNewInstance(child);
                  that.hideHistoric = true;
                  that.fromType = newItem.subtype;
                  this.savePublicConcept(child);
              }else if(hasErrors){
                  this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-public-fields-template'), 'error:accept:mandatory-field');
              }
            }
        })
    }

    configureNewInstance(child:any){
        if(child.conceptForm.temporalDocuments && child.conceptForm.temporalDocuments.length){
            child.conceptForm.concept.documents = child.conceptForm.temporalDocuments;
        }
        child.conceptForm.concept.new = true;
    }

    changeConceptName(child:any){
      let that: any = child.conceptForm;
      this.apiService.patch("conceptobjects/"+that.concept.conceptObjectId, 0,{ title: that.newTitle})
        .then((data:any)=>{
          if(that.concept && that.concept.publicLite){
            sessionStorage.setItem('modalInfo', 'createdPublicLite');
            this.context.logout();
            window.location.reload();
          }else{
            this.closeLoadersSaveConcept(child);
            if(that.fromType === 'generateTemplateFromFieldPublic'){
                delete that.concept.new;
                that.templateSelected.typeOption = 'generateTemplateFromField';
                this.changeConceptFromField(that.templateSelected, child);
            }else if(that.fromType === "saveBeforeSubcontainer"){
                delete that.concept.new;
                that.itsSaveConcept = true;
            }else if(that.fromType === "saveComunicationChannel"){
                this.exitLanding('concept-public', child);
            }else{
                let modalText = 'concept-form.finish-landing';
                let modalType = 'confirm:remain:check-round';
                if (this.globalCfg.skin === 'icam-red' && child.isLanding) {
                    modalText = 'concept-form.send-query-ok';
                    modalType = 'confirm:seeAll:check-round-full';
                }
                this.openQuestionModal(modalText, modalType, child);
            }
          }
        }, (errorData:any)=>{
          this.loader.closeError();
          let text =errorData.description==='UqConstraint'? 'concept-tab.error-same-name': 'concept-tab.error-name';
          this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
        });
    }

    exitLanding(exitType:any, child:any){
        let userEmail:any = null;
        let identifierField:any = this.findField(child.conceptForm.concept.fieldContainers, { isIdentifier: true }, child);
        if (identifierField && identifierField.evaluatedValue) {
          userEmail = identifierField.evaluatedValue.nvarcharValue;
        } else {
            identifierField = this.findAdditionalField(child.conceptForm.concept.fieldContainers, { isIdentifier: true }, child);
            if (identifierField && identifierField.evaluatedValue) {
              userEmail = identifierField.evaluatedValue;
            }
        }
        let finishData = {
            exitType: exitType,
            conceptObjectId: child.conceptForm.concept.conceptObjectId,
            footerConceptPublic: child.conceptForm.footerConceptPublic,
            userEmail: userEmail,
            conceptId: child.conceptForm.concept.conceptId
        }
        sessionStorage.setItem('exit-landing', JSON.stringify(finishData));
        let urlLocation = child.locationName.indexOf('root.public') !== -1?'public/exit':'shared/exit';
        this.router.navigate([urlLocation])

    }

    findField(containers:any, condition:any, child:any) {
        let foundField = null
        find(containers, (container:any)=>{
            foundField = find(container.fields, condition);
            if (!foundField && container.subContainers && container.subContainers.length) {
                foundField = this.findField(container.subContainers, condition, child);
            }
            return !!foundField;
        });
        return foundField;
    }

    findAdditionalField(containers:any, condition:any, child:any) {
        let foundField:any = null
        find(containers, (container:any)=>{
            find(container.fields, (field:any)=>{
                if (field.additionalRelatedFields) {
                    foundField = find(field.additionalRelatedFields, condition);
                }
            });
            if(foundField !==null){
              return !!foundField;
            }else if (!foundField && container.subContainers && container.subContainers.length) {
                foundField = this.findAdditionalField(container.subContainers, condition, child);
            }
            return !!foundField;
        });
        return foundField;
    }

    generateDocument(template:any, child:any){
        let configuration:any = {
            concept: child.conceptForm.concept,
            textModal: this.translate.instant('concept.advertence-generate-document'),
            typeModal: 'info:exitTemplate:question',
            noValidateFields:  this.translate.instant('concept-form.no-validate-fields'),
            blockedFields:  this.translate.instant('concept-form.has-blocked-fields'),
            textValidateField:  this.translate.instant('concept-form.error-validate-fields')
        }
        this.conceptUtilsService.exitConcept(configuration)
          .then((result:any)=>{
              if(result === 'generate-and-save'){
                  if(child.conceptForm.conceptNamedKey === 'Cliente' && child.conceptForm.productId === 'blanqueo' && child.conceptForm.addToScoring.valueRisk === 4 && child.conceptForm.clientStateId === 1){
                      child.conceptForm.clientStateId = 2;
                      child.conceptForm.menuBlockClientShow = child.conceptForm.menuManageBlockClient;
                      this.modalService.adviceModal(this.translate.instant('concept-form.block-client-concept'), 'error:accept:alert')
                        .subscribe((result:any)=>{
                            this.saveConceptAndGenerateDocument(template, child);
                        });
                  }else{
                      this.saveConceptAndGenerateDocument(template, child);
                  }
              }else{
                  this.modalGenerateDocument(template, child);
              }
        }, ()=>{})
        // this.conceptUtilsService.exitConcept(configuration) === 'generate-and-save'? this.saveConceptAndGenerateDocument(template, child) : this.modalGenerateDocument(template, child);
    }

    saveConceptAndGenerateDocument(template:any, child:any){
        this.loader.openLoader('concept-form-save-concept')
        let configuration = {
            concept: child.conceptForm.concept,
            finished: true,
            child: child,
            from: 'generateDocument'
        }
        if(this.saveConceptFields(configuration)){
          this.modalGenerateDocument(template, child);
        }
    }

    modalGenerateDocument(template:any, child:any){
      this.modalService.generateDocument(template, child.conceptForm.concept).subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('concept-form-new-file');
          this.createNewFile(result, template, child);
        }
      })
    }

    createNewFile(result:any, templateOrigin:any, child:any){
        let document:any = {
            name: result.name
        }
        if(templateOrigin && templateOrigin.fieldId && templateOrigin.from === 'fieldTemplate'){
            if(child.isLanding && !templateOrigin.conceptDataId){
                templateOrigin.conceptDataId = this.addConceptDataIdToDocument(templateOrigin, child);
            }
            document.conceptDataId = templateOrigin.conceptDataId;
        }
        this.apiService.add(result.url, document)
            .then((data:any)=>{
                if(templateOrigin && templateOrigin.fieldId && templateOrigin.from === 'fieldTemplate'){
                    data.sourceName = this.translate.instant('concept.new-template-general');
                    this.commonUtilsService.addDocumentToField(data, templateOrigin.fieldId, child.conceptForm.concept);
                    if(child.isLanding){
                        this.broadcastService.broadcast('addAttachmentsDocument', {
                            document: data
                        })
                    }
                }
                if(child.conceptForm.concept.documentation && child.conceptForm.concept.documentation.documents){
                    child.conceptForm.concept.documentation.documents.push(data);
                    child.conceptForm.concept.documentation.totalDocuments++;
                }
                this.loader.closeLoader(['concept-form-new-file']);
                child.launchAction.emit({
                    type:'generateTemplate',
                    template: data,
                    resolve: 'ok'
                });
            }, (errorData:any)=>{
                this.loader.closeError();
                child.launchAction.emit({
                    type:'generateTemplate',
                    template: templateOrigin,
                    resolve: 'error'
                });
            });
    }

    addConceptDataIdToDocument(field:any, child:any){
      child.conceptForm.concept.evaluatedFields.filter((evaluatedField:any)=>{ return evaluatedField.fieldId === field.fieldId})[0].conceptDataId;
    }

    launchActionGeneralConcept(action:any, child:any){
        switch(action.type){
            case 'downloadDocument':
                this.documentActions.doAction('download', action);
                break;

            case 'addDocumentToNewConcept':
                child.conceptForm.temporalDocuments = action.list;
                break;
            case 'deleteDocumentToConceptNew':
              child.conceptForm.temporalDocuments = action.list;
              this.loader.closeLoader(['attachments-template-delete']);
              break;
        }
    }

    sendChangeStep(step:any, child:any){
        child.conceptForm.showFieldContainerNav = false;
        if(child.conceptForm.viewConcept === 'steps'){
            child.conceptForm.changeStepsConcept = {
                step: step,
                action: 'changeStep',
            }
        }
    }

    goBack(child:any){
        if(!child.isLanding){
          this.loader.openLoader('folder-on-init')
          let evaluationId = child.params['evaluationId'];
          child.conceptForm.concept.productTypeId === 2? window.history.back() : this.router.navigateByUrl('evaluation/'+evaluationId+'/folder/'+child.conceptForm.concept.folderId);
        }else{
            if(!child.conceptForm.concept.poll){
                if(child.public){
                    if(child.conceptForm.concept.lockedEdit){
                        if (this.context.user.isLogged){
                          this.broadcastService.broadcast('concept-public-identify');
                        }else {
                          this.exitLanding("concept-public-locked", child);
                        }
                    }else{
                        this.exitLanding("concept-public", child);
                    }
                }else{
                    this.exitLanding("concept-shared", child);
                }
            }else{
              let expired:any = (child.conceptForm.concept.pollExpirationDate && (moment(new Date).valueOf() > moment(child.conceptForm.concept.pollExpirationDate).valueOf()))
              let text: string;
                if(child.public){
                    text = expired?"poll-expired-public" : "poll-public";
                }else{
                    text = expired?"poll-expired-shared" : "poll-shared"
                }
                this.exitLanding(text, child)
            }
        }

    }

    openRelatedInfo(relatedInfo:any, child:any){
        this.modalService.relatedInformationModal(relatedInfo)
    }

    nextQuestionPoll(child:any){
        let that:any = child.conceptForm
        let changeProgress:number = 0;
        let selectedField:boolean = false;
        if(!that.viewEditConcept && that.fieldSelected && that.fieldSelected.required && !that.fieldSelected.evaluatedValue.completePoll){
            that.fieldSelected.error = true;
        }else{
            if(!that.fieldSelected){
                that.fieldSelected = {
                    nextCountValue: 1
                }
                that.startedPoll = true;
                that.concept.startedPoll = true;
            }
            if(that.concept.sequentialView && child.isLanding){
              that.sequentialFieldsVisibility.forEach((field:any) => {
                field.selected = false;
                if(!selectedField && field.countFieldVisible !== null && that.fieldSelected.nextCountValue === field.countFieldVisible){
                    selectedField = true;
                    that.fieldSelected = field;
                    that.fieldSelected.error = false;
                    that.fieldSelected.nextCountValue = that.fieldSelected.countFieldVisible+1;
                    that.fieldSelected.prevCountValue = that.fieldSelected.countFieldVisible-1;
                    field.selected = true;
                    let countTotalFields:number = that.sequentialFieldsVisibility.length;
                    let quantityFieldsCompleted:any = field.countFieldVisible-1;
                    changeProgress = Math.round((quantityFieldsCompleted*100)/countTotalFields);
                    if(that.changeProgressSequential > changeProgress && !that.changeListSequential){
                        changeProgress = that.changeProgressSequential;
                    }
                    if(quantityFieldsCompleted+1 === countTotalFields){
                        changeProgress = 99;
                        that.changeProgressSequential = 99;
                    }
                    that.changeProgressSequential = changeProgress;
                    child.launchAction.emit({
                        type:'changeProgressSequential',
                        item: changeProgress
                    });
                }
              });
            }
        }
    }

    previousQuestionPoll(child:any){
        let selectedField:any = false;
        if(child.conceptForm.concept.sequentialView && child.isLanding){
          child.conceptForm.sequentialFieldsVisibility.forEach((field:any) => {
            field.selected = false;
            if(!selectedField && field.countFieldVisible !== null && child.conceptForm.fieldSelected.prevCountValue === field.countFieldVisible){
                selectedField = true;
                child.conceptForm.fieldSelected = field;
                child.conceptForm.fieldSelected.error = false;
                field.selected = true;
            }
          });
        }
    }

    changeResponsible(responsible:any, child:any) {
        let concept: any = child.conceptForm.concept
        if (concept.processTask && concept.processTask.participants && concept.processTask.participants.length) {
            let participant:any = concept.processTask.participants[0];
            participant.id = responsible.responsibleId;
            participant.fullName = (responsible.name + ' ' + responsible.surname).trim();
        }
    }

    changeLockedEdit(lockedEdit:any, child:any){
      let concept: any = child.conceptForm.concept
        if(!concept.sharedReadOnly && concept.saved){
            concept.stateLockedEdit = lockedEdit;
            concept.lockedEdit = lockedEdit;
            concept.fieldContainers.forEach((fieldContainer:any) => {
              fieldContainer.lockedEdit = (lockedEdit || concept.poll);
              fieldContainer.fields.forEach((field:any)=> {
                field.lockedEdit = (lockedEdit || concept.poll);
                delete field.noEditable;
              });
              fieldContainer.subContainers.forEach((subContainer:any) => {
                subContainer.lockedEdit = (lockedEdit || concept.poll);
                subContainer.fields.forEach((fieldSubcontainer:any) => {
                  fieldSubcontainer.lockedEdit = (lockedEdit || concept.poll);
                  delete fieldSubcontainer.noEditable;
                });
              });

            child.conceptForm.conceptLocked = (concept.lockedEdit && concept.saved);
          })
        }
      }

      openGroup(child:any){
        let anchorID:any = localStorage.getItem('anchorID');
        if(anchorID){
            localStorage.removeItem('anchorID');
            if(child.conceptForm.viewConcept !== 'steps'){
                setTimeout(()=>{
                  let element: any = $('#grupo-id-'+anchorID+'-concept-form')
                  element.click();
                    this.closeLoaders(child);
                }, 1000)
            }else{
                setTimeout(()=>{
                    let numOrderGroup = 0;
                    let fieldContainer: any = child.conceptForm.concept.fieldContainers;
                    for(let i = 0; i < fieldContainer.length; i++){
                        if(parseInt(anchorID) === fieldContainer[i].fieldContainerId){
                            numOrderGroup = fieldContainer[i].index;
                            break;
                        }
                    }
                    if(numOrderGroup){
                      this.sendChangeStep(numOrderGroup, child);
                    }
                    this.closeLoaders(child);
                })
            }

        }
    }

    changeConceptFromField(template:any, child:any){
        child.conceptForm.templateSelected = template;
        let concept: any = child.conceptForm.concept
        switch(template.typeOption){
            case 'generateTemplateFromFieldPublic':
                if(concept.conceptObjectId){
                    let hasErrors:any = this.commonUtilsService.checkRequiredFields(concept, child.conceptForm.userPublic );
                    if(hasErrors.validateErrors){
                      this.modalService.adviceModal(this.translate.instant('concept-form.no-validate-fields'), 'error:accept:warning');
                    }else if (hasErrors.blockedErrors) {
                      this.modalService.adviceModal(this.translate.instant('concept-form.has-blocked-fields'), 'error:accept:warning');
                    }else if(hasErrors.commonErrors){
                      this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-public-fields-template'), 'error:accept:mandatory-field');
                    }else{
                      this.modalGenerateDocument(template, child);
                    }
                }else{
                    concept.namedKey === 'Consulta'? this.checkInquiriesToday(child) : this.renamePublicConcept(template.typeOption, child);
                }
                break;
            case 'generateTemplateFromField':
                this.generateDocument(template, child);
                break;
        }
    }

    clearSaveConcept(child:any){
        child.conceptForm.itsSaveConcept = false;
    }

    shareFieldContainer(fieldContainer:any, child:any){
        let configuration:any = {
            concept: child.conceptForm.concept,
            textModal: this.translate.instant('concept-form.save-form-share-fieldcontainer'),
            typeModal: 'info:onlySaveContinueAndCancel:question',
            noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
            blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
            textValidateField: this.translate.instant('concept-form.error-validate-fields')
        }
        this.conceptUtilsService.exitConcept(configuration)
            .then(()=>{
                this.shareFieldContainerAction(fieldContainer, child);
            },()=>{})
    }

    shareFieldContainerAction(fieldContainer:any, child:any){
        let configuration = {
            addContacts: true,
            document: null,
            concept: child.conceptForm.concept,
            fieldContainer: fieldContainer,
            type: 'shareFieldContainer'
        }
        this.modalService.documentShare(configuration)
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.shareContainer(configuration, result.share, child);
            }
          })

    }

    shareContainer(configuration:any, share:any, child:any){
        this.loader.openLoader('folder-share-fieldcontainer');
        this.apiService.add('fieldcontainers/share/'+configuration.concept.conceptObjectId+'/'+configuration.fieldContainer.fieldContainerId, share)
            .then((data:any)=>{
                this.loader.closeLoader(['folder-share-fieldcontainer']);
                this.modalService.adviceModal(this.translate.instant('concept-form.share-fieldcontainer'), 'info:accept:check-round');

            }, (errorData:any)=>{
                this.loader.closeError();
                this.modalService.adviceModal(this.translate.instant('concept-form.error-add-shared'), 'error:accept:warning');
            })
    }

    createNewForm(form:any, child:any){
        this.loader.openLoader('concept-form-create-form')
        let newForm:any = {
            title:form.title,
            associatedEvaluationId:child.conceptForm.concept.evaluationId,
            sourceConceptObjectId:child.conceptForm.concept.conceptObjectId

        }
        this.apiService.add('conceptobjects/'+form.relatedInfoFieldId, newForm)
            .then((data:any)=>{
                this.openInNewTab(data, child)
            }, (errorData:any)=>{
                this.loader.closeError();
                this.modalService.adviceModal(this.translate.instant('concept-form.error-create-form'),'error:accept:warning' )
            })
    }

    openInNewTab(item:any, child:any) {
        this.apiService.get('conceptobjects/formpath', item.conceptObjectId)
        .then((data:any)=>{
            this.loader.closeLoader('concept-form-create-form');
            this.modalService.adviceModal(this.translate.instant('concept-form.create-form'), 'info:accept:check-round')
            window.open(data, '_blank');
            child.conceptForm.concept.formList.splice(child.conceptForm.concept.formList.length, 0, item)
        }, (errorData:any)=>{
            this.loader.closeError();
        })

    }

    launchActionBlockedConcept(type:any, child:any){
        let modifiedConcept = (this.conceptState.getAmount() || this.conceptState.getProcess())?true:false;
        let text: any = '';
        switch(type){
            case 'lock':
                text = this.translate.instant('concept-form.question-block-client-concept').replace('{role}',  child.conceptForm.roleLevel2.name);
                this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
                  if(result.result ==='ok'){
                    this.manageChangeState(type, 2, modifiedConcept, child);
                  }
                })
                break;
            case 'unlock':
                this.modalService.adviceModal(this.translate.instant('concept-form.question-unlock-client-concept'), 'info:question:question').subscribe((result:any)=>{
                  if(result.result==='ok'){
                    this.manageChangeState(type, 1, modifiedConcept, child);
                  }
                })
                break;
            case 'end-relationship':
                text = this.translate.instant((this.globalCfg.skin === 'dark-blue'?'concept-form.question-end-relationship-client-concept-with-report':'concept-form.question-end-relationship-client-concept'));
                this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
                  if(result.result==='ok'){
                    this.manageChangeState(type, 4, modifiedConcept, child);
                  }
                })

                break;
            case 'reactivate':
            case 'permanently-block':
                this.permanentlyBlock(type, modifiedConcept, child);
              break;
            case 'reassign':
                text = this.translate.instant('concept-form.reassgin-client-concept').replace('{role}',  child.conceptForm.roleLevel3.name);
                this.modalService.adviceModal(text, 'info:question:question')
                  .subscribe((result:any)=>{
                    if(result.result ==='ok'){
                      this.reassignClient(type, child);
                    }
                  })
              break;
        }
    }

    reassignClient(type: any, child: any){
        this.loader.openLoader('block-concept');
        this.apiService.get('blanqueo/reassignreview/' + child.concept.clientId)
          .then((data:any)=>{
            this.openInfoClientModal(type, child);
          }, (errorData:any)=> {
            this.loader.closeError();
          })
    }

    manageChangeState(type:any, stateId:any, modifiedConcept:any, child:any){
        if(modifiedConcept){
            this.loader.openLoader('block-concept');
            this.saveSelectedConcept(child.conceptForm.concept, type, child);
        }else if((type === 'end-relationship' || type ==='permanently-block') && this.globalCfg.skin === 'dark-blue'){
            this.generateGlobalCustomerIdentificationReport(type, stateId, child);
        }else{
            this.changeStateConcept(type, stateId, child);
        }
    }

    generateGlobalCustomerIdentificationReport(type: any, stateId: any, child: any){
      let itemSelected = {
          type: 'generate-global-customer-report',
          document: {
              name: this.translate.instant('concept-form.customer-identification') + ' ' + this.dateConverterService.convertDate(this.dateConverterService.setTimezone(new Date()), 'all-with-second-db') + '.docx',
          },
          item: child.conceptForm.concept
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any) => {
          if(result.result ==='ok'){
            child.conceptForm.concept.documentation.documents.push(result.item);
            child.conceptForm.concept.documentation.totalDocuments++;
            this.changeStateConcept(type, stateId, child);
          }
        })
    }

    changeStateConcept(type:any, newSateId:any, child:any){
        this.loader.openLoader('block-concept');
        this.apiService.patch('clients/state/' + child.conceptForm.concept.clientId, newSateId)
            .then((data:any)=>{
                if(data){
                  data.forEach((item:any)=> {
                    if(item.fieldTypeId === 'date'){
                        let newValueDate:any = newSateId === 3 || newSateId === 4? new Date(item.endRelation) : null;
                        this.broadcastService.broadcast('changeFieldFromConcept', {field: item, newValue: newValueDate, withOutSaves: true});
                    }else if(item.fieldTypeId === 'alert'){
                        let action:string = newSateId === 3 || newSateId === 4? 'add' : 'delete';
                        action === 'add'? child.conceptForm.concept.alerts++ : child.conceptForm.concept.alerts--;
                        this.broadcastService.broadcast('addOrDeleteAlertFromConcept', {field: item, newAlert: item.datealert, action: action});
                    }
                  });

                }
                this.openInfoClientModal(type, child);
            }, (errorDate:any)=>{
                this.loader.closeError();
            })
    }

    openInfoClientModal(type:any, child:any){
        switch(type){
            case 'lock':
                child.conceptForm.menuBlockClientShow = child.conceptForm.menuManageBlockClient;
                child.conceptForm.concept.clientStateId = 2;
                let text = this.translate.instant('concept-form.block-client-request').replace('{role}',  child.conceptForm.roleLevel2.name);
                this.modalService.adviceModal(text, 'error:accept:alert');
                break;
            case 'unlock':
            case 'reactivate':
                child.conceptForm.menuBlockClientShow = child.conceptForm.menuBlockClient;
                child.conceptForm.concept.clientStateId = 1;
                this.broadcastService.broadcast('changeEditableFields', {noEditable: false});
                if(type === 'unlock'){
                  this.modalService.adviceModal(this.translate.instant('concept-form.client-unlocked'), 'info:accept:check-round');
                }else{
                  this.saveSnapshot(this.translate.instant('concept-form.client-reactivated'), child);
                }
                break;
            case 'end-relationship':
                child.conceptForm.concept.clientStateId = 4;
                this.saveSnapshot(this.translate.instant('concept-form.info-confirmated-end-relationship'), child);
                break;
            case 'permanently-block':
                child.conceptForm.concept.clientStateId = 3;
                this.saveSnapshot(this.translate.instant('concept-form.client-not-supported'), child);
                break;
            case 'reassign':
                this.modalService.adviceModal(this.translate.instant('concept-form.reassgined-client-concept-ok'), 'info:accept:check-round');
                break;
        }
        this.configureOptionsConcept(child);
        this.loader.closeLoader(['block-concept', 'concept-form-save-concept']);

    }

    saveSnapshot(text: any, child: any){
      if(!child.fieldContainerId){
          let historicJson = this.snapShotsService.getConceptJson(child.conceptForm.concept);
          this.snapShotsService.saveForConcept(child.conceptForm.concept.conceptObjectId, historicJson)
            .then((data:any)=>{
                this.modalService.adviceModal(text, 'info:accept:check-round');
            }, (errorData:any)=> {})
      }
    }

    permanentlyBlock(type:any, modifiedConcept:any, child:any){
        let configurationModal = {
            from: type ==='permanently-block'?'clientConcept':'reactivateClient',
        }
        this.modalService.finishBlockModal(configurationModal).subscribe((result:any)=>{
          if(result.result === 'ok'){
            let newStateId = type ==='permanently-block'? 3 : 1 ;
            this.manageChangeState(type, newStateId, modifiedConcept, child);
          }
        })
    }

    configureOptionsConcept(child:any){
      let that: any = child.conceptForm;
        if(that.concept){
            that.conceptLockedState = that.concept.conceptNamedKey === 'Cliente' && (that.concept.clientStateId === 3 || that.concept.clientStateId === 4);
            that.lockedEvaluation = child.lockedDataEvaluation && that.concept.productId === 'blanqueo' && child.currentComponent === 'concept';
            that.lockedBlanqueoEvaluation = that.lockedEvaluation && (that.concept.conceptNamedKey === 'SujetoObligado' || that.concept.conceptNamedKey === 'Cliente' || that.concept.conceptNamedKey === 'Operacion');
            that.concept.saved = that.lockedBlanqueoEvaluation || that.conceptLockedState? true:that.concept.saved;
            that.conceptLocked = that.concept.sharedReadOnly || that.conceptLockedState || (that.concept.lockedEdit && that.concept.saved) || that.lockedBlanqueoEvaluation;// && that.nothingPrivateField);
            that.noPermissions = that.userPermissions === 'none';
            that.viewDesignConcept = that.concept.conceptObjectId === 'view';
            that.userPublicLocked = that.userPublic && that.concept.lockedEdit && that.concept.saved;
            that.nothingPrivateFieldLockedSave = that.nothingPrivateField && that.concept.lockedEdit && that.concept.saved;
            that.nothingPrivateFieldLocked = that.nothingPrivateField && that.concept.lockedEdit;
            that.showSaveButtons = !child.isLanding && !that.noPermissions && !that.viewDesignConcept && !that.concept.poll && !that.userPublicLocked && !that.nothingPrivateFieldLockedSave && that.productTypeId !== 4 && !that.lockedBlanqueoEvaluation; //&& !(that.concept.blockAfterSend && that.concept.saved)
            that.showClientButtons = !child.isLanding && that.concept.productId === 'blanqueo' && that.concept.conceptNamedKey === 'Cliente' && !that.viewDesignConcept && !that.userPublicLocked && !that.lockedBlanqueoEvaluation && (!that.nothingPrivateFieldLockedSave || that.conceptLockedState);
            that.showExitButton =  !child.isLanding && !that.conceptLockedState && !that.lockedEvaluation && (that.noPermissions || that.viewDesignConcept || that.concept.poll || that.userPublicLocked || that.nothingPrivateFieldLockedSave || that.lockedBlanqueoEvaluation) && child.currentComponent !== 'conceptWindow'; //&& (that.concept.blockAfterSend && that.concept.saved)
            that.showSaveButtonsCustom = child.isLanding && !that.conceptLockedState && !that.noPermissions && !that.viewDesignConcept && !that.userPublicLocked && !that.nothingPrivateFieldLocked && !that.concept.sharedReadOnly && that.productTypeId !== 4; //&& !(that.concept.blockAfterSend && that.concept.saved)
            that.showExitButtonCustom =  child.isLanding && !that.conceptLockedState && (that.noPermissions || that.viewDesignConcept || that.userPublicLocked || that.nothingPrivateFieldLocked || that.concept.sharedReadOnly); //&& (vm.concept.blockAfterSend && vm.concept.saved)
        }
        if(that.showClientButtons && !that.chargedRoles){
          this.getClientRoles(child);
        }
    }


    getClientRoles(child: any){
      this.apiService.get('productroles/product/' + child.concept.productId + '/client/' + child.concept.clientId + '/assignments/notempty')
        .then((data:any)=>{
          let configureRolesLevel = this.rolesUtilsService.configureRolesLevel(data);
          child.conceptForm.roleLevel2 = configureRolesLevel.roleLevel2;
          child.conceptForm.roleLevel3 = configureRolesLevel.roleLevel3;
          child.conceptForm.enabledButtonManageBlock = configureRolesLevel.enabledButtonManageBlock;
          child.conceptForm.rolesLevel = configureRolesLevel.rolesLevel;
          child.conceptForm.chargedRoles = true;
        }, (errorData:any)=> {})
  }
  ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
