import moment from 'moment';
import { Component, OnInit, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'establish-pattern-modal',
  templateUrl: './establish-pattern-modal.component.html'
})
export class EstablishPatternModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  establishPatternModal:any = {
    radioOptionDaily: '1',
    radioOptionWeekly: '1',
    radioOptionMonthly: '1',
    radioOptionAnnual: '1',
    newInstanceNameSuffix: 1,
    amountRepetitionsInterval: 10,
    periocityTypePredefined:'daily',
    finishIntervalDate: null,
    selectInterval: 'no-end-date',
    warningText: this.translate.instant('add-assignment.info-message'),
    periodicityType: {
      label: this.translate.instant('repeat-pattern.frecuency'),
      required: true
    },
    periodicityTypeSelected:{
        listValueId: 0
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private dateConverterService: DateConverterService,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal) { }

  @Input() item:any = null;
  @Input() repeatPattern:any = null;
  @Input() patternList:any = null;
  @Input() date:any = null;

  ngOnInit(): void {
    let that: any = this.establishPatternModal;
    that.repeatPattern = this.item.repeatPattern;
    that.patternList = this.patternList;
    that.frecuencyTitle = this.item.conceptId? this.translate.instant('repeat-pattern.instance-frecuency'):this.translate.instant('repeat-pattern.task-frecuency');
    that.repeatIntervalDate= {
      label: this.translate.instant('repeat-pattern.start-interval'),
      id: "repeat-inteval-date",
      noEditable: !(this.item.conceptId && that.repeatPattern === null),
      required: !!(this.item.conceptId)
    }

    if(that.repeatPattern?.frecuencyId){
        that.startInterval = new Date();
        that.infoText = this.translate.instant('repeat-pattern.modify-info');
        that.modifyPattern = true;
        this.configurePattern();
        this.extractInterval()
    }else{
        if(this.item.conceptId){
            that.newInstanceName = this.item.title;
        }
        if(this.date && this.date !== null){
            that.startInterval = this.dateConverterService.toDate(this.date);
            that.infoText = this.translate.instant('repeat-pattern.communication-modify-info');
        }else{
            that.startInterval = new Date();
        }
        this.selectFrecuency(1)

    }
    that.nameField={
        label: this.translate.instant('common.name'),
        required: true,
        noEditable: !!(this.item.conceptId && that.repeatPattern && that.repeatPattern.frecuencyId)
    }

    that.suffixField={
        label: this.translate.instant('repeat-pattern.id-series'),
        required: true,
        noEditable: !!(this.item.conceptId && that.repeatPattern && that.repeatPattern.frecuencyId)
    }

  }

  configurePattern(){
    let that: any = this.establishPatternModal
    let repeatPattern: any = that.repeatPattern

      this.selectFrecuency(repeatPattern.frecuencyId)
      if(this.item.conceptId){
          that.newInstanceName = repeatPattern.newInstanceName;
          that.newInstanceNameSuffix = repeatPattern.newInstanceNameSuffix;
      }
      switch(repeatPattern.frecuencyId){
          case 1:
              that.radioOptionDaily = repeatPattern.repeatOption.toString();
              if(repeatPattern.repeatOption === 1){
                  that.dailyEachDay = repeatPattern.repeatParam1;
              }
              break;
          case 2:
              that.radioOptionWeekly = repeatPattern.repeatOption.toString();
              that.weeklyEachWeek = repeatPattern.repeatParam1;
              this.extractWeekDays()
              break;
          case 3:
              that.radioOptionMonthly = repeatPattern.repeatOption.toString();
              if(repeatPattern.repeatOption === 1){
                  that.monthlyEachDay = repeatPattern.repeatParam1;
                  that.monthlyEachMonth = repeatPattern.repeatParam2;
              }else{
                  that.monthlyNumeralSelected = this.commonUtilsService.extractToArray(repeatPattern.repeatParam1, that.patternList.numeralTimeList, 'id');
                  that.monthlyWeekdaySelected = this.commonUtilsService.extractToArray(repeatPattern.repeatParam2, that.patternList.weekDayList, 'id');
                  that.monthlyEachMonthSecond = repeatPattern.repeatParam3;
              }
              break;
          default:
              that.radioOptionAnnual = repeatPattern.repeatOption.toString();
              if(repeatPattern.repeatOption === 1){
                  that.annualEachDay = repeatPattern.repeatParam1;
                  that.annualMonthSelected = this.commonUtilsService.extractToArray(repeatPattern.repeatParam2, that.patternList.monthList, 'id');
              }else{
                  that.annualNumeralSelected = this.commonUtilsService.extractToArray(repeatPattern.repeatParam1, that.patternList.numeralTimeList, 'id');;
                  that.annualWeekdaySelected =  this.commonUtilsService.extractToArray(repeatPattern.repeatParam2, that.patternList.weekDayList, 'id');
                  that.annualMonthSelectedSecond = this.commonUtilsService.extractToArray(repeatPattern.repeatParam3, that.patternList.monthList, 'id');
              }
              break;
      }
  }

  extractInterval(){
    let that: any = this.establishPatternModal
    that.selectInterval = 'no-end-date';
      if( that.repeatPattern.repeatTimes !== null){
          that.amountRepetitionsInterval = that.repeatPattern.repeatTimes;
          that.selectInterval = 'finish-after';
      }else if(that.repeatPattern.repeatUntil !== null){
          that.selectInterval = 'end-interval-day';
          that.finishIntervalDate = that.repeatPattern.repeatUntil
      }
  }

  extractWeekDays(){
      this.establishPatternModal.weekDaysSelected = this.establishPatternModal.repeatPattern.repeatParam2;
      this.patternList.weekDays.forEach((weekdays:any)=>{
        if(weekdays.bit && this.establishPatternModal.weekDaysSelected === weekdays.bit){
          weekdays.selected = true;
        }
      })
  }
  selectFrecuencyOn(e:any){
      this.establishPatternModal.periodicityTypeSelected = this.establishPatternModal.patternList.periodicityList[e.newValue - 1];
  }

  selectFrecuency(index:number){
      this.establishPatternModal.periodicityTypeSelected = this.establishPatternModal.patternList.periodicityList[index - 1];
    // this.configureFinishIntervalDate();
  }

    // configureFinishIntervalDate(){
  //   if(this.establishPatternModal.selectInterval !== 'end-interval-day'){
  //     let initInterval = cloneDeep(this.establishPatternModal.startInterval);
  //     switch(this.establishPatternModal.periodicityTypeSelected?.listValueId){
  //       case 1:
  //         this.establishPatternModal.finishIntervalDate = new Date(initInterval.setDate(initInterval.getDate()+9));
  //         break;
  //       case 2:
  //         this.establishPatternModal.finishIntervalDate = new Date(initInterval.setDate(initInterval.getDate()+63));//9*7
  //         break;
  //       case 3:
  //         this.establishPatternModal.finishIntervalDate = new Date(initInterval.setMonth(initInterval.getMonth()+9));
  //         break;
  //       case 4:
  //         this.establishPatternModal.finishIntervalDate = new Date(initInterval.setFullYear(initInterval.getFullYear()+9));
  //         break;
  //     }
  //   }
  // }

  setNumber(type:string, e:any){
    this.establishPatternModal[type] = e.newValue;
  }

  selectOptionSelectSimple(type:string, e:any){
    this.establishPatternModal[type] = e;
  }

  selectOption(type:string, e:any){
    this.establishPatternModal[type] = e.newValue;
  }

  addOrDeleteWeekDay(dayWeek:any){
    this.establishPatternModal.weekDaysSelected = 0;
      dayWeek.selected = !dayWeek.selected;
      this.establishPatternModal.patternList.weekDays.forEach((weekDay:any) => {
        if(weekDay.selected){
          this.establishPatternModal.weekDaysSelected += weekDay.bit
        }
      });
  }

  selectIntervalDate(e:any, type:string){
      this.establishPatternModal[type] = e.date;
  }

  validateRepetition(){
      let that: any = this.establishPatternModal;
      that.modifyPattern = false;
      that.periodicityType.error = false;
      that.repeatIntervalDate.error = false;
      that.nameField.error = false;
      that.suffixField.error = false;
      that.hasError = false;
      that.errorText ='';
      if(!that.periodicityTypeSelected){
          that.hasError = true;
          that.periodicityType.error = true;
          that.errorText = this.translate.instant('repeat-pattern.error-periodicity');
      }else if(that.periodicityTypeSelected.listValueId === 2 && !that.weekDaysSelected ){
          that.hasError = true;
          that.errorText = this.translate.instant('repeat-pattern.error-pattern');
      }else if(that.periodicityTypeSelected.listValueId === 3 && that.radioOptionMonthly === "2"){
          if(that.monthlyWeekdaySelected.id === 3 && that.monthlyNumeralSelected.id > 2 ){
              that.hasError = true;
              that.errorText = this.translate.instant('repeat-pattern.error-pattern');
          }
      }else if( that.periodicityTypeSelected.listValueId === 4 && that.radioOptionAnnual === "2" ){
          if( that.annualWeekdaySelected.id === 3 && that.annualNumeralSelected.id > 2){
              that.hasError = true;
              that.errorText = this.translate.instant('repeat-pattern.error-pattern');
          }
      }else if(that.periodicityTypeSelected.listValueId === 4 && that.radioOptionAnnual === "1"){
          if(that.annualEachDay > 29 && that.annualMonthSelected.id === 2 || that.annualEachDay > 30 && that.annualMonthSelected.id === 4 || that.annualEachDay > 30 && that.annualMonthSelected.id === 6 || that.annualEachDay > 30 && that.annualMonthSelected.id === 9 || that.annualEachDay > 30 && that.annualMonthSelected.id === 11){
              that.hasError = true;
              that.errorText = this.translate.instant('repeat-pattern.error-pattern');
          }
      }
      if(!that.hasError){
          if(moment(moment(that.startInterval).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))){
              that.hasError = true;
              that.repeatIntervalDate.error = true;
              that.errorText = this.translate.instant('repeat-pattern.error-init-interval');
          }else if(that.startInterval === null){
              that.hasError = true;
              that.repeatIntervalDate.error = true;
              that.errorText = this.translate.instant('repeat-pattern.error-init-interval');
          }else if(moment(moment(that.startInterval).format('YYYY-MM-DD')).isAfter(moment(that.finishIntervalDate).format('YYYY-MM-DD'))){
              that.hasError = true;
              that.errorText = that.errorText ===''? this.translate.instant('repeat-pattern.error-interval') : this.translate.instant('repeat-pattern.error-both');
          }
      }

      if(!that.hasError){
          if(this.item.conceptId){
              if(!that.newInstanceName || that.newInstanceName === ''){
                  that.hasError = true;
                  that.nameField.error = true;
                  that.errorText = this.translate.instant('repeat-pattern.error-name');
              }else if(!that.newInstanceNameSuffix){
                  that.hasError = true;
                  that.suffixField.error = true;
                  that.errorText = this.translate.instant('repeat-pattern.error-suffix');
              }
          }
      }
      if(!that.hasError){
          this.getNextRepeat();
      }
  }

  getNextRepeat(){
      let repeatPattern = this.generateRepeatPattern()
      this.apiService.add('repeatpattern/next-repeat', repeatPattern)
      .then((data:any)=>{
          if(moment(moment().format('YYYY-MM-DD')).isAfter(moment(data.nextRepeat).format('YYYY-MM-DD'))){
              this.establishPatternModal.hasError = true;
              this.establishPatternModal.errorText = this.translate.instant('repeat-pattern.repetition-error');
          }else{
              this.checkRestOfErrors()
          }

      }, (errorData:any)=>{})
  }

  generateRepeatPattern(){
      let repetition = this.getRepetition()
      let pattern ={
          frecuencyId: this.establishPatternModal.periodicityTypeSelected.listValueId,
          repeatStart: this.establishPatternModal.startInterval,
          repeatOption:  repetition.repeatOption,
          repeatTimes: null,
          repeatUntil: null,
          repeatParam1: null,
          repeatParam2: null,
          repeatParam3: null
      }
      this.setMomentCreation(pattern, this.establishPatternModal.periodicityTypeSelected.listValueId, pattern.repeatOption, repetition.repeatParams)
      return pattern;
  }

  setMomentCreation(pattern:any, frecuency:any, repeatOption:any, params:any){
      switch(frecuency){
          case 1:
              if(repeatOption === 1){
                  pattern.repeatParam1 = params.repeatParam1;
              }
              break;
          case 2:
              pattern.repeatParam1 = params.repeatParam1;
              pattern.repeatParam2 = params.repeatParam2;
              break;
          case 3:
              if(repeatOption === 1){
                  pattern.repeatParam1 = params.repeatParam1;
                  pattern.repeatParam2 = params.repeatParam2;

              }else{
                  pattern.repeatParam1 = params.repeatParam1
                  pattern.repeatParam2 = params.repeatParam2
                  pattern.repeatParam3 = params.repeatParam3
              }
              break;
          default:
              if(repeatOption === 1){
                  pattern.repeatParam1 = params.repeatParam1;
                  pattern.repeatParam2 = params.repeatParam2;
              }else{
                  pattern.repeatParam1 = params.repeatParam1
                  pattern.repeatParam2 = params.repeatParam2
                  pattern.repeatParam3 = params.repeatParam3
              }
              break;
      }
  }

  checkRestOfErrors(){
    let that:any = this.establishPatternModal
      if(that.periodicityTypeSelected.listValueId === 3 && that.radioOptionMonthly === "1" && parseInt(that.monthlyEachDay) > 28){
          this.modalService.adviceModal( this.translate.instant('task-form.advice-interval-info-first') + that.monthlyEachDay + this.translate.instant('task-form.advice-interval-info-second'), 'info:question:question').subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.handleAccept();
            }
          });
      }else{
          this.handleAccept();
      }
  }

  itemSelected(item:any, e:any){
      item = e.value;
  }

  getRepetition(){
    let that: any = this.establishPatternModal
      switch(that.periodicityTypeSelected.listValueId){
          case 1:
              if(that.radioOptionDaily === "1"){
                  return {
                      repeatOption: 1,
                      repeatParams: {
                          repeatParam1: that.dailyEachDay
                      }
                  }
              }else{
                  return {
                      repeatOption: 2,
                      repeatParams: null
                  }
              }
          case 2:
              return {
                  repeatOption: 1,
                  repeatParams: {
                      repeatParam1: that.weeklyEachWeek,
                      repeatParam2: that.weekDaysSelected
                  }
              }
          case 3:
              if(that.radioOptionMonthly === "1"){
                  return  {
                      repeatOption: 1,
                      repeatParams: {
                          repeatParam1: that.monthlyEachDay,
                          repeatParam2: that.monthlyEachMonth
                      }
                  }
              }else{
                  return  {
                      repeatOption: 2,
                      repeatParams: {
                          repeatParam1: that.monthlyNumeralSelected.id,
                          repeatParam2: that.monthlyWeekdaySelected.id,
                          repeatParam3: that.monthlyEachMonthSecond,
                      }
                  }
              }
          default:
              if(that.radioOptionAnnual === "1"){
                  return  {
                      repeatOption: 1,
                      repeatParams: {
                          repeatParam1: that.annualEachDay,
                          repeatParam2: that.annualMonthSelected.id
                      }
                  }
              }else{
                  return  {
                      repeatOption: 2,
                      repeatParams: {
                          repeatParam1: that.annualNumeralSelected.id,
                          repeatParam2: that.annualWeekdaySelected.id,
                          repeatParam3: that.annualMonthSelectedSecond.id,
                      }
                  }
              }
      }
  }

  closeAdvice(type:string){
    this.establishPatternModal[type] = false;
  }

  handleAccept() {
    let that: any = this.establishPatternModal
      let interval = {
          startInterval: that.startInterval,
          finishIntervalDate: that.finishIntervalDate,
          selectInterval: that.selectInterval,
          repeatTimes: that.selectInterval === 'finish-after'? that.amountRepetitionsInterval: null
      }


    this.activeModal.close({
        result: 'ok',
        frecuency: that.periodicityTypeSelected,
        repetition: this.getRepetition(),
        interval: interval,
        newInstanceName: that.newInstanceName,
        newInstanceNameSuffix: that.newInstanceNameSuffix

    })
  }

  handleDismiss(reason:any) {
    this.activeModal.close({result: reason});
  }
}
