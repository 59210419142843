import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service/api-service.service';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { cloneDeep } from 'lodash';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {

  constructor(
    private apiProperties: ApiPropertiesFactory,
    private context: ContextFactory
  ) {}

  setDataWhenZero(data:any, sharedPublicConceptConfigurationId: number){
    data.push({
      language: this.apiProperties.defaultLanguage,
      numOrder: 1,
      sharedPublicConceptConfigurationId: sharedPublicConceptConfigurationId,
      text: '',
    })
  }

  setListLanguages(list:any = []){
    let that:any = {
      languagesSelected: [],
      appLanguages: cloneDeep(this.context.settings.languagesApp),
      languageSelected: {}
    }
    list.forEach((selectedLanguage:any)=>{
      let textDefault = selectedLanguage.language === this.apiProperties.defaultLanguage;
      this.selectListLanguagesSelected(selectedLanguage.language, textDefault, selectedLanguage.text, that);
    }) 
    return that;
  }

  selectListLanguagesSelected( selectedLanguage:string, textDefault:boolean, textDescription:string, that:any){
    for(let i = 0; i < that.appLanguages.length; i++){
        let language = that.appLanguages[i];
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage){
            language.selected = true;
            language.textDescription = textDescription;
            language.oldText = textDescription;
            language.defaultOption = Boolean(textDefault);
            language.active = Boolean(language.defaultOption);
            that.languagesSelected.push(language);
            that.chargedLiteralDefault = true;
            if(language.active){
              that.languageSelected = language;
            }
            break;
        }
    }
  }
  changeActiveLanguagesSelected(selectedLanguage:any,addSelected:any, that:any, type:any){
    that[`${type}LanguagesSelected`].forEach((language:any) => {
        if(language.active){
            language.textDescription = that[`${type}DataTreatment`].description;
            language.active = false;
        }
    });
    selectedLanguage.active = true;
    that.errorActive = selectedLanguage.active && selectedLanguage.error;
    that[`${type}DataTreatment`].description = selectedLanguage.textDescription?selectedLanguage.textDescription:'';
    that[`${type}DataTreatment`].error = selectedLanguage.error;
    if(addSelected){
        that[`${type}LanguagesSelected`].push(selectedLanguage);
    }
  }
  removeActiveLanguage(selectedLanguage:any, that:any, type:any){
    for(let i = that[`${type}LanguagesSelected`].length-1; i >= 0; i--){
      let language = that[`${type}LanguagesSelected`][i];
      if(language.listValueId === selectedLanguage.listValueId){
          that[`${type}LanguagesSelected`].splice(i, 1);
          break;
      }
    }
    if(selectedLanguage.active){
      let newSelected = that[`${type}LanguagesSelected`][that[`${type}LanguagesSelected`].length-1];
      newSelected.active = true;
      that[`${type}DataTreatment`].description = newSelected.textDescription;
      that[`${type}DataTreatment`].error = newSelected.error;
      that.errorActive = newSelected.error;
    }
    that[`${type}LanguagesApp`][selectedLanguage.indexListLanguage].selected = false;
  }

  selectLanguage(selectedLanguage:any, that:any, type:any, textDefault?:any, ){
    for(let i = 0; i < that[`${type}LanguagesApp`].length; i++){
        let language = that[`${type}LanguagesApp`][i];
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage){
            language.selected = true;
            language.textDescription = textDefault?that[`${type}DataTreatment`].description:'';
            language.defaultOption = textDefault?true:false;
            let newSelectedLanguage = cloneDeep(language);
            this.changeActiveLanguagesSelected(newSelectedLanguage, true, that, type);
            break;
        }
    }
  }

  notSelectLanguage(language:any, that:any, type:string){
    for(let languageSelected of that[`${type}LanguagesSelected`]){
      if(language.listValueId === languageSelected.listValueId){
          return false;
      }
    }
    return true;
  }

  checkLanguages(that:any, withErrors:boolean, type:string){
    let configuration: any ={
      errors: 0,
      listSelectedLanguages: []
    }
   
    that[`${type}LanguagesSelected`].forEach((language:any, index: any) => {
      if(language.active){
          language.textDescription = that[`${type}DataTreatment`].description;
      }
      if(withErrors){
        language.error = !language.textDescription;
        that.errorActive = language.active && language.error;
        configuration.errors += Number(language.error);
      }
      if(configuration.errors === 0){
        configuration.listSelectedLanguages.push({
          sharedPublicConceptConfigurationId: that.company.sharedPublicConceptConfigurationId,
          language: language.appLanguage,
          numOrder: index+1,
          text: language.textDescription
        })
      }
    });
    if(withErrors){
      if(!that[`${type}DataTreatment`].description){
        configuration.errors++;
      }
    }
    return configuration;
  }

}
