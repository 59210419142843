import { Component, Input, OnInit} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'report-graph',
  templateUrl: './report-graph.component.html'
})
export class ReportGraphComponent implements OnInit {
  reportGraph:any = {
    reportsState:{
      original:[]
    },
    reportsInvestigation:{
      original:[]
    },
    areaTitle: this.translate.instant('dashboard-reports.areas-concerned'),
    stateLoad: false,
    investigationLoad: false,
    informationTypology: false,
    donutOptions: {
      subType: 1,
      type: 'poll'
    },

    esgHeatMapData: [],
    hourStates:[
      {
          days: '00',
          hours: '20',
          minutes: '22',
          color: '#C43741'
      },{
          days: '15',
          hours: '23',
          minutes: '07',
          color: '#F1BC3E'
      },{
          days: '00',
          hours: '00',
          minutes: '00',
          color: '#F1923E'
      },{
          days: '00',
          hours: '17',
          minutes: '11',
          color: '#77D166'
      }
    ]

  }
  constructor(private apiService: ApiServiceService,
    private reportVersion: ReportVersionFactory,
    private translate: AfTranslateFactory) { }

  @Input() report: any = null;
  @Input() configurateReport: any = null;
  @Input() showResidualOption: Boolean = false;
  @Input() filterColumns: any = [];

  ngOnInit(): void {
    let that: any = this.reportGraph;
    that.configurateReport = this.configurateReport;
    that.report = this.report;
    that.actualVersion = this.report.reportId === this.reportVersion.folderReportVersion || this.report.reportId === this.reportVersion.dashboardReportVersion;
    if(that.report.reportId === 197 || that.actualVersion){
      this.getReportState();
      this.getReportInvestigation();
      this.getReportTypology();
    }else if(that.report.reportId === 205 || that.report.reportId === 206 || that.report.reportId === 207){
      this.callEsgHeatMaps(1);
    }else{
      if(that.configurateReport.type === 4){
          this.getGraphtsTaskInfo();
          this.getGraphtsTaskAnualInfo();
          this.getDonoughtGraphInfo();
      }else {
          if(that.report.reportId === 180){
            this.getDrawHeatMapResidualInfo();
            this.getDonoughtGraphResidualInfo();
          }
          this.getDrawHeatMapInfo();
          this.getDonoughtGraphInfo();
      }
    }
  }

  getReportState(){
    this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/1`, this.filterColumns)
    .then((data)=>{
      data.forEach((item:any) => {
        item.label = item.state;
        item.count = item.complaints;
       });
       this.reportGraph.reportsState = this.configureReportData(data);
       this.reportGraph.stateLoad = true;
    }, (errorData)=>{})
  }

  getReportInvestigation(){
    this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/2` , this.filterColumns)
      .then((data)=>{
        this.reportGraph.reportsInvestigation.original = this.configureInvestigationData(data);
        this.reportGraph.reportsInvestigation.data = data;
        this.reportGraph.investigationLoad = true;
      }, (errorData)=>{})
  }

  getReportTypology(){
    this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/3` , this.filterColumns)
    .then((data)=>{
      data.forEach((item:any) => {
        item.label = item.tipology;
        item.answers = item.complaints;
        item.percentage = item.complaintsPerc;
       });
       this.reportGraph.informationTypologyData = this.configureReportData(data);
       this.reportGraph.informationTypology = true;
    }, (errorData)=>{})
  }




  callEsgHeatMaps(index:any){
    this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/${index}` , this.filterColumns)
    .then((data:any)=>{
        this.reportGraph.esgHeatMapData.push({
          graph: data,
          title: this.getEsgTitle(index),
          xAxxis:this.getXAxxis(index),
          yAxxis: this.getYAxxis(index)
        });
        index++
        if(index < 5){
          this.callEsgHeatMaps(index)
        }
    })
  }

  getEsgTitle(index:number){
    switch(index){
      case 1:
        return this.translate.instant('report-graph.risk-material-incident');
      case 2:
        return this.translate.instant('report-graph.stakeholder-impact');
      case 3:
        return this.translate.instant('report-graph.risk-impact');
      default:
        return this.translate.instant('report-graph.opportunity-impact');
    }
  }

  getXAxxis(index:number){
    switch(index){
      case 1:
        return this.translate.instant('report-graph.axxis-magnitude');
      default:
        return this.translate.instant('report-graph.axxis-probability');
    }
  }

  getYAxxis(index:number){
    switch(index){
      case 1:
      case 2:
        return this.translate.instant('report-graph.axxis-severite');
      default:
        return this.translate.instant('report-graph.axxis-magnitude');
    }
  }

  getDrawHeatMapInfo(){
      this.apiService.get('reportobjects/risksmapchart',this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphData = {
                graph: data,
                xAxxis: this.translate.instant('report-graph.axxis-impact'),
                yAxxis: this.translate.instant('report-graph.axxis-probability')
              }

          })
  }

  getDonoughtGraphInfo(){
      this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/donoughtgraphic`, this.filterColumns)
          .then((data:any)=>{
              this.reportGraph.otherGraphsData = this.configureData(data);
          })
  }

  getDrawHeatMapResidualInfo(){
      this.apiService.get('reportobjects/risksmapchartresidual',this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphResidualData ={
                graph: data,
                graphicId: 'residual',
                xAxxis: this.translate.instant('report-graph.axxis-impact'),
                yAxxis: this.translate.instant('report-graph.axxis-probability')
              }

          })
  }

  getDonoughtGraphResidualInfo(){
      this.apiService.add(`reportgraphics/${this.reportGraph.report.reportObjectId}/1`, this.filterColumns)
          .then((data:any)=>{
              this.reportGraph.otherGraphsResidualData = this.configureData(data);
              this.reportGraph.otherGraphsResidualData.graphicId = 'residual'
          })
  }

  getGraphtsTaskInfo(){
      this.apiService.get('reportobjects/type4graphic1', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphTaskData = [];
              this.reportGraph.graphTaskData.titles = [];
              this.reportGraph.graphTaskData.tipology = data;
              data.forEach((item:any) => {
                this.reportGraph.graphTaskData.titles.push(item.typology)
              });
          })
  }

  getGraphtsTaskAnualInfo(){
      this.apiService.get('reportobjects/type4graphic3', this.reportGraph.report.reportObjectId)
          .then((data)=>{
              this.reportGraph.graphTaskAnualData=[];
              this.reportGraph.graphTaskAnualData.month=data;
          },(error:any)=>{})
  }

  configureInvestigationData(data:any){
    let original:any = []
    for(let i = 0; i < data.length; i++){
      let amount:number = 0
      let amountPercent: number = 0
      let compliants: any = data[i].compliants;
      for(let j = 0; j < compliants.length; j++){
        amount+= compliants[j].complaints
        amountPercent += compliants[j].complaintsPerc
      }
      original.push({
        label: data[i].validation,
        color: '#00ff00',
        count: amount,
        complaints: compliants,
        complaintsPerc: amountPercent
      })
    }
    return original
  }

  configureReportData(data:any){
    let dataGraph:any = {
        data:[],
        colors:[],
        legend:[],
        amount: 0
    }
    data.forEach((item: any) => {
      dataGraph.amount+= item.count;
      dataGraph.colors.push(item.color);
      dataGraph.data.push(item.count);
      dataGraph.legend.push(item.label)
    });
    dataGraph.original = data;
    dataGraph.graphicCaption = this.translate.instant('dashboard-reports.num-complaints');
    dataGraph.legendCaption = data.legendCaption;
    return dataGraph;
  }

  configureData(data:any){
    let item: any={
          data:[],
          colors:[],
          legend:[],
          amount: 0
      }
      data.sourceData.forEach((sourceData:any) => {
        if(sourceData.count){
          item.amount+= sourceData.count;
          item.colors.push(sourceData.color);
          item.data.push(sourceData.count);
      }
      });

      item.original = data.sourceData;
      item.graphicCaption = data.graphicCaption;
      item.legendCaption = data.legendCaption;
      return item;
  }

  saveAsPng(tooltip: MatTooltip, graph: string){
    let documentObject:any = document;
    let that = this;
    that.reportGraph.disabledSaveAsPng = true;
    tooltip.hide();
    setTimeout(() => {
      html2canvas(documentObject.querySelector(graph))
        .then(function(canvas) {
            canvas.toBlob((blob:any)=>{
                let data = [new ClipboardItem({ [blob.type]: blob })];
                navigator.clipboard.write(data)
                    .then(() => {}, (err) => console.log(err));
            });
      }).then(() => {
        that.reportGraph.disabledSaveAsPng = false;
        tooltip.message = this.translate.instant('results-report.image-captured');
        tooltip.show();
        setTimeout(() => {
          tooltip.message = this.translate.instant('results-report.capture-image');
          tooltip.hide();
        }, 2000);
      });
    }, 100);
  }

}
