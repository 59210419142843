<div class="upload-document">
  <div class="common__block-icon-close-modal-custom">
      <button class="lf-icon-close-round common__icon-close-modal-custom"
          (click)="handleDismiss()"></button>
  </div>
  <div class="common__modal-header-block common__modal-header-block--custom-margin"
      *ngIf="type!=='evaluationShare'"
      [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}">
      <i class="lf-icon-up-file common__icon-header common__icon-header--custom" ></i>
      <span class="common__text-header common__text-header--custom"
          [afTranslate]="'up-document.file'"></span>
  </div>
  <div [ngShow]="uploadDocuments.viewOption === 'upload'"
      class="upload-document__modal-content-block-custom"
      [ngClass]="{'upload-document__modal-content-block-custom--with-list': uploadDocuments.showProcess}">
      <field-type-list-simple
          *ngIf="uploadDocuments.showProcess && type !== 'dropFiles' && !uploadDocuments.updating"
          [field]="uploadDocuments.processSteps"
          [listValues]="listSelect"
          [preSelectItem]="uploadDocuments.stateId"
          (itemSelected)="itemSelectedList($event, 'stateId')">
      </field-type-list-simple >
      <div class="upload-document__upload-box-custom"
          [ngShow]="type !== 'dropFiles'"
          dropZone
          (fileDropped)="dropNewDocument($event)">
          <div id="upload-document-dropzone">
              <i class="lf-icon-drag-drop upload-document__drop-icon-custom"></i>
              <p class="upload-document__dropzone-text-custom"
                  [afTranslate]="'documentation-list.dropzone-info-upload-modal'">Arrastra aquí tus documentos</p>
          </div>
      </div>
      <div class="upload-document__explore-box-custom">
          <button class="upload-document__button-explore-box-custom"
              (click)="openFileSearch()">
              <i class="lf-icon-search upload-document__icon-search-explore-custom"></i>
              <span [afTranslate]="'documentation-list.explore-pc'"></span>
          </button>
          <input class="upload-document__file-upload"
              id="fileDropRef"
              type="file"
              multiple
              (change)="loadFiles($event)">
      </div>
      <div class="upload-document__documentation-block-custom">
          <div class="upload-document__documentation-header-custom">
              <p class="upload-document__documentation-title-custom"
                  [afTranslate]="'up-document.select-document'">_Documentos seleccionados</p>
              <span class="upload-document__documentation-icon-custom"
                  [ngShow]="uploadDocuments.upDocuments.length">{{uploadDocuments.upDocuments.length}}</span>
          </div>
          <ul class="upload-document__documentation-list-custom">
              <li *ngFor="let document of uploadDocuments.upDocuments; let index = index"
                  class="upload-document__documentation-item-custom"
                  [ngClass]="{'upload-document__documentation-item-custom--updating': uploadDocuments.updating || document.status==='validate-error',
                      'upload-document__documentation-item-custom--error': document.status==='error' || document.status==='validate-error'}">
                  <div class="upload-document__block-element-list-custom">
                      <div class="upload-document__element-list-custom">
                          <i class="{{document.source | extensionDocument}} upload-document__documentation-icon-type-custom"></i>
                          <div class="common__cell-block upload-document__documentation-document-name-custom">
                              <parragraph-tooltip [text]="document.name"></parragraph-tooltip>

                          </div>
                          <span class="upload-document__documentation-document-size-custom">{{document.size | convertBytes}}</span>
                      </div>
                      <button class="lf-icon-trash upload-document__documentation-menu-options-button-custom upload-document__documentation-menu-options-button-custom--delete"
                        [ngShow]="uploadDocuments.complete"
                        (click)="deleteUploadDocument(document)"></button>
                      <button class="lf-icon-trash upload-document__documentation-menu-options-button-custom upload-document__documentation-menu-options-button-custom--delete"
                          [ngShow]="!uploadDocuments.complete"
                          (click)="deleteDocument(index)"></button>
                      <button class="lf-icon-rename upload-document__documentation-menu-options-button-custom upload-document__documentation-menu-options-button-custom--delete"
                          [ngShow]="!uploadDocuments.complete"
                          (click)="renameDocument(document)"></button>
                  </div>
                  <p class="upload-document__progress-upload-text-custom upload-document__progress-upload-text-custom--error"
                      [ngShow]="document.status==='validate-error'">{{document.statusText}}</p>
                  <div [ngShow]="uploadDocuments.updating"
                      class="upload-document__background-upload-block-custom">
                      <div class="upload-document__progress-upload-block-custom"
                          [ngStyle]="{'width': document.progress+'%'}"
                          [ngClass]="{'upload-document__progress-upload-block-custom--complete': document.status==='complete', 'upload-document__progress-upload-block-custom--upload-server': document.status==='upload', 'upload-document__progress-upload-block-custom--error':document.status==='error' || document.status==='validate-error'}"></div>
                  </div>
                  <p class="upload-document__progress-upload-text-custom"
                      [ngClass]="{'upload-document__progress-upload-text-custom--error':document.status==='error' || document.status==='validate-error'}"
                      [ngShow]="uploadDocuments.updating">
                      <span [ngHide]="document.status==='error' || (document.progress=== 100 && document.status!== 'complete')">{{document.progress}}%</span>
                      <span [ngShow]="document.status==='error'">{{document.statusText}}</span>
                  </p>
              </li>
          </ul>
          <advice-message [ngShow]="uploadDocuments.newConceptLanding && uploadDocuments.upDocuments.length"
              [icon]="'information'"
              [type]="'info'"
              [text]="uploadDocuments.textInfo">
          </advice-message>
      </div>
      <div class="upload-document__block-textarea upload-document__block-textarea--landing-custom"
          *ngIf="type === 'addNewDocumentsFromTasks' && !uploadDocuments.updating">
          <div class="upload-document__block-textarea-head upload-document__block-textarea-head--landing-custom">
              <span [afTranslate]="'attach-document.add-comment'">Añadir comentario</span>
          </div>
          <i class="lf-icon-expand common__input-icon common__input-icon-active--color-landing-custom"
              (click)="openTextareaModal()"></i>
          <textarea class="upload-document__add-comment upload-document__add-comment--landing-custom"
              [(ngModel)]="uploadDocuments.comments">
          </textarea>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal-landing-custom upload-document__buttons-block"
      *ngIf="type!=='evaluationShare'">
      <button type="reset"
          [ngShow]="uploadDocuments.complete"
          class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
          [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
          (click)="handleAccept()"
          [afTranslate]="'common.ok'">Aceptar</button>
      <button type="reset"
          [ngHide]="uploadDocuments.updating"
          class="common__button-landing-custom common__button-landing-custom--cancel"
          [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
          (click)="handleDismiss()"
          [afTranslate]="'common.discard'">Descartar</button>
      <button type="submit"
          [ngHide]="uploadDocuments.updating"
          class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
          [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
          [disabled]="!uploadDocuments.validateDocument"
          (click)="uploadFiles()"
          [afTranslate]="'common.add'">Añadir</button>
  </div>
</div>
