import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { SharepointLogoutService } from '@shared/services/sharepoint-logout/sharepoint-logout.service';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { DomSanitizer } from '@angular/platform-browser';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { OpenChatFactory } from '@shared/factories/open-chat/open-chat.factory';

@Component({
  selector: 'header-nav',
  templateUrl: './header-nav.component.html'
})
export class HeaderNavComponent implements OnInit, OnDestroy {
    subscribers: any = {};
    skin: string =  this.globalCfg.skin;
    productsHub: boolean = this.globalCfg.caps.productsHub;
    headerNav:any ={
        logoUser: '',
        name: null,
        videoconferences: false,
        hiddenLanguage: false,
        amountNotifications: 0,
        bell: '?',
        comment: '?',
        preview: !(this.context.user && this.context.user.companyId),
        selectedLanguage: {},
        languages: []
    }
    constructor(
      private sanitizer:DomSanitizer,
      private globalCfg:GlobalCfgFactory,
      private context:ContextFactory,
      private loader:LoaderFactory,
      private apiService:ApiServiceService,
      private route:ActivatedRoute,
      private commonUtilsService:CommonUtilsService,
      private router:Router,
      private broadcast:BroadcastService,
      private translate:AfTranslateFactory,
      private conceptState:ConceptStateFactory,
      private apiProperties:ApiPropertiesFactory,
      private modalService:ModalServiceService,
      private stateManagement:StateManagementFactory,
      private registerService:RegisterMixtapeService,
      private broadcastService: BroadcastService,
      private sharepointLogoutService: SharepointLogoutService,
      private openChat: OpenChatFactory) { }
    @Input() changeShow:any;
    @ViewChildren(NgbDropdown)
    dropdowns: QueryList<NgbDropdown>

    ngOnInit(): void {
        this.configInit();
    }

    configInit(){
      this.launchWatchers();
      this.configureOptions();
      this.getTranslate();
    }

    launchWatchers(){
      let that = this;
      this.subscribers.exitConceptHeaderNavBroadcast = this.broadcastService.subscribe('exitConceptHeaderNav', (data:any)=>{
        switch(data.configuration.action){
            case 'goToDashboard':
                this.goToDashboard();
                break;
            case 'toSearch':
                this.toSearch();
                break;
            case 'reload':
                window.location.reload();
                break;
            case 'logOut':
                setTimeout(()=>{
                    that.sharepointLogoutService.logout();
                    that.context.logout();
                    that.router.navigate(['login']);
                    
                }, 1000)
                this.openChat.logOut()
                break;
            case 'goToUrl':
                this.goTo(data.configuration);
                break;
        }
      });
      this.subscribers.getHeaderNotificationsBroadcast = this.broadcastService.subscribe('getHeaderNotifications', (data:any)=>{
          this.getNotifications();
      });
    }

    configureOptions(){
        //this.dashboardPanel.conceptObjectId = $state.params.conceptObjectId;
        if(this.context.user.canUseJitsi && this.globalCfg.caps.videoconferences){
            this.headerNav.videoconferences = true;
        }
        if(this.route.snapshot.params['text'] && this.route.snapshot.params['text'] !== ''){
            this.headerNav.searchInDashboard = this.route.snapshot.params['text'];
        }else{
            this.headerNav.searchInDashboard = '';
            // this.route.snapshot.params['text'] = '';
        }
        this.headerNav.text = this.route.snapshot.params['text'];
        this.headerNav.state = this.route.snapshot.data['componentName'];
        this.headerNav.logoHeader =  this.commonUtilsService.configureLogoHeader();
        this.configureLogoUser();
        this.onFullNameChange(this.context.user.fullName);
        if (!this.headerNav.preview) {
            this.getNotifications();
            this.getComments();
        }
    }

    configureLogoUser(){
      if(this.context.user.companyId && this.globalCfg.caps.userLogo) {
          this.apiService.getBlob('frontusers/logo', undefined, undefined, undefined)
              .then((data:any) => {
                  if (data.blob.size !== 0) {
                      let url = URL.createObjectURL(data.blob);
                      this.headerNav.logoUser = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                  }
              },(errorData:any)=>{console.log(errorData)});
      }
    }

    onFullNameChange(value:any) {
        this.headerNav.name = value;
        this.headerNav.initialsName = this.getInitials(this.headerNav.name);
    }

    getInitials(nameUser:any){
        let initials = nameUser.split(" ");
        if(initials.length===1){
            this.headerNav.logoInitials = true;
            return initials[0].substr(0,2);
        }else{
            return initials[0].substr(0,1) + initials[1].substr(0,1);
        }
    }

    getNotifications() {
        // this.apiService.get('notifications', 'notifiedtome-test')//Para hacer pruebas con notificaciones
        this.apiService.get('notifications', 'notifiedtome')
            .then((data:any) => {
                this.headerNav.notifications = this.showLastNotificacions(data);
            },(errorData:any)=>{console.log(errorData)});
    }

    showLastNotificacions(notifications:any){
        this.headerNav.amountNotifications = 0;
        let now:any = new Date();
        for(let i = 0; i < notifications.length; i++){
            let notification:any = notifications[i];
            let date:any = new Date(new Date(notification.date).getFullYear(),new Date(notification.date).getMonth(), new Date(notification.date).getDate(), 0, 0, 0);
            let delta = Math.round(Math.abs(now - date) / 1000);
            notification.showNotification = (delta <= (60 * 60 * 24 * 7));
            notification.breadcrumb = notification.title;
            this.setTypeIcon(notification);
            if(notification.showNotification){
                this.headerNav.amountNotifications++;
            }
        }
        return notifications;
    }

    setTypeIcon(notification:any){
        switch(notification.source){
            case 'Meeting':
                notification.icon ="lf-icon-meeting";
                break;
            case 'Document':
                notification.icon = "lf-icon-documentation";
                break;
            case 'Date':
                notification.icon = "lf-icon-calendar-check";
                break;
            case 'Task':
                notification.icon ="lf-icon-task-validate";
                break;
            case 'Process':
                notification.icon = "lf-icon-process";
                break;
            default:
                break;
        }
    }
    getComments() {
        this.apiService.get('company/dashboard', 'comments')
            .then((data:any) => {
                this.headerNav.comments = data;
            },(errorData:any)=>{});
    }

    getTranslate(){
      if(this.headerNav.languages.length === 1 || this.skin === 'uk-steel-blue' || this.skin === 'icam-red'){
          this.headerNav.hiddenLanguage = true;
      }else{
          this.headerNav.languages = this.commonUtilsService.configureAvailableLanguages(this.apiProperties.availableLanguages);
          this.context.settings.languagesApp = this.headerNav.languages;
          this.context.storeSettings();
          let language = sessionStorage.getItem('selectLanguage');
          if(language){
              this.headerNav.selectedLanguage = this.catchDefaultLanguaje(language);
          }else if(this.context.user.culture){
              this.headerNav.selectedLanguage = this.catchDefaultLanguaje(this.context.user.culture);
          }else{
              this.headerNav.selectedLanguage = this.catchDefaultLanguaje(null);
          }
      }
    }
    catchDefaultLanguaje(culture:any){
        let language = this.apiProperties.defaultLanguage;
        if(culture){
            language = culture;
        }
        for(let i = 0; i < this.headerNav.languages.length; i++){
            if(language === this.headerNav.languages[i].listValueId){
                return this.headerNav.languages[i];
            }
        }
    }

    logOut(){
      if(this.conceptState.getAmount() || this.conceptState.getProcess()){
          if(this.checkConceptComponent()){
            this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question').subscribe((result:any) => {
              if(result.result !== 'cancel'){
                if(result.result === 'save-exit'){
                    let configuration: any = {
                      from: 'headerNav',
                      action: 'logOut'
                    }
                    this.saveConcept(configuration);
                    this.conceptState.removeAll();
                }else{
                    this.conceptState.removeAll();
                    this.doLogout();
                }
              }
            });
          }else{
              this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question').subscribe((result:any) => {
                if(result.result !== 'cancel'){
                  this.conceptState.removeAll();
                  this.conceptState.setProcess(false)
                  this.doLogout();
                }
              });
          }
      }else{
          this.doLogout();
      }
    }

    doLogout() {
        let resgisterItem ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':'log out',
          'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(resgisterItem)
        if (this.context.user.isSsoLogin && Boolean(this.globalCfg.ssoLogoutUrl)) {
          let language = sessionStorage.getItem('selectLanguage') || this.context.user.culture;
          if (language) {
              language = language.split('-')[0];
          }
          this.sharepointLogoutService.logout();
          this.context.logout();
          let finishLogoutUrl = this.globalCfg.ssoLogoutUrl.replace('{language}', language)
          window.location.href = finishLogoutUrl;
        }else if (this.globalCfg.skin === 'steel-blue' && (location.hostname === 'localhost' || location.hostname === 'becentinelappd.lefebvre.es')){
          this.sharepointLogoutService.logout();
          this.context.logout();
          this.router.navigate(['BE_EZAccess']);
        } else {
          this.sharepointLogoutService.logout();
          this.context.logout();
          this.router.navigate(['login']);
        }
        this.openChat.logOut()
    }

    viewMenu(type:any){
      let dropdown: any = this.dropdowns.find((x:any)=>{
        return x._elementRef.nativeElement.id == type;
      });
      dropdown.close()
    }

    openSupport() {
      let resgisterItem ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event': 'open help',
        'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem);
      this.modalService.helpModal();
    }

    goToPressEnter(event:any){
        if(event.which === 13 && this.headerNav.searchInDashboard !== '' && this.headerNav.text !==  this.headerNav.searchInDashboard){
            this.innerGoToSearch();
        }
    }

    goToSearch(){
        if(this.headerNav.searchInDashboard !== '' && this.headerNav.text !==  this.headerNav.searchInDashboard){
            this.innerGoToSearch();
        }else{
            this.headerNav.searchInDashboard = '';
        }
    }

    goToDashboard() {
        if(this.conceptState.getAmount() || this.conceptState.getProcess()){
            if(this.checkConceptComponent()){
                this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question')
                  .subscribe((result:any) => {
                    if(result.result !== 'cancel'){
                      if(result.result === 'save-exit'){
                        let configuration: any = {
                          from: 'headerNav',
                          action: 'goToDashboard'
                        }
                        this.saveConcept(configuration);
                        this.conceptState.removeAll();
                      }else{
                          this.conceptState.removeAll();
                          this.goTransitionToDashboard();
                      }
                    }
                  });
            }else{
                this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
                  .subscribe((result:any) => {
                    if(result.result !== 'cancel'){
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(false);
                      this.goTransitionToDashboard();
                    }
                  });
            }
        }else{
            this.goTransitionToDashboard();
        }
    }

    goTransitionToDashboard(){
      if (this.context.user.companyId) {
        this.router.navigate(['dashboard']);
      }
    }

    // saveConcept(action: any, url:any=undefined){
    saveConcept(configuration: any){
        if(this.checkCurrentComponent() === 'conceptWindow'){
            this.subscribers.saveConceptWindowBroadcast = this.broadcast.broadcast('saveConceptWindow', configuration);
        }else{
          this.subscribers.saveConceptToEvaluation = this.broadcast.broadcast('saveConceptToEvaluation', configuration);
        }
    }

    innerGoToSearch(){
      if(this.conceptState.getAmount() || this.conceptState.getProcess()){
          if(this.checkConceptComponent()){
            this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question')
              .subscribe((result:any) => {
                if(result.result !== 'cancel'){
                  if(result.result === 'save-exit'){
                    let configuration: any = {
                      from: 'headerNav',
                      action: 'toSearch'
                    }
                    this.saveConcept(configuration);
                    this.conceptState.removeAll();
                    this.conceptState.setProcess(null);
                  }else{
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(null);
                      this.toSearch();
                  }
                }
              });
          }
          else{
              this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
                .subscribe((result:any) => {
                  if(result.result !== 'cancel'){
                    this.conceptState.removeAll();
                    this.conceptState.setProcess(null);
                    this.toSearch();
                  }
                });
          }
      }else{
          this.toSearch();
      }
    }

    toSearch(){
        if(this.checkCurrentComponent() === 'resultSearch' && this.headerNav.searchInDashboard !== this.headerNav.text){
            this.router.navigate(['search', this.headerNav.searchInDashboard])
              .then(() => {
                window.location.reload();
              });
        }else{
            this.loader.openLoader('search-result-from-header');
            this.stateManagement.setStateUrl(window.location.pathname);
            let resgisterItem:any ={
              'Distinct ID': this.context.user.externalId,
              'Client ID': this.context.user.companyExternalId,
              'event':'global search',
              'Centinela product': this.context.settings.defaultProduct
            }
            this.registerService.register(resgisterItem);
            this.router.navigate(['search', this.headerNav.searchInDashboard]);
        }
    }

    changeAppLanguage(language:any){
        if(this.conceptState.getAmount() || this.conceptState.getProcess()){
            if(this.checkConceptComponent()){
                this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question')
                  .subscribe((result:any) => {
                    if(result.result !== 'cancel'){
                      sessionStorage.setItem('selectLanguage', language.appLanguage);
                      if(result.result === 'save-exit'){
                          let configuration: any = {
                            from: 'headerNav',
                            action: 'reload'
                          }
                          this.saveConcept(configuration);
                          this.conceptState.removeAll();
                      }else{
                          this.conceptState.removeAll();
                          window.location.reload();
                      }
                    }
                  });
            }else{
                this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
                .subscribe((result:any) => {
                  if(result.result !== 'cancel'){
                    sessionStorage.setItem('selectLanguage', language.appLanguage);
                    this.conceptState.removeAll();
                    this.conceptState.setProcess(false);
                    window.location.reload();
                  }
                });
            }
        }else{
            sessionStorage.setItem('selectLanguage', language.appLanguage);
            window.location.reload();
        }
    }

    goToEmit(e:any){
      let configuration: any = {
        url: e.url,
        loader: e.loader,
        evaluationId: e.evaluationId,
        type: e.type,
        toEmit: 'menuHeader'
      }
      this.openOption(configuration);
    }

    openOptionInWindow(url: any, type: any){
      let configuration: any = {
        url: url,
        type: type
      }
      this.openOption(configuration);
    }

    openOption(configuration:any){
        if(this.conceptState.getAmount() || this.conceptState.getProcess()){
            if(this.checkConceptComponent()){
                this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question')
                .subscribe((result:any) => {
                  if(result.result !== 'cancel'){
                    if(result.result === 'save-exit'){
                      configuration.from = 'headerNav';
                      configuration.action = 'goToUrl';
                      this.saveConcept(configuration);
                      this.conceptState.removeAll();
                    }else{
                        this.conceptState.removeAll();
                        this.goTo(configuration);
                    }
                  }
                });
            }else{
                this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
                  .subscribe((result:any) => {
                    if(result.result !== 'cancel'){
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(true)
                      this.goTo(configuration);
                    }
                  });
            }
        }else{
            this.goTo(configuration);
        }
    }

    goTo(configuration: any){
        if(configuration.type === 'openWindow'){
            this.stateManagement.setStateUrl(window.location.pathname);
        }
        if(configuration.typeToEmit === 'menuHeader'){
          this.loader.openLoader(configuration.loader);
        }
        if(window.location.pathname === ('/' + configuration.url)){
          setTimeout(()=>{
              this.loader.closeLoader([configuration.loader]);
          }, 500)
        }else if(this.route.firstChild?.snapshot.data['componentName'] === 'concept'){
            let innerEvaluationId = parseInt(this.route.firstChild?.snapshot.params['evaluationId']) === configuration.evaluationId?configuration.evaluationId:null
            this.commonUtilsService.reloadNavigateUrlEvaluation(configuration.url, innerEvaluationId);
        }else{
            this.router.navigate([configuration.url]);
        }
    }

    changeAmount(){
        return this.headerNav.amountNotifications--;
    }

    checkCurrentComponent() {
      let currentComponent: any = this.route.snapshot.firstChild?.data['componentName']?this.route.snapshot.firstChild?.data['componentName']:this.route.snapshot.data['componentName'];
      return currentComponent;
    }

    checkConceptComponent() {
      let currentComponent: any = this.checkCurrentComponent();
      return currentComponent === 'concept' || currentComponent === 'conceptWindow';
    }

    ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
      URL.revokeObjectURL(this.headerNav.logoUser);
    }
}
