


import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep, find, isString } from 'lodash';

@Component({
  selector: 'field-item',
  templateUrl: './field-item.component.html'
})
export class FieldItemComponent implements OnInit, OnChanges, OnDestroy {
  subscribers: any = {};
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.globalCfg.skin;
  componentConceptForm: boolean = this.route.snapshot.data['componentName'] === 'concept' || this.route.snapshot.data['componentName'] === 'conceptWindow' || this.route.snapshot.data['componentName'] === 'landingPage';
  // toBack = this.route.snapshot.params['to'] === 'form-process' || this.route.snapshot.params['to'] === 'template' || this.route.snapshot.params['to'] === 'process-back';
  viewEditConcept = this.route.snapshot.data['name'] === 'root.shared.concept-preview' || this.route.snapshot.data['name'] === 'root.public.concept-preview';
  relatedIcam: boolean = false;
  to: string = this.route.snapshot.params['to']
  fieldNoEditable: any = false;
  fieldItem: any = {
    showReferences: false,
    fieldInfo: null,
    usersMultiple: [],
    usersSimple: [],
    userPermissions: this.userPermissions.getPermissions(),
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private dateConverterService: DateConverterService,
    private commonUtilsService: CommonUtilsService,
    private conceptState: ConceptStateFactory,
    private context: ContextFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private modalService : ModalServiceService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private broadcast: BroadcastService,
    private userPermissions: UserPermisionsFactory,
    private customLanding: CustomLandingFactory) { }

  @Input() fieldInfo: any = null;
  @Input() evaluatedConcepts: any = [];
  @Input() evaluatedFields: any = null;
  @Input() isPrivate: any = null;
  @Input() tabLength: any = null;
  @Input() public: boolean = false;
  @Input() viewMode: boolean = false;
  @Input() concept: any = {};
  @Input() evaluationId: any = null;
  @Input() conceptLocked: any = null;
  @Input() fieldContainer: any = null;

  @Output() createForm = new EventEmitter();
  @Output() evaluatedConceptChange = new EventEmitter();
  @Output() evaluatedConceptChangeAdditional = new EventEmitter();
  @Output() changeConceptFromField = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.watchChangeFieldsFromConcept();
    this.watchChangeEditableFields();
    if(this.skin === 'icam-red' && this.concept?.conceptNamedKey === 'Usuario' && this.fieldInfo?.evaluatedValue?.hasReferences){
        this.fieldInfo.evaluatedValue.hasReferences = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.fieldItem;
    that.fieldInfo = this.fieldInfo;
    if(this.evaluatedConcepts){
      this.configureFieldItem();
      if(!this.fieldInfo?.fieldTypeFrontId){
          this.configureFieldFront();
      }
      if(this.concept?.conceptObjectId !== 'view'){
          this.extractEvaluatedValue();
      }
    }else if(this.viewMode && !this.fieldInfo?.fieldTypeFrontId){
      this.configureFieldFront();
    }
    if(this.concept){
        that.fieldInfo.poll = this.concept.poll;
        that.fieldInfo.sequentialView = this.concept.sequentialView;
        if(that.fieldInfo && that.fieldInfo.poll && this.route.snapshot.data['componentName'] === 'concept'){
            that.fieldInfo.sequentialView = false;
            that.fieldInfo.noEditable = true;
        }
        if(this.concept.sequentialView){
            this.configureFieldFront();
        }
    }
    if(this.evaluationId){
      that.fieldInfo.evaluationId = this.evaluationId;
    }else{
      that.fieldInfo.evaluationId = this.route.snapshot.params['evaluationId'];
    }
    this.configureNoEditableField();
  }

  configureNoEditableField(){
    this.fieldNoEditable = this.fieldItem.fieldInfo && this.fieldItem.fieldInfo.fieldTypeId !== 'rich' && this.fieldItem.fieldInfo.isEditable;
    let fieldRichNoForm = this.fieldItem.fieldInfo && this.fieldItem.fieldInfo.fieldTypeId === 'rich' && !this.fieldItem.fieldInfo.isEditable && !this.componentConceptForm;
    let lockedSavedConcept = (this.fieldItem.fieldInfo?.lockedEdit || this.conceptLocked) && (this.concept?.saved);
    let fieldPrivate = (this.fieldItem.fieldInfo && this.fieldItem.fieldInfo.private) || this.isPrivate;
    let sharedReadOnly = this.concept?.sharedReadOnly;
    if ((this.fieldItem.userPermissions === 'none' && !fieldRichNoForm) || this.fieldNoEditable
        || sharedReadOnly || (lockedSavedConcept && !fieldPrivate) || this.to === 'form-process' || this.fieldItem.fieldInfo.notCurrentValue || this.viewMode) {
        this.fieldItem.fieldInfo.noEditable = true;
    }
  }

  watchChangeFieldsFromConcept(){
    this.subscribers.changeFieldFromConceptBroadcast =  this.broadcastService.subscribe("changeFieldFromConcept", (data:any) => {
      if(data.field.fieldId === this.fieldInfo.fieldId){
        this.fieldItem.preDefined = data.newValue;
        this.itemSelected(data, this.fieldInfo.fieldTypeId);
      }
    });
  }

  watchChangeEditableFields(){
    this.subscribers.changeEditableFieldsBroadcast = this.broadcastService.subscribe("changeEditableFields", (data:any) => {
        this.fieldItem.fieldInfo.noEditable = this.fieldNoEditable?this.fieldNoEditable:data.newValue;
    });
  }

  itemSelected(e: any, type:string){
    let newValue: any = e.newValue;
    let withOutSaves: any = e.withOutSaves;
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
    if(evaluatedValue){
        switch(type){
            case 'list':
            case 'listp':
            case 'l-fo':
            case 'tree':
            case 'treep':
                evaluatedValue.displayData = [];
                let value:any = !this.fieldInfo.multiple? 'floatvalue' : 'nvarcharValue';
                evaluatedValue[value] = newValue;
                this.configureDisplayListType();
                break;
            case 'l-co':
            case 'l-sg':
                if(newValue !== 'icam'){
                  let value:any = !this.fieldInfo.multiple? 'floatvalue' : 'nvarcharValue';
                  evaluatedValue[value] = newValue;
                }
                break;
            case 'user':
                evaluatedValue.nvarcharValue = newValue;
                break;
            case 'date':
                evaluatedValue.datetimeValue = newValue !== null? this.dateConverterService.toIso(newValue) : null;
                this.configureDisplayDataVarchar(evaluatedValue.datetimeValue);
                break;
            case 'emai':
            case 'text':
                evaluatedValue.nvarcharValue = newValue;
                this.configureDisplayDataVarchar(newValue);
                break;
            case 'num':
            case 'money':
                if(newValue === null){
                  evaluatedValue.floatvalue = null;
                }else{
                  evaluatedValue.floatvalue = newValue;
                }
                this.configureDisplayDataVarchar(evaluatedValue.floatvalue);
                break;
            case 'file':
                if(newValue === null){
                  evaluatedValue.nvarcharValue = newValue;
                }else if(newValue.deleted){
                  evaluatedValue.documents = this.commonUtilsService.deleteElement(newValue, evaluatedValue.documents, 'documentObjectId');
                }else{
                    if(!evaluatedValue.documents){
                        evaluatedValue.documents = [];
                    }
                    evaluatedValue.documents.push(newValue);
                }
                break;
            case 'bool':
            case 'boolp':
            case 'txchk':
                evaluatedValue.bitValue = newValue;
                this.configureDisplayDataVarchar(newValue);
                break;
            case 'formu':
            case 'formp':
            case 'agreg':
            case 'agrep':
            case 'score':
                if(evaluatedValue.nvarcharValue !== null && evaluatedValue.nvarcharValue !== ''){
                    evaluatedValue.default = newValue;
                }
                if(evaluatedValue.floatvalue || evaluatedValue.floatvalue === 0){
                    let replaceValue: any =  typeof newValue === 'string'? this.commonUtilsService.replaceDecimals(newValue) : newValue;
                    evaluatedValue.floatvalue = parseFloat(replaceValue);
                    evaluatedValue.default = parseFloat(replaceValue);
                    evaluatedValue.isModified = true;
                }
                this.configureDisplayDataVarchar(evaluatedValue.default);
                break;
            case 'rich':
              if (this.fieldItem.fieldInfo.fieldTypeFrontId === 'rich-editable'){
                  evaluatedValue.nvarcharValue = this.fieldItem.fieldInfo.description;
                  this.fieldItem.fieldInfo.error = this.fieldItem.fieldInfo.error?this.fieldItem.fieldInfo.description === '':false;
                  this.configureDisplayDataVarchar(this.fieldItem.fieldInfo.description);
              }
              break;
            default:
                break;
        }
        if(!withOutSaves && newValue !== evaluatedValue.default && this.fieldItem.fieldInfo.fieldTypeFrontId !== 'formu'){
            evaluatedValue.isModified = true;
            this.conceptState.add(this.fieldItem.fieldInfo);
        }
        this.completeFieldPoll(newValue);
        evaluatedValue.default = newValue;
    }
    this.changeInField();
  }

  configureDisplayListType(){
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
    let type = {
        id: 'listValueId',
        value: 'text'
    }
    evaluatedValue.displayData = [];
    if(this.fieldItem.fieldInfo.multiple && evaluatedValue.nvarcharValue){
        this.configureDisplayListMultiple(this.fieldItem.fieldInfo.listValues, type);
    }else{
        this.configureDisplayList(this.fieldItem.fieldInfo.listValues, type, evaluatedValue.floatvalue);
    }
  }

  configureDisplayDataVarchar(valueField:any){
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
    let fieldInfo: any = this.fieldItem.fieldInfo
    if(valueField || (fieldInfo.fieldTypeFrontId === 'bool' && valueField === false)){
        let valueText = valueField;
        if(fieldInfo.fieldTypeFrontId === 'bool'){
            valueText = valueField?this.translate.instant('common.yes'):this.translate.instant('common.no');
        }
        evaluatedValue.displayData = {
            id: evaluatedValue.fieldId,
            value: valueField,
            valueText: valueText
        };
        if(fieldInfo.fieldTypeFrontId === 'formu' && fieldInfo.fieldEncodings && fieldInfo.fieldEncodings.length){
            evaluatedValue.displayData.color = fieldInfo.color;
            evaluatedValue.displayData.value = evaluatedValue.nvarcharValue;
            evaluatedValue.displayData.floatvalue = evaluatedValue.floatvalue;
        }else if(fieldInfo.fieldTypeId === 'money'){
            evaluatedValue.displayData.value += ' ' + fieldInfo.currencySymbol;
        }else if(fieldInfo.fieldTypeFrontId === 'bool' && fieldInfo.withColor){
            if(valueField){
                evaluatedValue.displayData.color = fieldInfo.withColor===1?'green':'red';
            }else{
                evaluatedValue.displayData.color = fieldInfo.withColor===2?'green':'red';
            }
        }
    }else{
      fieldInfo.evaluatedValue.displayData = null;
    }
  }

  completeFieldPoll(newValue:any){
    if(this.concept.poll){
        let frontId: string = this.fieldItem.fieldInfo.fieldTypeFrontId;
        this.fieldItem.fieldInfo.evaluatedValue.completePoll = (newValue !== '' && newValue !== null && newValue !== undefined);
        if(frontId === 'emai'){
            this.fieldItem.fieldInfo.evaluatedValue.completePoll = !this.fieldItem.fieldInfo.error;
        }else if(frontId === 'txchk'){
            this.fieldItem.fieldInfo.evaluatedValue.completePoll = newValue;
        }
    }
  }

  configureDisplayListMultiple(list:any, type:any){
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
    let items:any  = evaluatedValue.nvarcharValue.indexOf('|') !==-1? evaluatedValue.nvarcharValue.split('|') : [evaluatedValue.nvarcharValue];
    items.forEach((item:any) => {
      this.configureDisplayList(list, type, parseFloat(item));
    });
  }

  configureDisplayList(list:any, type:any, itemSelected:any){
    if(list && list.length){
        for(let i = 0; i < list.length; i++){
            let item = list[i];
            if(itemSelected === item[type.id]){
                let itemValue:any = {
                    id: item[type.id],
                    value: item[type.value]
                }
                if(this.fieldItem.fieldInfo.isHighlight){
                    itemValue.color = item.color;
                }
                this.fieldItem.fieldInfo.evaluatedValue.displayData.push(itemValue);
                break;
            }
        }
    }
  }

  changeInField(){
    this.evaluatedConceptChange.emit({field:this.fieldItem.fieldInfo});
  }

  configureFieldFront(){
    let fieldInfo: any = this.fieldItem.fieldInfo
    if(fieldInfo.evaluatedValue || this.viewEditConcept || this.viewMode){
        switch(fieldInfo.fieldTypeId){
            case 'txchk':
                fieldInfo.conceptObjectId = this.concept?.conceptObjectId?this.concept.conceptObjectId:undefined;
                fieldInfo.publicLite = this.concept?.publicLite;
                fieldInfo.fieldTypeFrontId = 'txchk';
                break;
            case 'formu':
            case 'formp':
            case 'agreg':
            case 'agrep':
            case 'score':
                fieldInfo.fieldTypeFrontId = 'formu';
                break;
            case 'l-fo':
            case 'list':
            case 'listp':
                fieldInfo.isTypeButton = fieldInfo.isHtml;
                fieldInfo.fieldTypeFrontId = fieldInfo.extendedList? 'list-extended' :  'list';
                break;
            case 'tree':
            case 'treep':
                fieldInfo.isTree = true;
                fieldInfo.uniqueLastSelect = true;
                fieldInfo.fieldTypeFrontId = 'list';
                break;
            case 'l-co':
            case 'l-sg':
                fieldInfo.fieldTypeFrontId = 'l-co';
                break;
            case 'money':
            case 'num':
                fieldInfo.fieldTypeFrontId = 'num';
                break;
            case 'text':
                if (fieldInfo.fieldContentTypeId === 'emai') {
                    fieldInfo.fieldTypeFrontId = 'emai';
                } else if (fieldInfo.fieldContentTypeId === 'addr') {
                    fieldInfo.fieldTypeFrontId = 'link';
                }else{
                  fieldInfo.fieldTypeFrontId = 'text';
                }
                break;
            case 'rich':
                fieldInfo.fieldTypeFrontId = 'rich';
                if(fieldInfo.isEditable){
                    fieldInfo.fieldTypeFrontId = 'rich-editable';
                }
                break;
            case 'bool':
            case 'boolp':
                fieldInfo.fieldTypeFrontId = 'bool';
                break;
            case 'user':
                fieldInfo.addContacts = !this.isLanding;
                fieldInfo.fieldType = fieldInfo.multiple?'multiple':'simple';
                fieldInfo.typeConfiguration = 'field-user';
                fieldInfo.typeCall = 'contacts';
                fieldInfo.fieldTypeFrontId = 'user';
                break;
            case 'templ':
              fieldInfo.fieldTypeFrontId = 'templ';
              if(fieldInfo.fieldContentTypeId === 'pepl'){
                  fieldInfo.fieldTypeFrontId = 'pepl';
              }
              break;
            default:
                fieldInfo.fieldTypeFrontId = fieldInfo.fieldTypeId;
                break;
        }
    }
  }

  configureFieldItem(){
      for(let i = 0; i < this.evaluatedConcepts.length; i++){
          if(this.evaluatedConcepts[i].fieldId === this.fieldItem.fieldInfo.fieldId){
              this.fieldItem.fieldInfo.evaluatedValue = this.evaluatedConcepts[i];
              break;
          }
      }
  }

  extractEvaluatedValue(){
    if(this.concept.conceptObjectId !== 'view'){
      let fieldInfo: any = this.fieldItem.fieldInfo;
      if(fieldInfo.evaluatedValue){
          let type: any;
          switch(fieldInfo.fieldTypeId){
              case 'file':
                this.fieldItem.preDefined = fieldInfo.evaluatedValue.nvarcharValue;
                type = {
                    id: 'documentObjectId',
                    value: 'name'
                }
                this.configureDisplayDataList(fieldInfo.evaluatedValue.documents, type);
                this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                break;
              case 'formu':
              case 'formp':
              case 'agreg':
              case 'agrep':
              case 'score':
                  if(fieldInfo.evaluatedValue.nvarcharValue !== null && fieldInfo.evaluatedValue.nvarcharValue !== ''){
                      fieldInfo.evaluatedValue.default = cloneDeep(fieldInfo.evaluatedValue.nvarcharValue);
                  }else if(fieldInfo.evaluatedValue.floatvalue || fieldInfo.evaluatedValue.floatvalue === 0){
                      fieldInfo.evaluatedValue.default = cloneDeep(fieldInfo.evaluatedValue.floatvalue);
                  }
                  this.configureDisplayDataVarchar(fieldInfo.evaluatedValue.default);
                  this.fieldItem.hasValue = fieldInfo.evaluatedValue.default != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'date':
                  if(fieldInfo.evaluatedValue && fieldInfo.evaluatedValue !== null){
                      if(fieldInfo.evaluatedValue.datetimeValue !==null){
                        this.fieldItem.preDefined = isString(fieldInfo.evaluatedValue.datetimeValue)? this.dateConverterService.toDate(fieldInfo.evaluatedValue.datetimeValue) : fieldInfo.evaluatedValue.datetimeValue;
                      }else if (fieldInfo.evaluatedValue.datetimeValue === null && fieldInfo.defaultValue !== null && fieldInfo.defaultValue !==''){
                          this.fieldItem.preDefined = this.dateConverterService.toDate(fieldInfo.evaluatedValue.datetimeValue);
                      }else{
                          this.fieldItem.preDefined = null;
                      }
                      this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                      fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                      this.configureDisplayDataVarchar(fieldInfo.evaluatedValue.datetimeValue);
                  }
                  fieldInfo.showAlertButton = true;
                  break;
              case 'l-fo':
                  this.fieldItem.preDefined = fieldInfo.multiple? fieldInfo.evaluatedValue.nvarcharValue : fieldInfo.evaluatedValue.floatvalue;
                  fieldInfo.listValues = this.commonUtilsService.editList(fieldInfo.references, 'referenceId', 'text');
                  this.configureDisplayListType();
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'list':
              case 'listp':
              case 'tree':
              case 'treep':
                  this.configureValueList();
                  this.configureDisplayListType();
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'l-co':
              case 'l-sg':
                  this.configureValueList();
                  this.relatedIcam = this.skin === 'icam-red' && this.context && this.context.view && this.context.view.extraData && this.context.view.extraData.masterConceptId && this.context.view.extraData.masterConceptObjectId && this.fieldItem.fieldInfo.sourceConcept && this.fieldItem.fieldInfo.sourceConcept.conceptId === this.context.view.extraData.masterConceptId;
                  if(!this.fieldItem.preDefined && this.relatedIcam){
                    let masterConceptObjectId: any = this.context.view.extraData.masterConceptObjectId;
                    this.fieldItem.preDefined = masterConceptObjectId;
                    type = fieldInfo.multiple? 'nvarcharValue' : 'floatvalue';
                    fieldInfo.evaluatedValue[type] = masterConceptObjectId;
                    fieldInfo.evaluatedValue.isModified = true;
                  }
                  fieldInfo.evaluatedValue.displayData = [];
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'user':
                  this.fieldItem.preDefined = fieldInfo.evaluatedValue.nvarcharValue;
                  fieldInfo.evaluatedValue.displayData = [];
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'money':
              case 'num':
                  this.convertToNum();
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'text':
                  if(fieldInfo.evaluatedValue.nvarcharValue === null && fieldInfo.defaultValue !== null){
                      this.fieldItem.preDefined = fieldInfo.defaultValue;
                  }else if(fieldInfo.evaluatedValue && (fieldInfo.evaluatedValue.nvarcharValue || fieldInfo.evaluatedValue.nvarcharValue === '')){
                      this.fieldItem.preDefined = fieldInfo.evaluatedValue.nvarcharValue;
                  }
                  this.configureDisplayDataVarchar(this.fieldItem.preDefined);
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'rich':
                  if(fieldInfo.isEditable){
                    this.configureDisplayDataVarchar(this.fieldItem.preDefined);
                  }
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'bool':
              case 'boolp':
              case 'txchk':
                  this.fieldItem.preDefined = fieldInfo.evaluatedValue.bitValue;
                  this.configureDisplayDataVarchar(this.fieldItem.preDefined);
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              case 'templ':
              case 'pepl':
                  type = {
                      id: 'documentObjectId',
                      value: 'name'
                  }
                  this.configureDisplayDataList(fieldInfo.evaluatedValue.documents, type);
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
              default:
                  this.fieldItem.hasValue = this.fieldItem.preDefined != null;
                  fieldInfo.notCurrentValue = fieldInfo.fieldNotInForce && this.fieldItem.hasValue;
                  break;
          }

          fieldInfo.evaluatedValue.default = cloneDeep(this.fieldItem.preDefined);
          this.completeFieldPoll(fieldInfo.evaluatedValue.default);
      }
    }
  }

  configureDisplayDataList(list:any=[], type:any){
      this.fieldItem.fieldInfo.evaluatedValue.displayData = [];
      list.forEach((document:any) => {
        let documentValue = {
          id: document[type.id],
          value: document[type.value]
        }
        this.fieldItem.fieldInfo.evaluatedValue.displayData.push(documentValue);
      });
  }

  configureValueList(){
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
      if(evaluatedValue){
          if(this.fieldItem.fieldInfo.multiple){
            this.fieldItem.preDefined = evaluatedValue.floatvalue && !evaluatedValue.nvarcharValue? evaluatedValue.floatvalue : evaluatedValue.nvarcharValue;
          }else{
            this.fieldItem.preDefined = evaluatedValue.floatvalue;
          }
      }
  }

  convertToNum(){
    let evaluatedValue: any = this.fieldItem.fieldInfo.evaluatedValue;
      if(evaluatedValue && evaluatedValue.floatvalue !== null){
        let numberToText = "" +evaluatedValue.floatvalue;
        this.fieldItem.preDefined = numberToText.indexOf('.') !== -1? numberToText.replace('.', ',') : numberToText;
        this.configureDisplayDataVarchar(this.fieldItem.preDefined);
      }
  }

  itemSelectedAdditional(e:any, type:any) {
      let relatedAdditional:any = e.additionalData;
      let fieldInfo = this.fieldItem.fieldInfo;
      if (fieldInfo.evaluatedValue) {
          if ((type === 'l-co' || type === 'l-sg') && !fieldInfo.multiple) {
              relatedAdditional.forEach((fieldData:any) => {
                let additionalField = find(fieldInfo.additionalRelatedFields, { additionalFieldId: fieldData.fieldId })
                  if(additionalField){
                      additionalField.evaluatedValue = fieldData.data;
                  }
              });
          }
      }
      this.evaluatedConceptChangeAdditional.emit({ field: this.fieldItem.fieldInfo });
  }

  showFieldReferences(){
      this.fieldItem.showReferences = !this.fieldItem.showReferences;
  }

  createRegisterItem(){
    return {
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':'open legal docs',
      'Concept Name': sessionStorage.getItem('breadcrumbConcept') + this.concept.title,
      'Centinela product': this.context.settings.defaultProduct
    }
  }

  createAlert(e:any){
      let alert: any = e.alert;
      this.configureAlert(alert);
      let conceptDataId: any = this.fieldItem.fieldInfo.evaluatedValue.conceptDataId;
      this.apiService.add('datealerts/'+ conceptDataId, alert.alert)
          .then((data:any)=>{
              if(alert.share){
                  this.sendShare(data, alert.share);
              }else{
                  this.loader.closeLoader(['create-alert-field-item']);
                  this.configureText();
                  this.subscribers.addNewBroadcast  = this.broadcast.broadcast('addNew', {});
              }
          }, (errorDate:any)=>{
              this.loader.closeError();
      })
  }

  configureText(){
      let text = this.translate.instant('task-edit.generate-alert') +"'"+this.fieldItem.fieldInfo.label+"'.";
      this.modalService.adviceModal(text, 'info:accept:check-round')
  }

  sendShare(alert:any, share:any) {
      this.apiService.add('datealerts/sharealert/' + alert.dateAlertId + '/omit-notify-creator/' + alert.omitNotifyToCreator, share)
          .then((data:any)=>{
              this.configureText();
              this.loader.closeLoader(['create-alert-field-item']);
              this.subscribers.addNewBroadcast  = this.broadcast.broadcast('addNew', {});
          }, (errorDate:any)=>{
              this.loader.closeError();
          })
  }

  configureAlert(alert:any){
    let alertConst: any = alert.alert;
    if(alertConst.documentAlertTypeId){
        alertConst.dateAlertTypeId = alertConst.documentAlertTypeId;
    }
    delete alertConst.documentAlertTypeId;
  }

  receiveContacts(contacts:any, type:any){
      this.fieldItem['users' + type] = contacts;
  }

  openRelatedInfo(e:any){
    this.modalService.relatedInformationModal(e.relatedInfo)
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo(fieldRelatedInfo);
  }

  changeConcept(e:any){
      this.changeConceptFromField.emit({template: e.template});
  }

  launchActionOn(e:any){
    this.launchAction.emit({action: e.action});
  }

  saveVoiceRecord(e:any){
    this.fieldItem.fieldInfo.acceptAceptation = e.acceptAceptation;
    this.fieldItem.fieldInfo.voiceRecordFile = e.voiceRecordFile;
    this.fieldItem.fieldInfo.voiceRecordName = e.name;
    this.changeInField()
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
