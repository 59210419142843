<div class="report-incidents-list">
    <header-nav></header-nav>
    <div class="common__block-window-view">
        <div class="common__block-button-close-window">
            <button (click)="changeViewWindow()"
                class="common__back-link">
                <span [afTranslate]="'report-incidents-list.close-list-reports'">_Cerrar lista de informes</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="common__container-window-view">
            <div class="common__block-list-new-version">
                <div class="common__header-new-version"
                  [ngClass]="{'report-incidents-list__report-archived':reportIncidentsList.toShow==='archived'}"
                  [ngSwitch]="reportIncidentsList.toShow">
                  <div *ngSwitchCase="'report'">
                    <i class="lf-icon-label-analysis common__header-icon-new-version"></i>
                    <span class="common__header-title-new-version">{{reportIncidentsList.titleText}}</span>
                    <span class="reports__new-version reports__new-version--report-incident-list"
                      [ngShow]="isNewVersion"
                      [afTranslate]="'reports.new-version'">
                      _nueva version
                    </span>
                  </div>
                  <div *ngSwitchCase="'archived'">
                    <div [ngShow]="reportIncidentsList.toShow==='archived'">
                      <button class="folder__back-button folder__back-button--archived-incident-list-no-top float-start"
                          (click)="viewArchived()">
                          <i class="lf-icon-arrow-back folder__arrow-back-icon"></i>
                          <span class="folder__arrow-back-text"
                              [afTranslate]="'concept-list.archive-exit'">
                          </span>
                      </button>
                      <button class="float-end lf-icon-archive-empty folder__back-button folder__back-button--archived-incident-list"
                          (click)="deleteArchived()"
                          matTooltip="{{'folder.empty-file' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </button>
                      <p [ngShow]="archivedList.length"
                          class="folder__concept-archived-amount folder__concept-archived-amount--archived-incident-list float-end">
                          {{archivedList.length}}
                          <span class="span__auto-ajust"
                            [afTranslate]="archivedList.length===1? 'concept-list.report-archive' : 'concept-list.reports-archives'">
                          </span>
                      </p>
                  </div>
                  </div>
                </div>
                <div class="common__table-block">
                    <div class="common__table-new-version">
                        <div class="report-incidents-list__table-header">
                            <div class="report-incidents-list__col report-incidents-list__col--title-header report-incidents-list__col--first"
                                ngbDropdown
                                [autoClose]="'outside'">
                                <div ngbDropdownToggle>
                                    <p class="common__table-header-text common__cursor-pointer"
                                        [afTranslate]="'common.title'"
                                        (click)="openFocus('Title')">
                                        Título
                                    </p>
                                    <i class="common__filter-icon lf-icon-filter-1"
                                        [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': reportIncidentsList.searchTitle.length}"
                                        (click)="openFocus('Title')">
                                    </i>
                                </div>
                                <i class="common__order-by"
                                    [ngClass]="{'lf-icon-arrow-down':(reportIncidentsList.dateOrder==='title' && reportIncidentsList.orderOperationDates) || reportIncidentsList.dateOrder!=='title',
                                        'lf-icon-arrow-up': reportIncidentsList.dateOrder==='title' && !reportIncidentsList.orderOperationDates,
                                        'common__order-by--active': reportIncidentsList.dateOrder==='title'}"
                                    (click)="orderDate('title')"></i>
                                <div class="dropdown-menu common__select-list-with-input common__select-list-with-input--top-100"
                                    ngbDropdownMenu
                                    role="menu">
                                    <div class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                        <input type="text"
                                                class="common__dropdown-inner-input"
                                                (keyup)="filterReport()"
                                                [placeholder]="'concept-list.search' | afTranslate"
                                                [(ngModel)]="reportIncidentsList.searchTitle"
                                                [focusWhen]="reportIncidentsList.openFocusTitle">
                                        <i class="common__search-icon"
                                            [ngClass]="reportIncidentsList.searchTitle ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
                                            (click)="resetTitleFilter()"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="report-incidents-list__col report-incidents-list__col--author">
                                <filter-by-list [type]="'listFind'"
                                    [widthClass]="'250px'"
                                    [item]="reportIncidentsList.authorFilter"
                                    [list]="reportIncidentsList.authorList"
                                    [topStyle]="'29'"
                                    (filterElementEmit)="selectTypeOperation($event, 'author')">
                                </filter-by-list>
                            </div>
                            <div class="report-incidents-list__col report-incidents-list__col--creation-date">
                                <div class="common__cell-block">
                                    <p id="report-record-detail-creation-date-header-0"
                                        class="common__table-header-text common__table-header-text--ellipsis common__cursor-pointer"
                                        matTooltip="{{'common.creation' | afTranslate}}"
                                        matTooltipPosition="below"
                                        matTooltipClass="below"
                                        [matTooltipDisabled]="showTooltip('report-record-detail-creation-date-header-0')"
                                        (click)="orderDate('modificationDate')">
                                        <span id="span-report-record-detail-creation-date-header-0"
                                            [afTranslate]="'common.creation'">_Creación</span>
                                    </p>
                                    <i class="common__order-by"
                                        [ngClass]="{'lf-icon-arrow-down':(reportIncidentsList.dateOrder==='creationDate' && reportIncidentsList.orderOperationDates) || reportIncidentsList.dateOrder!=='creationDate',
                                            'lf-icon-arrow-up': reportIncidentsList.dateOrder==='creationDate' && !reportIncidentsList.orderOperationDates,
                                            'common__order-by--active': reportIncidentsList.dateOrder==='creationDate'}"
                                        (click)="orderDate('creationDate')"></i>
                                </div>
                            </div>
                            <div class="report-incidents-list__col report-incidents-list__col--modification-date">
                                <div class="common__cell-block">
                                    <p id="report-record-detail-modification-date-header-0"
                                        class="common__table-header-text common__table-header-text--ellipsis common__cursor-pointer"
                                        matTooltip="{{'common.modification' | afTranslate}}"
                                        matTooltipPosition="below"
                                        matTooltipClass="below"
                                        [matTooltipDisabled]="showTooltip('report-record-detail-modification-date-header-0')"
                                        (click)="orderDate('modificationDate')">
                                        <span id="span-report-record-detail-modification-date-header-0"
                                            [afTranslate]="'common.modification'">_Modificación</span>
                                    </p>
                                    <i class="common__order-by"
                                        [ngClass]="{'lf-icon-arrow-down':(reportIncidentsList.dateOrder==='modificationDate' && reportIncidentsList.orderOperationDates) || reportIncidentsList.dateOrder!=='modificationDate',
                                            'lf-icon-arrow-up': reportIncidentsList.dateOrder==='modificationDate' && !reportIncidentsList.orderOperationDates,
                                            'common__order-by--active': reportIncidentsList.dateOrder==='modificationDate'}"
                                        (click)="orderDate('modificationDate')"></i>
                                </div>
                            </div>
                            <div class="report-incidents-list__col report-incidents-list__col--actions">
                                <p class="common__table-header-text common__table-header-text--right"
                                    [afTranslate]="'common.actions'">Acciones</p>
                            </div>
                        </div>
                        <div class="common__table-body common__table-body--10-elements"
                            [ngClass]="{'common__table-body--more-elements': reportIncidentsList.filterReportList?.length > 10}">
                            <div class="common__table-row"
                                *ngFor="let report of reportIncidentsList.filterReportList | byWord:reportIncidentsList.searchReport:['title', 'author'] | afOrderBy:reportIncidentsList.dateOrder:reportIncidentsList.orderOperationDates">
                                <div class="report-incidents-list__col report-incidents-list__col--title report-incidents-list__col--first report-incidents-list__col--tooltip">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="report.title"></parragraph-tooltip>
                                    </div>
                                </div>

                                <div class="report-incidents-list__col report-incidents-list__col--author report-incidents-list__col--tooltip">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="report.author"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="report-incidents-list__col report-incidents-list__col--creation-date report-incidents-list__col--tooltip">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="report.creationDate | convertDate: 'without-time'"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="report-incidents-list__col report-incidents-list__col--modification-date-body report-incidents-list__col--tooltip">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="report.modificationDate | convertDate: 'without-time'"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="report-incidents-list__col report-incidents-list__col--actions-body">
                                    <ul class="common__options-row">
                                        <li class="common__menu-options-row"
                                            (click)="launchActionOption(report, 'edit')"
                                            matTooltip="{{(reportIncidentsList.userPermissions === 'none' || reportIncidentsList.toShow === 'archived'?'common.view':'expanded-menu.edit') | afTranslate}}"
                                            matTooltipPosition="below"
                                            matTooltipClass="below">
                                            <i [ngClass]="reportIncidentsList.userPermissions === 'none' || reportIncidentsList.toShow === 'archived'?'lf-icon-visible':'lf-icon-editable-form'"></i>
                                        </li>
                                        <li class="common__menu-options-row"
                                            (click)="launchActionOption(report, 'openWindow')"
                                            matTooltip="{{'report-table.icon-tooltip' | afTranslate}}"
                                            matTooltipPosition="below"
                                            matTooltipClass="below">
                                            <i class="lf-icon-open-window"></i>
                                        </li>
                                        <li class="common__menu-options-row"
                                            [ngShow]="reportIncidentsList.userPermissions !== 'none'"
                                            (click)="launchActionOption(report, 'delete')"
                                            matTooltip="{{'common.remove' | afTranslate}}"
                                            matTooltipPosition="below"
                                            matTooltipClass="below">
                                            <i class="lf-icon-trash"></i>
                                        </li>
                                    </ul>
                                    <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common__block-footer-table">
                    <!-- <div class="common__block-search">
                        <input type="text"
                            [ngShow]="reportIncidentsList.search"
                            class="common__search-input"
                            [value]="reportIncidentsList.searchReport"
                            placeholder="{{'common.search' | afTranslate}}"
                            [focusWhen]="reportIncidentsList.search">
                        <span class="common__text-search"
                            [ngShow]="!reportIncidentsList.search"
                            (click)="toggleSearch()"
                            [afTranslate]="'common.search'">_Buscar</span>
                        <button class="lf-icon-search common__icon-button common__icon-button-search"
                            [ngClass]="{'lf-icon-search': !reportIncidentsList.search,
                                'lf-icon-close-search': reportIncidentsList.search,
                                'common__icon-button-search--open': reportIncidentsList.search}"
                            (click)="toggleSearch()">
                        </button>
                    </div> -->
                    <div  [ngShow]="archivedList.length && reportIncidentsList.toShow === 'report'"
                          class="concept-list__block-info-archive"
                          [ngClass]="{'concept-list__block-info-archive--absolute': reportIncidentsList.filterReportList.length > reportIncidentsList.configPagination.pageSize}">
                        <button class="common__button-border-none concept-list__archive-button"
                            (click)="viewArchived()">
                            <i class="lf-icon-archive common__icon-button-underlined"></i>
                            <span class="common__text-button-underlined"
                            [afTranslate]="'concept-list.view-archive'"></span>
                        </button>
                        <p class="concept-list__archived-evaluated-amount">
                            <span>
                                {{archivedList.length}}
                                <span [afTranslate]="archivedList.length === 1 ? 'concept-list.report-archive' : 'concept-list.reports-archives'"></span>
                            </span>
                        </p>
                    </div>
                    <div class="common__pagination-block"
                        [ngShow]="reportIncidentsList.filterReportList.length > reportIncidentsList.configPagination.pageSize">
                        <pagination-control
                          [config]="reportIncidentsList.configPagination"
                          [collectionSize]="reportIncidentsList.filterReportList.length"
                          [(currentPage)]="reportIncidentsList.currentPage"
                          (configuredTotalChange)="reportIncidentsList.configuredTotal = $event.configuredTotal"></pagination-control>
                    </div>
                    <div class="common__block-footer-total">
                        <span [ngShow]="reportIncidentsList.filterReportList?.length > reportIncidentsList.configPagination.pageSize">
                          <span class="notranslate">{{reportIncidentsList.configuredTotal.first}}-{{reportIncidentsList.configuredTotal.last}}</span>
                          <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                          <span class="notranslate">{{reportIncidentsList.filterReportList?.length}}</span>
                        </span>
                        <span [ngShow]="!(reportIncidentsList.filterReportList?.length > reportIncidentsList.configPagination.pageSize)">
                          <span [afTranslate]="'common.total'">_Total</span>
                          <span class="common__total-number-element notranslate">{{reportIncidentsList.filterReportList.length}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
  </div>
