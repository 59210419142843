import moment from 'moment';

import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { HiddenElementsFactory } from '@shared/factories/hidden-elements/hidden-elements.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import Patterns from   '../../../../assets/data/pattern.json'
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { EvaluatedConceptFactory } from '@shared/factories/evaluated-concept/evaluated-concept.factory';
import { cloneDeep } from 'lodash';
import { DownloadService } from '@shared/services/download/download.service';
import { NgbDropdown, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ChannelConfigurationComponent } from '../channel-configuration/channel-configuration.component';

@Component({
  selector: 'folder',
  templateUrl: './folder.component.html'
})
export class FolderComponent implements OnInit, OnDestroy {
  expandMenu:any = ExpandMenu;
  skin: string = this.globalCfg.skin;
  fullMenu: boolean = this.getFullMenu();
  subscribers : any = {};
  // evaluation:any = this.route.snapshot.data['evaluationInfo'];
  evaluationId: string = this.route.snapshot.params['evaluationId'];
  folderId: string = this.route.snapshot.params['folderId'];
  productId = this.context.settings.defaultProduct;
  userPermissions:string = this.permissions.getPermissions()
  folder: any = {
    textIcamIcon: this.translate.instant('folder.icam-queries-icon-tooltip'),
    folder: {},
    showCreateConcept: false,
    activeCleanFilters: false,
    showFolder: false,
    conceptMenuList: [],
    evaluationInfo: this.route.snapshot.data['evaluationInfo'],
    patternList: Patterns,
    preview: !(this.context.user && this.context.user.companyId),
    description: '',
    titleEvaluated: this.skin === 'uk-steel-blue'?this.translate.instant('folder.total-processes'):this.translate.instant('folder.evaluated-concepts'),
    openAll: true,
    showHidden: false,
    configuredPatternList: false,
    folderMenuList: this.expandMenu['conceptMenuList'].menu
  }

  constructor(private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private permissions: UserPermisionsFactory,
    private hiddenElements: HiddenElementsFactory,
    private modalService: ModalServiceService,
    private dateConverterService: DateConverterService,
    private expandedMenuService: ExpandedMenuService,
    private registerService: RegisterMixtapeService,
    private evaluatedConceptFactory: EvaluatedConceptFactory,
    private downloadService: DownloadService) { }

  @ViewChildren(NgbTooltip) tooltips: QueryList<NgbTooltip>;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  @ViewChild(ChannelConfigurationComponent)
  channelConfiguration: ChannelConfigurationComponent;

  ngOnInit(): void {
    this.route.params.subscribe((params:any)=>{
      this.folder.showFolder = false;
      this.evaluationId= params['evaluationId'];
      this.folderId = params['folderId'];
      this.folder.folder = this.route.snapshot.data['folder'];
      this.setFolder();
      // this.folder.folder.channelView = (this.folder.folder.iD_Version0 === 393 || this.folder.folder.iD_Version1 === 393 || this.folder.folder.iD_Version0 === 90679 || this.folder.folder.iD_Version0 === 28886 || this.folder.folder.iD_Version1 === 28886)
      this.folder.folder.channelView = this.configureChannelView();
      this.folder.showHidden = this.hiddenElements.show;
      this.folder.description = this.folder.preview? this.translate.instant('common.preview') : this.translate.instant('folder.description');

      if(this.skin==='icam-red'){
        this.configureTitleEvaluated();
      }
    })
    this.initWatchers();
  }

  configureChannelView(){
    if(this.productId === 'denunc' || this.productId === 'whistle'){
      for (let concept of this.folder.folder.concepts) {
        if(concept.namedKey === 'ChannelView'){
          return true;
        }
      }
    }
    return false;
  }

  getFullMenu(){
    this.broadcastService.broadcast('changeFullMenu', {fullMenu: (window.innerWidth > 1367)})
    return window.innerWidth > 1367;
  }

  configureTitleEvaluated(){
    document.title = this.folder.folder.name;
    this.folder.folder.concepts.forEach((concept:any) => {
      switch (concept.namedKey) {
          case 'Consulta':
              this.folder.folder.channelView = true;
              concept.titleEvaluated = this.translate.instant('folder.evaluated-queries');
              break;
          case 'Publicacion':
              concept.titleEvaluated = this.translate.instant('folder.registered-publications');
              break;
          case 'Digitalizacion':
              document.title = 'BD Conocimiento';
              concept.titleEvaluated = this.translate.instant('folder.digitized-fragments');
              break;
          case 'Usuario':
              concept.titleEvaluated = this.translate.instant('folder.registered-profiles');
              break;
          default:
              concept.titleEvaluated = this.translate.instant('folder.evaluated-concepts');
              break;
      }
    });
  }

  initWatchers(){
    this.subscribers.deletechildrenFolderBroadcast = this.broadcastService.subscribe('deletechildrenFolder', (data:any)=>{
      this.folder.folder.children = this.commonUtilsService.deleteElement( data.folder, this.folder.folder.children, 'folderId')
    });
    this.subscribers.duplicateItemBroadcast = this.broadcastService.subscribe('duplicateItem', (data:any)=>{
      this.duplicateItem(data);
    });
    this.subscribers.changeHiddenElementsBroadcast = this.broadcastService.subscribe('changeHiddenElements', (data:any)=>{
      this.folder.showHidden = this.hiddenElements.show;
    });
    this.subscribers.changePropertyFolderBroadcast = this.broadcastService.subscribe('changePropertyFolder', (data:any)=>{
      for(let children of this.folder.folder.children){
          if(children.folderId === data.folder.folderId){
              children[data.property] = data.newValue;
              children.confidentialList = data.property==='confidential'?data.folder.confidentialList:children.confidentialList;
              break;
          }
      }
    });
    this.subscribers.cleanFiltersPropBroadcast = this.broadcastService.subscribe('cleanFiltersProp', (data:any)=>{
        this.folder.activeCleanFilters = Boolean(data.hasFilters);
        this.folder.idFilter = data.id;
    })
    this.subscribers.changeTreeFolderBroadcast = this.broadcastService.subscribe('changeTreeFolder', (data:any)=>{
      for(let i =0 ; i < this.folder.folder.children.length; i++){
          let children = this.folder.folder.children[i];
          if(children.folderId === data.folder.folderId){
              this.folder.folder.children.splice(i, 1);
              break;
          }
      }
    });
  }

  duplicateItem(data: any){
    if(this.folder.folder.folderId === data.item.folderId){
        if(data.type === 'folder'){
            this.folder.folder.children = data.item.children;
        }else{
          let item = data.item
            item.open = this.folder.openAll;
            this.folder.folder.concepts.push(item);
            this.getEvaluatedConcepts(item);
        }
    }
    this.loader.closeLoader(['evaluation-duplicate-item']);
  }

  setFolder(){
    this.folder.folderMenuList = this.userPermissions !== 'all'? this.expandMenu["folderMenuListNoPermissions"].menu : this.expandMenu['folderMenuList'].menu;
    if(this.userPermissions !== 'none'){
        this.folder.conceptMenuList = this.expandMenu['conceptMenuList'].menu;
    }
    this.folder.openAll = true;
    if(!this.folder.evaluationInfo.customizable){
        this.folder.folderMenuList = this.expandMenu['confidentialOptionMenu'].menu;
    }
    this.configureFolder();
  }

  toggleSwitchFoldUnfold(newValue:boolean) {
    this.folder.openAll = newValue;
    this.openAllConcepts();
  }

  openAllConcepts(){
    if(this.folder.folder && this.folder.folder.concepts){
      let concepts:any = this.folder.folder.concepts;
      concepts.forEach((item:any) => {
        item.open = item.hidden? false : this.folder.openAll;
        if(item.poll && item.toShow === 'report' && !this.folder.openAll){
            item.toShow = "evaluated";
        }
      });
    }
  }

  getEvaluatedConcepts(concept:any, last:boolean = false) {
    let path = this.skin === 'icam-red' ? 'icam/conceptobjects/byconceptid' : 'conceptobjects/byconceptid';
    this.apiService.get(path, concept.conceptId+'/archived/false')
        .then((data:any)=>{
            let items = data === 'null'?[]:data
            this.configureConceptList(concept, items);
            if(!concept.toShow){
                concept.toShow="evaluated";
            }
            let that = this;
            this.apiService.get('conceptobjects/objectscount', concept.conceptId+'/1')
              .then((data:any)=>{
                  concept.archivedAmount = data;
                  concept.archivedNoCall = true;
                  concept.archived = [];
                  if(that.loader.openFrom('folder-concept-shared')){
                      let text:string = concept.poll? 'concept-tab.poll-share-ok' : 'concept-tab.concept-share-ok';
                      concept.modify = true;
                      that.loader.closeLoader(['folder-concept-shared']);
                      that.modalService.adviceModal(that.translate.instant(text), 'info:accept:check-round')
                  }else if(last){
                    that.loader.closeLoader(['folder-on-init']);
                  }
              }, function(errorData){
                that.loader.closeError();
              })
        });
  }

  configureConceptList(concept:any, data:any){
      concept.allEvaluated = data;
      concept.list = data;
      concept.amountEvaluatedConcept = data.length;
  }

  // getEvaluationInfo(){
  //   this.apiService.get('evaluations', this.evaluationId)
  //       .then((data:any)=> {
  //           this.folder.evaluationInfo = data;
  //           // this.folder.evaluationInfo = this.evaluation;

  //           if(!this.folder.evaluationInfo.customizable){
  //               this.folder.folderMenuList = this.expandMenu['confidentialOptionMenu'].menu;
  //           }
  //       },(errorData:any)=>{})
  // }

  configureFolder(){
    let folder: any = this.folder.folder;
    let multipleInstancesInAuto: any = folder.conceptObjectId && folder.concepts && folder.concepts.length? folder.concepts[0].multipleInstancesInAuto : false;

    if(folder && folder.conceptObjectId && folder.conceptObjectId !== 0 && !multipleInstancesInAuto){
        this.loader.openLoader('folder-open-concept');
        this.broadcastService.broadcast('openEvaluatedConcept', {
          conceptInfo:{
            evaluatedConcept:folder,
            concept: folder.concepts[0],
            instanciable: this.route.snapshot.params['instanciable']
          }
        })

    }else{
        this.folder.showFolder =  true;
        this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: []})
        this.openAllConcepts();
        this.configureEvaluatedConcepts();
        this.loader.closeLoader(['change-concept-evaluated', 'folder-open-concept']);
    }

    this.getPatterns();
    folder.children.forEach((childFolder:any) => {
        if (childFolder.confidential) {
            this.setConfidential(childFolder);
        }
    })
  }

  setConfidential(folder: any) {
    this.apiService.get('folders/confidentialaccess', folder.folderId)
      .then((data:any)=>{
          folder.confidentialList = data;
      }, (errorData:any)=>{
          this.loader.closeError()
      });
  }

  configureEvaluatedConcepts(){
    let concepts: any = this.folder.folder.concepts;
    if(concepts && concepts.length){
        concepts.forEach((concept:any, index: any) => {
            this.getEvaluatedConcepts(concept, (index === concepts.length-1));
            if(concept.poll){
                this.checkExpirationDate(concept);
            }
        })
    }else{
        this.loader.closeLoader(['folder-on-init']);
    }
  }

  checkExpirationDate(concept:any){
    if(concept.pollExpirationDate){
       concept.onTime = moment(new Date()).valueOf() <= moment(concept.pollExpirationDate).valueOf()
    }
  }

  getPatterns(){
    if(!this.folder.configuredPatternList){
      this.configurePatternList();
    }
    this.configureConceptMenuList();
  }

  configurePatternList(){
    this.folder.patternList.weekDays.forEach((weekDay:any) => {
        weekDay.text = moment().weekday(weekDay.value).format('ddd');
    });

    this.folder.patternList.monthList.forEach((month:any) => {
        month.text = moment().month(month.value).format('MMMM');
    });

    this.folder.patternList.periodicityList.forEach((periodicity:any) => {
        periodicity.text = this.translate.instant(periodicity.text);
    });

    this.folder.patternList.numeralTimeList.forEach((numeralTime:any) => {
        numeralTime.text = this.translate.instant(numeralTime.text);
    });

    this.folder.patternList.weekDayList.forEach((weekDay:any) => {
        weekDay.text = weekDay.id < 10? moment().weekday(weekDay.value).format('dddd') : this.translate.instant(weekDay.text);
    });

    this.folder.patternList.suffixList.forEach((suffix:any) => {
        suffix.text = this.translate.instant(suffix.text);
    });
    this.folder.configuredPatternList = true;
  }

  configureConceptMenuList(){
    let concepts: any = this.folder.folder.concepts;
    concepts.forEach((concept:any) => {
      if(concept.repeatPattern && concept.repeatPattern.frecuencyId){
        concept.repeatPattern.newInstanceNameSuffix = this.extractSuffix(concept.repeatPattern.newInstanceNameSuffix);
        concept.repetitionActived = true;
        this.configureRepeatPattern(concept);
      }
    });

  }

  extractSuffix(suffix:any){
    for(let suffixItem of this.folder.patternList.suffixList){
        if(suffixItem.name === suffix){
            return suffixItem.listValueId;
        }
    }
  }

  configureRepeatPattern(concept:any){
    let pattern: any = concept.repeatPattern;
    let frecuency: any = pattern.frecuencyId;
    concept.repeatPatternTooltip = {
        frecuency: this.commonUtilsService.extractToArray(frecuency, this.folder.patternList.periodicityList, 'listValueId').text,
        startInterval: concept.repeatPattern.repeatStart
    }
    concept.repeatPatternTooltip.selectInterval = this.getSelectInterval(concept);
    concept.repeatPatternTooltip.finishInterval = this.extractFinishInterval(concept, concept.repeatPatternTooltip.selectInterval, pattern.repeatUntil, pattern.repeatTimes);
    this.setMomentCreation(concept, frecuency, pattern.repeatOption, pattern)
  }

  getSelectInterval(concept:any){
    if( concept.repeatPattern.repeatTimes !== null){
        return 'finish-after';
    }else if(concept.repeatPattern.repeatUntil !== null){
        return 'end-interval-day';
    }else{
        return 'no-end-date';
    }
  }

  extractFinishInterval(concept:any, selectInterval:string, repeatUntil:any, repeatTimes:any){
    switch(selectInterval){
        case 'finish-after':
            concept.repeatPattern.repeatTimes = repeatTimes;
            return repeatTimes +' '+this.translate.instant('repeat-pattern.repetitions');
        case 'end-interval-day':
            concept.repeatPattern.repeatUntil = repeatUntil;
            return typeof repeatUntil === 'string'? repeatUntil : this.dateConverterService.toIso(repeatUntil);
        default:
          return ''
    }
  }

  setMomentCreation(concept:any, frecuency:any, repeatOption:any, params:any){
    switch(frecuency){
        case 1:
            if(repeatOption === 1){
              concept.repeatPattern.repeatParam1 = params.repeatParam1;
              concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('add-alert.every') +" "+ params.repeatParam1 +" "+ this.translate.instant('repeat-pattern.days');
            }else{
              concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.all-days-week');
            }
            break;
        case 2:
            let weekSelect = this.extractWeekSelect(params.repeatParam2);
            concept.repeatPattern.repeatParam1 = params.repeatParam1;
            concept.repeatPattern.repeatParam2 = params.repeatParam2;
            concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.repeat-every')+" "+ params.repeatParam1 +" "+
                this.translate.instant('repeat-pattern.weeks-in-days') +" "+
                weekSelect;
            break;
        case 3:
            if(repeatOption === 1){
                concept.repeatPattern.repeatParam1 = params.repeatParam1;
                concept.repeatPattern.repeatParam2 = params.repeatParam2;
                concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.the-day') +" "+
                    params.repeatParam1 +" "+
                    this.translate.instant('repeat-pattern.kind') +" "+
                    params.repeatParam2 +" "+
                    this.translate.instant('repeat-pattern.month');
            }else{
                concept.repeatPattern.repeatParam1 = params.repeatParam1
                concept.repeatPattern.repeatParam2 = params.repeatParam2
                concept.repeatPattern.repeatParam3 = params.repeatParam3
                concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.the') +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam1, this.folder.patternList.numeralTimeList, 'id').text +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam2, this.folder.patternList.weekDayList, 'id').text +" "+
                    this.translate.instant('repeat-pattern.kind') +" "+ params.repeatParam3 +" "+ this.translate.instant('repeat-pattern.month');
            }
            break;
        default:
            if(repeatOption === 1){
                concept.repeatPattern.repeatParam1 = params.repeatParam1;
                concept.repeatPattern.repeatParam2 = params.repeatParam2;
                concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.the') +" "+
                    params.repeatParam1 +" "+
                    this.translate.instant('repeat-pattern.kind') +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam2, this.folder.patternList.monthList, 'id').text;
            }else{
                concept.repeatPattern.repeatParam1 = params.repeatParam1
                concept.repeatPattern.repeatParam2 = params.repeatParam2
                concept.repeatPattern.repeatParam3 = params.repeatParam3
                concept.repeatPatternTooltip.momentOfCreation = this.translate.instant('repeat-pattern.the') +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam1, this.folder.patternList.numeralTimeList, 'id').text +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam2, this.folder.patternList.weekDayList, 'id').text +" "+
                    this.translate.instant('repeat-pattern.kind') +" "+
                    this.commonUtilsService.extractToArray(params.repeatParam3, this.folder.patternList.monthList, 'id').text;
            }
            break;
    }
  }

  extractWeekSelect(weekdayMaskEnum:any){
    let weekDays = '';
    this.folder.patternList.weekDays.forEach((weekdays:any) => {
        if((weekdays.bit && weekdayMaskEnum) === weekdays.bit){
            weekDays += moment().weekday(weekdays.value).format('dddd')+', '
        }
    })
    return weekDays.substring(0 , weekDays.length - 2);
  }

  openFolderMenu(folder:any){
    folder.showMenu = !folder.showMenu;
    folder.menuList = folder.showMenu? this.expandedMenuService.menuFolder(this.folder.folderMenuList, folder, this.folder.evaluationInfo, parseInt(this.folderId)) : [];
  }

  openConceptMenu(concept:any){
    concept.showMenu = !concept.showMenu;
    if (concept.showMenu) {
      concept.channelView = this.folder.folder.channelView;
      concept.menuList = this.expandedMenuService.menuConcept(this.folder.conceptMenuList, concept, this.folder.evaluationInfo);
    }else{
      concept.menuList = [];
    }
  }

  newEvaluatedConcept(concept:any) {
    if(concept.singleInstance && concept.amountEvaluatedConcept > 0){
      this.modalService.adviceModal(this.translate.instant('folder.create-error'), 'error:accept:warning')
    }else if (!this.folder.preview) {
        let newItem = {
            title: this.translate.instant('rename-modal.name-concept-new'),
            type: 'concept',
            titleConcept: concept.title,
            conceptId: concept.conceptId,
            conceptObjectId: concept.conceptObjectId,
            listFolders: concept.allEvaluated,
            icon:'lf-icon-compliance',
            namedKey: concept.namedKey,
            rename: false
        }

        this.registerEvent('create instance', concept)

        if (concept.reportId) {
            newItem.title = this.translate.instant('rename-modal.name-report');
            newItem.type = 'report';
        }
        this.modalService.renameModal(newItem)
          .subscribe((result:any) => {
              if(result.result === 'ok'){
                  this.loader.openLoader('folder-open-concept')
                  this.newConcept(concept, result.name)
              }
          })

    } else {
      this.broadcastService.broadcast('openEvaluatedConcept', {
        conceptInfo: {
            evaluatedConcept: {
                conceptObjectId: 'view'
            },
            concept: concept
        }
      })
    }
  }


  registerEvent(eventType:any, concept:any){
    let resgisterItem ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':eventType,
      'Concept Name':sessionStorage.getItem('breadcrumbConcept') + concept.title,
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem);
  }

  newConcept(concept:any, name:string){
    if (this.skin !== 'icam-red' || concept.namedKey !== 'Usuario') {
      this.apiService.add('conceptobjects/'+concept.conceptId, {title:name})
        .then((data:any)=>{
            concept.toShow='evaluated';
            concept.amountEvaluatedConcept++;
            this.evaluateConcept(concept, data);
            this.evaluatedConceptFactory.changeState(!this.evaluatedConceptFactory.isNew)
        }, (errorData:any)=>{
            this.loader.closeError()
        });
    }else{
      this.newUserConcept(concept, name);
    }
  }

  newUserConcept(concept:any, name:string){
    this.apiService.add('icam/conceptobjects/usuario', { idUsuario: name })
      .then((data:any)=>{
          concept.toShow = 'evaluated';
          concept.amountEvaluatedConcept++;
          this.evaluateConcept(concept, data.postConceptObject);
          this.evaluatedConceptFactory.changeState(!this.evaluatedConceptFactory.isNew)
      }, (errorData:any)=>{
          if (errorData.status === 404 && errorData.description == 'ExtUsuario does not exist') {
              this.modalService.adviceModal('No existe ningún usuario con ese id en ICAM.', 'error:accept:warning')
          } else if (errorData.status === 409 && errorData.description == 'User already exists') {
              this.modalService.adviceModal('Ya existe un usuario con ese id en Centinela.', 'error:accept:warning')
          } else {
              this.modalService.adviceModal('Se ha producido un error inesperado al dar de alta el usuario.', 'error:accept:warning')
              console.error(errorData.description);
          }
          this.loader.closeError();
      });
  }

  evaluateConcept(concept:any, evaluateConcept:any){
    this.broadcastService.broadcast('openEvaluatedConcept', {
      conceptInfo: {
        evaluatedConcept:evaluateConcept,
        concept: concept
      }
    })
  }

  showElements(toShow:any, concept:any){
    concept.showArchived = !concept.showArchived;
    concept.toShow = toShow;
  }

  updateConcept(concept:any){
    this.loader.closeLoader(['folder-on-init']);
    concept.modify = false;
    concept.amountEvaluatedConcept = concept.list.length;
    this.getEvaluatedConcepts(concept, true);
  }

  folderAction(e:any, option:any, item:any, sinLoader:boolean = false) {
    this.closeDropdown('folder-menulist-'+item.folderId);
    this.launchAction(e, option, item, sinLoader);
  }

  launchAction(e:any, option:any, item:any, sinLoader:boolean = false) {
    if(e){
        e.stopPropagation();
    }
    item.openOption = false;
    if(option === 'edit-concept-folder'){
        item.concepts[0].folderId = item.folderId;
        this.conceptAction(option, item.concepts[0]);
    }else{
        if(!sinLoader){
            this.loader.openLoader('folder-on-init')
        }
        this.broadcastService.broadcast('launchAction', {
          action:{
            type: option,
            folder: item
          }
        })
    }
  }

  conceptActionOn(e:any){
    this.conceptAction(e.action, e.concept);
  }

  conceptMenuListAction(action:string, concept:any) {
    this.closeDropdown('concept-menulist-'+concept.conceptId);
    this.conceptAction(action, concept);
  }

  conceptAction(action:string, concept:any) {
    switch(action){
        case 'delete':
            this.confirmDeleteConcept(concept);
            break;
        case 'edit':
            this.editConceptConfirm(concept);
            break;
        case 'edit-concept-folder':
            this.editConceptInstanciable(concept);
            break;
        case 'publishConcept':
            this.registerEvent('setup public link', concept)
            concept.poll? this.getPublicPollConfiguration(concept) : this.getPublicConfiguration(concept);
            break;
        case 'shareConcept':
            this.registerEvent('open instance', concept)
            this.documentShare(concept, 'concept', true, null)
            break;
        case 'sharePoll':
            this.documentShare(concept, 'sharePoll', true, null)
            break;
        case 'exportExcel':
            this.broadcastService.broadcast('exportInstancesList'+concept.conceptId)
            break;
        case 'resetPoll':
            this.resetPoll(concept);
            break;
        case 'templates':
            this.goToTemplates(concept);
            break;
        case 'addProcess':
            let text: string = concept.isFront? 'process' : 'process-back';
            this.addOrEditProcess(concept, text);
            break;
        case 'manageProcess':
            this.showProcessOpsModal(concept);
            break;
        case 'expirationPeriod':
            this.establishExpirationPeriod(concept);
            break;
        case 'sendReminder':
            this.sendReminderPoll(concept);
            break;
        case 'publicLinkPoll':
            this.getPublicPollConfiguration(concept);
            break
        case 'migration':
            this.confirmMigration(concept);
            break;

        case 'duplicate':
            this.registerEvent(action+' instance', concept)
            this.launchAction(null, action, concept, true)
            break;
        default:
            this.launchAction(null, action, concept, true)
            break;
    }
  }

  confirmDeleteConcept(concept:any){
      this.modalService.adviceModal(this.translate.instant('folder.delete-all')+concept.title+this.translate.instant('folder.question-continue'), 'info:question:question')
        .subscribe((result:any) => {
            if(result.result === 'ok'){
              this.loader.openLoader('folder-delete-concept');
              this.deleteConcept(concept);
            }
        });
  }

  documentShare(concept: any, type:string, addContacts:any, document:any){
      let configuration = {
        type: type,
        addContacts: addContacts,
        document: document
      }
      this.modalService.documentShare(configuration)
        .subscribe((result:any) => {
            if(result.result === 'ok'){
                let userOwner = 0;
                if(type !== 'concept'){
                    userOwner = result.answerSurvey ? 1 : 0;
                }
                this.shareConceptOrPoll(concept, result.share, userOwner);
            }
        });
  }

  shareConceptOrPoll(concept:any, share:any, userOwner: number) {
      this.loader.openLoader('folder-concept-shared');
      this.apiService.add('conceptobjects/shareconcept/' + concept.conceptId + '/'+ userOwner, share)
          .then((data:any)=>{
              this.getEvaluatedConcepts(concept, false);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning')

          });
  }

  editConceptConfirm(concept:any){
      if(concept.amountEvaluatedConcept && concept.isFront && !concept.poll){
          this.modalService.adviceModal(this.translate.instant('folder.concept') + concept.title + this.translate.instant('folder.want-continue'), 'info:question:question')
            .subscribe((result:any) => {
                if(result.result === 'ok'){
                    this.editConceptInstanciable(concept)
                }
            });
      }else{
          this.editConceptInstanciable(concept)
      }
  }

  editConceptInstanciable(concept:any){
      let go = !concept.isFront? 'form-process' : 'form';
      this.loader.openLoader('folder-edit-concept');
      this.broadcastService.broadcast('breadCrumbEdit', {concept: concept, to: go});
  }

  getPublicPollConfiguration(poll:any){
    this.modalService.publicLinkPoll(poll, this.folder.evaluationInfo).subscribe((result:any) => {
          if(result.result === 'ok'){
            if(result.type !=='noUpdate'){
              let text:string = result.type === 'update'?'folder.save-configuration-poll':'folder.save-global-poll';
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round')
                .subscribe(() => {
                    this.openTooltip('tooltip-link-'+poll.conceptId);
                });
            }
          }else if(result.close !== ''){
            let textError: string='';
            let type: string='';
            if(result.close === 'unpublish'){
              textError= 'folder.unshared-link-poll';
              type= 'info:accept:unlink';
            }else if(result.close === 'closeError'){
              textError= 'common.unexpected-error';
              type= 'error:accept:warning';
            }
            this.modalService.adviceModal(this.translate.instant(textError), type)
          }
      })
  }

  getPublicConfiguration(concept:any){
      concept.channelView = this.folder.folder.channelView;
      this.modalService.publicLink(concept, this.folder.evaluationInfo).subscribe((result:any) => {
          if(result.result === 'ok'){
            if(this.folder.folder.channelView && this.channelConfiguration){
              this.channelConfiguration.setConfiguration();
            }
            this.openTooltip('tooltip-link-'+concept.conceptId);
          }else{
            if(result.close === 'unpublish'){
              this.modalService.adviceModal(this.translate.instant('folder.uncreate-shared-link'), 'info:accept:unlink')
            }else if(result.close === 'closeError'){
                this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning')
            }
          }

        })
  }

  resetPoll(concept:any){
    this.modalService.adviceModal(this.translate.instant('folder.question-reset-poll'), 'info:reset:question')
      .subscribe((result:any) => {
            if(result.result !== 'cancel'){
              this.loader.openLoader('folder-reset-poll');
              this.callResetPoll(concept);
            }
        });
  }

  callResetPoll(concept:any){
    this.apiService.delete('concepts/' + this.evaluationId, concept.conceptId, 'resetpoll')
        .then((data:any)=>{
            concept.list = [];
            concept.amountEvaluatedConcept = 0;
            concept.publicShared = false;
            concept.pollExpirationDate = null;
            concept.selectInterval = 'no-end-date';
            this.loader.closeLoader(['folder-reset-poll']);
            this.modalService.adviceModal(this.translate.instant('folder.poll-reset-ok'), 'info:accept:check-round')
        }, () => {
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('folder.error-delete-all-polls'), 'error:accept:warning' );
        })
  }

  goToTemplates(concept:any){
    this.loader.openLoader('documentation-list-on-init')
    let template = concept.isFront? 'template-front': 'template';
    this.broadcastService.broadcast('breadCrumbEdit', {concept: concept, to: template})
  }

  addOrEditProcess(concept:any, from:string){
    this.loader.openLoader('folder-edit-process')
    this.broadcastService.broadcast('breadCrumbEdit', {concept: concept, to: from})
  }

  showProcessOpsModal(concept:any) {
    concept.channelView = this.folder.folder.channelView;
    this.modalService.processOpsModal(this.folder.evaluationInfo, concept, 'folder').subscribe((result:any)=>{
        if (result && result.hasOps !== undefined) {
            concept.hasProcessOps = result.hasOps;
        }
      })
  }

  establishExpirationPeriod(concept:any){
    this.modalService.expirationPeriod(concept).subscribe((result: any)=>{
      if(result.result ==='ok'){
        concept.pollExpirationDate = result.interval.date;
        concept.selectInterval = result.interval.selectInterval;
        concept.pollBeginDate = result.interval.startInterval;
        this.checkExpirationDate(concept);
        this.updateExpirationDate(concept);
      }
    })
  }

  updateExpirationDate(concept:any){
    this.apiService.patch('concepts/'+this.evaluationId,concept.conceptId, concept)
      .then((data:any)=>{
        this.loader.closeLoader(['folder-rename-modal']);
    }, (error:any)=>{
        this.loader.closeError();
    })
  }

  sendReminderPoll(concept:any){
    this.loader.openLoader('folder-send-reminder')
    this.apiService.add('polls/pollreminder/'+concept.conceptId, {})
        .then((data:any)=>{
          this.loader.closeLoader(['folder-send-reminder']);
          this.modalService.adviceModal(this.translate.instant('folder.poll-reminder'), 'info:accept:check-round')
        }, (errorData:any)=>{})
  }

  confirmMigration(concept:any){
    this.apiService.get('concepts/canimportexportdata', concept.conceptId)
        .then((data:any)=>{
            data? this.openMigrationModal(concept) : this.modalService.adviceModal(this.translate.instant('folder.concept')+' '+concept.title+' '+this.translate.instant('folder.no-migration'), 'error:accept:warning')
        }, (errorData:any)=>{})
  }

  openMigrationModal(concept:any){
    this.modalService.migrationData()
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          result.optionSelect === 'import' ? this.openImportModal(concept) : this.exportData(concept, result.optionSelect);
        }
      })
  }

  openImportModal(concept:any){
    this.modalService.importModal('concepts', concept)
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('folder-upload-concepts');
          this.uploadConcept(concept, result);
        }
      })
  }

  uploadConcept(concept:any, result:any){
    let importConcepts = result.validateFields.concat(result.incompleteFields)
    this.configureImportConcepts(importConcepts);
    this.apiService.add('concepts/import-csv/'+concept.conceptId, importConcepts)
      .then((data:any)=>{
          let conceptList = cloneDeep(concept.list);
          concept.list = [...conceptList, ...data]
          this.loader.closeLoader(['folder-upload-concepts']);
          concept.modify = true;
          this.modalService.adviceModal(this.configureModalText(result), 'info:accept:warning', 'concepts', result.errorFields)
      }, (errorData:any)=>{
          this.loader.closeError();
      })

  }

  configureImportConcepts(concepts:any){
    concepts.forEach((concept: any) => {
      delete concept.errorList;
      delete concept.type;
      delete concept.$$hashKey;
      delete concept.nameComplete;
    });
  }

  configureModalText(result:any){
    let text = '';
    if(result.incompleteFields.length){
        text += this.translate.instant('users-admin.import.import-success-ok')+' '+(result.incompleteFields.length + result.validateFields.length)+' '+this.translate.instant('users-admin.import.concept-instances')+', '+result.incompleteFields.length+' '+this.translate.instant('users-admin.import.draf-mode');
        text += result.errorFields.length ? this.translate.instant('users-admin.import.error-instances-concept'): '';
    }else{
        text += this.translate.instant('users-admin.import.import-success-ok')+''+result.validateFields.length+' '+this.translate.instant('users-admin.import.concept-instances');
        text += result.errorFields.length? this.translate.instant('users-admin.import.error-instances-concept') : ' ' + this.translate.instant('users-admin.import.import-all-ok')
    }
    return text;
  }

  exportData(concept:any, type:string=''){
    let num = type ==='export' ? 1 : 0;
    this.loader.openLoader('folder-download-template')
    this.apiService.getBlob('concepts/export-csv', concept.conceptId+'/'+num)
      .then((data:any)=>{
        this.downloadService.triggerDownload(data.blob, data.fileName);
        this.loader.closeLoader(['folder-download-template']);
        let text =  type ==='export' ?'data':'template';
        this.modalService.adviceModal(this.translate.instant('folder.export-'+text), 'info:accept:check-round')
    }, (errorData:any)=>{
        this.loader.closeError();
    })
  }

  configureTaskRepeat(result:any, concept:any, type:any){
    concept.repetitionActived = true;
    if(type === 'patchAsPost'){
      concept.repeatPattern.frecuencyId = result.frecuency.listValueId;
      concept.repeatPattern.repeatStart = this.dateConverterService.toIso(result.interval.startInterval);
      concept.repeatPattern.repeatOption = result.repetition.repeatOption;
      concept.repeatPattern.newInstanceName = result.newInstanceName;
      concept.repeatPattern.newInstanceNameSuffix = result.newInstanceNameSuffix;
      concept.repeatPattern.repeatTimes= result.interval.repeatTimes
    }else{
        concept.repeatPattern = {
            frecuencyId: result.frecuency.listValueId,
            repeatStart: this.dateConverterService.toIso(result.interval.startInterval),
            repeatOption: result.repetition.repeatOption,
            repeatTimes: result.interval.repeatTimes,
            repeatUntil: null,
            repeatParam1: null,
            repeatParam2: null,
            repeatParam3: null,
            newInstanceName: result.newInstanceName,
            newInstanceNameSuffix: result.newInstanceNameSuffix
        }
    }

    concept.repeatPatternTooltip = {
        frecuency: result.frecuency.text,
        startInterval: result.interval.startInterval,
        finishInterval: this.extractFinishInterval(concept, result.interval.selectInterval, result.interval.finishIntervalDate, result.interval.repeatTimes),
        selectInterval: result.interval.selectInterval
    }
    this.setMomentCreation(concept, concept.repeatPattern.frecuencyId, concept.repeatPattern.repeatOption, result.repetition.repeatParams)
  }

  getNextRepeat(concept:any){
    this.apiService.add('repeatpattern/next-repeat', concept.repeatPattern)
        .then((data:any)=>{
            concept.repeatPattern.nextRepeat = data.nextRepeat;
        }, (errorData:any)=>{})
  }
  validateInterval(type:any, concept:any){
    let newInstance: string = concept.repeatPattern.newInstanceName;
    let nameSuffix: string = concept.repeatPattern.newInstanceNameSuffix
    this.apiService.get('repeatpattern/concept/'+concept.conceptId, 'check-existing-instances?newInstanceName='+newInstance+'&newInstanceNameSuffix='+nameSuffix)
        .then((data:any)=>{
          data.titleIdx !== null ? this.adviseHasPattern(type, concept) : this.savePattern(type, concept);
        }, (errorData:any)=>{})
  }

  adviseHasPattern(type:string, concept:any){
    if(type === 'patchAsPost'){
        this.savePattern(type, concept);
    }else{
      this.modalService.adviceModal(this.translate.instant('folder.advice-has-pattern'), 'info:question:question')
        .subscribe((result:any) => {
            if(result.result === 'ok'){
              this.savePattern(type, concept);
            }
        });
    }
  }

  savePattern(type:string, concept:any){
    this.loader.openLoader('folder-save-pattern')
    if(type ==='patchAsPost'){
      this.apiService.patchAsPost('repeatpattern/concept/'+concept.conceptId, concept.repeatPattern).then((data:any)=>{
        this.configureSavePatternRequest(concept, data);
      }, (errorData:any)=>{
        this.loader.closeError();
      })
    }else{
      this.apiService.add('repeatpattern/concept/'+ concept.conceptId, concept.repeatPattern)
        .then((data:any)=>{
          this.configureSavePatternRequest(concept, data);
        }, (errorData:any)=>{

        })
    }
  }

  configureSavePatternRequest(concept: any, data:any){
    this.loader.closeLoader(['folder-save-pattern']);
    if (data && data !== 'NotModified'){
        concept.repeatPattern.repeatPatternId =  data.repeatPatternId;
    }
    this.openTooltip('periodic-instance-'+concept.conceptId);
  }

  openTooltip(id:any, timeClose: any=3000){
    let tooltip: any = this.tooltips.find((x:any)=>{
      return x._elementRef.nativeElement.id == id;
    });
    tooltip.open();
    setTimeout(function(){
        tooltip.close();
    },timeClose);
  }

  deleteConceptPattern(concept:any){
    this.modalService.adviceModal(this.translate.instant('folder.delete-concept-pattern'), 'info:question:question')
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('folder-delete-pattern')
          this.deletePattern(concept)
        }
      });
  }

  deletePattern(concept:any){
      this.apiService.delete('repeatpattern/concept', concept.conceptId)
          .then((data:any)=>{
              concept.repeatPattern = null;
              this.loader.closeLoader(['folder-delete-pattern']);
              this.modalService.adviceModal(this.translate.instant('folder.delete-pattern'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  getTooltipText(concept:any){
    if(concept.publicShared && concept.poll){
        return this.translate.instant('folder.published-poll');
    }else if(concept.publicShared && !concept.poll){
        return this.translate.instant('folder.published-concept');
    }else{
        return this.translate.instant('common.share-link');
    }
  }

  deleteArchived(concept:any){
    this.modalService.adviceModal(this.translate.instant('concept-list.delete-all-archive'), 'info:question:question')
      .subscribe((result:any) => {
          if(result.result === 'ok'){
            this.loader.openLoader('folder-delete-archived')
            this.deleteAllArchived(concept);
          }
      });
  }

  deleteAllArchived(concept:any){
    //this.apiService.delete('conceptobjects/archived', concept.conceptId)
    // 26/06/2024. Oscar. Se unifica la llamada para borrer conceptobjects.
    // Si son archivados se pasa un 1 en el parametro archived, si son todos no se pasa ese parámetro
    this.apiService.delete('conceptobjects/byconceptid', concept.conceptId, 1)
        .then((data:any)=>{
            this.folder.archivedAmount = 0;
            let primaryText:string = concept.archived.length > 1? this.translate.instant('concept-list.delete-archive-concepts') : this.translate.instant('concept-list.delete-archive-concept');
            concept.archived = [];
            concept.toShow = 'evaluated';
            concept.archivedAmount = 0;
            this.loader.closeLoader(['folder-delete-archived']);
            this.modalService.adviceModal(this.translate.instant(primaryText), 'info:accept:check-round');

        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
        })
  }

  extractTooltip(concept:any){
    if(Boolean(concept.repeatPattern !== null) && concept.repeatPatternTooltip){
        let html ='<ul class="folder__pattern-tooltip-block">';
        html+= '<li class="folder__pattern-tooltip-list"><span>'+this.translate.instant('repeat-pattern.automatic-instance')+'</span>: <span>'+ concept.repeatPatternTooltip.momentOfCreation+'</span></li>';
        html+= '<li class="folder__pattern-tooltip-list"><span>'+this.translate.instant('repeat-pattern.interval')+'</span>: <span>'+this.establishInterval(concept)+'</span></li>';
        html+= '<li class="folder__pattern-tooltip-list"><span>'+this.translate.instant('repeat-pattern.serie')+'</span>: <span>'+concept.repeatPattern.newInstanceName+' '+this.extractIdSeries(concept.repeatPattern.newInstanceNameSuffix)+'</span></li>';
        html+= '<li class="folder__pattern-tooltip-list folder__pattern-tooltip-list--next"><span>'+this.translate.instant('repeat-pattern.creation-next-instance')+'</span>: <span>'+this.dateConverterService.convertDate(concept.repeatPattern.nextRepeat, 'without-time')+'</span></li>';
    return html;
    }else {
        return '';
    }
  }

  establishInterval(concept:any){
    if(concept.repeatPatternTooltip.selectInterval === 'no-end-date'){
        return this.translate.instant('repeat-pattern.no-end-date');
    }else{
        let interval:any = this.dateConverterService.convertDate(concept.repeatPatternTooltip.startInterval, 'without-time') +' - ';
        if(concept.repeatPatternTooltip.selectInterval === 'finish-after'){
          return interval + concept.repeatPatternTooltip.finishInterval;
        }else{
          return interval + this.dateConverterService.convertDate(concept.repeatPatternTooltip.finishInterval, 'without-time');
        }

    }
  }

  extractIdSeries(suffix:any){
    switch(suffix){
        case 1:
            return '[001]';
        case 2:
            return '[01]';
        case 3:
            return '[1]';
        case 4:
            return '[a, b, c...]';
        case 5:
            return '['+this.translate.instant('input-date.day')+'/'+this.translate.instant('input-date.month')+'/'+this.translate.instant('input-date.years')+']';
        default:
            return '[001 - '+this.translate.instant('input-date.day')+'/'+this.translate.instant('input-date.month')+'/'+this.translate.instant('input-date.years')+']';
    }
  }

  togglePublicState(concept:any) {
    this.loader.openLoader('folder-public-shared')
    this.apiService.patch('concepts/' +this.evaluationId + '/' + concept.conceptId + '/setpublic/', !concept.publicShared)
        .then((data:any)=>{
            concept.publicShared = !concept.publicShared;
            concept.publicUrl = data;
            this.loader.closeLoader(['folder-public-shared']);
            this.openTooltip('icam-header-button-'+concept.conceptId, 1000);
            concept.openTooltipPublished = true;
            setTimeout(function () {
                concept.openTooltipPublished = false;
            }, 1000);
        }, ()=>{
            this.loader.closeError();
        })
  }

  // archivedConcepts(concept:any){
  //   return  concept.archived.length > 1?this.translate.instant('concept-list.there-are')+' '+concept.archived.length+' '+this.translate.instant("concept-list.concepts-archives") : this.translate.instant('concept-list.there-is')+' '+concept.archived.length+' '+this.translate.instant("concept-list.concept-archive");
  // }

  deleteConcept(concept:any){
    this.apiService.delete('concepts/'+this.evaluationId, concept.conceptId)
    .then((data:any)=>{
        concept.folderId = !concept.folderId?parseInt(this.folderId) : concept.folderId;
        this.broadcastService.broadcast('changeConceptFromEvaluation', {concept: concept, action: 'deleteElement', type: 'conceptId'})
        this.folder.folder.concepts = this.commonUtilsService.deleteElement( concept, this.folder.folder.concepts, 'conceptId')
        this.loader.closeLoader(['folder-delete-concept']);
        this.modalService.adviceModal(this.translate.instant('folder.concept') +"'"+ concept.title +"'"+ this.translate.instant('folder.concept-remove'), 'info:accept:check-round');

    }, (errorData:any)=>{
        this.loader.closeError();
        let text:string = errorData.status === 403?'concept-list.delete-error-permissions' : 'folder.error-delete-concept';
        this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
    })
  }

  reloadAll(concept:any){
    this.broadcastService.broadcast('reloadConceptList', {concept: concept})
  }

  cleanFilters(){
    this.broadcastService.broadcast('cleanFiltersAction', {id: this.folder.idFilter})
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  closeCreateConcept(concept:any, folder:any){
      if(concept !== null){
          // folder.evaluated = false;
          folder.concepts.push(concept);
          this.broadcastService.broadcast('breadCrumbEdit', {concept: concept});
      }
      this.folder.showCreateConcept = false;
  }

  launchActionOn(e:any, concept: any){
    switch(e.type){
      case 'showElements':
        this.showElements(e.toShow, concept);
        break;
      case 'changeToShow':
        concept.toShow = e.toShow;
        break;
      case 'getEvaluatedConcepts':
        this.getEvaluatedConcepts(concept);
        break;
      case 'updateConcept':
        this.updateConcept(concept);
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
