
import { Component, EventEmitter, OnChanges, Input, Output, ViewChild} from '@angular/core';

import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

import { DateConverterService } from '@shared/services/date-converter/date-converter.service';

import moment from 'moment';

@Component({
  selector: 'calendar-datepicker',
  templateUrl: './calendar-datepicker.component.html'
})
export class CalendarDatepickerComponent implements OnChanges{
  @ViewChild(NgbDatepicker)
  datePicker: NgbDatepicker;
  skin: string = this.globalCfg.skin === 'uk-steel-blue'?'steel-blue':this.globalCfg.skin;
  isLanding: boolean = this.customLanding.isLanding();
  current: any = new Date();
  milisecondsDay = 24 * 60 * 60 * 1000;
  tomorrow: any = new Date(this.current.getTime() + this.milisecondsDay);
  calendarDatePicker: any = {
    convertDateType: this.isLanding?'day-calendar-min':'day-calendar',
    days: [1, 2, 3, 4, 5, 6, 7],
    showMonths: false,
    selectedMonth: this.current.getMonth() + 1,
    selectedYear: this.current.getFullYear(),
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory,
    private dateConverter: DateConverterService
  ) { }

  @Input() selectedDate: any = null;
  @Input() type: string = '';
  @Input() openDatePicker: boolean = false;
  @Input() dateLockType: number = 0;
  @Output() selectDatepicker = new EventEmitter();


  ngOnChanges(): void {
    if(this.openDatePicker){
      if(this.selectedDate){
        this.configureSelectedDate(this.selectedDate);
        setTimeout(()=>{
          this.datePicker.navigateTo(this.calendarDatePicker.selectedDate);
        })
      }
      this.calendarDatePicker.openDatePicker = true;
    }else{
      delete this.calendarDatePicker.selectedDate;
      this.calendarDatePicker.openDatePicker = false;
      this.calendarDatePicker.showMonths = false;
      this.calendarDatePicker.selectedMonth = this.current.getMonth() + 1;
      this.calendarDatePicker.selectedYear = this.current.getFullYear();
      setTimeout(()=>{
        this.datePicker.navigateTo({year: this.calendarDatePicker.selectedYear, month:this.calendarDatePicker.selectedMonth});
      })
    }
  }

  configureSelectedDate(previousSelectedDate: any){
    let selectedDate = isNaN(Date.parse(previousSelectedDate)) || typeof previousSelectedDate === 'string'?this.dateConverter.toDate(previousSelectedDate):previousSelectedDate;
    this.calendarDatePicker.selectedDate = {
      year: selectedDate.getFullYear(),
      month: selectedDate.getMonth() + 1,
      day: selectedDate.getDate()
    };
  }

  isDisabled(dt:any) {
      let todayDate = moment().format('MM-DD-YYYY');
      let selectedDate = dt.month+'-'+dt.day+'-'+dt.year;
      dt.disabled = false;
      switch (this.dateLockType) {
          case 1:
              dt.disabled = moment(selectedDate).isBefore(todayDate);
              break;
          case 2:
              dt.disabled = (moment(selectedDate).isBefore(todayDate) || (moment(selectedDate).isSame(todayDate)));
              break;
          case 3:
              dt.disabled = moment(selectedDate).isAfter(todayDate);
              break;
          case 4:
              dt.disabled = (moment(selectedDate).isAfter(todayDate) || (moment(selectedDate).isSame(todayDate)));
              break;
          default:
              dt.disabled = false;
              break;
      }
      return dt.disabled;
  }

  isSelectedDate(dt:any){
    if(this.calendarDatePicker.selectedDate){
      let todayDate: any = new Date(this.calendarDatePicker.selectedDate.year, this.calendarDatePicker.selectedDate.month-1, this.calendarDatePicker.selectedDate.day).setHours(0,0,0,0);
      let selectedDate: any = new Date(dt.year, dt.month-1, dt.day).setHours(0,0,0,0);
      return selectedDate === todayDate;
    }else{
      return false;
    }
  }

  changeDate(e: any){
    if(!e.disabled){
      let newDate = new Date(this.calendarDatePicker.selectedDate.year, this.calendarDatePicker.selectedDate.month-1, this.calendarDatePicker.selectedDate.day)
      this.selectDatepicker.emit({ newValue: newDate });
    }else if(this.selectedDate){
      this.configureSelectedDate(this.selectedDate);
    }else{
      delete this.calendarDatePicker.selectedDate;
    }
  }

  navigate(number: number){
    const { state, calendar } = this.datePicker;
		this.datePicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  navigateSelection(date: any){
    this.calendarDatePicker.showMonths = false;
    this.calendarDatePicker.selectedMonth = date.month;
    this.calendarDatePicker.selectedYear = date.year;
    this.datePicker.navigateTo(date);
  }
}

